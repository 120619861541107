import CommonAPIService from './CommonHandler';

export class UserGroupsAPIService {
  constructor() {
    this.CommonAPIService = new CommonAPIService();
  }
  create(usergroup, router) {
    const url = '/api/user_groups';
    return this.CommonAPIService.postCall(url, usergroup, router);
  }
  delete(id, router) {
    const url = `/api/user_groups/${id}`;
    return this.CommonAPIService.deleteCall(url, null, router);
  }
  update(id, usergroup, router) {
    const url = `/api/user_groups/${id}`;
    return this.CommonAPIService.putCall(url, usergroup, router);
  }

  addUsersToUsergroup(user_group_id, users, router) {
    const url = `/api/user_groups/${user_group_id}/users`;
    return this.CommonAPIService.postCall(url, users, router);
  }

  get(router) {
    const url = '/api/user_groups';
    return this.CommonAPIService.getCall(url, null, router);
  }

  getWithUsers(router) {
    const url = '/api/user_groups/users';
    return this.CommonAPIService.getCall(url, null, router);
  }
}

export default UserGroupsAPIService;
