<template>
  <div>
    <card v-if="spinning">
      <div class="row">
        <div class="col-sm-12 text-center">
          <b-spinner variant="primary" label="Spinning"></b-spinner>
        </div>
      </div>
    </card>
    <card>
      <h3 class="header-title">The purpose of this page is to create checks that are used to watch
        the server.</h3>
      <h4 class="header-title">Select the checks below or add new checks:</h4>
    </card>
    <card>
      <div slot="header">
        <h4 class="card-title">Checks
          <el-tooltip content="Add New Check">
            <el-button type="text" @click="openModal('create')">
              <i class="fa-solid fa-circle-plus"></i>
            </el-button>
          </el-tooltip>
        </h4>
      </div>
      <div class="row pb-5">
        <div class="col-sm-6">
          <el-input
            v-model="searchQuery"
            placeholder="Search by first name, last name, username, or email"
            clearable
            @clear="searchQuery = ''">
            <span slot="prepend">Search</span>
          </el-input>
        </div>
        <el-button @click="searchQuery = ''">Clear</el-button>
      </div>
      <el-table :data="paginatedChecks" border style="width: 100%">
        <el-table-column width="250" label="Command" align="center">
          <template slot-scope="scope">
            <el-tooltip :content="scope.row.name" placement="top">
              <fg-input placeholder="Name" v-model="scope.row.name"></fg-input>
            </el-tooltip>
          </template>
        </el-table-column>
        <el-table-column label="Description" width="250" align="center">
          <template slot-scope="scope">
            <el-tooltip :content="scope.row.description" placement="top">
              <fg-input placeholder="Description" v-model="scope.row.description"></fg-input>
            </el-tooltip>
          </template>
        </el-table-column>
        <el-table-column label="Arguments" width="131" align="center">
          <template slot-scope="scope">
            <el-button @click="setArgs(scope.row)">Arguments</el-button>
          </template>
        </el-table-column>
        <el-table-column label="Plugins" width="175" align="center">
          <template slot-scope="scope">
            <el-select @click="selectPlugin" v-model="scope.row.plugins" filterable
                       placeholder="Plugins" multiple>
              <el-option v-for="plugin in plugins" :key="plugin.id" :label="plugin.name"
                         :value="plugin.id">{{
                  plugin.name
                }}
              </el-option>
            </el-select>
          </template>
        </el-table-column>
        <el-table-column label="AI Command" width="175" align="center">
          <template slot-scope="scope">
            <div class="row">
              <div class="col-sm-12 center-checkbox">
                Autorun
                <el-tooltip content="Autorun Fix Command" placement="top">
                  <el-checkbox v-model="scope.row.autorun"></el-checkbox>
                </el-tooltip>
              </div>
            </div>
            <div class="row">
              <div class="col-sm-12 center-checkbox">
                <el-tooltip content="AI Command that will run om the client" placement="top">
                  <fg-input placeholder="Command to Run" type="text" v-model="scope.row.fix_command"></fg-input>
                </el-tooltip>
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="Time" width="110" align="center">
          <template slot-scope="scope">
            <div class="row">
              <div class="col-sm-12">
                <fg-input label="Interval" min=0 type="number" v-model="scope.row.interval"></fg-input>
              </div>
            </div>
            <div class="row">
              <div class="col-sm-12">
                <fg-input label="Timeout" min=0 type="number" v-model="scope.row.timeout"></fg-input>
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="Checkbox" width="90" align="center">
          <template slot-scope="scope">
            <div class="row">
              <div class="col-sm-12 center-checkbox">
                Notifications
                <el-tooltip content="Notifications Enabled" placement="top">
                    <el-checkbox v-model="scope.row.notifications_enabled"></el-checkbox>
                </el-tooltip>
              </div>
            </div>
            <div class="row">
              <div class="col-sm-12 center-checkbox">
                Host Check
                <el-tooltip content="Is Host Check" placement="top">
                    <el-checkbox v-model="scope.row.is_host_check"></el-checkbox>
                </el-tooltip>
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="Actions" width="75" align="center">
          <template slot-scope="scope">
            <link
              rel="stylesheet"
              href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.0.0/css/all.min.css">
            <el-tooltip content="Update Check" placement="top">
              <el-button type="text" @click="updateCheck(scope.row)">
                <i class="fa-regular fa-floppy-disk"></i>
              </el-button>
            </el-tooltip>
            <el-tooltip content="Delete Check" placement="top">
              <el-button type="text" @click="deleteCheck(scope.row.id)">
                <el-icon name="delete" class="icon-large icon-red"></el-icon>
              </el-button>
            </el-tooltip>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="currentPage"
        :page-sizes="pageSizes"
        :page-size="pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="totalItems">
      </el-pagination>
    </card>
    <el-dialog
      center
      title="Error"
      :visible.sync="modals.error">
      <div class="text-center">
        <span>Error: {{ error_txt }}</span>
        <div class="text-center mt-4">
          <span slot="footer">
<l-button round type="btn btn-danger" @click="closeModal('error')">OK</l-button>&nbsp;
</span>
        </div>
      </div>
    </el-dialog>
    <el-dialog
      center
      title="Success"
      :visible.sync="modals.success">
      <div class="text-center">
        <span>{{ success_txt }}</span>
        <div class="text-center mt-4">
          <span slot="footer">
<l-button round type="btn btn-success" @click="closeModal('success')">OK</l-button>&nbsp;
</span>
        </div>
      </div>
    </el-dialog>
    <el-dialog
      center
      :visible.sync="modals.arguments">
      <div class="text-center">
        <div class="row">
          <div class="col-sm-12">
            <h3 style="margin-top: -20px;">Arguments</h3>
            <p class="mb-4">The arguments @hostname and @ip_address@ are reserved and will be
              replaced by the IP or the hostname when the time is come to run the plugin</p>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-12">
            <div class="table-responsive table-full-width">
              <el-table border :data="args">
                <el-table-column align="center" label="Id" property="id"></el-table-column>
                <el-table-column align="center" label="Flag" property="flag"></el-table-column>
                <el-table-column align="center" label="Value" property="value"></el-table-column>
                <el-table-column align="center" label="Options" property="value">
                  <template slot-scope="scope">
                    <el-button type="danger" @click="removeArg(scope.row['id'])">-</el-button>
                  </template>
                </el-table-column>
              </el-table>
            </div>
          </div>
        </div>
        <div class="row mt-4 mb-4">
          <div class="col-sm-4">
            <label>
              ID:
              <input style="width: 200px;" type="text" class="form-control" placeholder="ID"
                     v-model="argument.id">
            </label>
          </div>
          <div class="col-sm-4">
            <label>
              Flag:
              <input style="width: 200px;" type="text" class="form-control" placeholder="Flag"
                     v-model="argument.flag">
            </label>
          </div>
          <div class="col-sm-4">
            <label>
              Value:
              <input style="width: 200px;" type="text" class="form-control" placeholder="Value"
                     v-model="argument.value">
            </label>
          </div>
        </div>
        <br>
        <span slot="footer" class="dialog-footer">
          <l-button class="mr-2" type="success" wide round @click="addArg">Add Argument</l-button>
<l-button round type="primary" @click="saveArguments('arguments')">Save Arguments</l-button>&nbsp;
<l-button round type="danger" @click="closeModal('arguments')">Cancel</l-button>&nbsp;
</span>
      </div>
    </el-dialog>
    <el-dialog
      center
      :visible.sync="modals.create">
      <div class="row">
        <div class="col-sm-12 d-flex justify-content-center">
          <div class="card-title text-center mb-3">
            <h2>Create New Check</h2>
          </div>
        </div>
      </div>
      <form>
        <div class="form-row">
          <div class="form-group col-md-4" v-for="(field, key) in formData" :key="key">
            <div class="row">
              <div class="col-sm-12 text-center">
                <label :for="key">{{ field.label }}</label>
                <input
                  v-if="field.type !== 'checkbox' && field.type !== 'args' && field.type !== 'plugins'"
                  :type="field.type" class="form-control"
                  :id="key" v-model="field.value" style="width: 225px; margin-bottom: 10px;">
              </div>
            </div>
            <div class="row">
              <div class="col-sm-12">
                <input v-if="field.type === 'checkbox'" :type="field.type" class="form-control"
                       :id="key" v-model="field.value">
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-12 d-flex justify-content-center">
            <div class="text-center mt-4">
              <h5 style="font-size: 17px;">Select plugin(s) to associate with the check</h5>
            </div>
          </div>
        </div>
        <div class="form-row d-flex justify-content-center">
          <div class="col-sm-12 text-center">
            <el-select v-model="selectedPlugins" filterable placeholder="Plugins" multiple>
              <el-option v-for="plugin in plugins" :key="plugin.id" :label="plugin.name"
                         :value="plugin.id">{{
                  plugin.name
                }}
              </el-option>
            </el-select>
          </div>
        </div>
      </form>
      <span slot="footer" class="dialog-footer">
<l-button type="btn btn-primary" round @click="createCheck('create')">Save Check</l-button>&nbsp;
<l-button class="btn btn-danger" round @click="closeModal('create')">Cancel</l-button>&nbsp;
</span>
    </el-dialog>
    <el-dialog
      center
      title="Upload PDF"
      :visible.sync="modals.plugin_type">
      <div class="text-center">
        <div class="row">
          <div class="col-sm-12">
            <label>Name
              <fg-input v-model="plugin_type_name"
                        placeholder="Enter a new plugin type name"></fg-input>
            </label>
          </div>
        </div>
        <br>
        <span slot="footer" class="dialog-footer">
<button class="btn btn-primary" @click="createNewPluginType">Create Plugin Type</button>&nbsp;
<button class="btn btn-danger" @click="closeModal('plugin_type')">Cancel</button>&nbsp;
</span>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {
  Icon,
  Checkbox,
  Input,
  Pagination,
  Table,
  TableColumn,
  Button,
  Select,
  Option,
  Tooltip
} from 'element-ui';
import { Dialog } from 'element-ui';
import ChecksAPIService from "src/servicehandlers/ChecksAPIService";
import PluginsAPIService from "src/servicehandlers/PluginsAPIService";

const pluginsAPIService = new PluginsAPIService();
const checksAPIService = new ChecksAPIService();

export default {
  name: "Checks",
  components: {
    [Icon.name]: Icon,
    [Input.name]: Input,
    [Checkbox.name]: Checkbox,
    [Pagination.name]: Pagination,
    [Button.name]: Button,
    [Select.name]: Select,
    [Option.name]: Option,
    [Dialog.name]: Dialog,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Tooltip.name]: Tooltip,
  },
  data() {
    return {
      currentCheck: null,
      spinning: false,
      selectedPluginSets: [],
      selectedPlugins: [],
      showArgumentsModal: false,
      searchQuery: '',
      showDropdown: {},
      totalPages: 1,
      limit: 50,
      pageSize: 50,
      currentPage: 1,
      totalItems: 0,
      pageSizes: [20, 50, 100, 200, 500],
      selected_plugin_id: null,
      headerAlignCenter: 'center',
      formData: {
        name: { label: 'Name', value: null, type: 'text' },
        description: { label: 'Description', value: null, type: 'text' },
        fix_command: { label: 'Fix Command', value: null, type: 'text' },
        interval: { label: 'Interval', value: 120, type: 'number' },
        timeout: { label: 'Timeout', value: null, type: 'number' },
        baseline: { label: 'Baseline', value: null, type: 'checkbox' },
        notifications_enabled: { label: 'Notifications', value: true, type: 'checkbox' },
        debug: { label: 'Debug', value: false, type: 'checkbox' },
        is_host_check: { label: 'Is Host Check', value: null, type: 'checkbox' },
        autorun: { label: 'Autorun', value: false, type: 'checkbox' },
        plugins: { selected: [], value: [], type: 'plugins' },
      },
      file_path: null,
      current_check_id: null,
      pluginFile: null,
      arch: null,
      formDataList: [],
      md5sum: null,
      download_location: null,
      host_type_id: null,
      plugin_type_id: null,
      message: '',
      filename: null,
      name: null,
      plugin_type_name: null,
      checks: [],
      description: null,
      plugin: null,
      media: null,
      last_order: null,
      args: [],
      argument: {
        id: null,
        flag: null,
        value: null,
      },
      form: null,
      plugins: [],
      host_types: [],
      plugin_types: [],
      error_txt: null,
      success_txt: null,
      argument_id: null,
      modals: {
        error: false,
        success: false,
        create: false,
        plugin: false,
        arguments: false,
        plugin_type: false,
      },
    }
  },
  watch: {
    filteredChecks: {
      handler(newVal) {
        this.totalItems = newVal.length;
      },
      immediate: true  // to run the handler immediately after the watcher is created
    }
  },
  computed: {
    paginatedChecks() {
      const start = (this.currentPage - 1) * this.pageSize;
      const end = this.currentPage * this.pageSize;
      return this.filteredChecks.slice(start, end);
    },

    filteredChecks() {
      const query = this.searchQuery.toLowerCase();
      return this.checks.filter(check => {
        const name = check.name ? check.name.toLowerCase() : '';
        const description = check.description ? check.description.toLowerCase() : '';

        return name.includes(query) ||
          description.includes(query)
      });
    }
  },
  mounted() {
    this.getChecks();
    this.getPlugins();
  },
  methods: {
    handleSizeChange(newSize) {
      this.pageSize = newSize;
      this.currentPage = 1;  // reset to the first page when the page size changes
    },
    handleCurrentChange(newPage) {
      this.currentPage = newPage;
    },
    async setArgs(row) {
      this.currentCheck = row;
      this.args = [];
      this.current_check_id = row.id;
      try {
        this.args = JSON.parse(row.args);
      } catch (e) {
        this.handleError('Failed to set arguments!')
        return;
      }

      this.form = row;
      this.openModal('arguments');
    },
    handleSuccess(text) {
      this.success_txt = text;
      this.openModal('success');
      this.spinning = false;
      setTimeout(() => {
        this.modals['success'] = false;
      }, 1500); // Hide after 1.5 secs
    },
    handleError(error) {
      this.error_txt = error;
      this.openModal('error');
      this.spinning = false;
      setTimeout(() => {
        this.modals['error'] = false;
      }, 1500); // Hide after 1.5 secs
    },
    saveArguments() {
      if (!this.args) {
        this.args = [];
      }
      const check = {
        args: this.args,
      };
      this.currentCheck.args = JSON.stringify(this.args);
      return checksAPIService.update(this.current_check_id, check, this.$router)
      .then((resp) => {
        this.closeModal('arguments');
        const self = this;
        this.success_txt = 'Arguments updated successfully';
        this.spinning = false;
        this.pluginFile = null;
        this.plugin_types = [];
        this.args = [];
        this.openModal('success');
        setTimeout(function () {
          self.modals['success'] = false; // Use your variable name
        }, 1500); // Hide after 5 secs
      })
      .catch((error) => {
        this.args = [];
        const self = this;
        this.error_txt = 'Failed to update check arguments' + error;
        this.openModal('error');
        this.spinning = false;
        setTimeout(function () {
          self.modals['error'] = false; // Use your variable name
        }, 1500); // Hide after 5 secs
      });
    },
    getPluginById(id) {
      return this.plugins.find(plugin => plugin.id === id);
    },
    selectPlugin() {
    },
    toggleDropdown(key) {
      this.$set(this.showDropdown, key, !this.showDropdown[key]);
    },
    removePlugin(id) {
      this.formData.plugins.selected = this.formData.plugins.selected.filter(pluginId => pluginId !== id);
    },
    addArg() {
      this.args.push(this.argument);
      this.argument = {};
    },
    removeArg(incoming_id) {
      for (let index = 0; index < this.args.length; index += 1) {
        if (this.args[index].id === incoming_id) {
          this.args.splice(index, 1);
          break;
        }
      }
    },
    createNewPluginType() {
      this.closeModal('plugin_type');
      const newPlugintype = {
        name: this.plugin_type_name
      };
      return pluginsAPIService.createPluginType(newPlugintype)
      .then((response) => {
        this.plugin_types.push(response);
        const self = this;
        this.success_txt = 'Plugin type creation success';
        this.spinning = false;
        this.pluginFile = null;
        this.plugin_types = [];
        this.getPluginTypes();
        this.closeModal('plugin_type');
        this.openModal('success');
        setTimeout(function () {
          self.modals['success'] = false; // Use your variable name
        }, 1500); // Hide after 5 secs
      })
      .catch((error) => {
        const self = this;
        this.error_txt = 'Failed to create new plugin type' + error;
        this.openModal('error');
        this.spinning = false;
        setTimeout(function () {
          self.modals['error'] = false; // Use your variable name
        }, 1500); // Hide after 5 secs
      })
    },
    openModal(name) {
      this.modals[name] = true
    },
    closeModal(name) {
      this.modals[name] = false;
    },
    deleteCheck(id) {
      return checksAPIService.delete(id, this.$router)
      .then(() => {
        const self = this;
        this.success_txt = 'Delete Check successful';
        this.openModal('success');
        setTimeout(function () {
          self.modals['success'] = false; // Use your variable name
        }, 1500); // Hide after 5 secs
        this.getChecks();
      })
      .catch((error) => {
        const self = this;
        this.error_txt = 'Error deleting Check: ' + error;
        this.openModal('error');
        this.spinning = false;
        setTimeout(function () {
          self.modals['error'] = false; // Use your variable name
        }, 1500); // Hide after 5 secs
      });
    },
    getChecks() {
      this.checks = [];
      return checksAPIService.getChecksWithPlugins(this.$router)
      .then((response) => {
        for (const check of response) {
          const plugin_array = [];
          const plugins = check.plugins;
          for (const plugin of plugins) {
            plugin_array.push(plugin.id);
          }
          check.plugins = plugin_array;
          this.checks.push(check);
        }
        /*
         this.formDataList = response.map(data => {
         let all_args = [];
         if (data.args) {
         all_args = JSON.parse(data.args);
         }
         return {
         id: { label: 'ID', value: data.id, type: 'number' },
         name: { label: 'Name', value: data.name, type: 'text' },
         description: { label: 'Description', value: data.description, type: 'text' },
         args: { label: 'Args', value: all_args, type: 'args' },
         interval: { label: 'Interval', value: data.interval, type: 'number' },
         condition: { label: 'Condition', value: data.condition, type: 'number' },
         delay_time: { label: 'Delay Time', value: data.delay_time, type: 'number' },
         alert_delay: { label: 'Alert Delay', value: data.alert_delay, type: 'number' },
         alert_warn: { label: 'Alert Warn', value: data.alert_warn, type: 'number' },
         alert_crit: { label: 'Alert Crit', value: data.alert_crit, type: 'number' },
         alert_max: { label: 'Alert Max', value: data.alert_max, type: 'number' },
         timeout: { label: 'Timeout', value: data.timeout, type: 'number' },
         run_once: { label: 'Run Once', value: data.run_once, type: 'checkbox' },
         baseline: { label: 'Baseline', value: data.baseline, type: 'checkbox' },
         debug: { label: 'Debug', value: data.debug, type: 'checkbox' },
         notifications: { label: 'Notifications', value: data.notifications, type: 'checkbox' },
         plugins: { selected: data.plugins ? data.plugins.map(plugin => plugin.id) : [] }
         };
         });

         */
      })
      .catch((error) => {
        const self = this;
        this.error_txt = 'Error getting Checks: ' + error;
        this.openModal('error');
        this.spinning = false;
        setTimeout(function () {
          self.modals['error'] = false; // Use your variable name
        }, 1500); // Hide after 5 secs
      });
    },
    getPlugins() {
      this.plugins = [];
      return pluginsAPIService.getPlugins(this.$router)
      .then((response) => {
        this.plugins = response;
      })
      .catch((error) => {
        const self = this;
        this.error_txt = 'Error getting Plugins: ' + error;
        this.openModal('error');
        this.spinning = false;
        setTimeout(function () {
          self.modals['error'] = false; // Use your variable name
        }, 1500); // Hide after 5 secs
      });
    },
    async updateCheck(check) {
      if (!check.run_once) {
        check.run_once = false;
      }
      if (!check.notifications_enabled) {
        check.notifications = false;
      }
      if (!check.baseline) {
        check.baseline = false;
      }
      if (!check['debug']) {
        check['debug'] = false;
      }
      const checkUpdate = {
        name: check.name,
        description: check.description,
        interval: check.interval,
        condition: check.condition,
        delay_time: check.delay_time,
        alert_delay: check.alert_delay,
        is_host_check: check.is_host_check,
        alert_warn: check.alert_warn,
        alert_crit: check.alert_crit,
        alert_max: check.alert_max,
        timeout: check.timeout,
        run_once: check.run_once,
        baseline: check.baseline,
        debug: check.debug,
        autorun: check.autorun,
        fix_command: check.fix_command,
        notifications_enabled: check.notifications_enabled,
      };
      let check_id = check.id;
      return checksAPIService.update(check.id, checkUpdate, this.$router)
      .then(async () => {
        // now link the plugins up
        const plugin_array = [];
        for (const plugin of check.plugins) {
          plugin_array.push(plugin);
        }
        let waited = await checksAPIService.linkCheckWithPlugins(check_id, plugin_array, this.$router);

        const self = this;
        this.getChecks();
        this.success_txt = 'Update Check successful';
        this.openModal('success');
        setTimeout(function () {
          self.modals['success'] = false; // Use your variable name
        }, 1500); // Hide after 5 secs
      })
      .catch((error) => {
        const self = this;
        this.error_txt = 'Error Updating Check: ' + error;
        this.openModal('error');
        this.spinning = false;
        setTimeout(function () {
          self.modals['error'] = false; // Use your variable name
        }, 1500); // Hide after 5 secs
      });
    },
    async createCheck() {
      this.closeModal('create')
      const check = this.formData;
      if (!check.notifications_enabled.value) {
        check.notifications_enabled.value = false;
      }
      if (!check.baseline.value) {
        check.baseline.value = false;
      }
      if (!check['debug'].value) {
        check['debug'].value = false;
      }
      const nc = {
        name: check.name.value,
        description: check.description.value,
        interval: check.interval.value,
        timeout: check.timeout.value,
        run_once: 0,
        fix_command: check.fix_command.value,
        autorun: check.autorun.value,
        baseline: 0,
        args: JSON.stringify([]),
        is_host_check: check.is_host_check.value,
        debug: check.debug.value,
        notifications_enabled: check.notifications_enabled.value,
      };
      let new_check = null;
      try {
        new_check = await checksAPIService.create(nc, this.$router);
        const link_checks = await checksAPIService.linkCheckWithPlugins(new_check.id, this.selectedPlugins, this.$router);
        const self = this;
        this.success_txt = 'Creation of check successful';
        this.openModal('success');
        setTimeout(function () {
          self.modals['success'] = false; // Use your variable name
        }, 1500); // Hide after 5 secs
        this.getChecks();
      } catch (e) {
        const self = this;
        this.error_txt = 'Error creating check: ' + e;
        this.openModal('error');
        this.spinning = false;
        setTimeout(function () {
          self.modals['error'] = false; // Use your variable name
        }, 1500); // Hide after 5 secs
      }
    }
  }
}
</script>

<style scoped>
.drop-zone {
  background-color: #eee;
  margin-bottom: 10px;
  padding: 10px;
}

.drag-el {
  background-color: #fff;
  margin-bottom: 10px;
  padding: 5px;
}

.icon-large {
  font-size: 20px;
}

.icon-red {
  color: red;
}

.icon-blue {
  color: blue;
}

.icon-green {
  color: green;
}

.fa-floppy-disk {
  font-size: 19px;
  color: dodgerblue;
}

.fa-circle-plus {
  color: mediumseagreen;
  font-size: 25px;
}

.card-title {
  font-size: 25px;
  color: grey;
}

.center-checkbox {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.header-title {
  color: dimgrey;
  text-align: left;
  font-size: 15px;
  margin-top: 0;
  margin-bottom: 0;
}

.card-category {
  color: dimgrey;
  font-size: 15px;
}

</style>
