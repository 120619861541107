import axios from 'axios';

export class CommonAPIService {
  constructor() {
    this.api_url = process.env.VUE_APP_API_URL;
    this.ai_url = process.env.VUE_APP_AI_URL;
    this.llm_url = process.env.VUE_APP_LLM_URL;
    this.loggedIn = false;
  }

  // main login method for the code
  login(login, router, store, errors) {
    axios.defaults.headers.common.Authorization = localStorage.getItem('jwtToken');
    return axios.post(`${this.api_url}/api/auth/login`, login)
      .then((response) => {
        localStorage.setItem('jwtToken' + '', response.data.token);
        // store.dispatch('ADD_USERID', response.data.user.id);
        store.setUsername(response.data.user.username);
        store.setUser(response.data.user);
        store.setClients(response.data.user.clients);
        store.setLoggedIn(true);
        store.setAppRights(response.data.user.app_rights);
        store.setHostRights(response.data.user.host_rights);
        store.setCommandRights(response.data.user.command_rights);
        return response.data;
      })
      .catch((e) => {
        errors.push(e);
        if (e.response.status === 401) {
          router.push({
            name: 'login',
          });
        }
        return {success: false, msg: 'Login Failure'};
      });
  }

  postCall(url, parameters, router) {
    axios.defaults.headers.common.Authorization = localStorage.getItem('jwtToken');
    axios.defaults.timeout = 100000;
    const fullUrl = this.api_url + url;
    parameters.timeout = 100000;
    return axios.post(fullUrl, parameters)
      .then((response) => {
        // console.debug('postCall response', response);
        return response.data
      })
      .catch((error) => {
        if (error.response.status === 401) {
          router.push({
            name: 'login',
          });
        }
        throw error
      });
  }

  llmCall(url, parameters, router) {
    axios.defaults.headers.common.Authorization = localStorage.getItem('jwtToken');
    axios.defaults.timeout = 100000;
    const fullUrl = this.llm_url + url;
    parameters.timeout = 100000;
    return axios.post(fullUrl, parameters)
    .then((response) => {
      return response
    })
    .catch((error) => {
      if (error.response.status === 401) {
        router.push({
          name: 'login',
        });
      }
      throw error
    });
  }
  aiCall(url, parameters, router) {
    axios.defaults.headers.common.Authorization = localStorage.getItem('jwtToken');
    axios.defaults.timeout = 100000;
    const fullUrl = this.ai_url + url;
    parameters.timeout = 100000;
    return axios.post(fullUrl, parameters)
    .then((response) => {
      return response
    })
    .catch((error) => {
      if (error.response.status === 401) {
        router.push({
          name: 'login',
        });
      }
      throw error
    });
  }

  newFileCall(url, formData) {
    const fullUrl = this.api_url + url;
    axios.defaults.headers.common.Authorization = localStorage.getItem('jwtToken');
    axios.post(fullUrl, formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
    .then(response => {
      // handle the response data
      return response;
    })
    .catch(error => {
      return error;
    });
  }

  updateFileCall(url, formData) {
    const fullUrl = this.api_url + url;
    axios.defaults.headers.common.Authorization = localStorage.getItem('jwtToken');
    axios.put(fullUrl, formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
      .then(response => {
        // handle the response data
        return response;
      })
      .catch(error => {
        return error;
      });
  }

  fileCall(url, files, router) {
    const fullUrl = this.api_url + url;
    axios.defaults.headers.common.Authorization = localStorage.getItem('jwtToken');
    return axios({
      method: 'get',
      url: fullUrl,
      responseType: 'blob',
    })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      if (error.response.status === 401) {
        router.push({
          name: 'Login',
        });
      }
    });
  }


  deleteCall(url, parameters, router) {
    axios.defaults.headers.common.Authorization = localStorage.getItem('jwtToken');
    const fullUrl = this.api_url + url;
    axios.defaults.timeout = 100000;

    // Pass parameters using the 'params' property
    const config = {
      params: parameters
    };

    return axios.delete(fullUrl, config)
    .then(response => response.data)
    .catch((error) => {
      if (error.response && error.response.status === 401) {
        router.push({
          name: 'Login',
        });
      }
    });
  }

  putCall(url, parameters, router) {
    axios.defaults.headers.common.Authorization = localStorage.getItem('jwtToken');
    axios.defaults.timeout = 100000;
    const fullUrl = this.api_url + url;
    return axios.put(fullUrl, parameters)
      .then(response => response.data)
      .catch((error) => {
        if (error.response.status === 401) {
          router.push({
            name: 'Login',
          });
        }
        throw error;
      });
  }

  getCall(url, parameters, router) {
    axios.defaults.headers.common.Authorization = localStorage.getItem('jwtToken');
    axios.defaults.timeout = 100000;
    const fullUrl = this.api_url + url;
    return axios.get(fullUrl, parameters)
      .then(response => response.data)
      .catch((error) => {
        if (error.response.status === 401) {
          router.push({
            name: 'Login',
          });
        }
        // throw error
      });
  }
}

export default CommonAPIService;
