<template>
  <div>
    <card v-if="spinning">
      <div class="row">
        <div class="col-sm-12 text-center">
          <b-spinner variant="primary" label="Spinning"></b-spinner>
        </div>
      </div>
    </card>
    <card>
      <div class="row col-sm-6">
          <h3 class="custom-header1">Command Maps</h3>
          <h4 class="custom-header">These command maps are the way the AI communicates with the server.</h4>
      </div>
    </card>
    <card>
      <el-table :data="filteredCommands" border style="width: 100%">
        <el-table-column min-width="210" label="Command" align="center">
          <template slot-scope="scope">
            <el-tooltip :content="scope.row.command_name" placement="top">
            <fg-input placeholder="Command" v-model="scope.row.command_name"></fg-input>
            </el-tooltip>
          </template>
        </el-table-column>
        <el-table-column min-width="210" label="Mapped Command" align="center">
          <template slot-scope="scope">
            <el-tooltip :content="scope.row.base_command" placement="top">
            <fg-input placeholder="Mapped Command" v-model="scope.row.base_command"></fg-input>
            </el-tooltip>
          </template>
        </el-table-column>
        <el-table-column min-width="200" label="Description" align="center">
          <template slot-scope="scope">
            <fg-input placeholder="Description" v-model="scope.row.description"></fg-input>
          </template>
        </el-table-column>
        <el-table-column label="Require Action" width="85" align="center">
          <template slot-scope="scope">
            <div class="text-center">
            <el-tooltip content="Click to enable/disable authentication for the server"
                        placement="top">
            <el-checkbox v-model="scope.row.needs_auth"></el-checkbox>
            </el-tooltip>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="Actions" width="80" align="center">
          <template slot-scope="scope">
            <link
              rel="stylesheet"
              href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.0.0/css/all.min.css">
            <el-tooltip content="Update Command" placement="top">
              <el-button type="text" @click="updateCommand(scope.row.id, scope.row)">
                <i class="fa-regular fa-floppy-disk"></i>
              </el-button>
            </el-tooltip>
            <el-tooltip content="Delete Command" placement="top">
            <el-button  type="text" @click="deleteCommand(scope.row.id)">
              <el-icon name="delete" class="icon-large icon-red"></el-icon>
            </el-button>
            </el-tooltip>
          </template>
        </el-table-column>
      </el-table>
    </card>
    <el-dialog
      center
      title="Error"
      :visible.sync="modals.error">
      <div class="text-center">
        <span>Error: {{ error_txt }}</span>
        <div class="text-center mt-4">
          <span slot="footer">
<l-button type="btn btn-danger" round @click="closeModal('error')">OK</l-button>&nbsp;
</span>
        </div>
      </div>
    </el-dialog>
    <el-dialog
      center
      title="Success"
      :visible.sync="modals.success">
      <div class="text-center">
        <span>{{ success_txt }}</span>
        <div class="text-center mt-4">
          <span slot="footer">
<l-button type="btn btn-success" round @click="closeModal('success')">OK</l-button>&nbsp;
</span>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
const moment = require('moment');
import {Tooltip, Button, Icon, Checkbox, Table, TableColumn, Dialog } from 'element-ui';
import CommandsMapAPIService from "src/servicehandlers/CommandMapAPIService";
import CompaniesAPIService from "src/servicehandlers/CompaniesAPIService";

const commandsMapAPIService = new CommandsMapAPIService();
const clientsAPIService = new CompaniesAPIService();

export default {
  name: "Queue",
  components: {
    [Tooltip.name]: Tooltip,
    [Button.name]: Button,
    [Icon.name]: Icon,
    [Checkbox.name]: Checkbox,
    [Dialog.name]: Dialog,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
  },
  data() {
    return {
      searchQuery: '',
      user: null,
      spinning: false,
      queue_string: null,
      command_name: null,
      base_command: null,
      needs_auth: false,
      commands: [],
      clients: [],
      error_txt: null,
      success_txt: null,
      result_txt: null,
      modals: {
        error: false,
        result: false,
        success: false,
      },
    }
  },
  async mounted() {
    await this.getCommands();
  },
  computed: {
    filteredCommands() {
      const query = this.searchQuery.toLowerCase();
      return this.commands.filter(command => {
        const name = command.command_name ? command.command_name.toLowerCase() : '';
        const description = command.description ? command.description.toLowerCase() : '';
        const base_command = command.base_command ? command.base_command.toLowerCase() : '';

        return name.includes(query) ||
          description.includes(query) ||
          base_command.includes(query)
      });
    }
  },
  methods: {
    updateCommand(id, command) {
      return commandsMapAPIService.updateCommandMap(id, command, this.$router)
      .then((response) => {
        const self = this;
        this.spinning = false;
        this.success_txt = 'Successfully updated map';
        this.openModal('success');
        setTimeout(function () {
          self.modals['success'] = false; // Use your variable name
        }, 1500); // Hide after 5 secs
      })
      .catch((error) => {
        const self = this;
        this.spinning = false;
        this.error_txt = 'Failed to update map ' + error;
        this.openModal('error');
        setTimeout(function () {
          self.modals['error'] = false; // Use your variable name
        }, 1500); // Hide after 5 secs
      });
    },
    saveCommand() {
      const parms = {
        'command_name': this.command_name,
        'base_command': this.base_command,
        'needs_auth': this.needs_auth,
      };
      return commandsMapAPIService.createCommandMap(parms, this.$router)
      .then((response) => {
        this.command_name = null;
        this.base_command = null;
        this.needs_auth = null;
        this.getCommands();
        const self = this;
        this.spinning = false;
        this.success_txt = 'Successfully created new map';
        this.openModal('success');
        setTimeout(function () {
          self.modals['success'] = false; // Use your variable name
        }, 1500); // Hide after 5 secs
      })
      .catch((error) => {
        const self = this;
        this.spinning = false;
        this.error_txt = 'Failed to created new map ' + error;
        this.openModal('error');
        setTimeout(function () {
          self.modals['error'] = false; // Use your variable name
        }, 1500); // Hide after 5 secs
      });
    },
    deleteCommand(id) {
      return commandsMapAPIService.deleteCommandMap(id, this.$router)
      .then((response) => {
        this.getCommands();
        const self = this;
        this.spinning = false;
        this.success_txt = 'deleted map successfully';
        this.openModal('success');
        setTimeout(function () {
          self.modals['success'] = false; // Use your variable name
        }, 1500); // Hide after 5 secs
      })
      .catch((error) => {
        const self = this;
        this.spinning = false;
        this.error_txt = 'Failed to delete map ' + error;
        this.openModal('error');
        setTimeout(function () {
          self.modals['error'] = false; // Use your variable name
        }, 1500); // Hide after 5 secs
      });
    },
    issueMacro(text) {
      this.queue_string = text;
      this.createQueue();
    },
    openModal(name) {
      this.modals[name] = true
    },
    closeModal(name) {
      this.modals[name] = false;
    },
    async getCommands() {
      return await commandsMapAPIService.getCommandMaps(this.$router)
      .then((response) => {
        this.commands = [];
        for (const command of response) {
          this.commands.push(command);
        }
      })
      .catch((error) => {
        const self = this;
        this.error = 'Failed to get commands' + error;
        this.openModal('error');
        setTimeout(function () {
          self.modals['error'] = false; // Use your variable name
        }, 1500); // Hide after 5 secs
      });
    },
    getAlternateColorClass(index) {
      return index % 2 === 0 ? "even-row" : "odd-row";
    },
  },
}
</script>

<style scoped>
.icon-large {
  font-size: 20px;
}
.icon-red {
  color: red;
}
.icon-blue {
  color: blue;
}
.icon-green {
  color: green;
}
.fa-floppy-disk {
  font-size: 19px;
  color: dodgerblue;
}
.custom-header1 {
  color: dimgrey;
  text-align: center;
  font-size: 17px;
  margin-top: 0;
  margin-bottom: 0;
}
.custom-header {
  color: dimgrey;
  text-align: center;
  font-size: 15px;
  margin-top: 0;
  margin-bottom: 0;
}
</style>
