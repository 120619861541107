import CommonAPIService from 'src/servicehandlers/CommonHandler';

export class AuditLogsAPIService {
  constructor() {
    this.search = '';
    this.CommonAPIService = new CommonAPIService();
  }

  getAuditLogs(router) {
    const url = '/api/audit_logs';
    return this.CommonAPIService.getCall(url, '', router);
  }
  getAuditLogsByDateTime(start_time, end_time, router) {
    const parms = {
      start_time,
      end_time
    };
    const url = `/api/audit_logs/timeframe`;
    return this.CommonAPIService.postCall(url, parms, router);
  }

  getAuditLogsById(id, router) {
    const url = `/api/audit_logs/${id}`;
    return this.CommonAPIService.getCall(url, '', router);
  }

  addAuditLog(router, parms) {
    const url = `/api/audit_logs`;
    return this.CommonAPIService.postCall(url, parms, router);
  }

  deleteAuditLog(router, id) {
    const url = `/api/audit_logs/${id}`;
    return this.CommonAPIService.deleteCall(url, '', router);
  }

  updateAuditLog(router, id, parms) {
    const url = `/api/audit_logs/${id}`;
    return this.CommonAPIService.putCall(url, parms, router);
  }

  searchAuditLogs(router, search) {
    const url = `/api/audit_logs?search=${search}`;
    return this.CommonAPIService.getCall(url, '', router);
  }
}

export default AuditLogsAPIService;
