import CommonAPIService from 'src/servicehandlers/CommonHandler';

export class PluginsAPIService {
  constructor() {
    this.CommonAPIService = new CommonAPIService();
  }

  createPlugin(plugin, router) {
    const url = '/api/plugins';
    return this.CommonAPIService.newFileCall(url, plugin, router);
  }

  updatePlugin(id, plugin, router) {
    const url = `/api/plugins/${id}`;
    return this.CommonAPIService.putCall(url, plugin, router);
  }
  updateOldPlugin(id, plugin, router) {
    const url = `/api/plugins/${id}/file`;
    return this.CommonAPIService.newFileCall(url, plugin, router);
  }

  deletePlugin(id, router) {
    const url = `/api/plugins/${id}`;
    return this.CommonAPIService.deleteCall(url, null, router);
  }

  getPlugins(router) {
    const url = '/api/plugins';
    return this.CommonAPIService.getCall(url, null, router);
  }

  getPluginById(id, router) {
    const url = `/api/plugins/${id}`;
    return this.CommonAPIService.getCall(url, null, router);
  }

  getPluginTypes(router) {
    const url = `/api/plugins/types`;
    return this.CommonAPIService.getCall(url, null, router);
  }
  createPluginType(newPluginType, router) {
    const url = `/api/plugins/types`;
    return this.CommonAPIService.postCall(url, newPluginType, router);
  }
}

export default PluginsAPIService;
