import CommonAPIService from 'src/servicehandlers/CommonHandler';

export class AlertsAPIService {
  constructor() {
    this.search = '';
    this.CommonAPIService = new CommonAPIService();
  }

  getAlerts(router) {
    const url = '/api/alerts';
    return this.CommonAPIService.getCall(url, '', router);
  }
  getAlertsByTimeframe(start_date, end_date, router) {
    const url = '/api/alerts/timeframe';
    return this.CommonAPIService.postCall(url, {start_date, end_date }, router);
  }

  getAlertsById(id, router) {
    const url = `/api/alerts/${id}`;
    return this.CommonAPIService.getCall(url, '', router);
  }

  addAlert(router, parms) {
    const url = `/api/alerts`;
    return this.CommonAPIService.postCall(url, parms, router);
  }

  deleteAlert(id, router) {
    const url = `/api/alerts/${id}`;
    return this.CommonAPIService.deleteCall(url, '', router);
  }

  updateAlert(id, parms, router) {
    const url = `/api/alerts/${id}`;
    return this.CommonAPIService.putCall(url, parms, router);
  }

  searchAlerts(router, search) {
    const url = `/api/alerts?search=${search}`;
    return this.CommonAPIService.getCall(url, '', router);
  }
}

export default AlertsAPIService;
