<template>
<div>
  <card v-if="spinning">
    <div class="row">
      <div class="col-sm-12 text-center">
        <b-spinner variant="primary" label="Spinning"></b-spinner>
      </div>
    </div>
  </card>
  <card>
    <h3 class="title-1">User Group Page</h3>
    <p class="title-2">User groups are used for sending alerts and they are tied to hosts or checks</p>
  </card>
  <card>
    <link
      rel="stylesheet"
      href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.0.0/css/all.min.css">
    <div slot="header">
      <h4 class="card-title">User Groups Table
      <el-tooltip content="Add New User Group">
        <el-button type="text" @click="createUsergroup('create')">
          <i class="fa-solid fa-circle-plus"></i>
        </el-button>
      </el-tooltip>

      </h4>
      <p class="card-category mb-2">Displays full list of user groups in the system</p>
      <div class="row">
        <div class="col-sm-6">
          <el-input
            v-model="searchTerm"
            placeholder="Search by group name or alias"
            clearable>
            <span slot="prepend">Search</span>
          </el-input>
        </div>
        <el-button @click="searchTerm = ''">Clear</el-button>
      </div>
    </div>
    <div class="table-responsive table-full-width" >
      <el-table class="table-striped" border :data="filteredGroups">
        <el-table-column label="User Group" property="name" width="400" :header-align="headerAlignCenter">
          <template v-slot="{ row }">
            <div class="center-content">
            <fg-input style="width: 350px;" placeholder="User Group Name" v-model="row.name"></fg-input>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="User Group Alias" property="alias" width="400" :header-align="headerAlignCenter">
          <template v-slot="{ row }">
            <div class="center-content">
            <fg-input style="width: 300px;" placeholder="User Group Alias" v-model="row.alias"></fg-input>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="Assign Users" width="150" :header-align="headerAlignCenter">
          <template v-slot="{ row }">
            <div class="center-content">
            <el-tooltip content="Assign Users to Group">
              <el-button type="text" @click="openAssignUserDialog(row)">
                <i class="fa-solid fa-users"></i>
              </el-button>
            </el-tooltip>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="Actions" width="200" :header-align="headerAlignCenter">
          <template v-slot="{ row }">
            <div class="center-content">
            <el-tooltip content="Update User Group">
              <el-button type="text" @click="updateUsergroup(row)">
                <i class="fa-regular fa-floppy-disk"></i>
              </el-button>
            </el-tooltip>
            <el-tooltip content="Delete User Group">
              <el-button type="text" @click="deleteUsergroup(row)">
                <el-icon name="delete" class="icon-large icon-red"></el-icon>
              </el-button>
            </el-tooltip>
            </div>
          </template>
        </el-table-column>
      </el-table>
    </div>
  </card>
  <el-dialog
    center
    title="Error"
    :visible.sync="modals.error">
    <div class="text-center">
      <span>Error: {{ error_txt }}</span>
      <br>
      <span slot="footer" class="dialog-footer">
<l-button type="primary" round @click="closeModal('error')">OK</l-button>&nbsp;
</span>
    </div>
  </el-dialog>
  <el-dialog
    center
    title="Success"
    :visible.sync="modals.success">
    <div class="text-center">
      <span>{{ success_txt }}</span>
      <br>
      <div class="text-center mt-4">
        <span slot="footer">
<l-button type="primary" round @click="closeModal('success')">OK</l-button>&nbsp;
</span>
      </div>
    </div>
  </el-dialog>
  <el-dialog :visible.sync="modals.assign_users" width="900px">
    <h3 style="margin-top: -20px;">Assign Users to {{ selectedUserGroup ? selectedUserGroup.name : '' }}</h3>
    <p class="mb-4">Select the hosts from the list below that you want to assign to the selected host group. You can select multiple users.</p>

    <b-row class="mt-3">
      <!-- User Selection Dropdown -->
      <b-col cols="6">
        <div class="box">
          <label>Select Users:</label>
          <el-select class="select-full-width" v-model="selectedUsers" filterable multiple placeholder="Select users">
            <el-option
              v-for="user in users"
              :key="user.id"
              :label="user.last_name + ',' + user.first_name"
              :value="user.id">
            </el-option>
          </el-select>
        </div>
      </b-col>

      <!-- List of Selected Users -->
      <b-col cols="6">
        <div class="box">
            <label>Selected Users:</label>
          <card>
            <div v-for="(userId, index) in selectedUsers" :key="userId" class="selected-user">
              {{ users.find(user => user.id === userId).last_name }},
              {{ users.find(user => user.id === userId).first_name }}
              <el-button icon="el-icon-delete" circle size="mini" @click="removeUserFromSelected(index)"></el-button>
            </div>
          </card>
        </div>
      </b-col>
    </b-row>
    <div class="text-center mt-4">
      <span slot="footer">
      <l-button class="mr-1" round type="primary" @click="assignUsersToGroup">Assign</l-button>
        <l-button round type="danger" @click="closeModal('assign_users')">Cancel</l-button>
  </span>
    </div>
  </el-dialog>
  <el-dialog
    width="600px"
    :visible.sync="modals.create">
    <div class="row">
      <div class="col-sm-12">
        <div class="text-center mb-4">
          <h3 style="margin-top: -15px;">Create User Group</h3>
        </div>
      </div>
    </div>

    <div class="container">
      <div class="row justify-content-center">
        <div class="col-sm-5 text-center">
          <fg-input label="User Group Name" v-model="name" placeholder="User Group Name"></fg-input>
        </div>
        <div class="col-sm-5 text-center">
          <fg-input label="User Group Alias" v-model="alias" placeholder="User Group Alias"></fg-input>
        </div>
      </div>
    </div>
    <div class="text-center mt-4">
      <span slot="footer">
        <l-button class="mr-1" type="primary" round @click="saveUsergroup('create')">Save</l-button>
        <l-button type="danger" round @click="closeModal('create')">Cancel</l-button>
    </span>
    </div>
  </el-dialog>
</div>
</template>

<script>
import { Tooltip, Button, Dialog, Icon } from 'element-ui';
import {Select, Option, Table, TableColumn} from 'element-ui';
import UserGroupsAPIService from "src/servicehandlers/UserGroupsAPIService";
const userGroupsAPIService = new UserGroupsAPIService();
import UserAPIService from "src/servicehandlers/UsersAPIService";
const userAPIService = new UserAPIService();

export default {
  data() {
    return {
      spinning: false,
      success_txt: null,
      error_txt: null,
      searchTerm: '',
      name: null,
      user_groups: [],
      users: [],
      selectedUserGroup: null,
      selectedUsers: [],
      alias: null,
      headerAlignCenter: 'center',
      modals: {
        error: false,
        success: false,
        create: false,
        assign_users: false,
      },
    }
  },
  mounted() {
    this.getUsergroups();
    this.getUsers();
  },
  computed: {
    filteredGroups() {
      if (!this.searchTerm) return this.user_groups;
      const term = this.searchTerm.toLowerCase();
      return this.user_groups.filter(group =>
        group.name.toLowerCase().includes(term) ||
        group.alias.toLowerCase().includes(term)
      );
    }
  },
  components: {
    [Button.name]: Button,
    ElSelect: Select,
    ElOption: Option,
    [Dialog.name]: Dialog,
    [Tooltip.name]: Tooltip,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Icon.name]: Icon,
  },
  name: "HostGroups",
  methods: {
    removeUserFromSelected(index) {
      this.selectedUsers.splice(index, 1);
    },
    assignUsersToGroup() {
      const users = {
        users: this.selectedUsers
      };

      // Check only for a valid user group, not for the users.
      if (!this.selectedUserGroup) return;

      return userGroupsAPIService.addUsersToUsergroup(this.selectedUserGroup.id, users, this.$router)
      .then(() => {
        this.selectedUsers = [];
        this.closeModal('assign_users');
        this.success_txt = 'Successfully assigned hosts to host group';
        this.openModal('success');
        this.spinning = false;
        setTimeout(() => {
          this.modals['success'] = false; // Use your variable name
        }, 1500); // Hide after 1.5 secs
        this.getUsergroups();
      })
      .catch((error) => {
        this.selectedHosts = [];
        this.closeModal('assign_users');
      });
    },
    clearSearch() {
      this.searchTerm = '';
    },
    getUsers() {
      return userAPIService.getUsersByCompanyID(this.$router)
      .then((response) => {
        this.users = response;
      })
      .catch((error) => {
        const self = this;
        this.error_txt = 'Failed to get users: ' + error;
        this.openModal('error');
        this.spinning = false;
        setTimeout(function () {
          self.modals['error'] = false; // Use your variable name
        }, 1500); // Hide after 5 secs
      });
    },
    createUsergroup(name) {
      this.openModal(name);
    },
    saveUsergroup(name) {
      this.closeModal(name);
      const newUsergroup = {
        name: this.name,
        alias: this.alias,
      };
      return userGroupsAPIService.create(newUsergroup, this.$router)
      .then((response) => {
        this.user_groups.push(response);
        this.name = null;
        this.alias = null;
        const self = this;
        this.success_txt = 'Successfully created user group';
        this.openModal('success');
        this.spinning = false;
        setTimeout(function () {
          self.modals['success'] = false; // Use your variable name
        }, 1500); // Hide after 5 secs
      })
      .catch((error) => {
        const self = this;
        this.error_txt = 'Failed to save user group: ' + error;
        this.openModal('error');
        this.spinning = false;
        setTimeout(function () {
          self.modals['error'] = false; // Use your variable name
        }, 1500); // Hide after 5 secs
      })
    },
    openAssignUserDialog(row) {
      // get a list of hosts that are associated with the group
      this.selectedUsers = [];
      this.selectedUserGroup = row;
      for (const user of row.users) {
        this.selectedUsers.push(user.id);
      }
      this.modals.assign_users = true;
    },
    deleteUsergroup(usergroup) {
      return userGroupsAPIService.delete(usergroup.id, this.$router)
      .then(() => {
        const self = this;
        this.success_txt = 'Successfully deleted user group';
        this.openModal('success');
        this.spinning = false;
        setTimeout(function () {
          self.modals['success'] = false; // Use your variable name
        }, 1500); // Hide after 5 secs
        this.getUsergroups();
      })
      .catch((error) => {
        const self = this;
        this.error_txt = 'Failed to get user group: ' + error;
        this.openModal('error');
        this.spinning = false;
        setTimeout(function () {
          self.modals['error'] = false; // Use your variable name
        }, 1500); // Hide after 5 secs
      });
    },
    updateUsergroup(contactgroup) {
      return userGroupsAPIService.update(contactgroup.id, contactgroup, this.$router)
      .then((response) => {
        const self = this;
        this.success_txt = 'Successfully updated user group';
        this.openModal('success');
        this.spinning = false;
        setTimeout(function () {
          self.modals['success'] = false; // Use your variable name
        }, 1500); // Hide after 5 secs
        this.getUsergroups();
      })
      .catch((error) => {
        const self = this;
        this.error_txt = 'Failed to update user groups: ' + error;
        this.openModal('error');
        this.spinning = false;
        setTimeout(function () {
          self.modals['error'] = false; // Use your variable name
        }, 1500); // Hide after 5 secs
      });
    },
    getUsergroups() {
      return userGroupsAPIService.getWithUsers(this.$router)
      .then((response) => {
        this.user_groups = response;
      })
      .catch((error) => {
        const self = this;
        this.error_txt = 'Failed to get user groups: ' + error;
        this.openModal('error');
        this.spinning = false;
        setTimeout(function () {
          self.modals['error'] = false; // Use your variable name
        }, 1500); // Hide after 5 secs
      });
    },
    openModal(name) {
      this.modals[name] = true
    },
    closeModal(name) {
      this.modals[name] = false;
    },
  }
}
</script>

<style scoped>
.box {
  padding: 20px;
  border: 1px solid #dcdfe6; /* color from Element UI's default theme */
  border-radius: 5px;
  background-color: #f5f7fa; /* color from Element UI's default theme */
  margin-bottom: 20px;
}

.selected-user {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
}

.select-full-width {
  width: 100%;
}

.dialog-footer {
  display: flex;
  justify-content: flex-end;
}
.fa-users {
  color: mediumseagreen;
}
.center-content {
  display: flex;
  align-items: center;
  justify-content: center;
}
.title-1 {
  color: dimgrey;
  font-size: 17px;
  margin-top: 5px;
  margin-bottom: 5px;
}
.title-2 {
  color: dimgrey;
  font-size: 15px;
  margin-top: 0;
  margin-bottom: 5px;
}
.fa-floppy-disk {
  font-size: 19px;
  color: dodgerblue;
}
</style>
