<template>
  <div>
    <card v-if="spinning">
      <div class="row">
        <div class="col-sm-12 text-center">
          <b-spinner variant="primary" label="Spinning"></b-spinner>
        </div>
      </div>
    </card>
    <card>
      <div slot="header">
        <h4 class="card-title">Script Assignments
          <el-tooltip content="Add New Script">
            <el-button type="text" @click="openNewModal">
              <i class="fa-solid fa-circle-plus"></i>
            </el-button>
          </el-tooltip>
        </h4>
      </div>
      <el-table :data="scriptAssignments" border style="width: 100%">
        <el-table-column prop="operatingSystemName" label="Operating System" width="200"></el-table-column>
        <el-table-column prop="scriptName" label="Script" width="200"></el-table-column>
        <el-table-column prop="hostName" label="Host" width="200"></el-table-column>
        <el-table-column label="Actions" width="200" align="center">
          <template slot-scope="scope">
            <link
              rel="stylesheet"
              href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.0.0/css/all.min.css">
            <div class="center-content">
              <el-tooltip content="Update Script Assignment">
                <el-button type="text" @click="openUpdateModal(scope.row, scope.$index)">
                  <i class="fa-regular fa-floppy-disk"></i>
                </el-button>
              </el-tooltip>
              <el-tooltip content="Delete Script Assignment">
                <el-button type="text" @click="deleteScriptAssignment(scope.$index)">
                  <el-icon name="delete" class="icon-large icon-red"></el-icon>
                </el-button>
              </el-tooltip>
            </div>
          </template>
        </el-table-column>
      </el-table>
    </card>
    <el-dialog
      center
      title="Error"
      :visible.sync="modals.error">
      <div class="text-center">
        <span>Error: {{ error_txt }}</span>
        <div class="text-center mt-4">
        <span slot="footer">
          <l-button type="btn btn-danger" round @click="closeModal('error')">OK</l-button>&nbsp;
        </span>
        </div>
      </div>
    </el-dialog>
    <el-dialog
      center
      title="Success"
      :visible.sync="modals.success">
      <div class="text-center">
        <span>{{ success_txt }}</span>
        <div class="text-center">
        <span slot="footer">
          <l-button type="btn btn-success" round @click="closeModal('success')">OK</l-button>&nbsp;
        </span>
        </div>
      </div>
    </el-dialog>
    <el-dialog :visible.sync="modals.add_or_update" width="59%">
      <div class="text-center">
        <h3 style="margin-top: -20px;">Add or Update Script Assignment</h3>
      </div>
      <div class="text-center">
        <div class="row my-5">
          <div class="col-sm-4">
            <label>Operating System:
              <el-select
                v-model="currentScriptAssignment.operating_system_id"
                filterable
                placeholder="Operating System"
                style="width: 350px"
              >
                <el-option
                  v-for="operating_system in this.operatingSystems"
                  :key="operating_system.id"
                  :label="operating_system.friendly_name"
                  :value="operating_system.id"
                >
                  {{operating_system.friendly_name}}
                </el-option>
              </el-select>
            </label>
          </div>
          <div class="col-sm-4">
            <label>Script:
              <el-select
                v-model="currentScriptAssignment.script_id"
                filterable
                placeholder="Script"
                style="width: 350px"
              >
                <el-option
                  v-for="script in this.scripts"
                  :key="script.id"
                  :label="script.name"
                  :value="script.id"
                >
                  {{script.name}}
                </el-option>
              </el-select>
            </label>
          </div>
          <div class="col-sm-4">
            <label>Host:
              <el-select
                v-model="currentScriptAssignment.host_id"
                filterable
                placeholder="Host"
                style="width: 350px"
              >
                <el-option
                  v-for="host in this.hosts"
                  :key="host.id"
                  :label="host.name"
                  :value="host.id"
                >
                  {{host.name}}
                </el-option>
              </el-select>
            </label>
          </div>
        </div>
      </div>
      <div class="text-center mt-5">
        <span slot="footer" class="dialog-footer">
          <l-button round type="btn btn-primary" class="mr-1" @click="addOrUpdateScriptAssignment">Save</l-button>
          <l-button round type="btn btn-danger" @click="closeModal('add_or_update')">Cancel</l-button>
        </span>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import ScriptsAPIService from "src/servicehandlers/ScriptsAPIService";
const scriptsAPIService = new ScriptsAPIService();
import OperatingSystemAPIService from "src/servicehandlers/OperatingSystemAPIService";
const operatingSystemAPIService = new OperatingSystemAPIService();
import HostsAPIService from "src/servicehandlers/HostsAPIService";
const hostsAPIService = new HostsAPIService();
import ScriptAssignmentsAPIService from "src/servicehandlers/ScriptAssignmentsAPIService";
const scriptAssignmentsAPIService = new ScriptAssignmentsAPIService();
import {Button, Dialog, Icon, Input, Option, Select, Table, TableColumn, Tooltip} from 'element-ui';

export default {
  name: "ScriptAssignments",
  components: {
    [Button.name]: Button,
    [Dialog.name]: Dialog,
    [Icon.name]: Icon,
    [Input.name]: Input,
    [Option.name]: Option,
    [Select.name]: Select,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Tooltip.name]: Tooltip,
  },
  data() {
    return {
      spinning: false,
      scripts: [],
      operatingSystems: [],
      hosts: [],
      scriptAssignments: [],
      error_txt: false,
      success_txt: false,
      currentScriptAssignmentIndex: null,
      currentScriptAssignment: {
        operating_system_id: null,
        script_id: null,
      },
      modals: {
        error: false,
        success: false,
        add_or_update: false,
      },
    }
  },
  async mounted() {
    await this.getScripts();
    await this.getOperatingSystems();
    await this.getHosts();
    await this.getScriptAssignments();
  },
  methods: {
    async getScripts() {
      return await scriptsAPIService.get(this.$router)
        .then((response) => {
          this.scripts = response;
        })
        .catch((error) => {
          this.handleError('Error getting scripts! ' + error);
        });
    },
    async getOperatingSystems() {
      return await operatingSystemAPIService.get(this.$router)
        .then((response) => {
          this.operatingSystems = response;
        })
        .catch((error) => {
          this.handleError('Error getting operating systems! ' + error);
        })
    },
    async getHosts() {
      return await hostsAPIService.getHostsWithOperatingSystems(this.$router)
        .then((response) => {
          this.hosts = response;
        })
        .catch((error) => {
          this.handleError('Error getting hosts! ' + error);
        });
    },
    getOperatingSystemName (operating_system_id) {
      const operatingSystem = this.operatingSystems.find(({id}) => id === operating_system_id);
      return operatingSystem.friendly_name;
    },
    getScriptName (script_id) {
      const script = this.scripts.find(({id}) => id === script_id)
      return script.name;
    },
    getHostName (host_id) {
      const host = this.hosts.find(({id}) => id === host_id)
      return host.name;
    },
    async getScriptAssignments() {
      this.scriptAssignments = []
      return await scriptAssignmentsAPIService.get(this.$router)
        .then((response) => {
          response.forEach((script) => {
            const updatedScriptAssignment = {
              ...script,
              operatingSystemName: this.getOperatingSystemName(script.operating_system_id),
              scriptName: this.getScriptName(script.script_id),
              hostName: this.getHostName(script.host_id),
            }
            this.scriptAssignments.push(updatedScriptAssignment);
          })
        })
        .catch((error) => {
          this.handleError('Error getting script assignment! ' + error);
        });
    },
    openNewModal() {
      this.currentScriptAssignment = {
        operating_system_id: null,
        script_id: null,
        host_id: null,
      };
      this.currentScriptAssignmentIndex = null;
      this.openModal('add_or_update');
    },
    openUpdateModal(row, index) {
      const scriptAssignment = this.scriptAssignments[index];
      if (!scriptAssignment) {
        return;
      }
      this.currentScriptAssignment = {
        operating_system_id: row.operating_system_id,
        script_id: row.script_id,
        host_id: row.host_id,
      };
      this.currentScriptAssignmentIndex = index;
      this.openModal('add_or_update');
    },
    async addOrUpdateScriptAssignment() {
      const scriptData = {
        ...this.currentScriptAssignment,
      }
      if (this.currentScriptAssignmentIndex !== null) {
        try {
          const scriptAssignmentId = this.scriptAssignments[this.currentScriptAssignmentIndex].id;
          await scriptAssignmentsAPIService.update(scriptAssignmentId, scriptData);
        } catch (e) {
          this.handleError('Failure to update script');
          this.closeModal('add_or_update');
          return;
        }
      } else {
        try {
          await scriptAssignmentsAPIService.create(scriptData);
        } catch (e) {
          this.handleError('Failure to add script');
          this.closeModal('add_or_update');
          return;
        }
      }
      setTimeout(async () => {
        await this.getScriptAssignments();
        this.closeModal('add_or_update');
      }, 50); // Allow create/update call to finish before getting updated list of script assignments
    },
    deleteScriptAssignment(index) {
      const scriptAssignmentId = this.scriptAssignments[index].id;
      return scriptAssignmentsAPIService.delete(scriptAssignmentId, this.$router)
        .then(() => {
          this.scriptAssignments.splice(index, 1);
          this.handleSuccess('Successfully deleted script assignment');
        })
        .catch((error) => {
          this.handleError('Error deleting script assignment: ' + error);
        });
    },
    openModal(name) {
      this.modals[name] = true
    },
    closeModal(name) {
      this.modals[name] = false;
    },
    handleSuccess(text) {
      this.success_txt = text;
      this.openModal('success');
      this.spinning = false;
      setTimeout(() => {
        this.modals['success'] = false;
      }, 1500); // Hide after 1.5 secs
    },
    handleError(error) {
      this.error_txt = error;
      this.openModal('error');
      this.spinning = false;
      setTimeout(() => {
        this.modals['error'] = false;
      }, 1500); // Hide after 1.5 secs
    },
  }
}
</script>

<style scoped>

</style>
