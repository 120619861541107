<template>
  <auth-layout pageClass="register-page">
    <div class="row d-flex justify-content-center">
      <div class="col-md-8">
        <div class="header-text">
          <h2>AI Superadmin Registration Page</h2>
          <h4>Register today to get access to the most advanced system</h4>
          <hr>
        </div>
      </div>
      <div class="col-md-5 ml-auto">
        <fade-render-transition :duration="200">
          <div class="media">
            <div class="media-left">
              <div class="icon">
                <i class="nc-icon nc-circle-09"></i>
              </div>
            </div>
            <div class="media-body">
              <h4>Connect today!</h4>
              <p>To get started, register with your information.</p>
            </div>
          </div>
        </fade-render-transition>
        <fade-render-transition :delay="200" :duration="200">
          <div class="media">
            <div class="media-left">
              <div class="icon">
                <i class="nc-icon nc-preferences-circle-rotate"></i>
              </div>
            </div>
            <div class="media-body">
              <h4>Awesome Performance</h4>
              <p>Learn how you can manage your systems and monitor them better using AI</p>
            </div>
          </div>
        </fade-render-transition>
        <fade-render-transition :delay="400" :duration="200">
          <div class="media">
            <div class="media-left">
              <div class="icon">
                <i class="nc-icon nc-planet"></i>
              </div>
            </div>
            <div class="media-body">
              <h4>Support</h4>
              <p>Having trouble signing in? Give us a call and we'll help you get started!.</p>
            </div>
          </div>
        </fade-render-transition>
      </div>
      <div class="col-md-4 mr-auto">
        <form method="#" action="#">
          <card class="card-plain">
            <div>
              <fg-input v-model="first_name" placeholder="Your First Name"></fg-input>
              <fg-input v-model="last_name" placeholder="Your Last Name"></fg-input>
              <fg-input v-model="company" placeholder="Company"></fg-input>
              <fg-input v-model="email" placeholder="Email" type="email"></fg-input>
              <fg-input v-model="password" placeholder="Password" type="password"></fg-input>
              <fg-input v-model="password2" placeholder="Password confirmation" type="password"></fg-input>
            </div>
            <div class="text-center">
              <button @click="createAccount" type="button" class="btn btn-fill btn-default btn-neutral btn-wd">Create Account</button>
            </div>
          </card>
        </form>
      </div>
    </div>
  </auth-layout>
</template>
<script>
  import Swal from "../Components/SweetAlert.vue";
  import { FadeRenderTransition } from 'src/components/index'
  import AuthLayout from './AuthLayout.vue'

  export default {
    components: {
      FadeRenderTransition,
      AuthLayout,
    },
    data() {
      return {
        first_name: null,
        last_name: null,
        company: null,
        email: null,
        password: null,
        password2: null,
      }
    },
    methods: {
      createAccount() {
        Swal.fire({
          title: `Account created!`,
          buttonsStyling: false,
          confirmButtonClass: 'btn btn-success btn-fill'
        })
      },
      toggleNavbar () {
        document.body.classList.toggle('nav-open')
      },
      closeMenu () {
        document.body.classList.remove('nav-open')
        document.body.classList.remove('off-canvas-sidebar')
      }
    },
    beforeDestroy () {
      this.closeMenu()
    }
  }
</script>
<style>
</style>
