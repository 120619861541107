import CommonAPIService from './CommonHandler';

export class ChecksAPIService {
  constructor() {
    this.CommonAPIService = new CommonAPIService();
  }
  create(check, router) {
    const url = '/api/checks';
    return this.CommonAPIService.postCall(url, check, router);
  }
  delete(id, router) {
    const url = `/api/checks/${id}`;
    return this.CommonAPIService.deleteCall(url, null, router);
  }
  update(id, check, router) {
    const url = `/api/checks/${id}`;
    return this.CommonAPIService.putCall(url, check, router);
  }
  get(router) {
    const url = '/api/checks';
    return this.CommonAPIService.getCall(url, null, router);
  }
  getChecksWithPlugins(router) {
    const url = '/api/checks/plugins';
    return this.CommonAPIService.getCall(url, null, router);
  }
  linkCheckWithPlugins(check_id, plugin_ids, router) {
    const url = `/api/checks/${check_id}/${plugin_ids}/link_plugins`;
    return this.CommonAPIService.getCall(url, null, router);
  }
}

export default ChecksAPIService;
