<template>
  <div>
    <card>
      <div class="col-sm-12 text-center">
        <h3 class="text-center" style="margin-top: 0">Super AI Interface</h3>
      </div>
      <div class="row justify-content-center" style="margin-bottom: 10px">
        <div class="col-auto text-right">
          <h5 style="margin: 0;">Show Context</h5>
        </div>
        <div class="col-auto text-left">
          <input type="checkbox" v-model="show_context">
        </div>
      </div>
      <div class="row" style="margin-bottom: 10px">
        <div class="col-sm-12 text-center">
          You can run commands such as
          <el-button
            class="btn btn-primary"
            @click="runCommand('reboot a server')">Reboot a server
          </el-button>
          ,
          <el-button
            class="btn btn-primary"
            @click="runCommand('Please patch my server')">Please patch my server
          </el-button>
          or
          <el-button
            class="btn btn-primary"
            @click="runCommand('I would like to monitor a server')">I would like to monitor a server
          </el-button>
          .
        </div>
      </div>
    </card>
    <card v-if="show_context">
      <div class="row">
        <div class="col-sm-12 text-center">
          <h5 style="margin-top: 5px">
            Use context to guide the AI and control its behavior for conversations.
          </h5>
          <textarea style="width: 1000px" rows="10" v-model="context"></textarea>
          <div class="row justify-content-center">
            <div class="col-auto text-right">
              <h5 style="margin: 0;">Send Context</h5>
            </div>
            <div class="col-auto text-left">
              <input type="checkbox" v-model="send_context">
            </div>
          </div>
        </div>
      </div>
    </card>
    <card>
      <div class="chat-component">
        <div class="chat-display" ref="chatDisplay">
          <div v-for="message in items" :key="message.id" :class="['message', message.type]">
            <span v-if="message.type === 'user'">You: </span>
            <span v-else-if="message.type === 'bot'">AI Super Admin: </span>
            <div v-else-if="message.type === 'loading'">
              <b-spinner small></b-spinner>
              {{ message.text }}
            </div>
            <span v-if="message.type !== 'loading'">{{ message.text }}</span>
          </div>
        </div>
        <input v-model="userInput" @keyup.enter="sendMessage" placeholder="Type a message..."/>
      </div>
      <div class="text-center">
        <el-button round type="primary" @click="clear">Clear</el-button>
      </div>
    </card>
    <el-dialog
      center
      title="Success"
      :visible.sync="modals.success">
      <div class="text-center">
        <span>Success: {{ success_txt }}</span>
        <br>
        <span slot="footer" class="dialog-footer">
          <button class="btn btn-success" @click="closeModal('success')">OK</button>&nbsp;
        </span>
      </div>
    </el-dialog>
    <el-dialog
      center
      title="Error"
      :visible.sync="modals.error">
      <div class="text-center">
        <span>Error: {{ error_txt }}</span>
        <br>
        <span slot="footer" class="dialog-footer">
          <button class="btn btn-danger" @click="closeModal('error')">OK</button>&nbsp;
        </span>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { Dialog, Button } from 'element-ui';
import { useLoginStore } from '@/stores/loginStore';
import RunningCommandsAPIService from "src/servicehandlers/RunningCommandsAPIService";
const runningCommandsAPIService = new RunningCommandsAPIService();
import AIAPIService from "src/servicehandlers/AIAPIService";
const aiAPIService = new AIAPIService();
import HostsAPIService from "src/servicehandlers/HostsAPIService";
const hostsAPIService = new HostsAPIService();
import axios from "axios";
export default {
  name: "AIInterface",
  components: {
    [Dialog.name]: Dialog,
    [Button.name]: Button,
  },
  data() {
    return {
      randomUUID: '',
      conversation_history: null,
      isSending: false,
      success_txt: null,
      error_txt: null,
      spinning: false,
      user_id: null,
      store: null,
      userInput: '',
      items: [],
      uuid: null,
      first_call: true,
      show_context: false,
      send_context: true,
      is_query_response: false,
      context: "Role: Your name is AI Super Admin. " +
        "You're an expert in servers, computers, and cyber security and you reply with friendly and brief answers only. \n" +
        "Only reply to the human with one statement or question at a time.",
      modals: {
        error: false,
        success: false,
        basic: false,
      },
    }
  },
  mounted() {
    const store = useLoginStore();
    this.store = store;
    this.user_id = store.user.id;
    const beginningMessage = {
      id: this.items.length,
      text: "Hi, how can I help you?",
      type: "bot",
    };
    this.generateRandomString();
    this.items.push(beginningMessage);
  },
  methods: {
    clear() {
      this.items = [];
      const beginningMessage = {
        id: this.items.length,
        text: "Hi, how can I help you?",
        type: "bot",
      };
      this.items.push(beginningMessage);
    },
    scrollToBottom() {
      this.$nextTick(() => {
        const chatDisplay = this.$refs.chatDisplay;
        chatDisplay.scrollTop = chatDisplay.scrollHeight;
      });
    },
    runCommand(text) {
      this.spinning = true;
      this.userInput = text;
      this.sendMessage();
    },
    generateRandomString() {
      const length = 10; // Adjust the desired length of the random string
      const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
      let result = '';

      for (let i = 0; i < length; i++) {
        const randomIndex = Math.floor(Math.random() * characters.length);
        result += characters.charAt(randomIndex);
      }

      this.randomUUID = result;
    },
    async sendMessage() {
      if (this.isSending) {
        return;
      }
      if (this.userInput.trim().toLowerCase() === 'clear') {
        this.clear();
        this.userInput = '';
        return;
      }
      this.isSending = true;

      this.scrollToBottom();
      if (!this.userInput.trim()) {
        return;
      }
      this.spinning = true;

      const userMessage = {
        id: this.items.length,
        text: this.userInput,
        type: "user",
      };
      if (this.send_context) {
        userMessage.context = this.context;
      }
      this.items.push(userMessage);
      this.scrollToBottom();

      const theMessage = {
        "query": this.userInput,
        "key": this.randomUUID,
      };
      if (this.send_context) {
        theMessage.context = this.context;
      }
      this.userInput = "";

      const loadingMessage = {
        id: this.items.length,
        text: "Waiting for response, this may take up to two minutes...",
        type: "loading",
      };
      this.items.push(loadingMessage);

      let base_action = null;
      try {
        const parameters = {
        };
        const response = await aiAPIService.cyber_ai_call(theMessage, this.$router);
        this.send_context = false;
        this.items.pop();
        const botMessage = {
          id: this.items.length,
          text: response,
          type: "bot",
        };
        this.items.push(botMessage);


        this.isSending = false;
      } catch (error) {
        this.spinning = false;
        this.isSending = false;
        if (error.code === 'ECONNABORTED') {
          this.handleError('Connection was aborted!')
        } else {
          this.handleError('There was an error sending the message! ' + error)
        }
      }
    },
    parseToObject(str) {
      try {
        const jsonStr = str.replace(/'/g, '"');
        return JSON.parse(jsonStr);
      } catch (e) {
        return null;
      }
    },
    handleError(error_msg) {
      const self = this;
      this.error_txt = error_msg;
      this.openModal('error');
      this.spinning = false;
      setTimeout(function () {
        self.modals['error'] = false; // Use your variable name
      }, 1500); // Hide after 5 secs
    },
    closeModal(name) {
      this.modals[name] = false;
    },
    openModal(name) {
      this.modals[name] = true;
    },
  }
}
</script>

<style scoped>
.chat-component {
  max-width: 800px;
  margin: auto;
  padding: 20px;
}
.chat-display {
  border: 1px solid #ccc;
  padding: 20px;
  margin-bottom: 10px;
  overflow-y: auto;
  max-height: 700px;
}
input {
  width: 100%;
  padding: 10px;
  box-sizing: border-box;
}
.message {
  padding: 10px;
  margin: 5px 0;
  border-bottom: 1px solid #eee; /* Lightly marked border between messages */
}
.user {
  background-color: #fff; /* White background for user message */
}
.bot {
  background-color: #f0f0f0; /* Light grey background for bot message */
}
.title-txt {
  color: dimgrey;
  font-size: 35px;
}
.title-txt-2 {
  color: dimgrey;
  font-size: 25px;
}
</style>
