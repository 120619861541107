import { CommonAPIService } from './CommonHandler';

export class FilesAPIHandler {
  constructor() {
    this.CommonAPIService = new CommonAPIService();
  }

  upload(formData, router) {
    const url = `/api/files`;
    return this.CommonAPIService.newFileCall(url, formData, router);
  }
  restoreFromGuardian(formData, router) {
    const url = `/api/files/guardian`;
    return this.CommonAPIService.newFileCall(url, formData, router);
  }
  downloadMib(mibName, router) {
    const url = `/api/snmp/download/${mibName}`;
    return this.CommonAPIService.fileCall(url, '', router);
  }
}

export default FilesAPIHandler;
