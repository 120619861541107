import CommonAPIService from 'src/servicehandlers/CommonHandler';

export class ScheduleAPIService {
  constructor() {
    this.search = '';
    this.CommonAPIService = new CommonAPIService();
  }

  get(router) {
    const url = '/api/schedules';
    return this.CommonAPIService.getCall(url, '', router);
  }
  delete(id, router) {
    const url = '/api/schedules';
    return this.CommonAPIService.deleteCall(url, null, router);
  }
  getById(id, router) {
    const url = `/api/schedules/${id}`;
    return this.CommonAPIService.getCall(url, null, router);
  }
  update(id, schedule, router) {
    const url = `/api/schedules/${id}`;
    return this.CommonAPIService.putCall(url, schedule, router);
  }
  create(schedule, router) {
    const url = `/api/schedules`;
    return this.CommonAPIService.postCall(url, schedule, router);
  }
}

export default ScheduleAPIService;
