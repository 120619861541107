<template>
  <div>
    <card v-if="spinning">
      <div class="row">
        <div class="col-sm-12 text-center">
          <b-spinner variant="primary" label="Spinning"></b-spinner>
        </div>
      </div>
    </card>
    <card>
      <div slot="header">
        <h4 class="card-title">Script Types
          <el-tooltip content="Add New Script Type">
            <el-button type="text" @click="openNewModal">
              <i class="fa-solid fa-circle-plus"></i>
            </el-button>
          </el-tooltip>
        </h4>
      </div>
      <el-table :data="scriptTypes" border style="width: 100%">
        <el-table-column prop="command_name" label="Command Name" width="300"></el-table-column>
        <el-table-column prop="description" label="Description" width="500"></el-table-column>
        <el-table-column label="Actions" width="200" align="center">
          <template slot-scope="scope">
            <link
              rel="stylesheet"
              href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.0.0/css/all.min.css">
            <div class="center-content">
              <el-tooltip content="Update Script Type">
                <el-button type="text" @click="openUpdateModal(scope.$index)">
                  <i class="fa-regular fa-floppy-disk"></i>
                </el-button>
              </el-tooltip>
              <el-tooltip>
                <template #content>
                  {{scriptTypes[scope.$index].disableDelete ?
                    'This type is currently assigned to a script and cannot be removed.'
                    : 'Delete Script Type' }}
                </template>
                <el-button type="text" :disabled=scriptTypes[scope.$index].disableDelete @click="deleteScriptType(scope.$index)">
                  <el-icon name="delete" class="icon-large icon-red"></el-icon>
                </el-button>
              </el-tooltip>
            </div>
          </template>
        </el-table-column>
      </el-table>
      <el-dialog
        center
        title="Error"
        :visible.sync="modals.error">
        <div class="text-center">
          <span>Error: {{ error_txt }}</span>
          <div class="text-center mt-4">
          <span slot="footer">
            <l-button type="btn btn-danger" round @click="closeModal('error')">OK</l-button>&nbsp;
          </span>
          </div>
        </div>
      </el-dialog>
      <el-dialog
        center
        title="Success"
        :visible.sync="modals.success">
        <div class="text-center">
          <span>{{ success_txt }}</span>
          <div class="text-center">
          <span slot="footer">
            <l-button type="btn btn-success" round @click="closeModal('success')">OK</l-button>&nbsp;
          </span>
          </div>
        </div>
      </el-dialog>
      <el-dialog :visible.sync="modals.add_or_update" width="49%">
        <div class="text-center">
          <h3 style="margin-top: -20px;">{{currentScriptTypeIndex === null ? 'Add' : 'Update'}} Script Type</h3>
        </div>
        <ValidationObserver v-slot="{ handleSubmit }">
          <Form @submit.prevent="handleSubmit(addOrUpdateScriptType)" >
            <div class="text-center">
              <div class="row my-5 ml-3">
                <div class="col-sm-6">
                  <ValidationProvider
                    rules="required"
                    v-slot="{ passed, failed }"
                  >
                    <fg-input
                      type="text"
                      v-model="currentScriptTypeName"
                      label="Name"
                      placeholder="Enter name"
                      style="width: 400px"
                      :error="failed ? 'Name is required': null"
                      :hasSuccess="passed">
                    </fg-input>
                  </ValidationProvider>
                </div>
                <div class="col-sm-6">
                  <ValidationProvider
                    rules="required"
                    v-slot="{ passed, failed }"
                  >
                    <fg-input
                      type="text"
                      v-model="currentScriptTypeDescription"
                      label="Description"
                      placeholder="Enter description"
                      style="width: 400px"
                      :error="failed ? 'Description is required': null"
                      :hasSuccess="passed">
                    </fg-input>
                  </ValidationProvider>
                </div>
              </div>
              <span slot="footer" class="text-center">
                <button type="submit" class="btn btn-primary btn-round btn-wd mr-1" >Save</button>
                <button type="button" class="btn btn-danger btn-round btn-wd" @click="closeModal('add_or_update')">Cancel</button>
              </span>
            </div>
          </Form>
        </ValidationObserver>
      </el-dialog>
    </card>
  </div>
</template>

<script>
import ScriptTypesAPIService from "src/servicehandlers/ScriptTypesAPIService";
const scriptTypesAPIService = new ScriptTypesAPIService();
import ScriptsAPIService from "src/servicehandlers/ScriptsAPIService";
const scriptsAPIService = new ScriptsAPIService();
import { Button, Dialog, Icon, Input, Table, TableColumn, Tooltip } from 'element-ui';

export default {
  name: "ScriptTypes",
  components: {
    [Button.name]: Button,
    [Dialog.name]: Dialog,
    [Icon.name]: Icon,
    [Input.name]: Input,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Tooltip.name]: Tooltip,
  },
  data() {
    return {
      spinning: false,
      scripts: [],
      scriptTypes: [],
      error_txt: false,
      success_txt: false,
      currentScriptTypeName: '',
      currentScriptTypeDescription: '',
      currentScriptTypeIndex: null,
      modals: {
        error: false,
        success: false,
        add_or_update: false,
      },
    }
  },
  async mounted() {
    await this.getScripts();
    await this.getScriptTypes();
  },
  methods: {
    async getScripts() {
      return await scriptsAPIService.get(this.$router)
        .then((response) => {
          this.scripts = response;
        })
        .catch((error) => {
          this.handleError('Error getting script types! ' + error);
        });
    },
    async getScriptTypes() {
      this.scriptTypes = [];
      return await scriptTypesAPIService.get(this.$router)
        .then((response) => {
          response.forEach((scriptType) => {
            const updatedScriptType = {
              ...scriptType,
              disableDelete: this.scripts.some(({ script_type_id }) => script_type_id === scriptType.id),
            }
            this.scriptTypes.push(updatedScriptType);
          })
        })
        .catch((error) => {
          this.handleError('Error getting script types! ' + error);
        });
    },
    openNewModal() {
      this.currentScriptTypeName = '';
      this.currentScriptTypeDescription = '';
      this.currentScriptTypeIndex = null;
      this.openModal('add_or_update');
    },
    openUpdateModal(index) {
      const scriptType = this.scriptTypes[index];
      if (!scriptType) {
        return;
      }
      this.currentScriptTypeIndex = index;
      this.currentScriptTypeName = scriptType.command_name;
      this.currentScriptTypeDescription = scriptType.description;
      this.openModal('add_or_update');
    },
    async addOrUpdateScriptType() {
      const scriptTypeData = {
        command_name: this.currentScriptTypeName,
        description: this.currentScriptTypeDescription,
      };

      if (this.currentScriptTypeIndex !== null) {
        this.scriptTypes[this.currentScriptTypeIndex].command_name = this.currentScriptTypeName;
        this.scriptTypes[this.currentScriptTypeIndex].description = this.currentScriptTypeDescription;
        this.scriptTypes[this.currentScriptTypeIndex] = { ...this.scriptTypes[this.currentScriptTypeIndex], ...scriptTypeData };
        try {
          const scriptTypeId = this.scriptTypes[this.currentScriptTypeIndex].id;
          const scriptType = {
            command_name: this.scriptTypes[this.currentScriptTypeIndex].command_name,
            description: this.scriptTypes[this.currentScriptTypeIndex].description,
          }
          await scriptTypesAPIService.update(scriptTypeId, scriptType, this.$router);
        } catch (e) {
          this.handleError('Failure to update script type');
          this.closeModal('add_or_update');
          return;
        }
      } else {
        try {
          await scriptTypesAPIService.create(scriptTypeData, this.$router);
        } catch (e) {
          this.handleError('Failure to add script type');
          this.closeModal('add_or_update');
          return;
        }
      }
      await this.getScriptTypes();
      this.closeModal('add_or_update');
    },
    deleteScriptType(index) {
      const scriptTypeId = this.scriptTypes[index].id;
      return scriptTypesAPIService.delete(scriptTypeId, this.$router)
        .then(() => {
          this.scriptTypes.splice(index, 1);
          this.handleSuccess('Successfully deleted script type');
        })
        .catch((error) => {
          this.handleError('Error deleting script type: ' + error);
        });
    },
    openModal(name) {
      this.modals[name] = true
    },
    closeModal(name) {
      this.modals[name] = false;
    },
    handleSuccess(text) {
      this.success_txt = text;
      this.openModal('success');
      this.spinning = false;
      setTimeout(() => {
        this.modals['success'] = false;
      }, 1500); // Hide after 1.5 secs
    },
    handleError(error) {
      this.error_txt = error;
      this.openModal('error');
      this.spinning = false;
      setTimeout(() => {
        this.modals['error'] = false;
      }, 1500); // Hide after 1.5 secs
    },
  }
}
</script>

<style scoped>

</style>
