import CommonAPIService from './CommonHandler';

export class ChecksAPIService {
  constructor() {
    this.CommonAPIService = new CommonAPIService();
  }
  getHostsAndChecksCounts(router) {
    const url = `/api/checks_to_hosts/stat_count`;
    return this.CommonAPIService.getCall(url, null, router);
  }

  update(id, check, router) {
    const url = `/api/checks_to_hosts/${id}`;
    return this.CommonAPIService.putCall(url, check, router);
  }
  get(router) {
    const url = `/api/checks_to_hosts`;
    return this.CommonAPIService.getCall(url, null, router);
  }
}

export default ChecksAPIService;
