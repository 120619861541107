<template>
  <div class="wrapper" :class="{'nav-open': $sidebar.showSidebar}">
    <side-bar>
      <template slot="links">
        <sidebar-item v-if="ai_link" :link="{name: 'AI', icon: 'nc-icon nc-chart-pie-35'}">
          <sidebar-item v-if="ai_interface_page" :link="{name: 'AI Interface', icon: 'nc-icon nc-chart-pie-35', path: '/ai/interface'}"></sidebar-item>
          <sidebar-item v-if="ai_cyber_security" :link="{name: 'AI Cyber Security', icon: 'nc-icon nc-chart-pie-35', path: '/ai/analysis'}"></sidebar-item>
        </sidebar-item>
        <sidebar-item v-if="configuration_link" :link="{name: 'Configuration', icon: 'nc-icon nc-chart-pie-35'}">
          <sidebar-item v-if="check_assignments_page" :link="{name: 'Check Assignments', icon: 'nc-icon nc-chart-pie-35', path: '/configuration/check_assignments'}"></sidebar-item>
          <sidebar-item v-if="collector_assignments_page" :link="{name: 'Collector Assignments', icon: 'nc-icon nc-chart-pie-35', path: '/configuration/collector_assignments'}"></sidebar-item>
          <sidebar-item v-if="rights_assignments_page" :link="{name: 'Rights Assignments', icon: 'nc-icon nc-chart-pie-35', path: '/configuration/rights_assignments'}"></sidebar-item>
          <sidebar-item v-if="script_assignments_page" :link="{name: 'Script Assignments', icon: 'nc-icon nc-chart-pie-35', path: '/configuration/script_assignments'}"></sidebar-item>
          <sidebar-item v-if="checks_page" :link="{name: 'Checks', icon: 'nc-icon nc-chart-pie-35', path: '/configuration/checks'}"></sidebar-item>
          <sidebar-item v-if="command_maps_page" :link="{name: 'Command Maps', icon: 'nc-icon nc-chart-pie-35', path: '/configuration/command_maps'}"></sidebar-item>
          <sidebar-item v-if="hosts_page" :link="{name: 'Hosts', icon: 'nc-icon nc-chart-pie-35', path: '/configuration/hosts'}"></sidebar-item>
          <sidebar-item v-if="host_groups_page" :link="{name: 'Host Groups', icon: 'nc-icon nc-chart-pie-35', path: '/configuration/host_groups'}"></sidebar-item>
          <sidebar-item v-if="operating_systems_page" :link="{name: 'Operating Systems', icon: 'nc-icon nc-chart-pie-35', path: '/configuration/operating_systems'}"></sidebar-item>
          <sidebar-item v-if="plugins_page" :link="{name: 'Plugins', icon: 'nc-icon nc-chart-pie-35', path: '/configuration/plugins'}"></sidebar-item>
          <sidebar-item v-if="profiles_page" :link="{name: 'Profiles', icon: 'nc-icon nc-chart-pie-35', path: '/configuration/profiles'}"></sidebar-item>
          <sidebar-item v-if="scheduling_page" :link="{name: 'Scheduling', icon: 'nc-icon nc-chart-pie-35', path: '/configuration/scheduling'}"></sidebar-item>
          <sidebar-item v-if="scripts_page" :link="{name: 'Scripts', icon: 'nc-icon nc-chart-pie-35', path: '/configuration/scripts'}"></sidebar-item>
          <sidebar-item v-if="script_types_page" :link="{name: 'Script Types', icon: 'nc-icon nc-chart-pie-35', path: '/configuration/script_types'}"></sidebar-item>
          <sidebar-item v-if="snmp_page" :link="{name: 'SNMP', icon: 'nc-icon nc-chart-pie-35', path: '/configuration/snmp'}"></sidebar-item>
          <sidebar-item v-if="snmp_trap_translation" :link="{name: 'SNMP Trap Translation', icon: 'nc-icon nc-chart-pie-35', path: '/configuration/snmp_trap_translation'}"></sidebar-item>
        </sidebar-item>
        <sidebar-item v-if="monitoring_link" :link="{name: 'Monitoring', icon: 'nc-icon nc-chart-pie-35'}">
          <sidebar-item v-if="alerts_page" :link="{name: 'Alerts', icon: 'nc-icon nc-chart-pie-35', path: '/monitoring/alerts'}"></sidebar-item>
          <sidebar-item v-if="check_view_page" :link="{name: 'Check View', icon: 'nc-icon nc-chart-pie-35', path: '/monitoring/check_view'}"></sidebar-item>
        </sidebar-item>
        <sidebar-item v-if="reports_link" :link="{name: 'Reports', icon: 'nc-icon nc-chart-pie-35'}">
          <sidebar-item v-if="audit_logs_page" :link="{name: 'Audit Logs', icon: 'nc-icon nc-chart-pie-35', path: '/reports/audit_logs'}"></sidebar-item>
          <sidebar-item v-if="ai_completed_page" :link="{name: 'AI Completed Commands', icon: 'nc-icon nc-chart-pie-35', path: '/reports/ai_completed_commands'}"></sidebar-item>
          <sidebar-item v-if="ai_running_commands" :link="{name: 'AI Running Commands', icon: 'nc-icon nc-chart-pie-35', path: '/reports/ai_running_commands'}"></sidebar-item>
          <sidebar-item v-if="availability_page" :link="{name: 'Availability Report', icon: 'nc-icon nc-chart-pie-35', path: '/reports/availability_report'}"></sidebar-item>
        </sidebar-item>
        <sidebar-item v-if="support_link" :link="{name: 'Support', icon: 'nc-icon nc-chart-pie-35'}">
          <sidebar-item v-if="support_page" :link="{name: 'Support', icon: 'nc-icon nc-chart-pie-35', path: '/support/support'}"></sidebar-item>
        </sidebar-item>
        <sidebar-item v-if="settings_link" :link="{name: 'Settings', icon: 'nc-icon nc-chart-pie-35'}">
          <sidebar-item v-if="license_page" :link="{name: 'License', icon: 'nc-icon nc-chart-pie-35', path: '/settings/license'}"></sidebar-item>
          <sidebar-item v-if="email_page" :link="{name: 'Email', icon: 'nc-icon nc-chart-pie-35', path: '/settings/email'}"></sidebar-item>
        </sidebar-item>
        <sidebar-item v-if="tools_link" :link="{name: 'Tools', icon: 'nc-icon nc-chart-pie-35'}">
          <sidebar-item v-if="backup_and_restore_page" :link="{name: 'Backup and Restore', icon: 'nc-icon nc-chart-pie-35', path: '/tools/backup_restore'}"></sidebar-item>
          <sidebar-item v-if="fixit_tools_page" :link="{name: 'Fixit Tools', icon: 'nc-icon nc-chart-pie-35', path: '/tools/fixit_tools'}"></sidebar-item>
          <sidebar-item v-if="backup_and_restore_guardian_page" :link="{name: 'Backup and Restore Guardian', icon: 'nc-icon nc-chart-pie-35', path: '/tools/backup_restore_guardian'}"></sidebar-item>
        </sidebar-item>
        <sidebar-item v-if="users_link" :link="{name: 'Users', icon: 'nc-icon nc-chart-pie-35'}">
          <sidebar-item v-if="auth_groups_page" :link="{name: 'Authentication Groups', icon: 'nc-icon nc-chart-pie-35', path: '/users/authentication_groups'}"></sidebar-item>
          <sidebar-item v-if="users_page" :link="{name: 'Users', icon: 'nc-icon nc-chart-pie-35', path: '/users/users'}"></sidebar-item>
          <sidebar-item v-if="user_rights_page" :link="{name: 'User Scopes', icon: 'nc-icon nc-chart-pie-35', path: '/users/user_scopes'}"></sidebar-item>
          <sidebar-item v-if="user_groups_page" :link="{name: 'User Groups', icon: 'nc-icon nc-chart-pie-35', path: '/users/user_groups'}"></sidebar-item>
          <sidebar-item v-if="user_group_assignments" :link="{name: 'User Group Assignments', icon: 'nc-icon nc-chart-pie-35', path: '/users/user_group_assignments'}"></sidebar-item>
        </sidebar-item>
      </template>
    </side-bar>
    <div class="main-panel">
      <top-navbar></top-navbar>
      <dashboard-content @click.native="toggleSidebar">
      </dashboard-content>
      <content-footer></content-footer>
    </div>
  </div>
</template>
<script>
  import { useLoginStore } from '@/stores/loginStore';
  import TopNavbar from './TopNavbar.vue'
  import ContentFooter from './ContentFooter.vue'
  import DashboardContent from './Content.vue'
  import PerfectScrollbar from 'perfect-scrollbar';
  import 'perfect-scrollbar/css/perfect-scrollbar.css';

  function hasElement(className) {
    return document.getElementsByClassName(className).length > 0;
  }

  function initScrollbar(className) {
    if (hasElement(className)) {
      new PerfectScrollbar(`.${className}`);
    } else {
      // try to init it later in case this component is loaded async
      setTimeout(() => {
        initScrollbar(className);
      }, 100);
    }
  }

  export default {
    components: {
      TopNavbar,
      ContentFooter,
      DashboardContent,
    },
    data() {
      return {
        ai_completed_page: false,
        ai_cyber_security: true,
        ai_interface_page: false,
        ai_link: false,
        ai_running_commands: false,
        ai_running_commands_page: false,
        alerts_page: false,
        assignments_link: false,
        audit_logs_page: false,
        auth_group_page: false,
        auth_groups_page: false,
        availability_page: false,
        backup_and_restore_guardian_page: false,
        backup_and_restore_page: false,
        check_assignments_page: false,
        check_view_page: false,
        checks_page: false,
        collector_assignments_page: false,
        command_maps_page: false,
        configuration_link: false,
        email_page: false,
        fixit_tools_page: false,
        host_groups_page: false,
        hosts_page: false,
        license_page: false,
        monitoring_link: false,
        operating_systems_page: false,
        plugins_page: false,
        profiles_page: false,
        reports_link: false,
        rights_assignments_page: true,
        scheduling_page: false,
        script_assignments_page: false,
        script_types_page: false,
        scripts_page: false,
        settings_link: false,
        sla_report_page: false,
        snmp_page: false,
        snmp_trap_translation: false,
        snmp_trap_translation_page: false,
        support_link: false,
        support_page: false,
        tools_link: false,
        user_group_assignments: false,
        user_group_assignments_page: false,
        user_groups_page: false,
        user_rights_page: false,
        users_link: false,
        users_page: false,
      }
    },
    methods: {
      toggleSidebar () {
        if (this.$sidebar.showSidebar) {
          this.$sidebar.displaySidebar(false)
        }
      },
      initScrollbar() {
        let docClasses = document.body.classList;
        let isWindows = navigator.platform.startsWith('Win');
        if (isWindows) {
          // if we are on windows OS we activate the perfectScrollbar function
          initScrollbar('main-panel');

          docClasses.add('perfect-scrollbar-on');
        } else {
          docClasses.add('perfect-scrollbar-off');
        }
      }
    },
    mounted() {
      const store = useLoginStore();
      this.pageRights = store.appRights;
      for (const right of store.appRights) {
        if (right.name === 'ai-link') {
          this.ai_link = true;
        }
        if (right.name === 'configuration-link') {
          this.configuration_link = true;
        }
        if (right.name === 'monitoring-link') {
          this.monitoring_link = true;
        }
        if (right.name === 'assignments-link') {
          this.assignments_link = true;
        }
        if (right.name === 'reports-link') {
          this.reports_link = true;
        }
        if (right.name === 'support-link') {
          this.support_link = true;
        }
        if (right.name === 'settings-link') {
          this.settings_link = true;
        }
        if (right.name === 'tools-link') {
          this.tools_link = true;
        }
        if (right.name === 'users-link') {
          this.users_link = true;
        }

        // now for the pages
        if (right.name === 'ai-interface-page') {
          this.ai_interface_page = true;
        }
        if (right.name === 'auth-groups-page') {
          this.auth_groups_page = true;
        }
        if (right.name === 'ai-running-commands') {
          this.ai_running_commands = true;
        }
        if (right.name === 'snmp-trap-translation') {
          this.snmp_trap_translation = true;
        }
        if (right.name === 'rights-assignments-page') {
          this.rights_assignments_page = true;
        }
        if (right.name === 'checks-page') {
          this.checks_page = true;
        }
        if (right.name === 'command-maps-page') {
          this.command_maps_page = true;
        }
        if (right.name === 'user-group-assignments') {
          this.user_group_assignments = true;
        }
        if (right.name === 'hosts-page') {
          this.hosts_page = true;
        }
        if (right.name === 'host-groups-page') {
          this.host_groups_page = true;
        }
        if (right.name === 'operating-systems-page') {
          this.operating_systems_page = true;
        }
        if (right.name === 'plugins-page') {
          this.plugins_page = true;
        }
        if (right.name === 'profiles-page') {
          this.profiles_page = true;
        }
        if (right.name === 'scheduling-page') {
          this.scheduling_page = true;
        }
        if (right.name === 'scripts-assignments-page') {
          this.script_assignments_page = true;
        }
        if (right.name === 'scripts') {
          this.scripts_page = true;
        }
        if (right.name === 'script-types') {
          this.script_types_page = true;
        }
        if (right.name === 'check-assignments-page') {
          this.check_assignments_page = true;
        }
        if (right.name === 'snnp-page') {
          this.snmp_page = true;
        }
        if (right.name === 'snnp-trap-translation-page') {
          this.snmp_trap_translation_page = true;
        }
        if (right.name === 'alerts-page') {
          this.alerts_page = true;
        }
        if (right.name === 'check-view-page') {
          this.check_view_page = true;
        }
        if (right.name === 'audit-logs-page') {
          this.audit_logs_page = true;
        }
        if (right.name === 'ai-completed-page') {
          this.ai_completed_page = true;
        }
        if (right.name === 'ai-running-commands-page') {
          this.ai_running_commands_page = true;
        }
        if (right.name === 'collector-assignments-page') {
          this.collector_assignments_page = true;
        }
        if (right.name === 'availability-page') {
          this.availability_page = true;
        }
        if (right.name === 'sla-report-page') {
          this.sla_report_page = true;
        }
        if (right.name === 'support-page') {
          this.support_page = true;
        }
        if (right.name === 'license-page') {
          this.license_page = true;
        }
        if (right.name === 'email-page') {
          this.email_page = true;
        }
        if (right.name === 'backup-and-restore-page') {
          this.backup_and_restore_page = true;
        }
        if (right.name === 'fixit-tools-page') {
          this.fixit_tools_page = true;
        }
        if (right.name === 'backup-and-restore-guardian-page') {
          this.backup_and_restore_guardian_page = true;
        }
        if (right.name === 'auth-groups-page') {
          this.auth_group_page = true;
        }
        if (right.name === 'users-page') {
          this.users_page = true;
        }
        if (right.name === 'user-rights-page') {
          this.user_rights_page = true;
        }
        if (right.name === 'user-groups-page') {
          this.user_groups_page = true;
        }
        if (right.name === 'user-group-assignments') {
          this.user_group_assignments_page = true;
        }
      }
      this.initScrollbar()
    }
  }

</script>
