import CommonAPIService from 'src/servicehandlers/CommonHandler';

export class SNMPAPIService {
  constructor() {
    this.search = '';
    this.CommonAPIService = new CommonAPIService();
  }

  get(router) {
    const url = '/api/snmp';
    return this.CommonAPIService.getCall(url, '', router);
  }
  getProcessedMibs(router) {
    const url = '/api/snmp/processed_mibs';
    return this.CommonAPIService.getCall(url, '', router);
  }
  processMib(mib, router) {
    const url = '/api/snmp/process';
    return this.CommonAPIService.postCall(url, mib, router);
  }
  deleteMib(mib, router) {
    const url = '/api/snmp';
    return this.CommonAPIService.deleteCall(url, mib, router);
  }
  getTrapsByID(mib_id, router) {
    const url = `/api/snmp/${mib_id}/traps`;
    return this.CommonAPIService.getCall(url, null, router);
  }
  updateTrap(trap_id, trap, router) {
    const url = `/api/snmp/${trap_id}/trap`;
    return this.CommonAPIService.putCall(url, trap, router);
  }
}

export default SNMPAPIService;
