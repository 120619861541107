<template>
  <div>
    <card v-if="spinning">
      <div class="col-sm-12 text-center">
        <b-spinner variant="primary" label="Spinning"></b-spinner>
      </div>
    </card>
    <card>
      <div slot="header">
        <h4 class="card-title">Auth Groups Rights</h4>
      </div>
      <el-table class="table-striped" border :data="auth_groups">
        <el-table-column label="Name" prop="name" align="center"></el-table-column>
        <el-table-column label="Web Rights" align="center">
          <template v-slot="{ row }">
            <el-button @click="setGroupWebAssignRights(row)">Assign Web Rights</el-button>
          </template>
        </el-table-column>
        <el-table-column label="Host Rights" align="center">
          <template v-slot="{ row }">
           <el-button @click="setGroupHostRights(row)">Assign Host Rights</el-button>
          </template>
        </el-table-column>
        <el-table-column label="Command Rights" align="center">
          <template v-slot="{ row }">
            <el-button @click="setGroupCommandRights(row)">Assign Command Rights</el-button>
          </template>
        </el-table-column>
      </el-table>
    </card>
    <card>
      <div slot="header">
        <h4 class="card-title">User Rights</h4>
      </div>
      <el-table class="table-striped" border :data="users">
        <el-table-column label="Name" align="center">
          <template v-slot="{ row }">
            {{ row.username + ' - ' + row.first_name + ' ' + row.last_name }}
          </template>
        </el-table-column>
        <el-table-column label="Web Rights" align="center">
          <template v-slot="{ row }">
            <el-button @click="setUserWebAssignRights(row)">Assign Web Rights</el-button>
          </template>
        </el-table-column>
        <el-table-column label="Host Rights" align="center">
          <template v-slot="{ row }">
            <el-button @click="setUserHostRights(row)">Assign Host Rights</el-button>
          </template>
        </el-table-column>
        <el-table-column label="Command Rights" align="center">
          <template v-slot="{ row }">
            <el-button @click="setUserCommandRights(row)">Assign Command Rights</el-button>
          </template>
        </el-table-column>
      </el-table>
    </card>
    <el-dialog
      center
      title="Error"
      :visible.sync="modals.error">
      <div class="text-center">
        <span>Error: {{ error_txt }}</span>
        <div class="text-center mt-4">
          <span slot="footer">
            <l-button type="btn btn-danger" round @click="closeModal('error')">OK</l-button>&nbsp;
          </span>
        </div>
      </div>
    </el-dialog>
    <el-dialog
      center
      title="Success"
      :visible.sync="modals.success">
      <div class="text-center">
        <span>{{ success_txt }}</span>
        <div class="text-center mt-4">
          <span slot="footer">
            <l-button type="btn btn-success" round @click="closeModal('success')">OK</l-button>&nbsp;
          </span>
        </div>
      </div>
    </el-dialog>
    <el-dialog
      center
      width="59%"
      :visible.sync="modals.rights_assignments">
      <div class="text-center">
        <div class="row">
          <div class="col-sm-12 mb-3">
            <h3 style="margin-top: -20px;">{{ dialog_title }}</h3>
          </div>
        </div>
      </div>
      <div class="ml-5">
        <el-transfer
          v-model="selectedRights"
          :data="availableRights"
          :props="{
            key: 'id',
            label: ['command', 'user_command'].includes(this.dialog_type) ? 'command_name' : 'name',
          }"
          :titles="['Available Rights', 'Selected Rights']"
          filterable
          filter-placeholder="Search check"
        />
      </div>
      <div class="text-center">
      <br>
      <span slot="footer" class="dialog-footer">
        <l-button round v-if="dialog_type === 'user_command'" type="primary" @click="saveUserHostCommand">Save</l-button>&nbsp;
        <l-button round v-if="dialog_type === 'user_host'" type="primary" @click="saveUserHostRights">Save</l-button>&nbsp;
        <l-button round v-if="dialog_type === 'user_scope'" type="primary" @click="saveUserScopeRights">Save</l-button>&nbsp;
        <l-button round v-if="dialog_type === 'scope'" type="primary" @click="saveAuthGroupScopeRights">Save</l-button>&nbsp;
        <l-button round v-if="dialog_type === 'host'" type="primary" @click="saveAuthGroupHosts">Save</l-button>&nbsp;
        <l-button round v-if="dialog_type === 'command'" type="primary" @click="saveAuthGroupCommands">Save</l-button>&nbsp;
        <l-button round type="danger" @click="closeModal('rights_assignments')">Cancel</l-button>&nbsp;
      </span>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {Table, TableColumn, Button, Dialog, Transfer } from 'element-ui';
import { useLoginStore } from '@/stores/loginStore';
import HostsAPIService from "../../../../../servicehandlers/HostsAPIService";
const hostsAPIService = new HostsAPIService();

import AuthGroupAPIService from "../../../../../servicehandlers/AuthGroupAPIService";
const authGroupAPIService = new AuthGroupAPIService();

import UsersAPIService from "../../../../../servicehandlers/UsersAPIService";
const usersAPIService = new UsersAPIService();

import CommandLookupTablesAPIService from "../../../../../servicehandlers/CommandLookupTablesAPIService";
const commandLookupTablesAPIService = new CommandLookupTablesAPIService();

import ScopesAPIService from "../../../../../servicehandlers/ScopesAPIService";
const scopesAPIService = new ScopesAPIService();

export default {
  components: {
    [Dialog.name]: Dialog,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Button.name]: Button,
    [Transfer.name]: Transfer,
  },
  name: "Assignments",
  data() {
    return {
      auth_groups: [],
      users: [],
      scopes: [],
      hosts: [],
      commands: [],
      selected_auth_group: null,
      selected_user: null,
      spinning: false,
      dialog_title: '',
      dialog_type: 'scope',
      pageRights: [],
      error_txt: null,
      success_txt: null,
      modals: {
        error: false,
        success: false,
        rights_assignments: false,
      },
      availableRights: [],
      selectedRights: [],
    }
  },
  mounted() {
    this.spinning = true;
    this.getRights();
    this.getAuthGroups();
    this.getUsers();
    this.getScopes();
    this.getHosts();
    this.getCommands();
    this.spinning = false;
  },
  methods: {
    async setGroupWebAssignRights(row) {
      this.dialog_title = 'Set Auth Group Web Rights';
      this.dialog_type = 'scope';
      this.selected_auth_group = row.id;
      this.availableRights = this.scopes;
      this.selectedRights = [];
      try {
        const linked_rights = await authGroupAPIService.getLinkedAuthgroupScopes(this.selected_auth_group, this.$router);
        for (const item of linked_rights) {
          this.selectedRights.push(item.scope_id);
        }
      } catch (e) {
        this.handleError('Failed to get auth group web rights!')
        return;
      }
      this.openModal('rights_assignments');
    },
    async setGroupHostRights(row) {
      this.dialog_title = 'Set Auth Group Host Rights';
      this.dialog_type = 'host';
      this.selected_auth_group = row.id;
      this.availableRights = this.hosts;
      this.selectedRights = [];
      try {
        const linked_rights = await authGroupAPIService.getLinkedAuthgroupHosts(this.selected_auth_group, this.$router);
        for (const item of linked_rights) {
          this.selectedRights.push(item.host_id);
        }
      } catch (e) {
        this.handleError('Failed to get auth group host rights!')
        return;
      }
      this.openModal('rights_assignments');
    },
    async setGroupCommandRights(row) {
      this.dialog_title = 'Set Auth Group Command Rights';
      this.dialog_type = 'command';
      this.selected_auth_group = row.id;
      this.availableRights = this.commands;
      this.selectedRights = [];
      try {
        const linked_rights = await authGroupAPIService.getLinkedAuthgroupsCommandLookups(this.selected_auth_group, this.$router);
        for (const item of linked_rights) {
          this.selectedRights.push(item.command_lookup_id);
        }
      } catch (e) {
        this.handleError('Failed to get auth group command rights!')
        return;
      }
      this.openModal('rights_assignments');
    },
    async setUserWebAssignRights(row) {
      this.dialog_title = 'Set User Web Rights';
      this.dialog_type = 'user_scope';
      this.selected_user = row.id;
      this.availableRights = this.scopes;
      this.selectedRights = [];
      try {
        const linked_rights = await usersAPIService.getUserToScopes(this.selected_user, this.$router);
        for (const item of linked_rights) {
          this.selectedRights.push(item.scope_id);
        }
      } catch (e) {
        this.handleError('Failed to get user web rights!')
        return;
      }
      this.openModal('rights_assignments');
    },
    async setUserHostRights(row) {
      this.dialog_title = 'Set User Host Rights';
      this.dialog_type = 'user_host';
      this.selected_user = row.id;
      this.availableRights = this.hosts;
      this.selectedRights = [];
      try {
        const linked_rights = await usersAPIService.getUserToHosts(this.selected_user, this.$router);
        for (const item of linked_rights) {
          this.selectedRights.push(item.host_id);
        }
      } catch (e) {
        this.handleError('Failed to get user host rights!')
        return;
      }
      this.openModal('rights_assignments');
    },
    async setUserCommandRights(row) {
      this.dialog_title = 'Set User Command Rights';
      this.dialog_type = 'user_command';
      this.selected_user = row.id;
      this.availableRights = this.commands;
      this.selectedRights = [];
      try {
        const linked_rights = await usersAPIService.getUserToCommandLookups(this.selected_user, this.$router);
        for (const item of linked_rights) {
          this.selectedRights.push(item.command_lookup_id);
        }
      } catch (e) {
        this.handleError('Failed to get User command rights!')
        return;
      }
      this.openModal('rights_assignments');
    },
    openModal(name) {
      this.modals[name] = true
    },
    closeModal(name) {
      this.modals[name] = false;
    },
    getCommands() {
      return commandLookupTablesAPIService.get(this.$router)
      .then((response) => {
        this.commands = response;
      })
      .catch((error) => {
        this.handleError('Error getting commands! ' + error);
      })
    },
    getScopes() {
      return scopesAPIService.getScopes(this.$router)
      .then((response) => {
        this.scopes = response;
      })
      .catch((error) => {
        this.handleError('Error getting scopes! ' + error);
      })
    },
    getHosts() {
      return hostsAPIService.get(this.$router)
      .then((response) => {
        this.hosts = response;
      })
      .catch((error) => {
        this.handleError('Error getting hosts! ' + error);
      })
    },
    getRights() {
      const store = useLoginStore();
      // get the local page rights to determine what the users can see
      this.pageRights = store.appRights;
      for (const right of store.appRights) {
        if (right) {
          this.pageRights.push(right.name);
        }
      }
    },
    getUsers() {
      return usersAPIService.getUsersByCompanyID(this.$router)
      .then((response) => {
        this.users = response;
      })
      .catch((error) => {
        this.error_txt = 'Error getting users: ' + error;
        this.openModal('error');
      });
    },
    getAuthGroups() {
      return authGroupAPIService.getAuthGroups(this.$router)
      .then((response) => {
        this.auth_groups = response;
      })
      .catch((error) => {
        this.error_txt = 'Error getting auth groups: ' + error;
        this.openModal('error');
      });
    },
    async saveAuthGroupScopeRights() {
      try {
        if (this.selectedRights.length === 0) {
          await authGroupAPIService.linkAuthgroupToScopes(this.selected_auth_group, 'empty', this.$router)
        } else {
          await authGroupAPIService.linkAuthgroupToScopes(this.selected_auth_group, this.selectedRights, this.$router)
        }
        this.selected_auth_group = null;
        this.selectedRights = [];
        this.handleSuccess('Successfully linked web rights to auth group');
      } catch (e) {
        this.handleError('Failed to save auth group rights!');
      }
      this.closeModal('rights_assignments');
    },
    async saveAuthGroupHosts() {
      try {
        if (this.selectedRights.length === 0) {
          await authGroupAPIService.linkAuthgroupToHosts(this.selected_auth_group, 'empty', this.$router)
        } else {
          await authGroupAPIService.linkAuthgroupToHosts(this.selected_auth_group, this.selectedRights, this.$router)
        }
        this.selected_auth_group = null;
        this.selectedRights = [];
        this.handleSuccess('Successfully linked auth group to hosts');
      } catch (e) {
        this.handleError('Could not link host to auth groups: ' + e);
      }
      this.closeModal('rights_assignments');
    },
    async saveAuthGroupCommands() {
      try {
        if (this.selectedRights.length === 0) {
          await authGroupAPIService.linkAuthgroupToCommandLookups(this.selected_auth_group, 'empty', this.$router)
        } else {
          await authGroupAPIService.linkAuthgroupToCommandLookups(this.selected_auth_group, this.selectedRights, this.$router)
        }
        this.selected_auth_group = null;
        this.selectedRights = [];
        this.handleSuccess('Successfully linked auth group to commands');
      } catch (e) {
        this.handleError('Could not link commands to auth groups: ' + e);
      }
      this.closeModal('rights_assignments');
    },
    async saveUserScopeRights() {
      try {
        if (this.selectedRights.length === 0) {
          await usersAPIService.linkUserToScopes(this.selected_user, 'empty', this.$router)
        } else {
          await usersAPIService.linkUserToScopes(this.selected_user, this.selectedRights, this.$router)
        }
        this.selected_user = null;
        this.selectedRights = [];
        this.handleSuccess('Successfully linked user to rights!');
      } catch (e) {
        this.handleError('Failed to save user to rights!');
      }
      this.closeModal('rights_assignments');
    },
    async saveUserHostRights() {
      try {
        if (this.selectedRights.length === 0) {
          await usersAPIService.linkUserToHosts(this.selected_user, 'empty', this.$router)
        } else {
          await usersAPIService.linkUserToHosts(this.selected_user, this.selectedRights, this.$router)
        }
        this.selected_user = null;
        this.selectedRights = [];
        this.handleSuccess('Successfully linked user to hosts!');
      } catch (e) {
        this.handleError('Failed to save user to hosts!');
      }
      this.closeModal('rights_assignments');
    },
    async saveUserHostCommand() {
      try {
        if (this.selectedRights.length === 0) {
          await usersAPIService.linkUserToCommandLookups(this.selected_user, 'empty', this.$router)
        } else {
          await usersAPIService.linkUserToCommandLookups(this.selected_user, this.selectedRights, this.$router)
        }
        this.selected_user = null;
        this.selectedRights = [];
        this.handleSuccess('Successfully linked user to command lookup!');
      } catch (e) {
        this.handleError('Failed to save user to command lookup!');
      }
      this.closeModal('rights_assignments');
    },
    handleSuccess(text) {
      this.success_txt = text;
      this.openModal('success');
      this.spinning = false;
      setTimeout(() => {
        this.modals['success'] = false;
      }, 1500); // Hide after 1.5 secs
    },
    handleError(error) {
      this.error_txt = error;
      this.openModal('error');
      this.spinning = false;
      setTimeout(() => {
        this.modals['error'] = false;
      }, 1500); // Hide after 1.5 secs
    },
  }
}
</script>

<style scoped>
</style>
