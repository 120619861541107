<template>
  <div>
    <card v-if="spinning">
      <div class="row">
        <div class="col-sm-12 text-center">
          <b-spinner variant="primary" label="Spinning"></b-spinner>
        </div>
      </div>
    </card>
    <card>
      <h3 class="custom-header1">Audit Logs Page</h3>
      <h4 class="custom-header">The purpose of this page is to show the actions performed by the users on the system.</h4>
    </card>
    <card>
      <h1 class="table-header">AuditLogs Table</h1>
      <p class="card-category">
        You can review the notifications by the time or date and view who was sent audit_logs.
      </p>
      <div class="audit_logs">
        <div class="row">
        <div class="col-sm-6">
          <el-input
            v-model="searchTerm"
            placeholder="Search by User or Description"
            clearable
            class="search-bar">
            <span slot="prepend">Search</span>
          </el-input>
        </div>
        <el-button @click="searchTerm = ''">Clear</el-button>
        </div>


        <div class="row">
        <label class="tp-header">Please select a time period</label>
        </div>
        <div class="row">
        <el-select class="tp-header2" v-model="timePeriod" placeholder="Select Time Period"
                   @change="onTimePeriodChange">
          <el-option label="4 hours" value="4h"></el-option>
          <el-option label="24 hours" value="24h"></el-option>
          <el-option label="1 week" value="1w"></el-option>
          <el-option label="1 month" value="1m"></el-option>
          <el-option :label="customDatesLabel" :value="customValue"></el-option>
        </el-select>
        </div>

        <el-table :data="paginatedAuditLogs" border>
          <el-table-column prop="user" label="User"></el-table-column>
          <el-table-column min-width="300" prop="description" label="Description"></el-table-column>
          <el-table-column prop="createdAt" label="Log Time"></el-table-column>
        </el-table>

        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="currentPage"
          :page-sizes="pageSizes"
          :page-size="pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="filteredAuditLogs.length"
        >
        </el-pagination>
      </div>
    </card>
    <el-dialog
      center
      title="Error"
      :visible.sync="modals.error">
      <div class="text-center">
        <span>Error: {{ error_txt }}</span>
        <div class="text-center">
          <span slot="footer" class="dialog-footer">
<l-button type="btn btn-danger" round @click="closeModal('error')">OK</l-button>&nbsp;
</span>
        </div>
      </div>
    </el-dialog>
    <el-dialog
      center
      title="Success"
      :visible.sync="modals.success">
      <div class="text-center">
        <span>{{ success_txt }}</span>
        <div class="text-center mt-4">
          <span slot="footer" class="dialog-footer">
<l-button type="btn btn-success" round @click="closeModal('success')">OK</l-button>&nbsp;
</span>
        </div>
      </div>
    </el-dialog>
    <el-dialog :visible.sync="modals.datetime" title="Select Custom Date and Time Period">
      <el-date-picker
        v-model="customDates"
        type="datetimerange"
        range-separator="to"
        start-placeholder="Start Date and Time"
        end-placeholder="End Date and Time"
        align="right"
        :default-time="['12:00:00', '12:00:00']"
      ></el-date-picker>
      <span slot="footer" class="dialog-footer">
      <l-button @click="modals.datetime = false">Cancel</l-button>
      <l-button type="primary" @click="applyCustomDates">Apply</l-button>
    </span>
    </el-dialog>
    <el-dialog :visible.sync="modals.message" width="50%">
      <span>{{ selectedMessage }}</span>
      <span slot="footer" class="dialog-footer">
    <el-button @click="closeModal('message')">Close</el-button>
  </span>
    </el-dialog>
  </div>
</template>

<script>
import moment from 'moment';
import { Tooltip, Button, Table, TableColumn } from 'element-ui';
import { DatePicker, Dialog, Select, Option, Pagination } from 'element-ui';
import AuditLogsAPIService from "src/servicehandlers/AuditLogsAPIService";

const auditLogsAPIService = new AuditLogsAPIService();

export default {
  data() {
    return {
      searchTerm: '',
      audit_logList: [], // To store the audit_logs fetched from the API or any other source.
      spinning: false,
      success_txt: null,
      timePeriod: '24h',
      totalItems: 0,
      customDates: [],
      error_txt: null,
      currentPage: 1,
      perPage: 50,
      pageSize: 20,
      pageSizes: [20, 50, 100, 200, 500, 1000],
      audit_logs: [],
      selectedMessage: '',
      selectedExitCode: '',
      modals: {
        error: false,
        success: false,
        datetime: false,
        message: false,
      },
    }
  },
  watch: {
    filteredAuditLogs: {
      handler(newVal) {
        this.totalItems = newVal.length;
      },
      immediate: true  // to run the handler immediately after the watcher is created
    }
  },
  computed: {
    filteredAuditLogs() {
      let filtered = this.audit_logs;

      if (this.searchTerm) {
        const lowercasedQuery = this.searchTerm.toLowerCase();

        filtered = filtered.filter(audit_log => {
          const userName = audit_log.user?.toLowerCase() ?? '';
          const description = audit_log.description?.toLowerCase() ?? '';

          return userName.includes(lowercasedQuery) ||
              userName.includes(lowercasedQuery)
        });
      }

      return filtered;
    },
    customDatesLabel() {
      return this.customDatesString ? `Custom: ${this.customDatesString}` : 'Custom';
    },
    customValue() {
      return this.customDatesString ? this.customDatesString : 'custom';
    },
    customDatesString() {
      if (this.customDates.length === 2) {
        const [start, end] = this.customDates;
        const startMoment = moment(start);
        const endMoment = moment(end);
        return `${startMoment.format('YYYY-MM-DD HH:mm:ss')} to ${endMoment.format('YYYY-MM-DD HH:mm:ss')}`;
      }
      return '';
    },
    paginatedAuditLogs() {
      const start = (this.currentPage - 1) * this.pageSize;
      const end = this.currentPage * this.pageSize;
      return this.filteredAuditLogs.slice(start, end);
    },
  },
  mounted() {
    this.timePeriod = '24h';
    this.getAuditLogs();
  },
  components: {
    [Tooltip.name]: Tooltip,
    [Table.name]: Table,
    [Button.name]: Button,
    [TableColumn.name]: TableColumn,
    'el-date-picker': DatePicker,
    'el-select': Select,
    'el-option': Option,
    'el-pagination': Pagination,
    [Dialog.name]: Dialog,
  },
  name: "AuditLogs",
  methods: {
    preOpenModal(message) {
      this.selectedMessage = message;
      this.openModal('message');
    },
    onTimePeriodChange() {
      if (this.timePeriod === 'custom' && !this.customDatesString) {
        this.modals.datetime = true;
      } else {
        // get the new stuff from the DB
        this.getAuditLogs();
      }
    },
    applyCustomDates() {
      if (this.customDates.length === 2) {
        this.timePeriod = this.customDatesString;
        this.modals.datetime = false;
        this.getAuditLogs();
      } else {
        // console.error("Invalid date and time range selected");
      }
    },
    formatTime(time) {
      return moment(time).format('YYYY-MM-DD HH:mm:ss');
    },
    getAuditLogs() {
      let startDateTime;
      let endDateTime = moment(); // Assuming the end date is now

      switch (this.timePeriod) {
        case '4h':
          startDateTime = moment().subtract(4, 'hours');
          break;
        case '24h':
          startDateTime = moment().subtract(24, 'hours');
          break;
        case '1w':
          startDateTime = moment().subtract(1, 'weeks');
          break;
        case '1m':
          startDateTime = moment().subtract(1, 'months');
          break;
        default: // Custom Date Range
          if (this.customDates.length === 2) {
            const [start, end] = this.customDates;
            startDateTime = moment(start); // convert start date to moment object
            endDateTime = moment(end); // convert end date to moment object
          }
          break;
      }

      return auditLogsAPIService.getAuditLogsByDateTime(startDateTime, endDateTime, this.$router)
      .then((response) => {
        this.audit_logs = [];
        for (const audit_log of response) {
          // host only
          const new_audit_log = {
            description: null,
            user: null,
            createdAt: null,
          };
          if (audit_log.description) {
            new_audit_log.description = audit_log.description;
          }
          if (audit_log.user && audit_log.user.first_name) {
            new_audit_log.user = audit_log.user.first_name + ' ' + audit_log.user.last_name;
          }
          if (audit_log.createdAt) {
            new_audit_log.createdAt = audit_log.createdAt;
          }
          this.audit_logs.push(new_audit_log);
        }
      })
      .catch((error) => {
        this.handleError('Error getting audit_logs! ' + error);
      });
    },
    openModal(name) {
      this.modals[name] = true
    },
    closeModal(name) {
      this.modals[name] = false;
    },
    handleTimeChange(value) {
      if (value === 'custom') {
        this.modals.datetime = true;
      } else {
        // Re-filter audit_logs based on the new time period
      }
    },
    handleSizeChange(val) {
      // console.log(`Each page ${val} lines`);
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      // console.log(`Current page: ${val}`);
    },
    handleSuccess(text) {
      this.success_txt = text;
      this.openModal('success');
      this.spinning = false;
      setTimeout(() => {
        this.modals['success'] = false;
      }, 1500); // Hide after 1.5 secs
    },
    handleError(error) {
      this.error_txt = error;
      this.openModal('error');
      this.spinning = false;
      setTimeout(() => {
        this.modals['error'] = false;
      }, 1500); // Hide after 1.5 secs
    },
  }
}
</script>

<style scoped>
.custom-table {
  width: 100%;
  border: 1px solid #ebeef5;
}

.table-header, .row {
  display: flex;
  font-size: 22px;
  color: dimgrey;
  margin-top: 10px;
  margin-bottom: 5px;
}
.table-description {
  font-size: 15px;
  color: dimgrey;
}

.cell {
  padding: 12px 8px;
  border-bottom: 1px solid #ebeef5;
  flex: 1;
}

/* Define your CSS styles for different row colors here */
.success-row {
  background-color: #d4edda !important;
}

.warning-row {
  background-color: #ffeeba !important;
}

.error-row {
  background-color: #f8d7da !important;
}

.unknown-row {
  background-color: #dee2e6 !important;
}

.el-table .success-row td {
  background-color: #d4edda !important;
}

.el-table .warning-row td {
  background-color: #ffeeba !important;
}

.el-table .error-row td {
  background-color: #f8d7da !important;
}

.el-table .unknown-row td {
  background-color: #dee2e6 !important;
}

::v-deep .el-table .success-row td {
  background-color: #d4edda !important;
}

::v-deep .el-table .warning-row td {
  background-color: #ffeeba !important;
}

::v-deep .el-table .error-row td {
  background-color: #f8d7da !important;
}

::v-deep .el-table .unknown-row td {
  background-color: #dee2e6 !important;
}

.ellipsis {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.tp-header {
  margin-left: 15px;
  margin-bottom: 0;
  //margin-top: 5px;
}
.tp-header2 {
  margin-left: 15px;
  margin-bottom: 15px;
}
.custom-header1 {
  color: dimgrey;
  font-size: 17px;
  margin-top: 0;
  margin-bottom: 0;
}
.custom-header {
  color: dimgrey;
  font-size: 15px;
  margin-top: 0;
  margin-bottom: 0;
}

</style>
