<template>
  <div>
    <card>
      <el-button type="primary" @click="clear">Clear</el-button>
      <div class="chat-component">
        <div class="chat-display" ref="chatDisplay">
          <div v-for="message in items" :key="message.id" :class="['message', message.type]">
            <span v-if="message.type === 'user'">You: </span>
            <span v-else-if="message.type === 'bot'">AI Super Admin: </span>
            <div v-else-if="message.type === 'loading'">
              <b-spinner small></b-spinner>
              {{ message.text }}
            </div>
            <span v-if="message.type !== 'loading'">{{ message.text }}</span>
          </div>
        </div>
        <input v-model="userInput" @keyup.enter="addItem" placeholder="Type a message..."/>
      </div>
    </card>
    <el-dialog
      center
      title="Success"
      :visible.sync="modals.success">
      <div class="text-center">
        <span>Success: {{ success_txt }}</span>
        <br>
        <span slot="footer" class="dialog-footer">
<button class="btn btn-success" @click="closeModal('success')">OK</button>&nbsp;
</span>
      </div>
    </el-dialog>
    <el-dialog
      center
      title="Error"
      :visible.sync="modals.error">
      <div class="text-center">
        <span>Error: {{ error_txt }}</span>
        <br>
        <span slot="footer" class="dialog-footer">
<button class="btn btn-danger" @click="closeModal('error')">OK</button>&nbsp;
</span>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { Dialog, Button } from 'element-ui';
import { useLoginStore } from '@/stores/loginStore';
import RunningCommandsAPIService from "src/servicehandlers/RunningCommandsAPIService";
import AIAPIService from "src/servicehandlers/AIAPIService";

const aiAPIService = new AIAPIService();
const runningCommandsAPIService = new RunningCommandsAPIService();
export default {
  props: {
    initial_text: {
      type: String,
      required: false,
    },
  },
  name: "AIInterface",
  components: {
    [Dialog.name]: Dialog,
    [Button.name]: Button,
  },
  data() {
    return {
      conversation_history: null,
      inputText: "",
      success_txt: null,
      error_txt: null,
      spinning: false,
      user_id: null,
      store: null,
      userInput: '',
      items: [],
      modals: {
        error: false,
        success: false,
        basic: false,
      },
    }
  },
  mounted() {
    const store = useLoginStore();
    this.store = store;
    this.user_id = store.user.id;
    if (this.initial_text) {
      this.runCommand(this.initial_text);
    } else {
      const beginningMessage = {
        id: this.items.length,
        text: "Go ahead and enter text in to perform a command...",
        type: "bot",
      };
      this.items.push(beginningMessage);
    }
  },
  methods: {
    clear() {
      this.items = [];
      const beginningMessage = {
        id: this.items.length,
        text: "Go ahead and enter text in to perform a command...",
        type: "bot",
      };
      this.items.push(beginningMessage);
    },
    scrollToBottom() {
      this.$nextTick(() => {
        const chatDisplay = this.$refs.chatDisplay;
        chatDisplay.scrollTop = chatDisplay.scrollHeight;
      });
    },
    runCommand(text) {
      this.spinning = true;
      this.userInput = text;
      this.addItem();
    },
    async addItem() {
      this.scrollToBottom();
      if (!this.userInput.trim()) {
        return;
      }
      this.spinning = true;

      const userMessage = {
        id: this.items.length,
        text: this.userInput,
        type: "user",
      };
      this.items.push(userMessage);
      this.scrollToBottom();

      this.userInput = "";

      const loadingMessage = {
        id: this.items.length,
        text: "Waiting for response, this may take up to two minutes...",
        type: "loading",
      };
      this.items.push(loadingMessage);

      let base_action = null;
      try {
        const context = 'You are an AI that can only answer questions related to system engineering,' +
          ' system monitoring, backups, and anything computer or server related. ' +
          'If the user asks anything about disk space, then have it append to the response "Call the engineer Nate - 801-222-222 to repair the issue"';
        const parameters = {
          conversation_id: this.user_id,
          key: this.user_id,
          query: userMessage.text,
          context: context,
        };
        const response = await aiAPIService.cyber_ai_call(parameters, this.$router)
        // Remove loading message once the response is received
        this.items.pop();
        // determine if it's something like text and multiple items
        if (response) {
          const message = response;

          const botMessage = {
            id: this.items.length,
            text: message,
            type: "bot",
          };
          this.items.push(botMessage);
        }
      } catch (error) {
        this.spinning = false;
        if (error.code === 'ECONNABORTED') {
          this.handleError('Connection was aborted!')
        } else {
          this.handleError('There was an error sending the message! ' + error)
        }
      }
    },
    getItemClass(index) {
      return index % 2 === 0 ? "grey-bg" : "white-bg";
    },
    parseToObject(str) {
      try {
        const jsonStr = str.replace(/'/g, '"');
        const obj = JSON.parse(jsonStr);
        return obj;
      } catch (e) {
        this.handleError('Failed to parse string')
        return null;
      }
    },
    handleSuccess(success_msg) {
      const self = this;
      this.success_txt = success_msg;
      this.openModal('error');
      this.spinning = false;
      setTimeout(function () {
        self.modals['error'] = false; // Use your variable name
      }, 1500); // Hide after 5 secs
    },
    handleError(error_msg) {
      const self = this;
      this.error_txt = error_msg;
      this.openModal('error');
      this.spinning = false;
      setTimeout(function () {
        self.modals['error'] = false; // Use your variable name
      }, 1500); // Hide after 5 secs
    },
    closeModal(name) {
      this.modals[name] = false;
    },
    openModal(name) {
      this.modals[name] = true;
    },
  }
}
</script>

<style scoped>
.chat-component {
  max-width: 800px;
  margin: auto;
  padding: 20px;
}

.chat-display {
  border: 1px solid #ccc;
  padding: 20px;
  margin-bottom: 10px;
  overflow-y: auto;
  max-height: 700px;
}

input {
  width: 100%;
  padding: 10px;
  box-sizing: border-box;
}

.message {
  padding: 10px;
  margin: 5px 0;
  border-bottom: 1px solid #eee; /* Lightly marked border between messages */
}

.user {
  background-color: #fff; /* White background for user message */
}

.bot {
  background-color: #f0f0f0; /* Light grey background for bot message */
}

.list-container {
  max-height: 600px; /* Adjust as needed */
  overflow-y: auto;
  border: 1px solid #cccccc;
}

.grey-bg {
  background-color: grey;
  padding: 5px;
}

.white-bg {
  background-color: white;
  padding: 5px;
}
</style>
