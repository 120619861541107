<template>
  <div>
    <card v-if="spinning">
      <div class="row">
        <div class="col-sm-12 text-center">
          <b-spinner variant="primary" label="Spinning"></b-spinner>
        </div>
      </div>
    </card>
    <card>
      <div class="row">
        <div class="col-sm-12">
          <h3 class="title-1">Collector Assignments Page</h3>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-12">
          <h3 class="title-2">This page is for modifying and updating collectors</h3>
          <p class="title-2">You can assign any host to a collector. Simply click from the list and assign the hosts
            as needed</p>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-12">
          <div class="row-container">
          <el-tooltip content="Promote Host">
            <el-button type="text" @click="showHostList = !showHostList">
              <i class="fa-solid fa-arrow-up-short-wide"></i>
            </el-button>
          </el-tooltip>
          <div v-if="showHostList" class="host-dropdown" style="padding: 8px 0">
            <label>Select a host to promote
              <select v-model="selectedHost" @change="openModal('hostedit')" class="form-control">
                <option value="" disabled>Select a host to promote</option>
                <option :value="host" v-for="host of hosts" v-bind:key="host.id">
                  {{ host.name }}
                </option>
              </select>
            </label>
          </div>
        </div>
      </div>
      </div>
    </card>
    <div v-for="collector in collectors" :key="collector.id">
      <card class="box-card">
        <div slot="header" class="clearfix">
          <span class="custom-text">Collector: {{ collector.name }}</span>
        </div>

        <div>
          <label for="host-select">Select Hosts you want to add to the collector by clicking on the
            box:</label>
          <el-tooltip content="Select All">
            <el-button type="text" @click="toggleSelectAll(collector.id)">
              <i class="fa-solid fa-square-check"></i>
            </el-button>
          </el-tooltip>
          <el-select id="host-select" v-model="collector.assignedHosts" multiple
                     placeholder="Select hosts" class="wide-select">
            <el-option
              v-for="host in availableHosts(collector.id)"
              :key="host.id"
              :label="host.name + ' - (' + host.ip_address + ')'"
              :value="host.id"
            />
          </el-select>
          <link
            rel="stylesheet"
            href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.0.0/css/all.min.css">
          <el-tooltip content="Edit Collector">
            <el-button type="text" @click="selectedCollector = collector; modals.collector = true">
              <i class="fa-regular fa-pen-to-square"></i>
            </el-button>
          </el-tooltip>
          <el-tooltip content="Save Changes">
            <el-button type="text" @click="saveHosts(collector)">
              <i class="fa-regular fa-floppy-disk"></i>
            </el-button>
          </el-tooltip>
        </div>
      </card>
    </div>

    <el-dialog
      center
      title="Error"
      :visible.sync="modals.error">
      <div class="text-center">
        <span>Error: {{ error_txt }}</span>
        <div class="text-center mt-4">
          <span slot="footer">
<l-button type="btn btn-danger" round @click="closeModal('error')">OK</l-button>&nbsp;
</span>
        </div>
      </div>
    </el-dialog>
    <el-dialog
      center
      title="Success"
      :visible.sync="modals.success">
      <div class="text-center">
        <span>{{ success_txt }}</span>
        <div class="text-center mt-4">
          <span slot="footer">
<l-button type="btn btn-success" round @click="closeModal('success')">OK</l-button>&nbsp;
</span>
        </div>
      </div>
    </el-dialog>
    <el-dialog
      width="40%"
      :visible.sync="modals.collector">
      <!-- Collector information to be updated goes here -->
      <div class="row">
        <div class="col-sm-12">
          <div class="text-center">
            <h3 style="margin-top: -20px;">Edit Collector</h3>
          </div>
        </div>
      </div>
        <div class="row text-center">
          <div class="col-sm-6 text-center">
            <label for="collector-name">Name:
              <fg-input style="width: 250px;" id="collector-name" v-model="selectedCollector.name"
                        placeholder="Enter collector name"></fg-input>
            </label>
          </div>
          <div class="col-sm-6 text-center">
            <label for="collector-description">Description:
              <fg-input style="width: 250px;" id="collector-description" v-model="selectedCollector.description"
                        placeholder="Enter collector description"></fg-input>
            </label>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-6">
            <div class="text-center">
              <label for="collector-alias">Alias:
                <fg-input style="width: 250px;" id="collector-alias" v-model="selectedCollector.alias"
                          placeholder="Enter collector alias"></fg-input>
              </label>
            </div>
          </div>
          <div class="col-sm-6">
            <div class="text-center">
              <label for="collector-ip">IP Address:
                <fg-input style="width: 250px;" id="collector-ip" v-model="selectedCollector.ip_address"
                          placeholder="Enter IP Address"></fg-input>
              </label>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-12">
            <div class="text-center">
              <label>Is a Collector
                <input type="checkbox" class="form-control" v-model="selectedCollector.is_collector">
              </label>
              <fg-input v-if="selectedCollector.is_collector" v-model="selectedCollector.port"
                        placeholder="Enter Port"></fg-input>
            </div>
          </div>
        </div>
      <div class="text-center mt-4">
        <span slot="footer" class="dialog-footer">
        <l-button type="primary" class="mr-1" round @click="updateCollector">Update</l-button>
        <l-button type="danger" round @click="modals.collector = false">Cancel</l-button>
    </span>
      </div>
    </el-dialog>

    <!-- Modal for Editing Selected Host -->
    <el-dialog :visible.sync="modals.hostedit" title="Edit Host">
      <div>
        <span>Hostname: {{ selectedHost?.name }}</span>
      </div>
      <div>
        <label>
          Promote to Collector?
        <input type="checkbox" class="form-control" v-model="selectedHost.is_collector">
        <fg-input v-if="selectedHost.is_collector" v-model="selectedHost.port" placeholder="Enter Port"></fg-input>
        </label>
      </div>
        <span slot="footer" class="dialog-footer">
        <l-button type="danger" @click="modals.hostedit = false">Cancel</l-button>
        <l-button type="primary" @click="updateHost">Update</l-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import { Dialog } from 'element-ui';
import { Option, Select, Table, TableColumn, Button, Tooltip, Icon, } from 'element-ui';
import HostsAPIService from "src/servicehandlers/HostsAPIService";

const hostsAPIService = new HostsAPIService();
import CollectorAPIService from "src/servicehandlers/CollectorAPIService";

const collectorAPIService = new CollectorAPIService();
export default {
  name: "UserGroupAssignments",
  components: {
    ElSelect: Select,
    ElOption: Option,
    [Dialog.name]: Dialog,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Button.name]: Button,
    [Tooltip.name]: Tooltip,
    [Icon.name]: Icon,
  },
  data() {
    return {
      selectedHostId: null,
      showHostList: false,
      selectedCollector: {},
      selectedHostGroup: '',
      searchQuery: null,
      spinning: false,
      success_txt: null,
      selectedHost: {},
      error_txt: null,
      name: null,
      user_groups: [],
      alias: null,
      modals: {
        error: false,
        success: false,
        create: false,
        collector: false,
        hostedit: false
      },
      globalCollectorId: null,
      globalGroupId: null,
      selectedOperatingSystem: '', // to hold the selected operating system
      operatingSystems: [], // list of all available operating systems
      hostGroups: [],
      hosts: [],
      users: [],
      collectors: [],
      userGroups: [],
    }
  },
  computed: {
    filteredHosts() {
      let filtered = this.hosts;
      if (this.searchQuery) {
        const lowercasedQuery = this.searchQuery.toLowerCase();
        filtered = filtered.filter(host =>
          host.name?.toLowerCase().includes(lowercasedQuery) ||
          host.ip_address?.includes(this.searchQuery) ||
          host.alias?.toLowerCase().includes(lowercasedQuery) ||
          host.operating_system?.toLowerCase().includes(lowercasedQuery)
        );
      }
      if (this.selectedOperatingSystem) {
        filtered = filtered.filter(host => host.operating_system === this.selectedOperatingSystem);
      }
      if (this.selectedHostGroup) {
        const group = this.hostGroups.find(group => group.id === this.selectedHostGroup);
        if (group && group.hosts) { // Check if group and group.hostIds are defined
          filtered = filtered.filter(host => group.hostIds.includes(host.id));
        }
      }
      return filtered;
    },
  },
  async mounted() {
    await this.getCollectors();
    await this.getHosts();
  },
  methods: {
    saveHosts(collector) {
      // Logic to update the host goes here
      // After success:
      this.closeModal('collector');
      this.selectedHost.needs_update = true;
      return collectorAPIService.connectHosts(collector.id, {hosts: collector.assignedHosts}, this.$router)
      .then(() => {
        const self = this;
        this.success_txt = 'Adding hosts to collector Succeeded';
        this.openModal('success');
        this.spinning = false;
        setTimeout(function () {
          self.modals['success'] = false; // Use your variable name
        }, 1500); // Hide after 5 secs
      })
      .catch((error) => {
        const self = this;
        this.error_txt = 'Failed to update hosts collector: ' + error;
        this.openModal('error');
        this.spinning = false;
        setTimeout(function () {
          self.modals['error'] = false; // Use your variable name
        }, 1500); // Hide after 5 secs
      });
    },
    updateHost() {
      // Logic to update the host goes here
      this.modals.hostedit = false;
      // API call to update collector
      // After success:
      this.closeModal('collector');
      this.selectedHost.needs_update = true;
      return hostsAPIService.update(this.selectedHost.id, this.selectedHost, this.$router)
      .then(() => {
        const self = this;
        this.success_txt = 'Promotion of Collector Succeeded';
        this.openModal('success');
        this.spinning = false;
        setTimeout(function () {
          self.modals['success'] = false; // Use your variable name
        }, 1500); // Hide after 5 secs
      })
      .catch((error) => {
        const self = this;
        this.error_txt = 'Failed to promote host to collector: ' + error;
        this.openModal('error');
        this.spinning = false;
        setTimeout(function () {
          self.modals['error'] = false; // Use your variable name
        }, 1500); // Hide after 5 secs
      });
    },
    availableHosts(collectorId) {
      return this.hosts.filter(
        (host) => !this.collectors.find(
          (collector) => collector.id !== collectorId && collector.assignedHosts.includes(host.id)
        )
      );
    },
    updateCollector() {
      // API call to update collector
      // After success:
      this.closeModal('collector');
      return hostsAPIService.update(this.selectedCollector.id, this.selectedCollector, this.$router)
      .then(() => {
        const self = this;
        this.success_txt = 'Update Collector Succeeded';
        this.openModal('success');
        this.spinning = false;
        setTimeout(function () {
          self.modals['success'] = false; // Use your variable name
        }, 1500); // Hide after 5 secs
      })
      .catch((error) => {
        const self = this;
        this.error_txt = 'Failed to update collector: ' + error;
        this.openModal('error');
        this.spinning = false;
        setTimeout(function () {
          self.modals['error'] = false; // Use your variable name
        }, 1500); // Hide after 5 secs
      });
    },
    addHost() {
      // API call to add host to collector
      // After success:
      this.collectors.hosts.push(this.selectedHost);
    },
    removeHost(host) {
      // API call to remove host from collector
      // After success:
      const index = this.collectors.assignedHosts.indexOf(host);
      if (index > -1) this.collectors.assignedHosts.splice(index, 1);
    },
    async getCollectors() {
      return collectorAPIService.get(this.$router)
      .then((response) => {
        this.collectors = [];
        for (const collector of response) {
          collector.assignedHosts = [];
          this.collectors.push(collector);
        }
      })
      .catch((error) => {
        const self = this;
        this.error_txt = 'Failed to get collectors: ' + error;
        this.openModal('error');
        this.spinning = false;
        setTimeout(function () {
          self.modals['error'] = false; // Use your variable name
        }, 1500); // Hide after 5 secs
      });
    },
    async getHosts() {
      this.hosts = [];
      // we might need the full list of hosts
      return hostsAPIService.get(this.$router)
      .then((response) => {
        for (const host of response) {
          if (host.collector_id) {
            let found_collector = this.collectors.filter(item => item.id === host.collector_id);
            if (found_collector) {
              found_collector[0].assignedHosts.push(host.id);
            }
          }
          this.hosts.push(host);
        }
      })
      .catch((error) => {
        const self = this;
        this.error_txt = 'Failed to get hosts: ' + error;
        this.openModal('error');
        this.spinning = false;
        setTimeout(function () {
          self.modals['error'] = false; // Use your variable name
        }, 1500); // Hide after 5 secs
      });
    },
    availableHostsForCollectors(collector_id, hostId) {
      const host = this.hosts.find(h => h.id === hostId);
      return this.collectors.filter(group => !group.assignedGroups.includes(group.id));
    },
    availableGroupsForHost(hostId) {
      const host = this.hosts.find(h => h.id === hostId);
      return this.userGroups.filter(group => !host.assignedGroups.includes(group.id));
    },
    assignGroupToHost(hostId, event) {
      const host = this.hosts.find(h => h.id === hostId);
      if (host.assignedGroupId && !host.assignedGroups.includes(host.assignedGroupId)) {
        host.assignedGroups.push(host.assignedGroupId);
        try {
          const linked = hostsAPIService.linkUserGroupToHost(hostId, host.assignedGroupId, this.$router);
        } catch (e) {
          const self = this;
          this.error_txt = 'Failed to get link user group to host: ' + e;
          this.openModal('error');
          this.spinning = false;
          setTimeout(function () {
            self.modals['error'] = false; // Use your variable name
          }, 1500); // Hide after 5 secs
        }
        host.assignedGroupId = null; // Reset the dropdown
      }
    },
    assignGlobalGroup() {
      this.spinning = true;
      if (this.globalGroupId) {
        this.filteredHosts.forEach(host => {
          if (!host.assignedGroups.includes(this.globalGroupId)) {
            host.assignedGroups.push(this.globalGroupId);
            try {
              const linked = hostsAPIService.linkUserGroupToHost(host.id, this.globalGroupId, this.$router);
            } catch (e) {
              const self = this;
              this.error_txt = 'Failed to get link user group to host: ' + e;
              this.openModal('error');
              this.spinning = false;
              setTimeout(function () {
                self.modals['error'] = false; // Use your variable name
              }, 1500); // Hide after 5 secs
            }
          }
        });
        this.globalGroupId = ""; // Reset the global group selection
      }
      this.spinning = false;
    },
    unassignSelectedGroups() {
      this.spinning = true;
      if (this.globalGroupId) {
        this.filteredHosts.forEach(host => {
          host.assignedGroups = host.assignedGroups.filter(groupId => groupId !== this.globalGroupId);
          try {
            const unlinked = hostsAPIService.unlinkUserGroupToHost(host.id, this.globalGroupId, this.$router);
          } catch (e) {
            const self = this;
            this.error_txt = 'Failed to unlink user group to host: ' + e;
            this.openModal('error');
            this.spinning = false;
            setTimeout(function () {
              self.modals['error'] = false; // Use your variable name
            }, 1500); // Hide after 5 secs
          }
        });

        this.globalGroupId = ""; // Reset the global group selection
      }
      this.spinning = false;
    },
    unassignGroupFromHost(hostId, groupId) {
      const host = this.hosts.find(h => h.id === hostId);
      host.assignedGroups = host.assignedGroups.filter(id => id !== groupId);
      try {
        const unlinked = hostsAPIService.unlinkUserGroupToHost(hostId, groupId, this.$router);
      } catch (e) {
        const self = this;
        this.error_txt = 'Failed to unlink user group to host: ' + e;
        this.openModal('error');
        this.spinning = false;
        setTimeout(function () {
          self.modals['error'] = false; // Use your variable name
        }, 1500); // Hide after 5 secs
      }
    },

    async assignUserToHost(hostId, event) {
      const host = this.hosts.find(h => h.id === hostId);
      if (host.assignedUserId && !host.assignedUsers.includes(host.assignedUserId)) {
        try {
          const linked = hostsAPIService.linkUserToHostAlert(hostId, host.assignedUserId, this.$router);
        } catch (e) {
          const self = this;
          this.error_txt = 'Failed to get link alert to host: ' + e;
          this.openModal('error');
          this.spinning = false;
          setTimeout(function () {
            self.modals['error'] = false; // Use your variable name
          }, 1500); // Hide after 5 secs
        }
        host.assignedUsers.push(host.assignedUserId);
        host.assignedUserId = ""; // Reset the dropdown
      }
    },
    removeHostFromCollector(collector_id, hostId) {
      const collector = this.collectors.find(h => h.id === collector_id);
      let index = collector.assignedHosts.indexOf(hostId);
      if (index !== -1) {
        collector.assignedHosts.splice(index, 1);
      }
    },
    getHostname(hostId) {
      const host = this.hosts.find(h => h.id === hostId);
      return host.name + '- (' + host.ip_address + ')';
    },
    availableCollectorForHost(hostId) {
      const host = this.hosts.find(h => h.id === hostId);
      return this.users.filter(user => !host.assignedUsers.includes(user.id));
    },
    assignGlobalCollector() {
      this.spinning = true;
      if (this.globalCollectorId) {
        this.filteredHosts.forEach(host => {
          if (!host.assignedUsers.includes(this.globalCollectorId)) {
            host.assignedUsers.push(this.globalCollectorId);
            try {
              const linked = hostsAPIService.linkUserToHostAlert(host.id, this.globalCollectorId, this.$router);
            } catch (e) {
              const self = this;
              this.error_txt = 'Failed to link alert to host: ' + e;
              this.openModal('error');
              this.spinning = false;
              setTimeout(function () {
                self.modals['error'] = false; // Use your variable name
              }, 1500); // Hide after 5 secs
            }
          }
        });
        this.globalCollectorId = null; // Reset the global user selection
        this.spinning = false;
      }
    },
    unassignGlobalCollector() {
      this.spinning = true;
      if (this.globalCollectorId) {
        this.filteredHosts.forEach(host => {
          host.assignedUsers = host.assignedUsers.filter(userId => userId !== this.globalCollectorId);
          try {
            const unlinked = hostsAPIService.unlinkUserToHostAlert(host.id, this.globalCollectorId, this.$router);
          } catch (e) {
            const self = this;
            this.error_txt = 'Failed to unlink alert to host: ' + e;
            this.openModal('error');
            this.spinning = false;
            setTimeout(function () {
              self.modals['error'] = false; // Use your variable name
            }, 1500); // Hide after 5 secs
          }
        });
        this.globalCollectorId = ''; // Reset the selected user after unassigning
        this.spinning = false;
      }
    },
    unassignUserFromHost(hostId, userId) {
      const host = this.hosts.find(h => h.id === hostId);
      host.assignedUsers = host.assignedUsers.filter(id => id !== userId);
      try {
        const unlinked = hostsAPIService.unlinkUserToHostAlert(hostId, userId, this.$router);
      } catch (e) {
        const self = this;
        this.error_txt = 'Failed to get link alert to host: ' + e;
        this.openModal('error');
        this.spinning = false;
        setTimeout(function () {
          self.modals['error'] = false; // Use your variable name
        }, 1500); // Hide after 5 secs
      }
    },
    openModal(name) {
      this.modals[name] = true
    },
    closeModal(name) {
      this.modals[name] = false;
    },
    filterByHostGroup() {
    },
    search() {
    },
    filterByOperatingSystem() {
      // The filtering is handled by the computed property 'filteredHosts'
      // This method can be used for future enhancements or logging.
    },
    toggleSelectAll(collectorId) {
      const collector = this.collectors.find(col => col.id === collectorId);
      if (collector) {
        if (collector.assignedHosts.length === this.availableHosts(collectorId).length) {
          collector.assignedHosts = [];
        } else {
          collector.assignedHosts = this.availableHosts(collectorId).map(host => host.id);
        }
      }
    },
  }
}
</script>

<style scoped>
.box-card {
  margin-bottom: 20px; /* Adjust as needed */
}

.wide-select {
  width: 100%; /* Take the full width of the parent container */
}
.drop-2{
  width: 50%;
}
.title-1 {
  font-size: 17px;
  color: dimgrey;
  text-align: left;
  margin-bottom: 0;
  margin-top: 5px;
}
.title-2 {
  font-size: 15px;
  color: dimgrey;
  text-align: left;
  margin-top: 5px;
  margin-bottom: 5px;
}
.fa-pen-to-square {
  font-size: 25px;
  color: cornflowerblue;
}
.fa-floppy-disk {
  font-size: 25px;
  color: mediumseagreen;
}
.fa-square-check {
  font-size: 20px;
  color: dodgerblue;
  margin-left: 5px;
}
.fa-arrow-up-short-wide {
  font-size: 30px;
  color: gray;
}
.custom-text {
  color: dimgrey;
  font-size: 20px;
}
.row-container {
  display: flex;
  //align-items: center;
}
.host-dropdown {
  margin-left: 10px;
}
</style>
