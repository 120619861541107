<template>
  <div>
    <card v-if="spinning">
      <div class="row">
        <div class="col-sm-12 text-center">
          <b-spinner variant="primary" label="Spinning"></b-spinner>
        </div>
      </div>
    </card>
    <card>
      <h1 class="card-title2">SNMP Page</h1>
      <h4 class="card-title2">The purpose of this page is to allow the user to upload and process
        MIBs</h4>
    </card>
    <card>
      <div class="row">
        <div class="col-sm-12 card-title">
          <h3 class="mib-class">MIB Uploader</h3>
          <h4 class="mib-class-2">Uploading MIB files enhances monitoring of SNMP devices</h4>
          <div
            @dragover.prevent
            @dragenter.prevent
            @drop="onFilesSelected"
            class="dropzone"
            :class="{ 'active-dropzone': isActive }"
          >
            <input type="file" class="choose-files" multiple @change="onFilesSelected"
                   ref="fileInput"/>
            Drag & drop your files or <span @click="selectFiles">click here</span> to upload.
          </div>
        </div>
      </div>
    </card>
    <card>
      <div slot="header">
        <h4 class="card-title">SNMP</h4>
      </div>
      <div class="row pb-5">
        <div class="col-sm-6">
          <el-input
            v-model="searchQuery"
            placeholder="Search MIBs"
            @input="filterMibs"
            clearable
            @clear="searchQuery = ''">
            <span slot="prepend">Search</span>
          </el-input>
        </div>
        <el-button @click="searchQuery = ''">Clear</el-button>
      </div>
      <el-table stripe :data="filteredMibs" border style="width: 100%">
        <el-table-column prop="name" label="File Name" width="300"></el-table-column>
        <el-table-column prop="mib" label="MIB" width="250"></el-table-column>
        <el-table-column prop="date" label="Date" :formatter="formatDate"
                         width="200"></el-table-column>
        <el-table-column label="Process Traps" width="200" align="center">
          <template #default="scope">
            <div class="center-content">
              <input type="checkbox" class="form-control"
                     v-model="scope.row.processTraps"
                     @change="processTraps(scope.row)">
            </div>
          </template>
        </el-table-column>
        <el-table-column label="Actions" width="200" align="center">
          <template slot-scope="scope">
            <div class="center-content">
              <el-tooltip content="Download MIB File" placement="top">
                <el-button type="text" @click="downloadMib(scope.row)">
                  <el-icon name="download" class="icon-large icon-blue"></el-icon>
                </el-button>
              </el-tooltip>
              <el-tooltip content="Delete MIB File" placement="top">
                <el-button type="text" @click="openDialog(scope.row)">
                  <el-icon name="delete" class="icon-large icon-red"></el-icon>
                </el-button>
              </el-tooltip>
            </div>
          </template>
        </el-table-column>
      </el-table>
      <el-dialog
        center
        title="Error"
        :visible.sync="modals.error">
        <div class="text-center">
          <span>Error: {{ error_txt }}</span>
          <div class="text-center mt-4">
          <span slot="footer">
<l-button type="btn btn-danger" round @click="closeModal('error')">OK</l-button>&nbsp;
</span>
          </div>
        </div>
      </el-dialog>
      <el-dialog
        center
        title="Success"
        :visible.sync="modals.success">
        <div class="text-center">
          <span>{{ success_txt }}</span>
          <div class="text-center mt-4">
          <span slot="footer" class="dialog-footer">
<l-button type="btn btn-success" round @click="closeModal('success')">OK</l-button>&nbsp;
</span>
          </div>
        </div>
      </el-dialog>
      <el-dialog :visible.sync="modals.are_you_sure" width="30%">
        <span>Are you sure you want to delete this MIB?</span>
        <span slot="footer" class="dialog-footer">
        <l-button type="danger" @click="modals.are_you_sure = false">Cancel</l-button>
        <l-button type="primary" @click="deleteMib">Confirm</l-button>
      </span>
      </el-dialog>
    </card>
  </div>
</template>

<script>
import FilesAPIHandler from "src/servicehandlers/FilesAPIHandler";

const filesAPIHandler = new FilesAPIHandler();
import SNMPAPIService from "src/servicehandlers/SNMPAPIService";

const snmpAPIService = new SNMPAPIService();
import { Table, TableColumn, Tooltip, Icon, Button } from 'element-ui';
import { Dialog } from 'element-ui';

export default {
  name: "SNMP",
  components: {
    [Dialog.name]: Dialog,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Tooltip.name]: Tooltip,
    [Icon.name]: Icon,
    [Button.name]: Button,
  },
  data() {
    return {
      spinning: false,
      searchQuery: '',
      isActive: false,
      targetMib: null,
      mibs: [],
      error_txt: null,
      success_txt: null,
      filteredMibs: [], // Filtered MIBs data
      modals: {
        error: false,
        success: false,
        are_you_sure: false,
      },
    }
  },
  mounted() {
    this.getSNMP();
  },
  methods: {
    onDrop(e) {
      this.isActive = false;
      const files = e.dataTransfer.files;
      this.uploadFiles(files);
    },
    onFilesSelected(e) {
      const files = e.target.files;
      this.uploadFile(files);
    },
    selectFiles() {
      this.$refs.fileInput.click();
    },
    uploadFiles(files) {
      if (!files.length) return;

      // Handle your file uploads here.
      // You might use a service like axios to post your files to a server.
    },
    async processTraps(mib) {
      this.spinning = true;
      const snmp = {
        mibName: mib.name,
      };

      return snmpAPIService.processMib(snmp, this.$router)
      .then(() => {
        this.handleSuccess('Successfully uploaded mib: ' + mib.name);
        this.spinning = false;
        mib.processTraps = false;
      })
      .catch((error) => {
        this.handleError(error);
      });
    },
    openDialog(mib) {
      this.targetMib = mib; // Set the MIB to delete
      this.openModal('are_you_sure'); // Open the dialog
    },
    deleteMib() {
      // Here, perform the deletion logic like calling an API endpoint to delete the MIB.
      this.closeModal('are_you_sure'); // Close the dialog after deleting the MIB
      return snmpAPIService.deleteMib(this.targetMib, this.$router)
      .then((response) => {
        this.mibs = response;
        this.filteredMibs = response;
      })
      .catch((error) => {
        this.handleError('Error deletingn mib: ', error);
      });
    },
    filterMibs() {
      if (this.searchQuery.trim() === "") {
        this.filteredMibs = this.mibs; // Reset to original MIBs if search query is empty
        return;
      }

      // Perform client-side filtering based on the search query
      const query = this.searchQuery.toLowerCase();
      this.filteredMibs = this.mibs.filter(mib =>
        mib.name.toLowerCase().includes(query) ||
        mib.mib.toLowerCase().includes(query)
      );
    },
    getSNMP() {
      return snmpAPIService.get(this.$router)
      .then((response) => {
        this.mibs = response;
        this.filteredMibs = response;
      })
      .catch((error) => {
        this.handleError('Getting SNMP errors failed: ', error);
      });
    },
    formatDate(row, column, cellValue) {
      const date = new Date(cellValue);
      return date.toLocaleString();
    },
    async downloadMib(row) {
      try {
        const response = await filesAPIHandler.downloadMib(row.name, this.$router);
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', row.name);
        document.body.appendChild(link);
        link.click();
      } catch (e) {
        this.handleError('Error downloading MIB!');
      }
    },
    openModal(name) {
      this.modals[name] = true
    },
    closeModal(name) {
      this.modals[name] = false;
    },
    handleSuccess(text) {
      this.success_txt = text;
      this.openModal('success');
      this.spinning = false;
      setTimeout(() => {
        this.modals['success'] = false;
      }, 1500); // Hide after 1.5 secs
    },
    handleError(error) {
      this.error_txt = error;
      this.openModal('error');
      this.spinning = false;
      setTimeout(() => {
        this.modals['error'] = false;
      }, 1500); // Hide after 1.5 secs
    },
  }
}
</script>

<style scoped>
.dropzone {
  padding: 20px;
  border: 2px dashed #ccc;
  text-align: center;
  cursor: pointer;
  transition: background-color 0.2s;
}

.dropzone.active-dropzone {
  background-color: #f0f0f0;
}

.list-container {
  max-height: 600px; /* Adjust as needed */
  overflow-y: auto;
  border: 1px solid #cccccc;
}

.grey-bg {
  background-color: #b7b6b6;
  padding: 5px;
}

.white-bg {
  background-color: white;
  padding: 5px;
}

.card-title {
  color: dimgrey;
}

.choose-files {
  font-size: 15px;
  position: absolute;
  left: 5%;
}

.center-content {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.card-title2 {
  color: dimgrey;
  text-align: left;
  font-size: 15px;
  margin-bottom: 0;
  margin-top: 0;
}

.mib-class {
  color: dimgrey;
  font-size: 23px;
  margin-top: 0;
  margin-bottom: 10px;
}

.mib-class-2 {
  color: dimgrey;
  font-size: 17px;
  margin-top: 0;
  margin-bottom: 5px;
}
</style>
