import CommonAPIService from 'src/servicehandlers/CommonHandler';
//testing

export class ServerSettingsAPIService {
  constructor() {
    this.search = '';
    this.CommonAPIService = new CommonAPIService();
  }

  get(router) {
    const url = '/api/server_settings';
    return this.CommonAPIService.getCall(url, '', router);
  }
  delete(id, router) {
    const url = '/api/server_settings';
    return this.CommonAPIService.deleteCall(url, null, router);
  }
  getById(id, router) {
    const url = `/api/server_settings/${id}`;
    return this.CommonAPIService.getCall(url, null, router);
  }
  update(id, system, router) {
    const url = `/api/server_settings/${id}`;
    return this.CommonAPIService.putCall(url, system, router);
  }
  create(system, router) {
    const url = `/api/server_settings`;
    return this.CommonAPIService.postCall(url, system, router);
  }
}

export default ServerSettingsAPIService;
