<template>
  <div>
    <div slot="header">
      <div class="row m-auto">
        <div class="mb-3">
          <el-input v-model="searchTerm" placeholder="Search" style="width: 300px" clearable></el-input>
          <el-button class="ml-1" @click="clearAllFilters">Clear All</el-button>
        </div>
        <div class="mx-5 mb-3">
          <el-select v-model="selectedHostGroups" multiple placeholder="Filter by Host groups">
            <el-option v-for="hostgroup in availableHostGroups" :key="hostgroup.id" :label="hostgroup.name" :value="hostgroup.id"></el-option>
          </el-select>
          <el-button class="ml-1" @click="clearHostGroups">Clear</el-button>
        </div>
        <div class="mb-3">
          <el-select v-model="selectedOS" multiple placeholder="Filter by OS">
            <el-option v-for="os in availableOS" :key="os.id" :label="os.friendly_name" :value="os.id"></el-option>
          </el-select>
          <el-button class="ml-1" @click="clearOperatingSystems">Clear</el-button>
        </div>
      </div>
    </div>

    <!-- Hosts Table -->
    <el-table :data="filteredHosts">
      <el-table-column prop="name" label="Host Name" :min-width="20">
        <template slot-scope="{ row }">
          <p class="card-category my-0">{{'Host: ' + row.name}}</p>
          <p class="card-category my-0">{{'Alias: ' + row.alias}}</p>
          <p class="card-category my-0">{{'IP Address: ' + row.ip_address}}</p>
        </template>
      </el-table-column>
      <el-table-column label="Analyze" :min-width="11">
        <template slot-scope="{ row }">
          <el-button type="primary" size="small" @click="analyzeHost(row.id)">Analyze</el-button>
        </template>
      </el-table-column>
      <el-table-column label="Custom Command" :min-width="34">
        <template slot-scope="{ row }">
          <el-input v-model="row.command" style="width: 65%; margin-right: 20px" placeholder="Enter command"></el-input>
          <el-button type="primary" size="small" @click="customCommand(row)">Submit</el-button>
        </template>
      </el-table-column>
      <el-table-column label="View Running Commands" :min-width="19">
        <template slot-scope="{ row }">
          <el-button type="info" size="small" @click="viewJobs(row.id)">View Running Commands</el-button>
        </template>
      </el-table-column>
      <el-table-column label="View Results" :min-width="14">
        <template slot-scope="{ row }">
          <el-button type="info" size="small" @click="viewResults(row.id)">View Results</el-button>
        </template>
      </el-table-column>
    </el-table>

    <!-- Results Dialog -->
    <el-dialog title="Host Results" :visible.sync="isResultsDialogVisible">
      <p>{{ selectedHostResult }}</p>
    </el-dialog>
    <el-dialog
      center
      title="Error"
      :visible.sync="modals.error">
      <div class="text-center">
        <span>Error: {{ error_txt }}</span>
        <div class="text-center mt-4">
          <span slot="footer" class="dialog-footer">
            <l-button type="btn btn-danger" round @click="closeModal('error')">OK</l-button>&nbsp;
          </span>
        </div>
      </div>
    </el-dialog>
    <el-dialog
      center
      title="Success"
      :visible.sync="modals.success">
      <div class="text-center">
        <span>{{ success_txt }}</span>
        <div class="text-center mt-4">
          <span slot="footer" class="dialog-footer">
            <l-button type="btn btn-success" round @click="closeModal('success')">OK</l-button>&nbsp;
          </span>
        </div>
      </div>
    </el-dialog>
    <el-dialog
      center
      width="80%"
      :visible.sync="modals.jobs">
      <div class="text-center">
        <card>
          <div class="row">
            <div class="col-sm-12">
              <div slot="header">
                <h4 class="card-title">Current Running Jobs</h4>
                <p class="card-category mb-2">Click on each job to view more about it</p>
              </div>
              <el-table class="table-striped" border :data="hostJobs">
                <el-table-column label="AI Command" property="action"></el-table-column>
                <el-table-column label="Status" property="status"></el-table-column>
              </el-table>
            </div>
          </div>
        </card>
        <card>
          <div class="row">
            <div class="col-sm-12">
              <div slot="header">
                <h4 class="card-title">Old Jobs</h4>
                <p class="card-category mb-2">Click on each job to view more about it</p>
              </div>
              <el-table class="table-striped" border :data="hostArchiveJobs">
                <el-table-column label="AI Command" property="action"></el-table-column>
                <el-table-column label="Status" property="status"></el-table-column>
                <el-table-column label="Result" property="result"></el-table-column>
              </el-table>
            </div>
          </div>
          <br>
        </card>
        <span slot="footer" class="dialog-footer">
          <button class="btn btn-primary" @click="closeModal('jobs')">Close</button>&nbsp;
        </span>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {Dialog, Pagination, Button, Select, Option, Tooltip, Checkbox, Table, TableColumn} from 'element-ui';
import HostsAPIService from "src/servicehandlers/HostsAPIService";
const hostsAPIService = new HostsAPIService();
import HostGroupsAPIService from "src/servicehandlers/HostGroupsAPIService";
const hostGroupsAPIService = new HostGroupsAPIService();
import OperatingSystemAPIService from "src/servicehandlers/OperatingSystemAPIService";
const operatingSystemAPIService = new OperatingSystemAPIService();
import RunningCommandsAPIService from "src/servicehandlers/RunningCommandsAPIService";
const runningCommandsAPIService = new RunningCommandsAPIService();
import RunningCommandsArchiveAPIService from "src/servicehandlers/RunningCommandsArchiveAPIService";
const runningCommandsArchiveAPIService = new RunningCommandsArchiveAPIService();
import HostActionQueuesAPIService from "../../../../servicehandlers/HostActionQueuesAPIService";
const hostActionQueuesAPIService = new HostActionQueuesAPIService();

export default {
  name: "AICyberSecurity",
  components: {
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Pagination.name]: Pagination,
    [Button.name]: Button,
    [Select.name]: Select,
    [Option.name]: Option,
    [Tooltip.name]: Tooltip,
    [Checkbox.name]: Checkbox,
    [Dialog.name]: Dialog,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
  },
  data() {
    return {
      searchTerm: '',
      selectedHostGroups: [],
      selectedOS: [],
      availableHostGroups: [], // Populate this from your data source
      availableOS: [], // Populate this from your data source
      hosts: [], // Populate this with host data
      isResultsDialogVisible: false,
      selectedHostResult: '',
      success_txt: '',
      error_txt: '',
      hostJobs: [],
      hostArchiveJobs: [],
      modals: {
        error: false,
        success: false,
        jobs: false,
      },
    };
  },
  async mounted() {
    await this.getHosts();
    await this.getHostGroups();
    await this.getOperatingSystems();
  },
  computed: {
    filteredHosts() {
      let hosts = this.hosts; // Assuming hostTableData contains your hosts data

      // Search by host name
      if (this.searchTerm) {
        const term = this.searchTerm.toLowerCase();
        hosts = hosts.filter(host => host.name && (
          host.name.toLowerCase().includes(term) ||
          host.alias.toLowerCase().includes(term) ||
          host.ip_address.toLowerCase().includes(term)
        ));
      }

      // Filter by selected host groups
      if (this.selectedHostGroups && this.selectedHostGroups.length) {
        hosts = hosts.filter(host => {
          return host.host_groups && host.host_groups.some(group => this.selectedHostGroups.includes(group.id));
        });
      }

      // Filter by selected operating systems
      if (this.selectedOS && this.selectedOS.length) {
        hosts = hosts.filter(host => this.selectedOS.includes(host.operating_system_id)); // Assuming each host has an operatingSystemId property
      }

      return hosts;
    }
  },
  methods: {
    async getOperatingSystems() {
      try {
        this.availableOS = await operatingSystemAPIService.get(this.$router);
      } catch (e) {
        this.handleError('Error getting operating systems');
      }
    },
    async getHostGroups() {
      try {
        this.availableHostGroups = await hostGroupsAPIService.get(this.$router);
      } catch (e) {
        this.handleError('Error getting host groups');
      }
    },
    async getHosts() {
      return hostsAPIService.getHostsWithOperatingSystems(this.$router)
      .then((response) => {
        this.hosts = response;
      })
      .catch((error) => {
        this.handleError('Error getting hosts: ' + error);
      });
    },
    clearAllFilters() {
      this.searchTerm = '';
      this.selectedHostGroups = [];
      this.selectedOS = [];
    },
    clearHostGroups() {
      this.selectedHostGroups = [];
    },
    clearOperatingSystems() {
      this.selectedOS = [];
    },
    analyzeHost(hostId) {
      return hostsAPIService.createHostQueueItem(hostId, {items: ['analyze the server now']}, this.$router)
      .then((response) => {
        return hostsAPIService.update(hostId, {needs_update: true}, this.$router)
      })
      .then((response) => {
        this.handleSuccess('Successfully started system analysis');
      })
      .catch((error) => {
        this.handleError('Error setting host analysis: ' + error);
      });
    },
    customCommand(row) {
      return hostsAPIService.createHostQueueItem(row.id, {items: [row.command]}, this.$router)
        .then((response) => {
          return hostsAPIService.update(row.id, {needs_update: true}, this.$router)
        })
        .then((response) => {
          this.handleSuccess('Successfully started custom command');
        })
        .catch((error) => {
          this.handleError('Error setting custom command: ' + error);
        });
    },
    viewResults(hostId) {
      this.selectedHostResult = 'Results for host ' + hostId; // Replace with actual results
      return hostsAPIService.getHostQueueResults(hostId, this.$router)
      .then((response) => {
        for (const resp of response) {
          this.selectedHostResult = 'Results for host: ' + resp.result;
        }
        this.isResultsDialogVisible = true;
      })
      .catch((error) => {
        this.handleError('Error getting hosts queue results ' + error);
      })
    },
    viewJobs(host) {
      return hostActionQueuesAPIService.getById(host, this.$router)
        .then((currentJobs) => {
          for (const job of currentJobs) {
            if (job.status === 'running' || job.status === 'pending') {
              this.hostJobs.push(job);
            } else if (job.status === 'completed' ) {
              this.hostArchiveJobs.push(job);
            }
          }
          this.openModal('jobs');
        })
        .catch((error) => {
          this.handleError('Error getting host jobs and host archive jobs: ' + error);
        })
    },
    handleSuccess(text) {
      this.success_txt = text;
      this.openModal('success');
      this.spinning = false;
      setTimeout(() => {
        this.modals['success'] = false;
      }, 1500); // Hide after 1.5 secs
    },
    handleError(error) {
      this.error_txt = error;
      this.openModal('error');
      this.spinning = false;
      setTimeout(() => {
        this.modals['error'] = false;
      }, 1500); // Hide after 1.5 secs
    },
    openModal(name) {
      this.modals[name] = true
    },
    closeModal(name) {
      this.modals[name] = false;
    },
  },
}
</script>

<style scoped>
.dropdown-container {
  display: flex;
  align-items: center;
  gap: 5px;
  margin-top: 10px;
  margin-bottom: 10px;
}
</style>
