import CommonAPIService from 'src/servicehandlers/CommonHandler';

export class HostActionQueuesAPIService {
  constructor() {
    this.CommonAPIService = new CommonAPIService();
  }

  create(host_action_queue, router) {
    const url = '/api/host_action_queues';
    return this.CommonAPIService.postCall(url, host_action_queue, router);
  }

  update(host_action_queue_id, host_action_queue, router) {
    const url = `/api/host_action_queues/${host_action_queue_id}`;
    return this.CommonAPIService.putCall(url, host_action_queue, router);
  }

  delete(id, router) {
    const url = `/api/host_action_queues/${id}`;
    return this.CommonAPIService.deleteCall(url, null, router);
  }

  get(router) {
    const url = '/api/host_action_queues';
    return this.CommonAPIService.getCall(url, null, router);
  }

  getById(id, router) {
    const url = `/api/host_action_queues/${id}`;
    return this.CommonAPIService.getCall(url, null, router);
  }
}

export default HostActionQueuesAPIService;
