<template>
  <div>
    <card v-if="spinning">
      <div class="row">
        <div class="col-sm-12 text-center">
          <b-spinner variant="primary" label="Spinning"></b-spinner>
        </div>
      </div>
    </card>
    <card>
      <h3 class="custom-header">Alerts Page</h3>
      <h3 class="custom-header-2">The purpose of this page is to show who has been notified by the monitoring software.</h3>
    </card>
    <card>
      <h1 class="table-header">Alerts Table</h1>
      <h1 class="card-category mb-3">
        You can review the notifications by the time or date and view who was sent alerts.
      </h1>
      <div class="row">
        <div class="col-sm-5">
          <el-input
            v-model="searchTerm"
            placeholder="Search by Name, IP Address, or Alias">
            <span slot="prepend">Search</span>
          </el-input>
        </div>
      </div>
        <label class="dropdown-1" for="exit_codeFilter">Filter by State: </label>
      <div class="dropdown-container col-sm-4">
        <el-select v-model="selectedExitCode" id="exit_codeFilter">
          <el-option label="ALL" value=""></el-option>
          <el-option label="OK" value="0"></el-option>
          <el-option label="Warning" value="1"></el-option>
          <el-option label="Error" value="2"></el-option>
          <el-option label="Unknown" value="unknown"></el-option>
        </el-select>
      </div>
        <label>Please select a time period</label>
      <div class="dropdown-2 dropdown-container col-sm-4">
        <el-select v-model="timePeriod" placeholder="Select Time Period"
                   @change="onTimePeriodChange">
          <el-option label="4 hours" value="4h"></el-option>
          <el-option label="24 hours" value="24h"></el-option>
          <el-option label="1 week" value="1w"></el-option>
          <el-option label="1 month" value="1m"></el-option>
          <el-option :label="customDatesLabel" :value="customValue"></el-option>
        </el-select>
      </div>

        <el-table border :data="paginatedAlerts" :row-class-name="tableRowClassName">
          <el-table-column prop="time_sent" label="Time" width="130"></el-table-column>
          <el-table-column min-width="150" prop="hostname" label="Hostname" width="120"></el-table-column>
          <el-table-column min-width="200" prop="check" label="Check Name" width="130"></el-table-column>
          <el-table-column prop="message" label="Message" width="300">
            <template v-slot="{ row }">
              <el-tooltip content="Click to view full message" placement="top">
                <div class="ellipsis" @click="preOpenModal(row.message)">{{ row.message }}</div>
              </el-tooltip>
            </template>
          </el-table-column>
          <el-table-column prop="user" label="Recipient" width="140">
          </el-table-column>
          <el-table-column prop="email" label="Email" width="160"></el-table-column>
          <el-table-column prop="sms" label="SMS" width="100"></el-table-column>
          <el-table-column prop="exit_code" label="Exit Code" width="100" :align="cellAlignCenter"></el-table-column>
        </el-table>

        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="currentPage"
          :page-sizes="pageSizes"
          :page-size="pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="filteredAlerts.length"
        >
        </el-pagination>
    </card>
    <el-dialog
      center
      title="Error"
      :visible.sync="modals.error">
      <div class="text-center">
        <span>Error: {{ error_txt }}</span>
        <div class="text-center mt-4">
          <span slot="footer" class="dialog-footer">
<l-button type="btn btn-danger" round @click="closeModal('error')">OK</l-button>&nbsp;
</span>
        </div>
      </div>
    </el-dialog>
    <el-dialog
      center
      title="Success"
      :visible.sync="modals.success">
      <div class="text-center">
        <span>{{ success_txt }}</span>
        <div class="text-center mt-4">
          <span slot="footer" class="dialog-footer">
<l-button type="btn btn-success" round @click="closeModal('success')">OK</l-button>&nbsp;
</span>
        </div>
      </div>
    </el-dialog>
    <el-dialog :visible.sync="modals.datetime" title="Select Custom Date and Time Period">
      <el-date-picker
        v-model="customDates"
        type="datetimerange"
        range-separator="to"
        start-placeholder="Start Date and Time"
        end-placeholder="End Date and Time"
        align="right"
        :default-time="['12:00:00', '12:00:00']"
      ></el-date-picker>
      <span slot="footer" class="dialog-footer">
      <l-button @click="modals.datetime = false">Cancel</l-button>
      <l-button type="primary" @click="applyCustomDates">Apply</l-button>
    </span>
    </el-dialog>
    <el-dialog :visible.sync="modals.message" width="50%">
      <span>{{ selectedMessage }}</span>
      <span slot="footer" class="dialog-footer">
    <el-button @click="closeModal('message')">Close</el-button>
  </span>
    </el-dialog>
  </div>
</template>

<script>
import moment from 'moment';
import { Tooltip, Button, Table, TableColumn } from 'element-ui';
import { DatePicker, Dialog, Select, Option, Pagination } from 'element-ui';
import AlertsAPIService from "src/servicehandlers/AlertsAPIService";

const alertsAPIService = new AlertsAPIService();

export default {
  data() {
    return {
      searchTerm: '',
      alertList: [], // To store the alerts fetched from the API or any other source.
      spinning: false,
      success_txt: null,
      timePeriod: '24h',
      totalItems: 0,
      customDates: [],
      error_txt: null,
      currentPage: 1,
      perPage: 50,
      pageSize: 20,
      pageSizes: [20, 50, 100, 200, 500, 1000],
      alerts: [],
      selectedMessage: '',
      selectedExitCode: '',
      cellAlignCenter: 'center',
      modals: {
        error: false,
        success: false,
        datetime: false,
        message: false,
      },
    }
  },
  watch: {
    filteredAlerts: {
      handler(newVal) {
        this.totalItems = newVal.length;
      },
      immediate: true  // to run the handler immediately after the watcher is created
    }
  },
  computed: {
    filteredAlerts() {
      let filtered = this.alerts;

      if (this.searchTerm) {
        const lowercasedQuery = this.searchTerm.toLowerCase();

        filtered = filtered.filter(alert => {
          const timeSent = alert.time_sent?.toString().toLowerCase() ?? '';
          const hostName = alert.hostname?.toLowerCase() ?? '';
          const userEmail = alert.email?.toLowerCase() ?? '';
          const userName = alert.user?.toLowerCase() ?? '';
          const userMessage = alert.message?.toLowerCase() ?? '';
          const checkDescription = alert.check?.toLowerCase() ?? '';

          return timeSent.includes(lowercasedQuery) ||
            userMessage.includes(lowercasedQuery) ||
            hostName.includes(lowercasedQuery) ||
            userEmail.includes(lowercasedQuery) ||
            userName.includes(lowercasedQuery) ||
            checkDescription.includes(lowercasedQuery);
        });
      }

      if (this.selectedExitCode !== '') {
        filtered = filtered.filter(alert => {
          switch (this.selectedExitCode) {
            case '0':
              return alert.exit_code === 0;
            case '1':
              return alert.exit_code === 1;
            case '2':
              return alert.exit_code === 2;
            case 'unknown':
              return ![0, 1, 2].includes(alert.exit_code);
            default:
              return true;
          }
        });
      }

      return filtered;
    },
    customDatesLabel() {
      return this.customDatesString ? `Custom: ${this.customDatesString}` : 'Custom';
    },
    customValue() {
      return this.customDatesString ? this.customDatesString : 'custom';
    },
    customDatesString() {
      if (this.customDates.length === 2) {
        const [start, end] = this.customDates;
        const startMoment = moment(start);
        const endMoment = moment(end);
        return `${startMoment.format('YYYY-MM-DD HH:mm:ss')} to ${endMoment.format('YYYY-MM-DD HH:mm:ss')}`;
      }
      return '';
    },
    paginatedAlerts() {
      const start = (this.currentPage - 1) * this.pageSize;
      const end = this.currentPage * this.pageSize;
      return this.filteredAlerts.slice(start, end);
    },
  },
  mounted() {
    this.timePeriod = '24h';
    this.getAlerts();
  },
  components: {
    [Tooltip.name]: Tooltip,
    [Table.name]: Table,
    [Button.name]: Button,
    [TableColumn.name]: TableColumn,
    'el-date-picker': DatePicker,
    'el-select': Select,
    'el-option': Option,
    'el-pagination': Pagination,
    [Dialog.name]: Dialog,
  },
  name: "Alerts",
  methods: {
    preOpenModal(message) {
      this.selectedMessage = message;
      this.openModal('message');
    },
    tableRowClassName({ row }) {
      switch (row.exit_code) {
        case 0:
          return 'success-row';
        case 1:
          return 'warning-row';
        case 2:
          return 'error-row';
        default:
          return 'unknown-row';
      }
    },
    onTimePeriodChange() {
      if (this.timePeriod === 'custom' && !this.customDatesString) {
        this.modals.datetime = true;
      } else {
        // get the new stuff from the DB
        this.getAlerts();
      }
    },
    applyCustomDates() {
      if (this.customDates.length === 2) {
        this.timePeriod = this.customDatesString;
        this.modals.datetime = false;
        this.getAlerts();
      } else {
        // console.error("Invalid date and time range selected");
      }
    },
    formatTime(time) {
      return moment(time).format('YYYY-MM-DD HH:mm:ss');
    },
    getRowClass(alert) {
      switch (alert.exit_code) {
        case 0:
          return 'success-row';
        case 1:
          return 'warning-row';
        case 2:
          return 'error-row';
        default:
          return 'unknown-row';
      }
    },
    getAlerts() {
      let startDateTime;
      let endDateTime = moment(); // Assuming the end date is now

      switch (this.timePeriod) {
        case '4h':
          startDateTime = moment().subtract(4, 'hours');
          break;
        case '24h':
          startDateTime = moment().subtract(24, 'hours');
          break;
        case '1w':
          startDateTime = moment().subtract(1, 'weeks');
          break;
        case '1m':
          startDateTime = moment().subtract(1, 'months');
          break;
        default: // Custom Date Range
          if (this.customDates.length === 2) {
            const [start, end] = this.customDates;
            startDateTime = moment(start); // convert start date to moment object
            endDateTime = moment(end); // convert end date to moment object
          }
          break;
      }

      return alertsAPIService.getAlertsByTimeframe(startDateTime, endDateTime, this.$router)
      .then((response) => {
        this.alerts = [];
        for (const alert of response) {
          const new_alert = {
            time_sent: null,
            exit_code: null,
            hostname: null,
            message: null,
            email: null,
            sms: null,
            check: 'HOST CHECK',
          };
          if (alert.time_sent) {
            new_alert.time_sent = alert.time_sent;
          }
          new_alert.exit_code = alert.exit_code;
          if (alert.host && alert.host.name) {
            new_alert.hostname = alert.host.name;
          }
          if (alert.host && alert.host.msg) {
            new_alert.message = alert.content;
          }
          if (alert.user && alert.user.email) {
            new_alert.email = alert.user.email;
          }
          if (alert.user && alert.user.sms) {
            new_alert.sms = alert.user.sms;
          }
          if (alert.user && alert.user.first_name) {
            new_alert.user = alert.user.first_name + ' ' + alert.user.last_name;
          }
          if (alert && alert.checks_to_host && alert.checks_to_host.check) {
            new_alert.check = alert.checks_to_host.check.description;
          }
          this.alerts.push(new_alert);
        }
      })
      .catch((error) => {
        this.handleError('Error getting alerts! ' + error);
      });
    },
    openModal(name) {
      this.modals[name] = true
    },
    closeModal(name) {
      this.modals[name] = false;
    },
    handleTimeChange(value) {
      if (value === 'custom') {
        this.modals.datetime = true;
      } else {
        // Re-filter alerts based on the new time period
      }
    },
    handleSizeChange(val) {
     // console.log(`Each page ${val} lines`);
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      //console.log(`Current page: ${val}`);
    },
    handleSuccess(text) {
      this.success_txt = text;
      this.openModal('success');
      this.spinning = false;
      setTimeout(() => {
        this.modals['success'] = false;
      }, 1500); // Hide after 1.5 secs
    },
    handleError(error) {
      this.error_txt = error;
      this.openModal('error');
      this.spinning = false;
      setTimeout(() => {
        this.modals['error'] = false;
      }, 1500); // Hide after 1.5 secs
    },
  }
}
</script>

<style scoped>
.custom-table {
  width: 100%;
  border: 1px solid #ebeef5;
}

.table-header, .row {
  display: flex;
}

.cell {
  padding: 12px 8px;
  border-bottom: 1px solid #ebeef5;
  flex: 1;
}

/* Define your CSS styles for different row colors here */
.success-row {
  background-color: #d4edda !important;
}

.warning-row {
  background-color: #ffeeba !important;
}

.error-row {
  background-color: #f8d7da !important;
}

.unknown-row {
  background-color: #dee2e6 !important;
}

.el-table .success-row td {
  background-color: #d4edda !important;
}

.el-table .warning-row td {
  background-color: #ffeeba !important;
}

.el-table .error-row td {
  background-color: #f8d7da !important;
}

.el-table .unknown-row td {
  background-color: #dee2e6 !important;
}

::v-deep .el-table .success-row td {
  background-color: #d4edda !important;
}

::v-deep .el-table .warning-row td {
  background-color: #ffeeba !important;
}

::v-deep .el-table .error-row td {
  background-color: #f8d7da !important;
}

::v-deep .el-table .unknown-row td {
  background-color: #dee2e6 !important;
}

.ellipsis {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.dropdown-container {
  display: flex;
  flex-direction: column;
  //align-items: center;
}
.table-header {
  color: dimgrey;
  font-size: 25px;
  margin-top: 5px;
}
.custom-header {
  color: dimgrey;
  font-size: 17px;
  margin-bottom: 10px;
  margin-top: 0;
}
.custom-header-2 {
  color: dimgrey;
  font-size: 15px;
  margin-top: 0;
}
.dropdown-1 {
  margin-top: 10px;
}
.dropdown-2 {
  margin-bottom: 20px;
}
.card-category {
  margin-top: 0;
}
.center-content {
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
