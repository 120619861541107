import CommonAPIService from './CommonHandler';

export class CollectorAPIService {
  constructor() {
    this.CommonAPIService = new CommonAPIService();
  }
  create(collector, router) {
    const url = '/api/collectors';
    return this.CommonAPIService.postCall(url, collector, router);
  }
  connectHosts(id, hosts, router) {
    const url = `/api/collectors/${id}/hosts`;
    return this.CommonAPIService.postCall(url, hosts, router);
  }
  delete(id, router) {
    const url = `/api/collectors/${id}`;
    return this.CommonAPIService.deleteCall(url, null, router);
  }
  update(id, collector, router) {
    const url = `/api/collectors/${id}`;
    return this.CommonAPIService.putCall(url, collector, router);
  }

  getCollectorHosts(id, router) {
    const url = `/api/collectors/${id}/hosts`;
    return this.CommonAPIService.getCall(url, null, router);
  }
  get(router) {
    const url = '/api/collectors';
    return this.CommonAPIService.getCall(url, null, router);
  }
}

export default CollectorAPIService;
