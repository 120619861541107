<template>
  <div>
    <card>
      <div slot="header">
        <h4 class="card-title">Operating Systems
        <el-tooltip content="Add New Operating System">
          <el-button type="text" @click="beginAddOperatingSystem()">
            <i class="fa-solid fa-circle-plus"></i>
          </el-button>
        </el-tooltip>
        </h4>
      </div>

      <div class="row">
        <div class="col-sm-12">
          <div class="container">
            <table class="table table-bordered mt-2">
              <thead>
              <tr>
                <th>Friendly Name</th>
                <th>Name</th>
                <th>Aliases</th>
                <th>Actions</th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="operating_system in operating_systems" :key="operating_system.id">
                <td>
                  <input type="text" class="form-control" v-model="operating_system.friendly_name"/>
                </td>
                <td>
                  <input type="text" class="form-control" v-model="operating_system.name"/>
                </td>
                <td>
                  <input type="text" class="form-control" v-model="operating_system.patterns"/>
                </td>
                <td>

                  <div class="center-btn">
                  <link
                    rel="stylesheet"
                    href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.0.0/css/all.min.css">
                  <el-tooltip content="Update Operating System" placement="top">
                    <el-button type="text" @click="updateOperatingSystem(operating_system)">
                      <i class="fa-regular fa-floppy-disk"></i>
                    </el-button>
                  </el-tooltip>
                  <el-tooltip content="Delete Operating System" placement="top">
                    <el-button type="text" @click="deleteOperatingSystem(operating_system)">
                      <el-icon name="delete" class="icon-large icon-red"></el-icon>
                    </el-button>
                  </el-tooltip>
                  </div>
                </td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </card>
    <el-dialog
      center
      width="30%"
      :visible.sync="modals.newOs">
      <div class="row">
        <div class="col-sm-12 d-flex justify-content-center">
          <div class="card-title text-center pb-3 mt-1">
            Add Operating System
          </div>
        </div>
      </div>
      <div class="text-center">
        <div class="row">
          <div class="col-sm-12 mb-2">
            <label>
              Friendly Name:
              <fg-input style="width: 300px;" class="form-row" placeholder="Friendly Name" v-model="operating_system.friendly_name"></fg-input>
            </label>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-12 mb-2">
            <label>
              Name:
              <fg-input style="width: 300px;" placeholder="Name" v-model="operating_system.name"></fg-input>
            </label>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-12">
            <label>
              Patterns:
              <fg-input style="width: 300px;" placeholder="Patterns" v-model="operating_system.patterns"></fg-input>
            </label>
          </div>
        </div>
        <br>
        <span slot="footer" class="dialog-footer">
<l-button round type="btn btn-primary" @click="saveOperatingSystem('newOs')">Save</l-button>&nbsp;
<l-button round type="btn btn-danger" @click="closeModal('newOs')">Cancel</l-button>&nbsp;
</span>
      </div>
    </el-dialog>
    <el-dialog
      center
      title="Success"
      :visible.sync="modals.success">
      <div class="text-center">
        <span>{{ success_txt }}</span>
        <div class="text-center mt-4">
          <span slot="footer">
            <l-button type="btn btn-success" round @click="closeModal('success')">OK</l-button>
          </span>
        </div>
      </div>
    </el-dialog>
    <el-dialog
      center
      title="Error"
      :visible.sync="modals.error">
      <span>Error: {{ error_txt }}</span>
      <div class="text-center mt-4">
        <span slot="footer">
          <l-button type="btn btn-danger" round @click="closeModal('error')">OK</l-button>
        </span>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { Dialog, Button, Icon, Tooltip } from 'element-ui';
import OperatingSystemAPIService from "src/servicehandlers/OperatingSystemAPIService";
const operatingSystemAPIService = new OperatingSystemAPIService();
export default {
  name: "OperatingSystems",
  components: {
    [Dialog.name]: Dialog,
    [Button.name]: Button,
    [Icon.name]: Icon,
    [Tooltip.name]: Tooltip,
  },
  data() {
    return {
      operating_systems: [],
      success_txt: null,
      error_txt: null,
      operating_system: {
        friendly_name: '',
        patterns: '',
        name: '',
      },
      modals: {
        error: false,
        success: false,
        newOs: false,
      },
    }
  },
  mounted() {
    this.getOperatingSystems();
  },
  methods: {
    saveOperatingSystem() {
      this.closeModal('newOs');
      return operatingSystemAPIService.create(this.operating_system, this.$router)
      .then(() => {
        const self = this;
        this.success_txt = 'Created Operating System successfully';
        this.spinning = false;
        this.openModal('success');
        setTimeout(function () {
          self.modals['success'] = false; // Use your variable name
        }, 1500); // Hide after 5 secs
        this.getOperatingSystems();
      })
      .catch((error) => {
        const self = this;
        this.error_txt = 'Failed to create operating systems' + error;
        this.openModal('error');
        this.spinning = false;
        setTimeout(function () {
          self.modals['error'] = false; // Use your variable name
        }, 1500); // Hide after 5 secs
      });
    },
    updateOperatingSystem(operating_system) {
      return operatingSystemAPIService.update(operating_system.id, operating_system, this.$router)
      .then(() => {
        const self = this;
        this.success_txt = 'Updated Operating System successfully';
        this.spinning = false;
        this.openModal('success');
        setTimeout(function () {
          self.modals['success'] = false; // Use your variable name
        }, 1500); // Hide after 5 secs
        this.getOperatingSystems();
      })
      .catch((error) => {
        const self = this;
        this.error_txt = 'Failed to update operating systems' + error;
        this.openModal('error');
        this.spinning = false;
        setTimeout(function () {
          self.modals['error'] = false; // Use your variable name
        }, 1500); // Hide after 5 secs
      });
    },
    deleteOperatingSystem(operating_system) {
      return operatingSystemAPIService.delete(operating_system.id, this.$router)
      .then(() => {
        const self = this;
        this.success_txt = 'Deletion of Operating System successfully';
        this.spinning = false;
        this.openModal('success');
        setTimeout(function () {
          self.modals['success'] = false; // Use your variable name
        }, 1500); // Hide after 5 secs
        this.getOperatingSystems();
      })
      .catch((error) => {
        const self = this;
        this.error_txt = 'Failed to delete operating system' + error;
        this.openModal('error');
        this.spinning = false;
        setTimeout(function () {
          self.modals['error'] = false; // Use your variable name
        }, 1500); // Hide after 5 secs
      });
    },
    beginAddOperatingSystem() {
      this.openModal('newOs');
      this.success_txt = 'Operating System creation success';
      this.error_txt = 'Failed to create new Operating System';
    },
    addOperatingSystem() {
      return operatingSystemAPIService.create(this.operating_system, this.$router)
      .then(() => {
        const self = this;
        this.success_txt = 'Operating System added successfully';
        this.spinning = false;
        this.openModal('success');
        setTimeout(function () {
          self.modals['success'] = false; // Use your variable name
        }, 1500); // Hide after 5 secs
        this.getOperatingSystems();
      })
      .catch((error) => {
        const self = this;
        this.error_txt = 'Failed to get operating systems' + error;
        this.openModal('error');
        this.spinning = false;
        setTimeout(function () {
          self.modals['error'] = false; // Use your variable name
        }, 1500); // Hide after 5 secs
      });
    },
    getOperatingSystems() {
      this.operating_systems = [];
      return operatingSystemAPIService.get(this.$router)
      .then((operatingsystems) => {
        this.operating_systems = operatingsystems;
      })
      .catch((error) => {
        const self = this;
        this.error_txt = 'Failed to get operating systems' + error;
        this.openModal('error');
        this.spinning = false;
        setTimeout(function () {
          self.modals['error'] = false; // Use your variable name
        }, 1500); // Hide after 5 secs
      })
    },
    openModal(name) {
      this.modals[name] = true
    },
    closeModal(name) {
      this.modals[name] = false;
    },
  }
}
</script>

<style scoped>
.icon-update {

}
.fa-circle-plus {
  color: mediumseagreen;
  font-size: 25px;
}
.card-title {
  font-size: 25px;
  border-left: 1px;
  color: dimgrey;
}
.center-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}
.fa-floppy-disk {
  font-size: 19px;
  color: dodgerblue;
}
</style>
