<template>
<div>
  <card v-if="spinning">
    <div class="row">
      <div class="col-sm-12 text-center">
        <b-spinner variant="primary" label="Spinning"></b-spinner>
      </div>
    </div>
  </card>
  <card>
  <div class="row">
    <div class="col-sm-12">
      <h2 class="card-category">Restore from Guardian</h2>
      <h3 class="card-cat-2">Upload your backup file here</h3>
      <div
        @dragover.prevent
        @dragenter.prevent
        @drop="onFilesSelected"
        class="dropzone"
        :class="{ 'active-dropzone': isActive }"
      >
        <input type="file" multiple @change="onFilesSelected" ref="fileInput" />
        Drag & drop your files or <span @click="selectFiles">click here</span> to upload.
      </div>
    </div>
  </div>
  </card>
  <el-dialog
    center
    title="Error"
    :visible.sync="modals.error">
    <div class="text-center">
      <span>Error: {{ error_txt }}</span>
      <br>
      <span slot="footer" class="dialog-footer">
<l-button class="btn btn-danger" @click="closeModal('error')">OK</l-button>&nbsp;
</span>
    </div>
  </el-dialog>
  <el-dialog
    center
    title="Success"
    :visible.sync="modals.success">
    <div class="text-center">
      <span>{{ success_txt }}</span>
      <br>
      <span slot="footer" class="dialog-footer">
<l-button class="btn btn-primary" @click="closeModal('success')">OK</l-button>&nbsp;
</span>
    </div>
  </el-dialog>
</div>
</template>

<script>
import { Dialog } from 'element-ui';
import FilesAPIHandler from "src/servicehandlers/FilesAPIHandler";
const filesAPIHandler = new FilesAPIHandler();
export default {
  name: "BackupRestore",
  data() {
    return {
      isActive: false,
      spinning: false,
      success_txt: '',
      error_txt: '',
      modals: {
        error: false,
        success: false,
      },
    }
  },
  components: {
    [Dialog.name]: Dialog,
  },
  methods: {
    onDrop(e) {
      this.isActive = false;
      const files = e.dataTransfer.files;
      this.uploadFiles(files);
    },
    uploadFiles(files) {
      if (!files.length) return;

      // Handle your file uploads here.
      // You might use a service like axios to post your files to a server.
      // console.log(files);
    },
    async uploadFile() {
      this.spinning = true;
      const formData = new FormData();
      formData.append('file', this.$refs.fileInput.files[0]);
      let response = null;
      try {
        response = await filesAPIHandler.restoreFromGuardian(formData, this.$router);
        const self = this;
        this.success_txt = 'Upload of file successful';
        this.openModal('success');
        this.spinning = false;
        setTimeout(function () {
          self.modals['success'] = false; // Use your variable name
        }, 1500); // Hide after 5 secs
        this.$refs.fileInput = [];
      } catch (e) {
        this.spinning = false;
        this.handleError('Error Uploading backup file: ' + e);
      }
    },
    onFilesSelected(e) {
      this.spinning = true;
      const files = e.target.files;
      this.uploadFile(files);
    },
    selectFiles() {
      this.$refs.fileInput.click();
    },
    handleSuccess(text) {
      this.success_txt = text;
      this.openModal('success');
      this.spinning = false;
      setTimeout(() => {
        this.modals['success'] = false;
      }, 1500); // Hide after 1.5 secs
    },
    handleError(error) {
      this.error_txt = error;
      this.openModal('error');
      this.spinning = false;
      setTimeout(() => {
        this.modals['error'] = false;
      }, 1500); // Hide after 1.5 secs
    },
    openModal(name) {
      this.modals[name] = true
    },
    closeModal(name) {
      this.modals[name] = false;
    },
  }
}
</script>

<style scoped>
.dropzone {
  padding: 20px;
  border: 2px dashed #ccc;
  text-align: center;
  cursor: pointer;
  transition: background-color 0.2s;
}
.dropzone.active-dropzone {
  background-color: #f0f0f0;
}
.list-container {
  max-height: 600px; /* Adjust as needed */
  overflow-y: auto;
  border: 1px solid #cccccc;
}

.grey-bg {
  background-color: #b7b6b6;
  padding: 5px;
}

.white-bg {
  background-color: white;
  padding: 5px;
}
.card-category {
  color: dimgrey;
  font-size: 23px;
  margin-top: 5px;
  margin-bottom: 3px;
}
.card-cat-2 {
  color: dimgrey;
  font-size: 17px;
  margin-top: 3px;
  margin-bottom: 5px;
}
</style>
