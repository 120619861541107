<template>
  <div>
    <card v-if="spinning">
      <div class="row">
        <div class="col-sm-12 text-center">
          <b-spinner variant="primary" label="Spinning"></b-spinner>
        </div>
      </div>
    </card>
    <card>
      <div class="row">
        <div class="col-sm-12">
          <h3 class="title-1">The purpose of this page is to assign the users who will be notified when issues
            occur</h3>
          <p class="title-2">You can assign any user or user group to a specific host. Users will be notified when
            they are connected.</p>
        </div>
      </div>
    </card>
    <card>
      <div slot="header">
        <h4 class="card-title">User and User Group Assignments</h4>
        <p class="card-category mb-3">Use this to easily add users and groups to the hosts</p>
      </div>
      <div class="row search-bar">
        <fg-input label="Filter by hostname" class="wide-search" v-model="searchQuery"
                  placeholder="Search hosts..."/>
      </div>
        <div class="row">
          <div class="col-sm-6">
            <div>
              <label for="hostGroupSelect">Filter by Hostgroup: </label>
            </div>
            <el-select
              class="wide-select"
              id="hostGroupSelect"
              v-model="selectedHostGroup"
              filterable
              placeholder="All Host Groups">
              <el-option value="">All Host Groups</el-option>
              <el-option
                v-for="group in hostGroups"
                :key="group.id"
                :label="group.name"
                :value="group.id">
                {{ group.name }}
              </el-option>
            </el-select>
        </div>
          <div class="col-sm-6">
            <div>
              <label for="osSelect">Filter by Operating System</label>
            </div>
            <el-select
              class="wide-select"
              id="osSelect"
              v-model="selectedOperatingSystem"
              placeholder="All Operating Systems">
              <el-option value="">All Operating Systems</el-option>
              <el-option v-for="os in operatingSystems" :key="os.id" :label="os.friendly_name"
                         :value="os.id">{{ os.friendly_name }}
              </el-option>
            </el-select>
          </div>
        </div>


        <div class="row">
          <div class="col-sm-6 assign-drop">
            <div>
              <label for="global-user-select">Assign User Globally:&nbsp;&nbsp;</label>
            </div>
            <el-select
              class="wide-select"
              id="global-user-select"
              v-model="globalUserId"
              placeholder="Select User">
              <el-option value="" disabled>Select User</el-option>
              <el-option
                v-for="user in users"
                :key="user.id"
                :value="user.id"
                :label="user.first_name + ' ' + user.last_name">
                {{ user.last_name + ', ' + user.first_name }}
              </el-option>
            </el-select>
            <div>
              <el-button class="assign-btn" type="primary" @click="assignGlobalUser">Assign to All Hosts</el-button>
              <el-button class="assign-btn" type="primary" @click="unassignSelectedUsers">Un-assign Selected Users
              </el-button>
            </div>
          </div>
          <div class="col-sm-6 assign-drop">
            <div>
              <label for="global-group-select">Assign User Group Globally: </label>
            </div>
            <el-select
              class="wide-select"
              id="global-group-select"
              v-model="globalGroupId"
              placeholder="Select User Group">
              <el-option value="" disabled>Select User Group</el-option>
              <el-option
                v-for="group in userGroups"
                :key="group.id"
                :value="group.id"
                :label="group.name">
                {{ group.name }}
              </el-option>
            </el-select>
            <div>
              <el-button class="assign-btn" type="primary" @click="assignGlobalGroup">Assign Group to All Hosts
              </el-button>
              <el-button class="assign-btn" type="primary" @click="unassignSelectedGroups">Un-assign Selected Groups
              </el-button>
            </div>
          </div>
        </div>

      <el-table :data="filteredHosts" border style="width: 100%">
        <el-table-column align="center" sortable label="Host" prop="name">
        </el-table-column>
        <el-table-column label="Assign User" :header-align="headerAlignCenter">
          <template v-slot="{ row }">
            <el-select
              v-model="row.assignedUserId"
              @change="assignUserToHost(row.id, row, $event)"
              placeholder="Select User"
            >
              <el-option
                v-for="user in availableUsersForHost(row.id)"
                :key="user.id"
                :value="user.id"
              >
                {{ user.last_name + ',' + user.first_name }}
              </el-option>
            </el-select>
          </template>
        </el-table-column>
        <el-table-column label="Assign User Group" :header-align="headerAlignCenter">
          <template slot-scope="scope">
            <el-select
              v-model="scope.row.assignedGroupId"
              @change="assignGroupToHost(scope.row.id, $event)"
              placeholder="Select User Group"
            >
              <el-option
                v-for="group in availableGroupsForHost(scope.row.id)"
                :key="group.id"
                :label="group.name"
                :value="group.id"
              >
                {{ group.name }}
              </el-option>
            </el-select>
          </template>
        </el-table-column>
        <el-table-column label="Assigned Users" :header-align="headerAlignCenter">
          <template slot-scope="scope">
            <ul>
              <li v-for="userId in scope.row.assignedUsers" :key="userId">
                {{
                    getUserName(userId)
                }}
                <el-button type="text" @click="unassignUserFromHost(scope.row.id, userId)">
                  <el-icon name="delete" class="icon-large icon-red"></el-icon>
                </el-button>
              </li>
            </ul>
          </template>
        </el-table-column>
        <el-table-column label="Assigned Groups" :header-align="headerAlignCenter" :align="cellAlignCenter">
          <template slot-scope="scope">
            <ul>
              <li v-for="groupId in scope.row.assignedGroups" :key="groupId">
                {{ userGroups.find(group => group.id === groupId).name }}
                <el-button type="text" @click="unassignGroupFromHost(scope.row.id, groupId)">
                  <el-icon name="delete" class="icon-large icon-red"></el-icon>
                </el-button>
              </li>
            </ul>
          </template>
        </el-table-column>
      </el-table>
    </card>
    <el-dialog
      center
      title="Error"
      :visible.sync="modals.error">
      <div class="text-center">
        <span>Error: {{ error_txt }}</span>
        <br>
        <span slot="footer" class="dialog-footer">
<l-button type="primary" round @click="closeModal('error')">OK</l-button>&nbsp;
</span>
      </div>
    </el-dialog>
    <el-dialog
      center
      title="Success"
      :visible.sync="modals.success">
      <div class="text-center">
        <span>{{ success_txt }}</span>
        <br>
        <span slot="footer" class="dialog-footer">
<l-button type="primary" round @click="closeModal('success')">OK</l-button>&nbsp;
</span>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {Message, Icon, Dialog, Select, Option, Table, TableColumn, Button } from 'element-ui';
import UserGroupsAPIService from "src/servicehandlers/UserGroupsAPIService";

const userGroupsAPIService = new UserGroupsAPIService();
import UsersAPIService from "src/servicehandlers/UsersAPIService";

const usersAPIService = new UsersAPIService();
import HostGroupsAPIService from "src/servicehandlers/HostGroupsAPIService";

const hostGroupsAPIService = new HostGroupsAPIService();
import HostsAPIService from "src/servicehandlers/HostsAPIService";

const hostsAPIService = new HostsAPIService();
import OperatingSystemAPIService from "src/servicehandlers/OperatingSystemAPIService";

const operatingSystemAPIService = new OperatingSystemAPIService();
export default {
  name: "UserGroupAssignments",
  components: {
    [Message.name]: Message,
    [Icon.name]: Icon,
    [Button.name]: Button,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Select.name]: Select,
    [Option.name]: Option,
    [Dialog.name]: Dialog,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
  },
  data() {
    return {
      selectedHostGroup: '',
      searchQuery: null,
      spinning: false,
      pagedHostList: [],
      success_txt: null,
      error_txt: null,
      name: null,
      user_groups: [],
      alias: null,
      headerAlignCenter: 'center',
      cellAlignCenter: 'center',
      modals: {
        error: false,
        success: false,
        create: false,
        assign_users: false,
      },
      currentPage: 1,
      pageSize: 15,
      totalHosts: 0,
      transferValue: [],
      transferData: [],
      globalUserId: null,
      globalGroupId: null,
      selectedOperatingSystem: '', // to hold the selected operating system
      operatingSystems: [], // list of all available operating systems
      hostGroups: [],
      hosts: [],
      users: [],
      userGroups: [],
    }
  },
  computed: {
    filteredHosts() {
      let filtered = this.hosts;
      if (this.searchQuery) {
        const lowercasedQuery = this.searchQuery.toLowerCase();
        filtered = filtered.filter(host =>
          (host.name && host.name.toLowerCase().includes(lowercasedQuery)) ||
          host.ip_address?.includes(this.searchQuery) ||
          (host.alias && host.alias.toLowerCase().includes(lowercasedQuery)) ||
          (typeof host.operating_system === 'string' && host.operating_system.toLowerCase().includes(lowercasedQuery))
        );
      }

      if (this.selectedOperatingSystem) {
        filtered = filtered.filter(host => host.operating_system_id === this.selectedOperatingSystem);
      }
      if (this.selectedHostGroup) {
        const group = this.hostGroups.find(group => group.id === this.selectedHostGroup);
        if (group && group.hosts) { // Check if group and group.hostIds are defined
          filtered = filtered.filter(host => group.hostIds.includes(host.id));
        }
      }
      return filtered;
    },
  },
  mounted() {
    this.getHostGroups();
    this.getHosts();
    this.getUsers();
    this.getUserGroups();
    this.getOperatingSystems();
  },
  methods: {
    getUserName(userId) {
      const user = this.users.find(u => u.id === userId);
      if (!user) return "Unknown User";
      return [user.first_name, user.last_name].filter(Boolean).join(' ');
   },
    getOperatingSystems() {
      return operatingSystemAPIService.get(this.$router)
      .then((response) => {
        this.operatingSystems = [];
        for (const group of response) {
          this.operatingSystems.push(group);
        }
      })
      .catch((error) => {
        const self = this;
        this.error_txt = 'Failed to get operating systems: ' + error;
        this.openModal('error');
        this.spinning = false;
        setTimeout(function () {
          self.modals['error'] = false; // Use your variable name
        }, 1500); // Hide after 5 secs
      });
    },
    showAssignUsersDialog() {
      // Load available users and show the dialog
      this.getUsers();
    },
    pageChange(page) {
      // Handle page change, update hostList based on the new page
      this.currentPage = page;
      this.getHosts();
    },
    pageSizeChange(size) {
      // Handle page size change, update hostList based on the new page size
      this.pageSize = size;
      this.getHosts();
    },
    getUserGroups() {
      return userGroupsAPIService.get(this.$router)
      .then((response) => {
        this.userGroups = [];
        for (const group of response) {
          this.userGroups.push(group);
        }
      })
      .catch((error) => {
        const self = this;
        this.error_txt = 'Failed to get user groups: ' + error;
        this.openModal('error');
        this.spinning = false;
        setTimeout(function () {
          self.modals['error'] = false; // Use your variable name
        }, 1500); // Hide after 5 secs
      });
    },
    getUsers() {
      return usersAPIService.getUserList(this.$router)
      .then((response) => {
        this.users = [];
        for (const user of response) {
          this.users.push(user);
        }
      })
      .catch((error) => {
        const self = this;
        this.error_txt = 'Failed to get users: ' + error;
        this.openModal('error');
        this.spinning = false;
        setTimeout(function () {
          self.modals['error'] = false; // Use your variable name
        }, 1500); // Hide after 5 secs
      });
    },
    getHosts() {
      this.hosts = [];
      // we might need the full list of hosts
      return hostsAPIService.getWithAlerts(this.$router)
      .then((response) => {
        for (const host of response) {
          host.assignedUsers = [];
          host.assignedGroups = [];
          // host.operating_system = host.operating_system.friendly_name;
          const user_groups = host.user_groups;
          const users = host.users;
          for (const user_group of user_groups) {
            host.assignedGroups.push(user_group.id);
          }
          for (const user of users) {
            host.assignedUsers.push(user.id);
          }
          this.hosts.push(host);
        }
      })
      .catch((error) => {
        const self = this;
        this.error_txt = 'Failed to get hosts: ' + error;
        this.openModal('error');
        this.spinning = false;
        setTimeout(function () {
          self.modals['error'] = false; // Use your variable name
        }, 1500); // Hide after 5 secs
      });
    },
    getHostGroups() {
      this.hostGroups = [];
      return hostGroupsAPIService.getHostgroupsWithHosts(this.$router)
      .then((response) => {
        // get it in the format of name, group, then hostIds, has to be reassigned...
        for (const hostgroup of response) {
          hostgroup.hostIds = [];
          const hosts = hostgroup.hosts;
          if (hosts) {
            for (const host of hosts) {
              hostgroup.hostIds.push(host.id);
            }
          }
          this.hostGroups.push(hostgroup);
        }
      })
      .catch((error) => {
        const self = this;
        this.error_txt = 'Failed to get host groups: ' + error;
        this.openModal('error');
        this.spinning = false;
        setTimeout(function () {
          self.modals['error'] = false; // Use your variable name
        }, 1500); // Hide after 5 secs
      });
    },
    availableGroupsForHost(hostId) {
      const host = this.hosts.find(h => h.id === hostId);
      return this.userGroups.filter(group => !host.assignedGroups.includes(group.id));
    },
    assignGroupToHost(hostId, event) {
      const host = this.hosts.find(h => h.id === hostId);
      if (host.assignedGroupId && !host.assignedGroups.includes(host.assignedGroupId)) {
        host.assignedGroups.push(host.assignedGroupId);
        try {
          const linked = hostsAPIService.linkUserGroupToHost(hostId, host.assignedGroupId, this.$router);
          Message.success('Assigned group to host successfully');
        } catch (e) {
          const self = this;
          this.error_txt = 'Failed to assign group to host: ' + e;
          this.openModal('error');
          this.spinning = false;
          setTimeout(function () {
            self.modals['error'] = false; // Use your variable name
          }, 1500); // Hide after 5 secs
        }
        host.assignedGroupId = null; // Reset the dropdown
      }
    },
    assignGlobalGroup() {
      this.spinning = true;
      if (this.globalGroupId) {
        this.filteredHosts.forEach(host => {
          if (!host.assignedGroups.includes(this.globalGroupId)) {
            host.assignedGroups.push(this.globalGroupId);
            try {
              const linked = hostsAPIService.linkUserGroupToHost(host.id, this.globalGroupId, this.$router);
            } catch (e) {
              const self = this;
              this.error_txt = 'Failed to get link user group to host: ' + e;
              this.openModal('error');
              this.spinning = false;
              setTimeout(function () {
                self.modals['error'] = false; // Use your variable name
              }, 1500); // Hide after 5 secs
            }
          }
        });
        this.globalGroupId = ""; // Reset the global group selection
      }
      this.spinning = false;
    },
    unassignSelectedGroups() {
      this.spinning = true;
      if (this.globalGroupId) {
        this.filteredHosts.forEach(host => {
          host.assignedGroups = host.assignedGroups.filter(groupId => groupId !== this.globalGroupId);
          try {
            const unlinked = hostsAPIService.unlinkUserGroupToHost(host.id, this.globalGroupId, this.$router);
          } catch (e) {
            const self = this;
            this.error_txt = 'Failed to unlink user group to host: ' + e;
            this.openModal('error');
            this.spinning = false;
            setTimeout(function () {
              self.modals['error'] = false; // Use your variable name
            }, 1500); // Hide after 5 secs
          }
        });
        this.globalGroupId = ""; // Reset the global group selection
      }
      this.spinning = false;
    },
    unassignGroupFromHost(hostId, groupId) {
      const host = this.hosts.find(h => h.id === hostId);
      host.assignedGroups = host.assignedGroups.filter(id => id !== groupId);
      try {
        const unlinked = hostsAPIService.unlinkUserGroupToHost(hostId, groupId, this.$router);
        Message.success('Successfully unassigned group from host');
      } catch (e) {
        const self = this;
        this.error_txt = 'Failed to unlink user group to host: ' + e;
        this.openModal('error');
        this.spinning = false;
        setTimeout(function () {
          self.modals['error'] = false; // Use your variable name
        }, 1500); // Hide after 5 secs
      }
    },

    async assignUserToHost(hostId, row, event) {
      const host = this.hosts.find(h => h.id === hostId);

      // Assuming this.assignedUserId is the correct user ID you want to assign.
      if (host && !host.assignedUsers.includes(row.assignedUserId)) {
        try {
          // Use this.assignedUserId for the API call, ensuring consistency.
          const linked = await hostsAPIService.linkUserToHostAlert(hostId, row.assignedUserId, this.$router);
          Message.success('Successfully added user');

          // Only add the user to the array if the API call was successful.
          host.assignedUsers.push(row.assignedUserId);

          // It might be unnecessary to clear host.assignedUserId if you're using this.assignedUserId.
          // If necessary, make sure you're clearing the right one.
          host.assignedUserId = ""; // Reset the dropdown if needed.
        } catch (e) {
          this.error_txt = 'Failed to link alert to host: ' + e;
          this.openModal('error');
          this.spinning = false;
          setTimeout(() => {
            this.modals['error'] = false;
          }, 1500);
        }
      } else {
        // console.log('User already assigned or host not found');
      }
    },

    availableUsersForHost(hostId) {
      const host = this.hosts.find(h => h.id === hostId);
      return this.users.filter(user => !host.assignedUsers.includes(user.id));
    },
    assignGlobalUser() {
      this.spinning = true;
      if (this.globalUserId) {
        this.filteredHosts.forEach(host => {
          if (!host.assignedUsers.includes(this.globalUserId)) {
            host.assignedUsers.push(this.globalUserId);
            try {
              const linked = hostsAPIService.linkUserToHostAlert(host.id, this.globalUserId, this.$router);
            } catch (e) {
              const self = this;
              this.error_txt = 'Failed to link alert to host: ' + e;
              this.openModal('error');
              this.spinning = false;
              setTimeout(function () {
                self.modals['error'] = false; // Use your variable name
              }, 1500); // Hide after 5 secs
            }
          }
        });
        this.spinning = false;
      }
    },
    unassignSelectedUsers() {
      this.spinning = true;
      if (this.globalUserId) {
        this.filteredHosts.forEach(host => {
          host.assignedUsers = host.assignedUsers.filter(userId => userId !== this.globalUserId);
          try {
            const unlinked = hostsAPIService.unlinkUserToHostAlert(host.id, this.globalUserId, this.$router);
          } catch (e) {
            const self = this;
            this.error_txt = 'Failed to unlink alert to host: ' + e;
            this.openModal('error');
            this.spinning = false;
            setTimeout(function () {
              self.modals['error'] = false; // Use your variable name
            }, 1500); // Hide after 5 secs
          }
        });
        this.globalUserId = ''; // Reset the selected user after unassigning
        this.spinning = false;
      }
    },
    unassignUserFromHost(hostId, userId) {
      const host = this.hosts.find(h => h.id === hostId);
      host.assignedUsers = host.assignedUsers.filter(id => id !== userId);
      try {
        const unlinked = hostsAPIService.unlinkUserToHostAlert(hostId, userId, this.$router);
        Message.success('Successfully unassigned users from host');
      } catch (e) {
        const self = this;
        this.error_txt = 'Failed to get link alert to host: ' + e;
        this.openModal('error');
        this.spinning = false;
        setTimeout(function () {
          self.modals['error'] = false; // Use your variable name
        }, 1500); // Hide after 5 secs
      }
    },
    openModal(name) {
      this.modals[name] = true
    },
    closeModal(name) {
      this.modals[name] = false;
    },
    filterByHostGroup() {
    },
    search() {
    },
    filterByOperatingSystem() {
      // The filtering is handled by the computed property 'filteredHosts'
      // This method can be used for future enhancements or logging.
    },
  }
}
</script>

<style scoped>
/* ... existing styles ... */
.search-bar {
  margin-left: 2px;
}

.filter-container {
  display: flex;
  align-items: center;
  gap: 10px;
}

.active-filters {
  display: flex;
  gap: 10px;
  margin-bottom: 10px;
}

.active-filters > span {
  display: flex;
  align-items: center;
  background-color: #f2f2f2;
  padding: 5px 10px;
  border-radius: 5px;
  gap: 5px;
}
.card-title {
  color: dimgrey;
  font-size: 20px;
}

.title-1 {
  color: dimgrey;
  font-size: 15px;
  margin-top: 5px;
}
.title-2 {
  color: dimgrey;
  font-size: 15px;
  margin-bottom: 5px;
}
.wide-select {
  width: 300px;
}
.wide-search {
  width: 300px;
  //align-items: center;
}
.assign-btn {
  margin-top: 15px;
  margin-bottom: 15px;
}
.assign-drop {
  margin-top: 20px;
  margin-bottom: 20px;
}
.center-content {
  align-items: center;
}
.global-select {
  width: 350px;
}
</style>
