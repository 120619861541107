<template>
<div>
  <card v-if="spinning">
    <div class="row">
      <div class="col-md-12 text-center">
        <b-spinner variant="primary" label="Spinning"></b-spinner>
      </div>
    </div>
  </card>
  <card>
    <link
      rel="stylesheet"
      href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.0.0/css/all.min.css">
    <h4 class="custom-txt">User Scopes Management
    <el-tooltip content="Add New Scope">
      <el-button type="text" @click="openModal('scope')">
        <i class="fa-solid fa-circle-plus icon-green"></i>
      </el-button>
    </el-tooltip>
    </h4>
    <!-- Search Box -->
    <div class="row mb-4">
      <div class="col-sm-6">
    <el-input
      v-model="search"
      placeholder="Search scopes..."
      clearable>
      <span slot="prepend">Search</span>
    </el-input>
      </div>
      <el-button @click="search = ''">Clear</el-button>
    </div>
    <el-table :data="filteredScopes" border style="width: 100%">
      <el-table-column label="Assign to Auth Group" width="250" :header-align="headerAlignCenter">
        <template slot-scope="scope">
          <div class="center-content">
          <el-tooltip content="Assign Scope to Auth Group">
          <el-button type="text" @click="selectAuthgroup(scope.row)">
            <i class="fa-solid fa-arrow-right"></i>
          </el-button>
          </el-tooltip>
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="name" label="Scope Name" width="300">
        <template slot-scope="scope">
          <el-input v-model="scope.row.name" />
        </template>
      </el-table-column>
      <el-table-column prop="description" label="Description" width="375">
        <template slot-scope="scope">
          <el-input v-model="scope.row.description" />
        </template>
      </el-table-column>
      <el-table-column class="text-center" label="Actions" width="250" :header-align="headerAlignCenter">
        <template slot-scope="scope">
          <div class="center-content">
          <el-tooltip content="Update Scope">
            <el-button type="text" @click="updateScope(scope.row)">
              <i class="fa-regular fa-floppy-disk"></i>
            </el-button>
          </el-tooltip>
          <el-tooltip content="Delete Scope">
            <el-button type="text" @click="deleteScope(scope.row)">
              <el-icon name="delete" class="icon-large icon-red"></el-icon>
            </el-button>
          </el-tooltip>
          </div>
        </template>
      </el-table-column>
    </el-table>
  </card>
  <el-dialog
    center
    title="Confirm Deletion of Scope"
    :visible.sync="modals.basic">
    <span>Are you sure you want to delete the scope: {{ delete_scope_name }}?</span>
    <span slot="footer" class="dialog-footer">
              <button class="btn btn-danger" @click="closeModal('basic')">Cancel</button>&nbsp;
              <button class="btn btn-success" @click="deleteScope()">Confirm</button>
            </span>
  </el-dialog>
  <el-dialog
    center
    title="Success"
    :visible.sync="modals.success">
    <div class="text-center">
      <span>Success: {{ success_txt }}</span>
      <br>
      <span slot="footer" class="dialog-footer">
<l-button class="mt-4" round type="btn btn-success" @click="closeModal('success')">OK</l-button>&nbsp;
</span>
    </div>
  </el-dialog>
  <el-dialog
    center
    title="Error"
    :visible.sync="modals.error">
    <div class="text-center">
      <span>Error: {{ error_txt }}</span>
      <br>
      <span slot="footer" class="dialog-footer">
<l-button round class="mt-4" type="btn btn-danger" @click="closeModal('error')">OK</l-button>&nbsp;
</span>
    </div>
  </el-dialog>
  <!-- Assign Dialog -->
  <el-dialog title="Assign Scopes to Auth Groups" :visible.sync="modals.assign" width="70%">
    <div style="display: flex;">
      <!-- Auth Groups List -->
      <div style="flex: 1;">
        <h3>Select an Auth Group:</h3>
        <el-select v-model="selected_authgroup_id" placeholder="Select an Auth Group">
          <el-option
            v-for="group in auth_groups"
            :key="group.id"
            :label="group.name"
            :value="group.id">
          </el-option>
        </el-select>
      </div>

      <!-- Scopes List for Assignment -->
      <div style="flex: 2; margin-left: 20px;">
        <h3>Assign Scopes:</h3>
        <el-checkbox-group v-model="selected_scope_ids">
          <el-checkbox
            v-for="scope in scopes"
            :key="scope.id"
            :label="scope.id">
            {{ scope.name }}
          </el-checkbox>
        </el-checkbox-group>
      </div>
    </div>

    <span slot="footer" class="dialog-footer">
      <el-button @click="saveScopes">Assign</el-button>
      <el-button @click="closeModal('assign')">Close</el-button>
    </span>
  </el-dialog>
  <el-dialog width="30%" :visible.sync="modals.scope">
    <div class="row">
      <div class="col-sm-12">
        <div class="text-center">
          <h3 style="margin-top: -10px;">Add a New Scope</h3>
        </div>
      </div>
    </div>
    <el-form ref="addScopeForm">
      <div class="row">
        <div class="col-sm-12">
          <div class="text-center mt-3">
            <div>
              <label>Scope Name:</label>
            </div>
              <el-input style="width: 300px;" class="sml-search" v-model="newScope.name"></el-input>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-12">
          <div class="text-center mt-3">
            <div>
              <label>Description:</label>
            </div>
              <el-input style="width: 300px;" class="sml-search" v-model="newScope.description"></el-input>
          </div>
        </div>
      </div>
    </el-form>
    <div class="text-center mt-4">
      <span slot="footer" class="dialog-footer">
        <l-button round class="mr-1" type="primary" @click="addScope">Add</l-button>
      <l-button round type="danger" @click="closeModal('scope')">Cancel</l-button>
      </span>
    </div>
  </el-dialog>

  <!-- Scopes Table -->
  <el-table :data="filteredScopes" border style="width: 100%">
    <!-- ... the rest of your table as before ... -->
  </el-table>
</div>
</template>

<script>
import {Option, Select, Dialog, Button, Table, TableColumn, Form, FormItem, Checkbox, CheckboxGroup, Tooltip, Icon } from 'element-ui';
import { ScopesAPIService } from 'src/servicehandlers/ScopesAPIService';
import { AuthGroupAPIService } from 'src/servicehandlers/AuthGroupAPIService';
const scopesAPIService = new ScopesAPIService();
const authGroupAPIService = new AuthGroupAPIService();

export default {
  components: {
    [Option.name]: Option,
    [Select.name]: Select,
    [Checkbox.name]: Checkbox,
    [CheckboxGroup.name]: CheckboxGroup,
    [Form.name]: Form,
    [FormItem.name]: FormItem,
    [Dialog.name]: Dialog,
    [Button.name]: Button,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Tooltip.name]: Tooltip,
    [Icon.name]: Icon,
  },
  name: "AuthGroupsScopesAdmin",
  data() {
    return {
      newScope: {
        name: '',
        description: '',
      },
      search: '',
      searchTerm: null,
      spinning: false,
      scope_name: null,
      auth_groups: [],
      scope_description: null,
      delete_scope_id: null,
      delete_scope_name: null,
      selected_authgroup_id: null,
      selected_scope_id: null,
      last_selected_item: null,
      selected_scope_ids: [],
      headerAlignCenter: 'center',
      modals: {
        error: false,
        success: false,
        basic: false,
        scope: false,
        assign: false,
      },
      scopes: [],
      success_txt: '',
      error_txt: null,
    };
  },
  computed: {
    filteredScopes() {
      if (!this.search) {
        return this.scopes;
      }
      const searchLowerCase = this.search.toLowerCase();
      return this.scopes.filter(
        s => s.name.toLowerCase().includes(searchLowerCase) ||
          s.description.toLowerCase().includes(searchLowerCase)
      );
    }
  },
  watch: {
    selected_authgroup_id(newValue, oldValue) {
      if (newValue !== oldValue) {
        const selectedRow = this.auth_groups.find(group => group.id === newValue);
        if (selectedRow) {
          this.selectAuthgroup(selectedRow);
        }
      }
    }
  },
  created() {
    this.getAllScopes();
    this.getAllAuthgroups();
  },
  methods: {
    created() {
      // By default, the filtered scopes are all the scopes
      this.filteredScopes = this.scopes;
    },
    saveScopes() {
      this.spinning = true;
      if (!this.selected_authgroup_id) {
        this.spinning = false;
        this.error_txt = 'No authgroup was selected!';
        this.openModal('error');
        return;
      }
      if (this.selected_scope_ids.length <= 0) {
        this.spinning = false;
        this.error_txt = 'No scopes were selected!';
        this.openModal('error');
        return;
      }

      return scopesAPIService.addScopesToAuthgroup(this.selected_authgroup_id, this.selected_scope_ids, this.$router)
      .then(() => {
        this.clear();
        this.getAllScopes();
        this.getAllAuthgroups();
        this.spinning = false;
        this.success_txt = 'Successfully added scopes to authgroup!';
        this.openModal('success');
      })
      .catch((error) => {
        this.spinning = false;
        this.error_txt = 'An error occurred while adding scopes to groups: ' + error;
        this.openModal('error');
      });
    },
    clear() {
      this.selected_authgroup_id = null;
      this.selected_scope_ids = [];
    },
    updateScope(row) {
      this.spinning = true;
      return scopesAPIService.updateScope(row.id, row, this.$router)
      .then(() => {
        this.handleSuccess('Successfully updated scope');
      })
      .catch((error) => {
        this.handleSuccess('failed to update scope!: ' + error);
      });
    },
    async selectAuthgroup(row = null) {
      if (row) {
        this.selected_scope_id = row.id;
      }

      if (!this.selected_scope_id) {
        return;
      }

      this.spinning = true;

      try {
        const selectedAuthgroup = await authGroupAPIService.getAuthGroupsWithScope(this.selected_scope_id, this.$router);

        this.selected_scope_ids = selectedAuthgroup.auth_groups.map(auth_group => {
          auth_group.text = auth_group.name;
          auth_group.value = auth_group.id;
          return auth_group.id;
        });

        this.openModal('assign');
        this.spinning = false;
      } catch (error) {
        this.spinning = false;
        this.error_txt = 'Error getting scopes for auth group: ' + error;
        this.openModal('error');
      }
    },

    getAllAuthgroups() {
      this.spinning = true;
      return authGroupAPIService.getAuthGroups(this.$router)
      .then((agps) => {
        this.auth_groups = agps;
        for (const group of this.auth_groups) {
          group.text = group.name;
          group.value = group.id;
        }
        this.spinning = false;
      })
      .catch((error) => {
        this.error_txt = error;
        this.spinning = false;
        this.openModal('error');
      });
    },
    onSelect (items, lastSelectItem) {
      this.selected_scope_ids = items
      this.lastSelectItem = lastSelectItem
    },
    confirmDelete(scope_id, scope_name,) {
      this.delete_scope_id = scope_id;
      this.delete_scope_name = scope_name;
      this.openModal('basic');
    },
    deleteScope(row) {
      this.closeModal('basic');
      this.spinning = true;
      if (!row.id) {
        this.spinning = false;
        this.error_txt = 'No scope was selected';
        this.openModal('error');
        return;
      }

      return scopesAPIService.deleteScope(row.id, this.$router)
      .then(() => {
        this.getAllScopes();
        this.getAllAuthgroups();
        this.spinning = false;
        this.success_txt = 'Successfully deleted scope!';
        this.openModal('success');
        this.delete_scope_id = null;
        this.delete_scope_name = null;
      })
      .catch((error) => {
        this.spinning = false;
        this.error_txt = 'Scope deletion failed: ' + error;
        this.openModal('error');
      });
    },
    addScope() {
      this.spinning = true;
      if (!this.newScope.name) {
        this.spinning = false;
        this.error_txt = 'No scope name set';
        this.openModal('error');
        return;
      }
      if (!this.newScope.description) {
        this.spinning = false;
        this.error_txt = 'No scope description set';
        this.openModal('error');
        return;
      }
      this.closeModal('scope');

      return scopesAPIService.createScope(this.newScope, this.$router)
      .then(() => {
        this.getAllScopes();
        this.getAllAuthgroups();
        this.spinning = false;
        this.success_txt = 'Successfully created scope!';
        this.openModal('success');
        this.newScope.name = '';
        this.newScope.description = '';
      })
      .catch((error) => {
        this.spinning = false;
        this.error_txt = 'Scope creation failed: ' + JSON.stringify(error);
        this.openModal('error');
      });
    },

    getAllScopes() {
      this.spinning = true;
      return scopesAPIService.getScopes(this.$router)
      .then((response) => {
        this.spinning = false;
        this.scopes = response;
        for (const scope of this.scopes) {
          scope.text = scope.name;
          scope.value = scope.id;
        }
      })
      .catch((error) => {
        this.spinning = false;
        this.error_txt = 'Error getting scopes!: ' + error;
        this.openModal('error');
      });
    },

    closeModal(name) {
      this.modals[name] = false;
    },
    openModal(name) {
      this.modals[name] = true;
    },
    handleSuccess(text) {
      this.success_txt = text;
      this.openModal('success');
      this.spinning = false;
      setTimeout(() => {
        this.modals['success'] = false;
      }, 1500); // Hide after 1.5 secs
    },
    handleError(error) {
      this.error_txt = error;
      this.openModal('error');
      this.spinning = false;
      setTimeout(() => {
        this.modals['error'] = false;
      }, 1500); // Hide after 1.5 secs
    },
  }
}
</script>

<style scoped>
.custom-txt {
  color: dimgrey;
  //font-size: 22px;
  margin-top: 5px;
  margin-bottom: 5px;
}
.fa-circle-plus {
  color: mediumseagreen;
  font-size: 25px;
}
.center-content {
  display: flex;
  align-items: center;
  justify-content: center;
}
.fa-arrow-right {
  color: mediumseagreen;
  font-size: 18px;
}
.text-center {
  text-align: center;
}
.fa-floppy-disk {
  font-size: 19px;
  color: dodgerblue;
}
.sml-search {
  width: 250px;
}
</style>
