import CommonAPIService from './CommonHandler';

export class AIAPIService {
  constructor() {
    this.CommonAPIService = new CommonAPIService();
  }

  ner_ai_call(parameters, router) {
    const url = '/api/ai/ner';
    return this.CommonAPIService.postCall(url, parameters, router);
  }
  cyber_ai_call(parameters, router) {
    const url = '/api/ai/cyber';
    return this.CommonAPIService.postCall(url, parameters, router);
  }
  process_sentence(sender, message, llm, router) {
    if (llm) {
      const context = 'You are an AI that can only answer questions related to system engineering,' +
        ' system monitoring, backups, and anything computer or server related. ' +
        ' If the question falls outside of that, then you need to answer with the following text' +
        ' "I cannot answer that question, it is outside what I have been trained on"';
      const url = '/search';
      return this.CommonAPIService.llmCall(url, {
        llm,
        conversation_id: sender,
        key: sender,
        query: message,
        context: context,
      }, router);
    } else {
      const url = '/test';
      return this.CommonAPIService.aiCall(url, {
        llm,
        conversation_id: sender,
        text: message
      }, router);

    }
  }
}

export default AIAPIService;
