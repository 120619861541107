<template>
  <div>
    <card v-if="spinning">
      <div class="row">
        <div class="col-md-12 text-center">
          <b-spinner variant="primary" label="Spinning"></b-spinner>
        </div>
      </div>
    </card>
    <card>
      <link
        rel="stylesheet"
        href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.0.0/css/all.min.css">
      <!-- Header and Button -->
      <div slot="header">
        <h4 class="card-category">Users
          <el-tooltip content="Add New User">
            <el-button type="text" @click="createOrUpdateUser">
              <i class="fa-solid fa-circle-plus" style="font-size: 25px;"></i>
            </el-button>
          </el-tooltip>
        </h4>
      </div>

        <div class="row">
          <div class="col-sm-6 mb-4">
            <el-input
              v-model="searchQuery"
              placeholder="Search by first name, last name, username, or email"
              clearable
              @clear="searchQuery = ''">
              <span slot="prepend">Search</span>
            </el-input>
          </div>
            <el-button class="mb-4" @click="searchQuery = ''">Clear</el-button>
        </div>

      <el-table :data="filteredUsers" border>
        <el-table-column min-width="150" label="Username">
          <template slot-scope="{ row }">
            <el-input v-model="row.username" placeholder="Username"></el-input>
          </template>
        </el-table-column>
        <el-table-column min-width="150" label="Email">
          <template slot-scope="{ row }">
            <el-input v-model="row.email" placeholder="Email"></el-input>
          </template>
        </el-table-column>
        <el-table-column label="Password" width="100">
          <template slot-scope="{ row }">
            <div v-if="editingPasswordForUserId !== row.id">
              ****
              <el-button icon="el-icon-edit" size="mini" @click="editPassword(row.id)"></el-button>
            </div>
            <div v-else>
              <el-input
                v-model="row.password"
                type="password"
                placeholder="Enter new password"
                @blur="stopEditingPassword"
                @keyup.enter="stopEditingPassword">
              </el-input>
            </div>
          </template>
        </el-table-column>
        <el-table-column min-width="125" label="First Name" width="125">
          <template slot-scope="{ row }">
            <el-input v-model="row.first_name" placeholder="First Name"></el-input>
          </template>
        </el-table-column>
        <el-table-column min-width="125" label="Last Name" width="125">
          <template slot-scope="{ row }">
            <el-input v-model="row.last_name" placeholder="Last Name"></el-input>
          </template>
        </el-table-column>
        <el-table-column min-width="115" label="Phone" width="130">
          <template slot-scope="{ row }">
            <el-input v-model="row.phone" placeholder="Phone"></el-input>
          </template>
        </el-table-column>
        <el-table-column label="Enabled" width="75">
          <template slot-scope="{ row }">
            <el-switch v-model="row.enabled"></el-switch>
          </template>
        </el-table-column>
        <el-table-column label="Notify" width="75">
          <template slot-scope="{ row }">
            <el-switch v-model="row.notify"></el-switch>
          </template>
        </el-table-column>
        <el-table-column label="Actions" width="100" :header-align="headerAlignCenter">
          <template slot-scope="{ row }">
            <div class="center-content">
            <el-tooltip content="Update User">
              <el-button type="text" @click="updateUser(row)">
                <i class="fa-regular fa-floppy-disk"></i>
              </el-button>
            </el-tooltip>
            <el-tooltip content="Delete User">
              <el-button type="text" @click="confirmDelete(row)">
                <el-icon name="delete" class="icon-large icon-red"></el-icon>
              </el-button>
            </el-tooltip>
            </div>
          </template>
        </el-table-column>
      </el-table>
    </card>
    <el-dialog
      center
      title="Success"
      :visible.sync="modals.success">
      <div class="text-center">
        <span>Success: {{ success_txt }}</span>
        <div class="text-center mt-4">
          <span slot="footer">
<l-button round class="btn btn-success mt-4" @click="closeModal('success')">OK</l-button>&nbsp;
</span>
        </div>
      </div>
    </el-dialog>
    <el-dialog
      center
      title="Error"
      :visible.sync="modals.error">
      <div class="text-center">
        <span>Error: {{ error_txt }}</span>
        <div class="text-center mt-4">
          <span slot="footer" class="dialog-footer">
<l-button type="btn btn-danger" round @click="closeModal('error')">OK</l-button>&nbsp;
</span>
        </div>
      </div>
    </el-dialog>
    <el-dialog :visible.sync="modals.create_user">
      <div class="row">
        <div class="col-sm-12">
          <div class="text-center mb-4">
            <h3 style="margin-top: -15px;">Create a New User</h3>
          </div>
        </div>
      </div>
        <el-form ref="createForm" :model="newUser" label-position="top" label-width="120px">
          <div class="row">
            <div class="col-sm-6">
              <label>Username
                <el-input class="small-select mt-1 mb-2" v-model="newUser.username" placeholder="Enter username"></el-input>
              </label>
            </div>
            <div class="col-sm-6">
              <label>Email
                <el-input class="small-select mt-1 mb-2" v-model="newUser.email" placeholder="Enter email"></el-input>
              </label>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-6">
              <label> Password
                <el-input
                  class="small-select mt-1 mb-2"
                  v-model="newUser.password"
                  type="password"
                  placeholder="Enter password"
                  :suffix-icon="showPassword ? 'el-icon-view' : 'el-icon-eye-off'"
                  @click:append="togglePasswordVisibility">
                </el-input>
                <!-- You can add a password strength indicator component below this input -->
              </label>
            </div>
            <div class="col-sm-6">
              <label :error="passwordError">Confirm Password
                <el-input
                  class="small-select mt-1 mb-2"
                  v-model="newUser.confirmPassword"
                  type="password"
                  placeholder="Confirm password">
                </el-input>
              </label>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-6">
              <label>First Name
                <el-input class="small-select mt-1 mb-2" v-model="newUser.first_name" placeholder="First Name"></el-input>
              </label>
            </div>
            <div class="col-sm-6">
              <label>Last Name
                <el-input class="small-select mt-1 mb-2" v-model="newUser.last_name" placeholder="Last Name"></el-input>
              </label>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-6">
              <label>Phone
                <el-input class="small-select mt-1 mb-2" v-model="newUser.phone" placeholder="Phone"></el-input>
              </label>
            </div>
              <div class="col-sm-6 notif-btn">
                <label>Notification
                  <el-switch class="ml-1" v-model="newUser.notify"></el-switch>
                </label>
              </div>
          </div>

          <!-- List out password requirements -->
          <div style="font-size: 0.9em; color: grey;">
            Password requirements:
            <ul>
              <li>At least 8 characters</li>
              <li>At least one number</li>
              <li>At least one special character</li>
            </ul>
          </div>
          <div class="text-center">
            <l-button class="mr-1" type="primary" round @click="createUser">Create</l-button>
            <l-button type="danger" round @click="closeModal('create_user')">Cancel</l-button>
          </div>
        </el-form>
    </el-dialog>
  </div>
</template>

<script>
import {
  Icon,
  Button,
  Tooltip,
  Dialog,
  Select,
  Option,
  Table,
  TableColumn,
  Checkbox,
  Input,
  Switch,
  Form,
  FormItem,
} from 'element-ui';
import { UserAPIService } from 'src/servicehandlers/UsersAPIService';

const usersAPIService = new UserAPIService();

export default {
  name: "UserAdmin",
  components: {
    [Form.name]: Form,
    [FormItem.name]: FormItem,
    [Input.name]: Input,
    [Dialog.name]: Dialog,
    [Select.name]: Select,
    [Option.name]: Option,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Checkbox.name]: Checkbox,
    [Switch.name]: Switch,
    [Button.name]: Button,
    [Icon.name]: Icon,
    [Tooltip.name]: Tooltip,
  },
  data() {
    return {
      passwordError: '',
      headerAlignCenter: 'center',
      newUser: {
        username: '',
        email: '',
        password: '',
        first_name: '',
        last_name: '',
        phone: '',
        sms: '',
        enabled: true,
        notify: true,
      },
      showPassword: false,
      modals: {
        error: false,
        success: false,
        create_user: false,
      },
      searchQuery: '',
      filteredUserOptions: [],
      editingPasswordForUserId: null,
      searchUserTerm: null,
      selected_user_id: null,
      error_txt: null,
      success_txt: null,
      spinning: false,
      username: null,
      first_name: null,
      last_name: null,
      password: null,
      enabled: true,
      users: [],
    }
  },
  computed: {
    filteredUsers() {
      const query = this.searchQuery.toLowerCase();
      return this.users.filter(user => {
        const firstName = user.first_name ? user.first_name.toLowerCase() : '';
        const lastName = user.last_name ? user.last_name.toLowerCase() : '';
        const username = user.username ? user.username.toLowerCase() : '';
        const email = user.email ? user.email.toLowerCase() : '';

        return firstName.includes(query) ||
          lastName.includes(query) ||
          username.includes(query) ||
          email.includes(query);
      });
    }
  },
  mounted() {
    this.getAllUsers();
  },
  methods: {
    arePasswordsSame() {
      return this.newUser.password === this.newUser.confirmPassword;
    },
    togglePasswordVisibility() {
      this.showPassword = !this.showPassword;
    },
    confirmDelete(user) {
      this.$confirm('Are you sure?', 'Warning', {
        confirmButtonText: 'Yes',
        cancelButtonText: 'Cancel',
        type: 'warning'
      }).then(() => {
        this.deleteUser(user);
      }).catch(() => {
        // User canceled the delete action
      });
    },

    deleteUser(user) {
      // Remove the user from local data
      const index = this.users.indexOf(user);
      if (index !== -1) {
        this.users.splice(index, 1);
        return usersAPIService.deleteUser(user.id, this.$router)
        .then(() => {
          this.handleSuccess('Successfully delete user!');
        })
        .catch((error) => {
          this.handleError('Error while deleting user!: ' + error);
        });
      }
    },
    handleSuccess(text) {
      this.success_txt = text;
      this.openModal('success');
      this.spinning = false;
      setTimeout(() => {
        this.modals['success'] = false;
      }, 1500); // Hide after 1.5 secs
    },
    handleError(error) {
      this.error_txt = error;
      this.openModal('error');
      this.spinning = false;
      setTimeout(() => {
        this.modals['error'] = false;
      }, 1500); // Hide after 1.5 secs
    },
    editPassword(userId) {
      this.editingPasswordForUserId = userId;
    },
    stopEditingPassword() {
      this.editingPasswordForUserId = null;
      // Here, you can also send the updated password to your server
      // to save the changes permanently
    },
    getAllUsers() {
      return usersAPIService.getUserList(this.$router)
      .then((allUsers) => {
        this.users = allUsers;
      })
      .catch((error) => {
        this.error_txt = error;
        this.openModal('error');
      });
    },
    clear() {
      this.newUser.username = '';
      this.newUser.password = '';
      this.newUser.first_name = '';
      this.newUser.last_name = null;
      this.newUser.enabled = true;
      this.spinning = false;
      this.searchUserTerm = null;
    },
    closeModal(name) {
      this.modals[name] = false;
    },
    openModal(name) {
      this.modals[name] = true;
    },
    updateUser(user) {
      this.spinning = true;
      return usersAPIService.updateUser(user.id, user, this.$router)
      .then(() => {
        this.spinning = false;
        this.success_txt = 'Successfully updated user: ';
        this.openModal('success');
        this.searchUserTerm = null;
        this.getAllUsers();
      })
      .catch((error) => {
        this.spinning = false;
        this.error_txt = 'Error updating user: ' + error;
        this.openModal('error');
      });
    },
    createUser() {
      if (this.arePasswordsSame()) {
        this.passwordError = '';  // Clear any previous error message.
      } else {
        this.passwordError = 'Passwords do not match.';
        return;
      }
      if (!this.newUser.email) {
        this.handleError('No user email!');
        return;
      }
      if (!this.newUser.password) {
        this.handleError('No user password!');
        return;
      }
      if (!this.newUser.first_name) {
        this.handleError('No user first name!');
        return;
      }
      if (!this.newUser.last_name) {
        this.handleError('No user last name!');
        return;
      }
      this.closeModal('create_user');
      this.spinning = true;
      return usersAPIService.createUser(this.newUser, this.$router)
      .then((newUser) => {
        this.spinning = false;
        this.success_txt = 'Successfully created user: ';
        this.openModal('success');
        this.newUser.username = '';
        this.newUser.password = '';
        this.newUser.first_name = '';
        this.newUser.last_name = null;
        this.newUser.enabled = true;
        this.spinning = false;
        this.searchUserTerm = null;
        this.getAllUsers();
      })
      .catch((error) => {
        this.spinning = false;
        this.error_txt = 'Error creating user: ' + error;
        this.openModal('error');
      });
    },
    createOrUpdateUser() {
      this.openModal('create_user');
    }
  }
}
</script>

<style scoped>
.el-dialog {
  border-radius: 10px;
}

.el-form-item {
  margin-bottom: 20px;
}
.card-category {
  margin-top: 5px;
  color: dimgrey;
  font-size: 22px;
}
.fa-circle-plus {
  font-size: 25px;
  color: mediumseagreen;
}
.center-content {
  display: flex;
  align-items: center;
  justify-content: center;
}
.fa-floppy-disk {
  font-size: 19px;
  color: dodgerblue;
}
.notif-btn {
  margin-top: 35px;
  //text-align: center;
}
.small-select {
  width: 325px;
}
</style>
