<template>
  <div>
    <card v-if="spinning">
      <div class="row">
        <div class="col-md-12 text-center">
          <b-spinner variant="primary" label="Spinning"></b-spinner>
        </div>
      </div>
    </card>
    <card>
      <div class="row">
        <div class="col-sm-12">
          <el-button type="text" @click="openModal('helpBtn')">
            <i class="fa-solid fa-circle-info help-btn"></i>
          </el-button>
          <div class="main-txt-1">General Mail Settings</div>
        </div>
        <div class="col-sm-12">
          <div class="custom-txt-2 ml-1">Note: Mail messages may fail to be delivered if your server does not have valid DNS name.</div>
        </div>
      </div>
      <hr>
      <div class="row">
        <div class="col-sm-2 txt">Mail Method:</div>
        <div class="col-sm-2">
          <label class="container1 mt-2">
            <input type="checkbox" :checked="mail_type === 'send_mail'" @click="toggleMailType('send_mail')">
            <span class="checkmark"></span> Send Mail
          </label>
          <label class="container1">
            <input type="checkbox" :checked="mail_type === 'smtp'" @click="toggleMailType('smtp')">
            <span class="checkmark"></span> SMTP
          </label>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-12 mt-2">
          <label>Send Mail From:</label>
        </div>
        <div class="col-sm-4 mb-2">
          <fg-input placeholder="Email" v-model="mail_from"></fg-input>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-12 main-txt">SMTP Settings</div>
      </div>
      <hr>
      <div class="row">
        <div class="col-sm-12">
          <label>Host:</label>
        </div>
        <div class="col-sm-3">
          <fg-input placeholder="Host" v-model="mail_host"></fg-input>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-12">
          <label>Port:</label>
        </div>
        <div class="col-sm-1">
          <fg-input placeholder="Port" v-model="mail_port"></fg-input>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-12">
          <label>Username:</label>
        </div>
        <div class="col-sm-2">
          <fg-input placeholder="Username" v-model="mail_user"></fg-input>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-12">
          <label>Password:</label>
        </div>
        <div class="col-sm-2">
          <fg-input placeholder="Password" v-model="mail_password"></fg-input>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-2 txt">Security:</div>
        <div class="col-sm-2">
          <label class="container2 mt-2">
            <input type="checkbox" :checked="mail_security === 'none'" @click="toggleSecurityType('none')">
            <span class="checkmark"></span> None
          </label>
          <label class="container2">
            <input type="checkbox" :checked="mail_security === 'tls'" @click="toggleSecurityType('tls')">
            <span class="checkmark"></span> TLS
          </label>
          <label class="container2">
            <input type="checkbox" :checked="mail_security === 'ssl'" @click="toggleSecurityType('ssl')">
            <span class="checkmark"></span> SSL
          </label>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-12 mt-4 mb-4">
          <button type="button" @click="updateSettings">Update Settings</button>
          <button class="ml-2" type="button" @click="clearSettings">Clear</button>
        </div>
      </div>
    </card>
    <el-dialog
      width="45%"
      :visible.sync="modals.helpBtn">
      <div class="row">
        <div class="col-sm-12 text-center">
          <p class="main-help">Mail Settings</p>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-12 text-center">
          <p class="main-help-txt">Here, you can modify the settings used by your system for sending email alerts and informational messages.</p>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-12 text-center">
          <button class="btn btn-primary"  @click="closeModal('helpBtn')">Close</button>
        </div>
      </div>
    </el-dialog>
    <el-dialog
      center
      width="30%"
      title="Error"
      :visible.sync="modals.error">
      <div class="text-center">
        <span>Error: {{ error_txt }}</span>
        <br>
        <span slot="footer" class="dialog-footer">
<button class="btn btn-danger mt-4" @click="closeModal('error')">OK</button>&nbsp;
</span>
      </div>
    </el-dialog>
    <el-dialog
      center
      width="30%"
      title="Success"
      :visible.sync="modals.success">
      <div class="text-center">
        <span>{{ success_txt }}</span>
        <br>
        <span slot="footer" class="dialog-footer">
<button class="btn btn-primary mt-4" @click="closeModal('success')">OK</button>&nbsp;
</span>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import {Select, Option, Icon, Button, Table, TableColumn, Tooltip, Dialog } from 'element-ui';
import ServerSettingsAPIService from "../../../../servicehandlers/ServerSettingsAPIService";
const serverSettingsAPIService = new ServerSettingsAPIService();
//import { getById } from "../../../../servicehandlers/ServerSettingsAPIService";
export default {
  name: "email_settings",
  components: {
    [Select.name]: Select,
    [Option.name]: Option,
    [Button.name]: Button,
    [Icon.name]: Icon,
    [Dialog.name]: Dialog,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Tooltip.name]: Tooltip,
  },
  data() {
    return {
      error_txt: null,
      success_txt: null,
      spinning: false,
      /*email_settings: {
        id: '',
        mail_type: '',
        mail_from: '',
        mail_host: '',
        mail_port: '',
        mail_user: '',
        mail_password: '',
        mail_security: '',
      },*/
      id: '',
      mail_type: '',
      mail_from: '',
      mail_host: '',
      mail_port: '',
      mail_user: '',
      mail_password: '',
      mail_security: '',
      modals: {
        error: false,
        success: false,
        helpBtn: false,
      }
    }
  },
  mounted() {
    this.getEmailSettings();
  },
  methods: {
    async getEmailSettings() {
      try {
        await serverSettingsAPIService.get(this.$router)
          .then((response) => {
            const emailSettings = Array.isArray(response) ? response[0] : response;
            this.id = emailSettings.id;
            this.mail_type = emailSettings.mail_type;
            this.mail_from = emailSettings.mail_from;
            this.mail_host = emailSettings.mail_host;
            this.mail_port = emailSettings.mail_port;
            this.mail_user = emailSettings.mail_user;
            this.mail_password = emailSettings.mail_password;
            this.mail_security = emailSettings.mail_security;
          })
        this.handleSuccess('Successfully retrieved your email settings!');
      } catch (error) {
        this.handleError('Error getting email settings: ' + error);
      }
    },
    toggleSecurityType(type) {
      if (this.mail_security === type) {
        this.mail_security = '';
      } else {
        this.mail_security = type;
      }
    },
    toggleMailType(type) {
      if (this.mail_type === type) {
        this.mail_type = '';
      } else {
        this.mail_type = type;
      }
    },
    async updateSettings() {
      //const id = this.idCounter++;
      const email_settings = {
        id: 1,
        mail_type: this.mail_type,
        mail_from: this.mail_from,
        mail_host: this.mail_host,
        mail_port: +this.mail_port,
        mail_user: this.mail_user,
        mail_password: this.mail_password,
        mail_security: this.mail_security,
      }
      try {
        await serverSettingsAPIService.create(email_settings, this.$router);
        this.handleSuccess('Successfully updated email settings!');
        this.mail_type = '';
        this.mail_from = '';
        this.mail_host = '';
        this.mail_port = '';
        this.mail_user = '';
        this.mail_password =  '';
        this.mail_security = '';
      } catch (error) {
        this.handleError('Error updating email settings: ' + error);
      }
    },
    clearSettings() {
      try {
        this.mail_type = '';
        this.mail_from = '';
        this.mail_host = '';
        this.mail_port = '';
        this.mail_user = '';
        this.mail_password =  '';
        this.mail_security = '';
      } catch (error) {
        this.handleError('Error clearing email settings: ' + error);
      }
    },
    handleSuccess(text) {
      this.success_txt = text;
      this.openModal('success');
      this.spinning = false;
      setTimeout(() => {
        this.modals['success'] = false;
      }, 1500); // Hide after 1.5 secs
    },
    handleError(error) {
      this.error_txt = error;
      this.openModal('error');
      this.spinning = false;
      setTimeout(() => {
        this.modals['error'] = false;
      }, 1500); // Hide after 1.5 secs
    },
    openModal(name) {
      this.modals[name] = true
    },
    closeModal(name) {
      this.modals[name] = false;
    },
  }
}
</script>

<style scoped>

</style>

<style>
.custom-txt {
  color: dimgrey;
  font-size: 25px;
}
.custom-txt-2 {
  color: dimgrey;
  font-size: 15px;
}
.main-txt-1 {
  color: dimgrey;
  font-size: 23px;
  margin-left: 3px;
  margin-top: -30px;
}
.main-txt {
  color: dimgrey;
  font-size: 23px;
}
.txt {
  color: dimgrey;
  font-size: 17px;
}
.container1 {
  display: block;
  position: relative;
  margin-left: -90px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 17px;
  white-space: nowrap;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.container2 {
  display: block;
  position: relative;
  margin-left: -120px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 17px;
  white-space: nowrap;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.help-btn {
  font-size: 18px;
  position: absolute;
  top: 5px;
  right: 5px;
  padding-right: 10px;
}
</style>
