<template>
  <div>
    <card v-if="spinning">
      <div class="row">
        <div class="col-sm-12 text-center">
          <b-spinner variant="primary" label="Spinning"></b-spinner>
        </div>
      </div>
    </card>
    <card>
      <div slot="header">
        <h4 class="card-title">Profiles
        <el-tooltip content="Add New Profile">
          <el-button type="text" @click="openNewModal">
            <i class="fa-solid fa-circle-plus"></i>
          </el-button>
        </el-tooltip>
        </h4>
      </div>
    <!-- Table view for profiles -->
    <el-table :data="profiles" border style="width: 100%">
      <el-table-column prop="name" label="Name" width="700"></el-table-column>
      <el-table-column label="Enabled" width="200" align="center">
        <template slot-scope="scope">
          <div class="center-content">
          {{ scope.row.enabled ? 'Yes' : 'No' }}
          </div>
        </template>
      </el-table-column>
      <el-table-column label="Actions" width="200" align="center">
        <template slot-scope="scope">
          <link
            rel="stylesheet"
            href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.0.0/css/all.min.css">
          <div class="center-content">
          <el-tooltip content="Update Profile">
            <el-button type="text" @click="openUpdateModal(scope.$index)">
              <i class="fa-regular fa-floppy-disk"></i>
            </el-button>
          </el-tooltip>
          <el-tooltip content="Copy Profile">
          <el-button type="text" @click="copyProfile(scope.$index)">
            <el-icon name="files" class="icon-large icon-green"></el-icon>
          </el-button>
          </el-tooltip>
          <el-tooltip content="Delete Profile">
            <el-button type="text" @click="deleteProfile(scope.$index)">
              <el-icon name="delete" class="icon-large icon-red"></el-icon>
            </el-button>
          </el-tooltip>
          </div>
        </template>
      </el-table-column>
    </el-table>

    <!-- Add New Button at the bottom -->
    <el-dialog
      center
      title="Error"
      :visible.sync="modals.error">
      <div class="text-center">
        <span>Error: {{ error_txt }}</span>
        <div class="text-center mt-4">
          <span slot="footer">
<l-button type="btn btn-danger" round @click="closeModal('error')">OK</l-button>&nbsp;
</span>
        </div>
      </div>
    </el-dialog>
    <el-dialog
      center
      title="Success"
      :visible.sync="modals.success">
      <div class="text-center">
        <span>{{ success_txt }}</span>
        <div class="text-center">
          <span slot="footer">
<l-button type="btn btn-success" round @click="closeModal('success')">OK</l-button>&nbsp;
</span>
        </div>
      </div>
    </el-dialog>
    <!-- Modal for Add/Update Profile -->
    <el-dialog :visible.sync="modals.add_update" width="59%" top="20px">
      <div class="text-center">
        <h3 style="margin-top: -20px;">Add or Update Profiles</h3>
      </div>
      <div class="col-sm-6 m-auto">
        <el-input v-model="newProfileName" placeholder="Enter name"></el-input>
      </div>
      <div class="ml-5">
        <el-transfer
          v-model="selectedCheckValues"
          :data="checks"
          :titles="['Available Checks', 'Selected Checks']"
          filterable
          filter-placeholder="Search check"
        />
        <el-transfer
          v-model="selectedHostValues"
          :data="hosts"
          :titles="['Available Hosts', 'Selected Hosts']"
          filterable
          filter-placeholder="Search host"
        />
        <el-transfer
          v-model="selectedUserGroupValues"
          :data="userGroups"
          :titles="['Available User Groups', 'Selected User Groups']"
          filterable
          filter-placeholder="Search user group"
        />
      </div>
      <span slot="footer" class="dialog-footer">
        <l-button round type="btn btn-primary" class="mr-1" @click="addOrUpdateProfile">Save</l-button>
        <l-button round type="btn btn-danger" @click="closeModal('add_update')">Cancel</l-button>
      </span>
    </el-dialog>
    </card>
  </div>
</template>

<script>
import ProfilesAPIService from "src/servicehandlers/ProfilesAPIService";
const profilesAPIService = new ProfilesAPIService();
import HostsAPIService from "src/servicehandlers/HostsAPIService";
const hostsAPIService = new HostsAPIService();
import ChecksAPIService from "src/servicehandlers/ChecksAPIService";
const checksAPIService = new ChecksAPIService();
import UserGroupsAPIService from "src/servicehandlers/UserGroupsAPIService";
const userGroupsAPIService = new UserGroupsAPIService();
import { Transfer, Button, Select, Option, Input, Icon, Table, TableColumn, Tooltip } from 'element-ui';
import { Dialog } from 'element-ui';

export default {
  name: "Profiles",
  components: {
    [Dialog.name]: Dialog,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Icon.name]: Icon,
    [Select.name]: Select,
    [Option.name]: Option,
    [Button.name]: Button,
    [Input.name]: Input,
    [Transfer.name]: Transfer,
    [Tooltip.name]: Tooltip,
  },
  data() {
    return {
      spinning: false,
      profiles: [],
      error_txt: false,
      success_txt: false,
      newProfileName: '',
      showAddModal: false,
      showUpdateModal: false,
      updateProfileName: '',  // dedicated property for updates
      updateSelectedChecks: [],  // dedicated property for updates
      selectedCheckValues: [],
      currentProfileIndex: null,
      hosts: [],
      selectedHostValues: [],
      checks: [],
      userGroups: [],
      selectedUserGroupValues: [],
      initialSelectedHostValues: [],
      initialSelectedCheckValues: [],
      initialSelectedUserGroupValues: [],
      modals: {
        error: false,
        success: false,
        update: false,
        add: false,
        add_update: false,
      },
    }
  },
  async mounted() {
    await this.getHosts();
    await this.getChecks();
    await this.getUserGroups();
    await this.getProfiles();
  },
  methods: {
    async getChecks() {
      return checksAPIService.get(this.$router)
      .then((response) => {
        for (const check of response) {
          check.key = check.id;
          check.label = check.name;
          this.checks.push(check);
        }
      })
      .catch((error) => {
        this.handleError('Error getting checks! ' + error);
      });
    },
    async getUserGroups() {
      return userGroupsAPIService.get(this.$router)
      .then((response) => {
        for (const user_group of response) {
          user_group.key = user_group.id;
          user_group.label = user_group.name;
          this.userGroups.push(user_group);
        }
      })
      .catch((error) => {
        this.handleError('Error getting hosts! ' + error);
      });
    },
    async getHosts() {
      return hostsAPIService.get(this.$router)
      .then((response) => {
        for (const host of response) {
          host.key = host.id;
          host.label = host.name;
          this.hosts.push(host);
        }
      })
      .catch((error) => {
        this.handleError('Error getting hosts! ' + error);
      });
    },
    async getProfiles() {
      return await profilesAPIService.get(this.$router)
      .then((response) => {
        this.profiles = []; // Clear out the existing profiles

        response.forEach(profile => {
          const newProfile = {
            id: profile.id,
            name: profile.name,
            enabled: profile.enabled,
            checks: profile.checks ? profile.checks.map(check => check.id) : [],
            hosts: profile.hosts ? profile.hosts.map(host => host.id) : [],
            userGroups: profile.user_groups ? profile.user_groups.map(user_group => user_group.id) : []
          };
          this.profiles.push(newProfile);
        });
      })
      .catch((error) => {
        this.handleError('Error getting profiles! ' + error);
      });
    },
    async copyProfile(index) {
      const originalProfile = this.profiles[index];
      const copiedProfile = {
        ...originalProfile,
        name: `${originalProfile.name} - Copy`
      };
      const [, checks] = this.transformValues(copiedProfile.checks, this.checks);
      const [, hosts] = this.transformValues(copiedProfile.hosts, this.hosts);
      const [, user_groups] = this.transformValues(copiedProfile.userGroups, this.userGroups);
      try {
        const profileData = {
          name: copiedProfile.name,
          enabled: true,
          checks,
          hosts,
          user_groups,
        };
        await profilesAPIService.create(profileData, this.$router);
      } catch (e) {
        this.handleError('Failure to add profile');
        this.closeModal('add_update');
        return;
      }
      await this.getProfiles();
    },
    openNewModal() {
      this.resetModalData();
      this.openModal('add_update');
    },
    openUpdateModal(index) {
      const profile = this.profiles[index];
      if (!profile || !profile.checks) {
        // console.error(`Error: Unable to find profile or checks for index: ${index}`);
        return;
      }

      this.currentProfileIndex = index;
      this.newProfileName = profile.name;

      this.selectedCheckValues = profile.checks;
      this.selectedHostValues = profile.hosts;
      this.selectedUserGroupValues = profile.userGroups;

      this.openModal('add_update');
    },
    resetModalData() {
      this.newProfileName = '';
      this.selectedCheckValues = [];
      this.selectedHostValues = [];
      this.selectedUserGroupValues = [];
      this.currentProfileIndex = null;
    },
    transformValues(selectedValues, referenceArray) {
      const labels = [];
      const postValues = [];
      selectedValues.forEach(value => {
        const foundItem = referenceArray.find(item => item.id === value);
        if (foundItem) {
          labels.push(foundItem.name);
          postValues.push(foundItem);
        }
      });
      return [labels, postValues];
    },
    async addOrUpdateProfile() {
      const hostChanges = this.getChanges(this.initialSelectedHostValues, this.selectedHostValues);
      const checkChanges = this.getChanges(this.initialSelectedCheckValues, this.selectedCheckValues);
      const userGroupChanges = this.getChanges(this.initialSelectedUserGroupValues, this.selectedUserGroupValues);

      const [selectedChecks, postSelectedChecks] = this.transformValues(this.selectedCheckValues, this.checks);
      const [selectedHosts, postSelectedHosts] = this.transformValues(this.selectedHostValues, this.hosts);
      const [selectedUserGroups, postSelectedUserGroups] = this.transformValues(this.selectedUserGroupValues, this.userGroups);

      const profileData = {
        name: this.newProfileName,
        enabled: true,
        checks: selectedChecks,
        hosts: selectedHosts,
        userGroups: selectedUserGroups,
      };

      if (this.currentProfileIndex !== null) {
        // Updating an existing profile
        this.profiles[this.currentProfileIndex].name = this.newProfileName; // Explicitly setting the name
        this.profiles[this.currentProfileIndex] = { ...this.profiles[this.currentProfileIndex], ...profileData };
        try {
          const profileId = this.profiles[this.currentProfileIndex].id;
          const profileName = this.profiles[this.currentProfileIndex].name;
          await this.updateBackend(profileId, profileName, hostChanges, checkChanges, userGroupChanges);
        } catch (e) {
          this.handleError('Failure to add profile');
          this.closeModal('add_update');
          return;
        }
      } else {
        // Adding a new profile
        this.profiles.push(profileData);

        try {
          const postProfileData = {
            ...profileData,
            checks: postSelectedChecks,
            hosts: postSelectedHosts,
            user_groups: postSelectedUserGroups,
          };
          await profilesAPIService.create(postProfileData, this.$router);
        } catch (e) {
          this.handleError('Failure to add profile');
          this.closeModal('add_update');
          return;
        }
      }
      await this.getProfiles();
      this.closeModal('add_update');
    },
    async updateBackend(profile_id, profile_name, hostChanges, checkChanges, usergroupChanges) {
      const profileInfo = {
        profile_id,
        profile_name,
        hostChanges,
        checkChanges,
        usergroupChanges,
      };
      let profile_changes = null;
      try {
        profile_changes = await profilesAPIService.setProfileInformation(profile_id, profileInfo, this.$router);
      } catch (e) {
        this.handleError('Unable to add hosts to the profile! ');
        return;
      }
      this.closeModal('add_update');
      await this.getProfiles();
    },
    deleteProfile(index) {
      const profileId = this.profiles[index].id;

      return profilesAPIService.delete(profileId, this.$router)
      .then(() => {
        this.profiles.splice(index, 1);  // Move this line after API call is successful
        this.handleSuccess('Successfully deleted profile');
      })
      .catch((error) => {
        this.handleError('Error deleting profile: ' + error);
      });
    },
    getChanges(original, updated) {
      const added = updated.filter(item => !original.includes(item));
      const removed = original.filter(item => !updated.includes(item));

      return { added, removed };
    },
    openModal(name) {
      this.initialSelectedHostValues = [...this.selectedHostValues];
      this.initialSelectedCheckValues = [...this.selectedCheckValues];
      this.initialSelectedUserGroupValues = [...this.selectedUserGroupValues];
      this.modals[name] = true
    },
    closeModal(name) {
      this.modals[name] = false;
      this.initialSelectedHostValues = [];
      this.initialSelectedCheckValues = [];
      this.initialSelectedUserGroupValues = [];
    },
    handleSuccess(text) {
      this.success_txt = text;
      this.openModal('success');
      this.spinning = false;
      setTimeout(() => {
        this.modals['success'] = false;
      }, 1500); // Hide after 1.5 secs
    },
    handleError(error) {
      this.error_txt = error;
      this.openModal('error');
      this.spinning = false;
      setTimeout(() => {
        this.modals['error'] = false;
      }, 1500); // Hide after 1.5 secs
    },
  }
}
</script>


<style>
.el-transfer-panel {
  width: 400px;
  margin-top: 15px;
}
.el-transfer-panel__filter .el-input__inner {
  width: 80%;
}
.el-transfer-panel__body {
  height: 270px;
}
.el-transfer-panel__list.is-filterable {
  height: 208px;
  padding-top: 0;
}
.icon-large {
  font-size: 20px;
}
.icon-red {
  color: red;
}
.icon-blue {
  color: blue;
}
.icon-green {
  color: green;
}
.fa-compact-disc {
  font-size: 18px;
  rotate: 90deg;
  color: cornflowerblue;
}
.fa-circle-plus {
  color: mediumseagreen;
  font-size: 25px;
}
div.card h4.card-title {
  font-size: 25px;
  color: dimgrey;
}
.center-content {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}
.fa-floppy-disk {
  font-size: 19px;
  color: dodgerblue;
}
</style>
