<template>
  <button v-if="!isActive"
    @click="toggleState"
    style="color: black; border: 2px solid black"
    class="round-button"
  >
    {{ buttonText }}
  </button>
  <button v-else
          @click="toggleState"
          :style="buttonStyle"
          class="round-button"
  >
    {{ buttonText }}
  </button>
</template>

<script>
export default {
  props: {
    activeColor: String,
    inactiveColor: String,
    buttonText: String,
    initialActive: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      isActive: this.initialActive // Use the prop value for initialization
    };
  },
  computed: {
    buttonStyle() {
      return {
        backgroundColor: this.isActive ? this.activeColor : this.inactiveColor,
        border: '2px solid white'
      };
    }
  },
  methods: {
    toggleState() {
      this.isActive = !this.isActive;
    }
  }
};
</script>


<style scoped>
.round-button {
  border-radius: 25px;    /* Adjust for a rounded appearance */
  padding: 10px 20px;     /* Vertical and horizontal padding */
  height: 50px;           /* Adjust as needed */
  outline: none;
  cursor: pointer;
  transition: background-color 0.3s, border 0.3s;
  font-weight: bold;
  min-width: 50px;        /* Optional, if you want a minimum width */
  display: inline-flex;   /* Helps with centering the text vertically */
  align-items: center;    /* Vertically align the text */
  justify-content: center; /* Horizontally center the text */
  color: white;
}
</style>
