import CommonAPIService from 'src/servicehandlers/CommonHandler';

const base_url = '/api/script_types'

export class ScriptTypesAPIService {
  constructor() {
    this.CommonAPIService = new CommonAPIService();
  }

  create(scriptType, router) {
    return this.CommonAPIService.postCall(base_url, scriptType, router);
  }

  update(script_type_id, scriptType, router) {
    const url = `${base_url}/${script_type_id}`;
    return this.CommonAPIService.putCall(url, scriptType, router);
  }

  delete(id, router) {
    const url = `${base_url}/${id}`;
    return this.CommonAPIService.deleteCall(url, null, router);
  }

  get(router) {
    return this.CommonAPIService.getCall(base_url, null, router);
  }

  getScriptByName(command_name, router) {
    const url = `${base_url}/${command_name}`;
    return this.CommonAPIService.getCall(url, null, router);
  }
}

export default ScriptTypesAPIService;
