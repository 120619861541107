import CommonAPIService from './CommonHandler';

export class HostsAPIService {
  constructor() {
    this.CommonAPIService = new CommonAPIService();
  }
  create(host, router) {
    const url = '/api/hosts';
    return this.CommonAPIService.postCall(url, host, router);
  }
  delete(id, router) {
    const url = `/api/hosts/${id}`;
    return this.CommonAPIService.deleteCall(url, null, router);
  }
  update(id, host, router) {
    const url = `/api/hosts/${id}`;
    return this.CommonAPIService.putCall(url, host, router);
  }

  getHostsWithOperatingSystems(router) {
    const url = '/api/hosts/operatingsystems';
    return this.CommonAPIService.getCall(url, null, router);
  }

  getUntrainedHosts(router) {
    const url = '/api/hosts/untrained';
    return this.CommonAPIService.getCall(url, null, router);
  }

  getHostsServicesOperatingSystems(router) {
    const url = '/api/hosts/services';
    return this.CommonAPIService.getCall(url, null, router);
  }

  getHostsLinkedToAuthgroups(authgroup_id, router) {
    const url = `/api/hosts/${authgroup_id}/linked`;
    return this.CommonAPIService.getCall(url, null, router);
  }

  getHostsByClientID(router) {
    const url = `/api/hosts/clients`;
    return this.CommonAPIService.getCall(url, null, router);
  }

  get(router) {
    const url = '/api/hosts';
    return this.CommonAPIService.getCall(url, null, router);
  }

  getHostsWithRights(router) {
    const url = '/api/hosts/hosts_with_rights';
    return this.CommonAPIService.getCall(url, null, router);
  }
  getWithAlerts(router) {
    const url = '/api/hosts/alerts';
    return this.CommonAPIService.getCall(url, null, router);
  }
  getHostAuthGroupRights(router) {
    const url = `/api/hosts/auth_groups`;
    return this.CommonAPIService.getCall(url, null, router);
  }

  linkHostsToAuthGroup(auth_group_id, host_ids, router) {
    const url = `/api/hosts/${host_ids}/${auth_group_id}/link`;
    return this.CommonAPIService.getCall(url, null, router);
  }
  getHostTypes(router) {
    const url = `/api/hosts/types`;
    return this.CommonAPIService.getCall(url, null, router);
  }
  createHostType(host_type, router) {
    const url = `/api/hosts/types`;
    return this.CommonAPIService.postCall(url, host_type, router);
  }
  unlinkHostsToAuthGroup(auth_group_id, host_ids, router) {
    const url = `/api/hosts/${host_ids}/${auth_group_id}/unlink`;
    return this.CommonAPIService.deleteCall(url, null, router);
  }
  getHostsWithChecks(auth_group_id, host_ids, router) {
    const url = `/api/hosts/checks`;
    return this.CommonAPIService.getCall(url, null, router);
  }
  getHostCountsWithChecks(router) {
    const url = `/api/hosts/full_count`;
    return this.CommonAPIService.getCall(url, null, router);
  }
  linkUserToHostAlert(host_id, user_id, router) {
    const url = `/api/hosts/${host_id}/${user_id}/linkalert`;
    return this.CommonAPIService.getCall(url, null, router);
  }
  unlinkUserToHostAlert(host_id, user_id, router) {
    const url = `/api/hosts/${host_id}/${user_id}/unlinkalert`;
    return this.CommonAPIService.getCall(url, null, router);
  }
  linkUserGroupToHost(host_id, user_group_id, router) {
    const url = `/api/hosts/${host_id}/${user_group_id}/linkusergroup`;
    return this.CommonAPIService.getCall(url, null, router);
  }
  unlinkUserGroupToHost(host_id, user_group_id, router) {
    const url = `/api/hosts/${host_id}/${user_group_id}/unlinkusergroup`;
    return this.CommonAPIService.getCall(url, null, router);
  }

  createHostQueueItem(host_id, text, router) {
    const url = `/api/hosts/${host_id}/host_queue_items`;
    return this.CommonAPIService.postCall(url, text, router);
  }
  getHostQueueResults(host_id, router) {
    const url = `/api/hosts/${host_id}/host_queue_results`;
    return this.CommonAPIService.getCall(url, null, router);
  }
  async setHostInformation(host_id, hostInfo, $router) {
    return null;
  }
}

export default HostsAPIService;
