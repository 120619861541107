<template>
<div>
  <card>
    <h3>This page is to schedule events for the hosts</h3>
    <h4>You can filter the hosts and apply the patching</h4>
  </card>
  <div class="row">
    <div class="col-sm-6">
      <!-- Search bar -->
      <el-input
        v-model="searchTerm"
        placeholder="Search Host"
        clearable>
      </el-input>
    </div>
  </div>
  <div class="row pb-5">
    <div class="col-sm-6">
      <!-- OS Filtering Dropdown -->
      <el-select v-model="selectedOS" placeholder="Select OS">
        <el-option
          v-for="os in operatingSystems"
          :key="os.id"
          :label="os.friendly_name"
          :value="os.id">
        </el-option>
      </el-select>
      <el-button @click="clearOSFilter">Clear</el-button>
    </div>
  </div>
  <div class="row">
    <div class="col-sm-12">
      <el-button @click="setScheduleForAll('patching')">Set Patching for All</el-button>
      <el-button @click="setScheduleForAll('reboot')">Set Reboot for All</el-button>
      <el-button @click="setScheduleForAll('backup')">Set Backup for All</el-button>
      <el-button @click="setScheduleForAll('analyze')">Set Analyze for All</el-button>
      <!-- Hosts Table -->
      <el-table :data="filteredHostList" style="width: 100%">
        <el-table-column prop="name" label="Host Name"></el-table-column>
        <el-table-column label="Patching">
          <template slot-scope="scope">
            <el-button @click="openPatchingDialog(scope.row)">Set Patching Schedule</el-button>
          </template>
        </el-table-column>
        <el-table-column label="Reboot">
          <template slot-scope="scope">
            <el-button @click="openRebootDialog(scope.row)">Set Reboot Schedule</el-button>
          </template>
        </el-table-column>
        <el-table-column label="Backup">
          <template slot-scope="scope">
            <el-button @click="openBackupDialog(scope.row)">Set Backup Schedule</el-button>
          </template>
        </el-table-column>
        <el-table-column label="Analyze">
          <template slot-scope="scope">
            <el-button @click="openAnalyzeDialog(scope.row)">Set Analyze Schedule</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
  </div>
  <el-dialog :visible.sync="scheduleDialogVisible" :title="scheduleDialogTitle">
    <el-radio-group v-model="selectedFrequency">
      <el-radio :value="'daily'" label="daily">Daily</el-radio>
      <el-radio :value="'weekly'" label="weekly">Weekly</el-radio>
      <el-radio :value="'monthly'" label="monthly">Monthly</el-radio>
    </el-radio-group>

    <!-- Time Picker -->
    <el-time-picker v-if="selectedFrequency !== 'custom'" v-model="selectedTime" placeholder="Select time"></el-time-picker>

    <!-- Day of Week Dropdown for Weekly Schedule -->
    <el-select v-if="selectedFrequency === 'weekly'" v-model="selectedDayOfWeek" placeholder="Select day of the week">
      <el-option label="Sunday" value="Sunday"></el-option>
      <el-option label="Monday" value="Monday"></el-option>
      <el-option label="Tuesday" value="Tuesday"></el-option>
      <el-option label="Wednesday" value="Wednesday"></el-option>
      <el-option label="Thursday" value="Thursday"></el-option>
      <el-option label="Friday" value="Friday"></el-option>
      <el-option label="Saturday" value="Saturday"></el-option>
    </el-select>

    <!-- Day of Month Dropdown for Monthly Schedule -->
    <el-select v-if="selectedFrequency === 'monthly'" v-model="selectedDayOfMonth" placeholder="Select day of the month">
      <el-option v-for="day in 31" :key="day" :label="day" :value="day"></el-option>
    </el-select>

    <!-- Custom Schedule Input -->
    <el-input v-if="selectedFrequency === 'custom'" v-model="customSchedule" placeholder="Enter custom schedule (e.g., Mon 10:00,Wed 15:00)"></el-input>

    <!-- Footer Buttons -->
    <span slot="footer" class="dialog-footer">
    <el-button @click="closeDialog">Cancel</el-button>
    <el-button type="primary" @click="setSchedule">Confirm</el-button>
  </span>
  </el-dialog>

</div>
</template>

<script>
import moment from 'moment';
import HostsAPIService from "src/servicehandlers/HostsAPIService";
const hostsAPIService = new HostsAPIService();
import OperatingSystemAPIService from "src/servicehandlers/OperatingSystemAPIService";
const operatingSystemAPIService = new OperatingSystemAPIService();
import ScheduleAPIService from "src/servicehandlers/ScheduleAPIService";
const scheduleAPIService = new ScheduleAPIService();
import {Message, Select, Option, Radio, RadioGroup, TimePicker, Dialog, Button, Table, TableColumn } from 'element-ui';
export default {
  name: "Scheduling",
  data() {
    return {
      searchTerm: '',
      selectedDayOfWeek: '',
      selectedDayOfMonth: '',
      operatingSystems: ['Windows', 'Linux', 'macOS'],
      selectedOS: '',
      scheduleForAll: false,
      searchText: '',
      scheduleDialogVisible: false,
      scheduleDialogTitle: '',
      selectedFrequency: 'daily',
      selectedTime: '',
      customSchedule: '',
      currentScheduleType: '',
      selectedHost: null,
      hosts: [
        { id: 1, name: 'Host1', os: 'Windows', patchingSchedule: '', rebootSchedule: '', backupSchedule: '' },
        { id: 2, name: 'Host2', os: 'Linux', patchingSchedule: '', rebootSchedule: '', backupSchedule: '' },
        { id: 3, name: 'Host3', os: 'macOS', patchingSchedule: '', rebootSchedule: '', backupSchedule: '' },
      ],
    };
  },
  components: {
    [RadioGroup.name]: RadioGroup,
    [Radio.name]: Radio,
    [Select.name]: Select,
    [Option.name]: Option,
    [Button.name]: Button,
    [Dialog.name]: Dialog,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [TimePicker.name]: TimePicker,
    [Message.name]: Message,
  },
  computed: {
    filteredHostList() {
      return this.hosts.filter(host => {
        let matchesSearchTerm = true;
        let matchesOS = true;

        if (this.searchTerm) {
          matchesSearchTerm = host.name.toLowerCase().includes(this.searchTerm.toLowerCase());
        }

        if (this.selectedOS) {
          matchesOS = host.operating_system_id === this.selectedOS;
        }

        return matchesSearchTerm && matchesOS;
      });
    }
  },
  mounted() {
    this.getHosts();
    this.getOperatingSystems();
  },
  methods: {
    getOperatingSystems() {
      return operatingSystemAPIService.get(this.$router)
      .then((allOperatingSystems) => {
        this.operatingSystems = allOperatingSystems;
      })
      .catch((error) => {
        this.handleError('Failed to get operating systems! ' + error);
      });
    },
    getHosts() {
      return hostsAPIService.getHostsWithOperatingSystems(this.$router)
      .then((allHosts) => {
        this.hosts = allHosts;
      })
      .catch((error) => {
        this.handleError('Failed to get hosts! ' + error);
      });
    },
    clearOSFilter() {
      this.selectedOS = '';
    },
    clearSearch() {
      this.searchText = '';
    },
    openDialog(row, type) {
      this.selectedHost = row;
      this.currentScheduleType = type;

      switch (type) {
        case 'patching':
          this.scheduleDialogTitle = 'Set Patching Schedule';
          break;
        case 'reboot':
          this.scheduleDialogTitle = 'Set Reboot Schedule';
          break;
        case 'backup':
          this.scheduleDialogTitle = 'Set Backup Schedule';
          break;
        case 'analyze':
          this.scheduleDialogTitle = 'Set Analyze Schedule';
          break;
      }

      this.scheduleDialogVisible = true;
    },
    openPatchingDialog(row) {
      this.openDialog(row, 'patching');
    },
    openRebootDialog(row) {
      this.openDialog(row, 'reboot');
    },
    openBackupDialog(row) {
      this.openDialog(row, 'backup');
    },
    openAnalyzeDialog(row) {
      this.openDialog(row, 'analyze');
    },
    closeDialog() {
      this.scheduleDialogVisible = false;
      this.selectedFrequency = 'daily';
      this.selectedTime = '';
      this.customSchedule = '';
    },
    setScheduleForAll(type) {
      this.currentScheduleType = type;
      this.scheduleDialogVisible = true;
      this.scheduleForAll = true;
    },
    confirmScheduleForAll() {
      let scheduleValue = this.selectedFrequency === 'custom'
        ? this.customSchedule
        : `${this.selectedFrequency} at ${this.selectedTime}`;

      this.filteredHostList.forEach(host => {
        switch (this.currentScheduleType) {
          case 'patching':
            host.patchingSchedule = scheduleValue;
            break;
          case 'reboot':
            host.rebootSchedule = scheduleValue;
            break;
          case 'backup':
            host.backupSchedule = scheduleValue;
            break;
        }
      });

      const currentDateTime = new Date();
      const formattedDateTime = `${currentDateTime.getFullYear()}-${String(currentDateTime.getMonth() + 1).padStart(2, '0')}-${String(currentDateTime.getDate()).padStart(2, '0')} ${String(currentDateTime.getHours()).padStart(2, '0')}:${String(currentDateTime.getMinutes()).padStart(2, '0')}:${String(currentDateTime.getSeconds()).padStart(2, '0')}`;

      this.closeDialog();
      this.scheduleForAll = false;
    },
    getNextScheduledTime() {
      const now = new Date();
      let nextSchedule = new Date(now);

      // Ensure the selected time is a Date object
      if (!(this.selectedTime instanceof Date)) {
        return null; // Or handle this case as you see fit
      }

      // Set the hours and minutes from the selected time
      nextSchedule.setHours(this.selectedTime.getHours(), this.selectedTime.getMinutes(), 0);

      switch (this.selectedFrequency) {
        case 'daily':
          if (nextSchedule < now) {
            // If the selected time is before the current time, schedule for the next day
            nextSchedule.setDate(nextSchedule.getDate() + 1);
          }
          break;

        case 'weekly':
          if (this.selectedDayOfWeek) {
            // Calculate the next date for the selected day of the week
            const currentDayOfWeek = nextSchedule.getDay();
            const daysUntilNext = (7 + this.dayOfWeekToNumber(this.selectedDayOfWeek) - currentDayOfWeek) % 7;
            nextSchedule.setDate(nextSchedule.getDate() + daysUntilNext || 7);
          }
          break;

        case 'monthly':
          if (this.selectedDayOfMonth) {
            // Set the date to the selected day of the month
            nextSchedule.setDate(this.selectedDayOfMonth);
            if (nextSchedule < now) {
              // If the selected day is before the current day, schedule for the next month
              nextSchedule.setMonth(nextSchedule.getMonth() + 1);
            }
          }
          break;
      }

      return nextSchedule.toISOString().slice(0, 19).replace('T', ' ');
    },

    dayOfWeekToNumber(day) {
      const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
      return days.indexOf(day);
    },
    async setSchedule() {
      if (!this.selectedHost) return;

      let scheduleValue = this.selectedFrequency === 'custom'
        ? this.customSchedule
        : `${this.selectedFrequency} at ${this.selectedTime}`;

      switch (this.currentScheduleType) {
        case 'patching':
          this.selectedHost.patchingSchedule = scheduleValue;
          break;
        case 'reboot':
          this.selectedHost.rebootSchedule = scheduleValue;
          break;
        case 'backup':
          this.selectedHost.backupSchedule = scheduleValue;
          break;
        case 'analyze':
          this.selectedHost.analyzeSchedule = scheduleValue;
          break;
      }

      // Logging the current date and time using Moment.js
      const currentDateTime = moment(); // current date and time

      const next_time = this.getNextScheduledTime();

      const selectedTimeMoment = moment(this.selectedTime, 'YYYY-MM-DD HH:mm:ss');

      // Now format it to extract hours, minutes, and seconds
      const hours = selectedTimeMoment.format('HH');
      const minutes = selectedTimeMoment.format('mm');
      const seconds = selectedTimeMoment.format('ss');
      const db_time = `${hours}:${minutes}:${seconds}`;

      // now insert into the DB
      try {
        const schedule = {
          frequency: this.selectedFrequency,
          host_id: this.selectedHost.id,
          type: this.currentScheduleType,
          day: this.selectedDayOfWeek,
          next_run: next_time,
          time: db_time,
          custom_value: scheduleValue,
        };
        if (this.selectedFrequency === 'monthly') {
          schedule.day = this.selectedDayOfMonth;
        }
        await scheduleAPIService.create(schedule, this.$route);
        Message.success("Successfully set schedule for host");
      } catch(e) {
        Message.error("Failed to set schedule for host!");
        this.handleError('Failed to set schedule for host!');
      }

      this.closeDialog();
    },
    handleSuccess(text) {
      this.success_txt = text;
      this.openModal('success');
      this.spinning = false;
      setTimeout(() => {
        this.modals['success'] = false;
      }, 1500); // Hide after 1.5 secs
    },
    handleError(error) {
      this.error_txt = error;
      this.openModal('error');
      this.spinning = false;
      setTimeout(() => {
        this.modals['error'] = false;
      }, 1500); // Hide after 1.5 secs
    },
  }
}
</script>

<style scoped>

</style>
