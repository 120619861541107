import CommonAPIService from './CommonHandler';

export class RunningCommandsAPIService {
  constructor() {
    this.CommonAPIService = new CommonAPIService();
  }
  create(running_command, router) {
    const url = '/api/running_commands';
    return this.CommonAPIService.postCall(url, running_command, router);
  }
  delete(id, router) {
    const url = `/api/running_commands/${id}`;
    return this.CommonAPIService.deleteCall(url, null, router);
  }
  update(id, running_command, router) {
    const url = `/api/running_commands/${id}`;
    return this.CommonAPIService.putCall(url, running_command, router);
  }
  get(router) {
    const url = '/api/running_commands';
    return this.CommonAPIService.getCall(url, null, router);
  }
  getRunningCommandsByHostID(host_id, router) {
    const url = `/api/running_commands/${host_id}/host`;
    return this.CommonAPIService.getCall(url, null, router);
  }
  getRunningCommandsByOperatingSystemID(operating_system_id, router) {
    const url = `/api/running_commands/${operating_system_id}/operating_system`;
    return this.CommonAPIService.getCall(url, null, router);
  }
  getAll(router) {
    const url = '/api/running_commands/all';
    return this.CommonAPIService.getCall(url, null, router);
  }
}

export default RunningCommandsAPIService;
