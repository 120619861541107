<template>
<div>
  <card>
    <div class="row">
      <div class="col-sm-12 text-center">
        <h1 class="card-title mt-4 mb-4">AI Running Commands Page</h1>
      </div>
    </div>
    <el-table class="table-striped" border :data="queues">
      <el-table-column label="Command" property="command_string" width="250" :header-align="headerAlignCenter" :align="cellAlignCenter"></el-table-column>
      <el-table-column label="Client Action" property="client_action" width="250" :header-align="headerAlignCenter" :align="cellAlignCenter"></el-table-column>
      <el-table-column label="Results" property="needs_auth" width="200" :header-align="headerAlignCenter" :align="cellAlignCenter">
        <template slot-scope="scope">
          <button class="btn btn-primary" @click="showResult(scope.row.id)">Show Full Result Text</button>
        </template>
      </el-table-column>
      <el-table-column label="Exit Code" property="exit_code" width="110" :header-align="headerAlignCenter" :align="cellAlignCenter"></el-table-column>
      <el-table-column label="Initiator" property="full_name" width="130" :header-align="headerAlignCenter" :align="cellAlignCenter"></el-table-column>
      <el-table-column label="Time" property="createdAt" width="150" :header-align="headerAlignCenter" :align="cellAlignCenter"></el-table-column>
      <el-table-column label="Actions" property="actions" width="100" :align="cellAlignCenter" :header-align="headerAlignCenter">
        <template slot-scope="scope">
          <el-tooltip content="Delete Running Command">
            <el-button type="text" @click="removeRunningCommand(scope.row.id)">
              <el-icon name="delete" class="icon-large icon-red"></el-icon>
            </el-button>
          </el-tooltip>
        </template>
      </el-table-column>
    </el-table>

  </card>
  <el-dialog
    center
    title="Error"
    :visible.sync="modals.error">
    <div class="text-center">
      <span>Error: {{ error_txt }}</span>
      <div class="text-center mt-4">
        <span slot="footer" class="dialog-footer">
<l-button type="btn btn-danger" round @click="closeModal('error')">OK</l-button>&nbsp;
</span>
      </div>
    </div>
  </el-dialog>
  <el-dialog
    center
    :visible.sync="modals.result">
    <div class="row">
      <div class="col-sm-12">
        <div class="text-center">
          <h3 style="margin-top: -20px;">Results:</h3>
        </div>
      </div>
    </div>
    <div class="text-center">
      <span v-html="result_txt"></span>
      <br>
      <span slot="footer" class="dialog-footer">
<l-button type="btn btn-primary" round @click="closeModal('result')">OK</l-button>&nbsp;
</span>
    </div>
  </el-dialog>
  <el-dialog
    center
    title="Success"
    :visible.sync="modals.success">
    <div class="text-center">
      <span>{{ success_txt }}</span>
      <div class="text-center mt-4">
        <span slot="footer">
<l-button type="btn btn-success" round @click="closeModal('success')">OK</l-button>&nbsp;
</span>
      </div>
    </div>
  </el-dialog>
</div>
</template>

<script>
import RunningCommandsAPIService from "src/servicehandlers/RunningCommandsAPIService";

const moment = require('moment');
import { Dialog, Button, Tooltip, Icon, Table, TableColumn, } from 'element-ui';
const runningCommandsAPIService = new RunningCommandsAPIService();

export default {
  name: "ClientRunningCommands",
  components: {
    [Dialog.name]: Dialog,
    [Button.name]: Button,
    [Tooltip.name]: Tooltip,
    [Icon.name]: Icon,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
  },
  data() {
    return {
      queue_string: null,
      queues: [],
      //queue: [],
      error_txt: null,
      success_txt: null,
      result_txt: null,
      cellAlignCenter: 'center',
      headerAlignCenter: 'center',
      modals: {
        error: false,
        result: false,
        success: false,
      },
    }
  },
  mounted() {
    this.getQueue();
  },
  methods: {
    removeRunningCommand(id) {
      return runningCommandsAPIService.delete(id, this.$router)
      .then((updated) => {
        const self = this;
        this.getQueue();
        this.error_txt = 'command removed';
        this.openModal('success');
        setTimeout(function () {
          self.modals['success'] = false; // Use your variable name
        }, 1500); // Hide after 5 secs
      })
      .catch((error) => {
        const self = this;
        this.error = 'Failure to delete command' + error;
        this.openModal('error');
        setTimeout(function () {
          self.modals['error'] = false; // Use your variable name
        }, 1500); // Hide after 5 secs
      });
    },
    authorizeCommand(id) {
      const payload = {
        needs_auth: false,
      };
      return runningCommandsAPIService.update(id, payload, this.$router)
      .then((updated) => {
        const self = this;
        this.success_txt = 'Authorization Granted';
        this.openModal('success');
        setTimeout(function () {
          self.modals['success'] = false; // Use your variable name
        }, 1500); // Hide after 5 secs
      })
      .catch((error) => {
        const self = this;
        this.error = 'Authorization update failure' + error;
        this.openModal('error');
        setTimeout(function () {
          self.modals['error'] = false; // Use your variable name
        }, 1500); // Hide after 5 secs
      });
    },
    getAlternateColorClass(index) {
      return index % 2 === 0 ? "even-row" : "odd-row";
    },
    showResult(id) {
      for (const queue of this.queues) {
        if (id === queue.id) {
          this.result_txt = queue.results;
          if (queue.results) {
            this.result_txt = this.result_txt.replace(/\n/g, "<br />");
          }
          break;
        }
      }
      this.openModal('result');
    },
    openModal(name) {
      this.modals[name] = true
    },
    closeModal(name) {
      this.modals[name] = false;
    },
    beforeDestroy() {
      clearInterval(this.refreshInterval);
    },
    getQueue() {
      this.queues = [];
      return runningCommandsAPIService.getAll(this.$router)
      .then((queues) => {
        for (const queue of queues) {
          queue.full_name = queue.user.first_name + ' ' + queue.user.last_name;
          queue.createdAt = moment(queue.createdAt).format('MM-DD-YYYY HH:MM:SS');
          this.queues.push(queue);
        }
      })
      .catch((error) => {
        const self = this;
        this.error = 'Failed to get command results' + error;
        this.openModal('error');
        setTimeout(function () {
          self.modals['error'] = false; // Use your variable name
        }, 1500); // Hide after 5 secs
      });
    },

  },
}
</script>

<style scoped>
.even-row {
  background-color: #f2f2f2; /* Replace with your desired color for even rows */
}

.odd-row {
  background-color: #ffffff; /* Replace with your desired color for odd rows */
}
.card-title {
  color: dimgrey;
  font-size: 25px;
}
</style>
