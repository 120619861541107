import CommonAPIService from './CommonHandler';

export class HostGroupsAPIService {
  constructor() {
    this.CommonAPIService = new CommonAPIService();
  }
  create(hostgroup, router) {
    const url = '/api/host_groups';
    return this.CommonAPIService.postCall(url, hostgroup, router);
  }
  delete(id, router) {
    const url = `/api/host_groups/${id}`;
    return this.CommonAPIService.deleteCall(url, null, router);
  }
  update(id, hostgroup, router) {
    const url = `/api/host_groups/${id}`;
    return this.CommonAPIService.putCall(url, hostgroup, router);
  }

  get(router) {
    const url = '/api/host_groups';
    return this.CommonAPIService.getCall(url, null, router);
  }

  getHostgroupsWithHosts(router) {
    const url = '/api/host_groups/hosts';
    return this.CommonAPIService.getCall(url, null, router);
  }
  addHostsToHostgroup(hostgroup_id, hosts, router) {
    const url = `/api/host_groups/${hostgroup_id}/hosts`;
    return this.CommonAPIService.postCall(url, hosts, router);
  }
}

export default HostGroupsAPIService;
