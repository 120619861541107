<template>
  <auth-layout pageClass="login-page">
    <div class="row d-flex justify-content-center align-items-center">
      <div class="col-lg-4 col-md-6 col-sm-8">
        <ValidationObserver v-slot="{ handleSubmit }">
          <!--You can specify transitions on initial render. The `card-hidden` class will be present initially and then it will be removed-->
          <form @submit.prevent="handleSubmit(submit)">
            <fade-render-transition>
              <card>
                <div slot="header">
                  <h3 class="card-title text-center">Login</h3>
                </div>
                <div>
                  <ValidationProvider
                    name="email"
                    rules="required"
                    v-slot="{ passed, failed }"
                  >
                    <fg-input  type="text"
                               :error="failed ? 'The field is required': null"
                               :hasSuccess="passed"
                               label="Username"
                               name="email"
                               v-model="email">
                    </fg-input>
                  </ValidationProvider>
                  <ValidationProvider
                    name="password"
                    rules="required|min:5"
                    v-slot="{ passed, failed }"
                  >
                    <fg-input  type="password"
                               :error="failed ? 'The Password field is required': null"
                               :hasSuccess="passed"
                               name="password"
                               label="Password"
                               v-model="password">
                    </fg-input>
                  </ValidationProvider>
                </div>
                <div class="text-center">
                  <button type="submit" class="btn btn-fill btn-info btn-round btn-wd ">Login</button>
                  <br>
                  <div class="forgot">
                    <router-link to="/forgot" class="card-category">
                      Forgot your password?
                    </router-link>
                  </div>
                </div>
              </card>
            </fade-render-transition>
          </form>
        </ValidationObserver>
      </div>
    </div>
    <el-dialog
      center
      title="Error"
      :visible.sync="modals.error">
      <span><h5>Error Occurred</h5></span>
      <div class="row">
        <div class="col-md-12"></div>
      </div>
      <div class="row text-center">
        <div class="col-md-12">
          <label>{{ error_text}}</label>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
<button class="btn btn-danger" @click="closeModal('error')">Error</button>&nbsp;
</span>
    </el-dialog>
  </auth-layout>
</template>
<script>
import { useLoginStore } from '@/stores/loginStore'
import { FadeRenderTransition } from 'src/components/index'
import AuthLayout from './AuthLayout.vue'
import { extend } from "vee-validate";
import { required, email, min } from "vee-validate/dist/rules";
import CommonAPIService from "src/servicehandlers/CommonHandler";
const apiService = new CommonAPIService();
import {
  Dialog,
} from 'element-ui';

extend("email", email);
extend("required", required);
extend("min", min);

  export default {
    components: {
      // LCheckbox,
      FadeRenderTransition,
      AuthLayout,
      [Dialog.name]: Dialog,
    },
    data() {
      return {
        email: "",
        password: "",
        store: null,
        subscribe: true,
        error_text: '',
        modals: {
          error: false,
        },
      };
    },
    mounted() {
      this.store = useLoginStore();
    },
    methods: {
      goToHome() {
        let loggedIn = this.store.loggedIn;
        if (loggedIn) {
          this.$router.push({
            name: 'Check View'
          });
        }
      },
      openModal(name) {
        this.modals[name] = true;
      },
      closeModal(name) {
        this.modals[name] = false;
      },
      submit() {
        let loggedIn = this.store.loggedIn;
        const errors = [];
        const login = {
          username: this.email,
          password: this.password
        };
        return apiService.login(login, this.$router, this.store, errors)
        .then((response) => {
          if (response && response.success) {
            loggedIn = this.store.loggedIn;
            if (loggedIn) {
              this.goToHome();
            }
          } else {
            this.error_text = 'Login Failed';
            this.openModal('error');
          }
        })
        .catch((error) => {
          this.error_text = error;
          this.openModal('error');
        });
      },
      toggleNavbar () {
        document.body.classList.toggle('nav-open');
      },
      closeMenu () {
        document.body.classList.remove('nav-open');
        document.body.classList.remove('off-canvas-sidebar');
      }
    },
    beforeDestroy () {
      this.closeMenu();
    }
  }
</script>
<style>
  .navbar-nav .nav-item p{
    line-height: inherit;
    margin-left: 5px;
  }
</style>
