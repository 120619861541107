import CommonAPIService from './CommonHandler';

export class RunningCommandsArchiveAPIService {
  constructor() {
    this.CommonAPIService = new CommonAPIService();
  }
  create(host, router) {
    const url = '/api/running_commands_archive';
    return this.CommonAPIService.postCall(url, host, router);
  }
  delete(id, router) {
    const url = `/api/running_commands_archive/${id}`;
    return this.CommonAPIService.deleteCall(url, null, router);
  }
  update(id, host, router) {
    const url = `/api/running_commands_archive/${id}`;
    return this.CommonAPIService.putCall(url, host, router);
  }
  get(router) {
    const url = '/api/running_commands_archive';
    return this.CommonAPIService.getCall(url, null, router);
  }
  getAll(router) {
    const url = '/api/running_commands_archive/all';
    return this.CommonAPIService.getCall(url, null, router);
  }
  getByHostID(host_id, router) {
    const url = `/api/running_commands_archive/${host_id}/host`;
    return this.CommonAPIService.getCall(url, null, router);
  }
  getByOperatingSystemID(operating_system_id, router) {
    const url = `/api/running_commands_archive/${operating_system_id}/operating_system`;
    return this.CommonAPIService.getCall(url, null, router);
  }
}

export default RunningCommandsArchiveAPIService;
