import CommonAPIService from './CommonHandler';

export class CommandLookupTablesAPIService {
  constructor() {
    this.CommonAPIService = new CommonAPIService();
  }
  create(command_lookup_service, router) {
    const url = '/api/command_lookup';
    return this.CommonAPIService.postCall(url, command_lookup_service, router);
  }
  delete(id, router) {
    const url = `/api/command_lookup/${id}`;
    return this.CommonAPIService.deleteCall(url, null, router);
  }
  update(id, command_lookup_service, router) {
    const url = `/api/command_lookup/${id}`;
    return this.CommonAPIService.putCall(url, command_lookup_service, router);
  }

  get(router) {
    const url = '/api/command_lookup';
    return this.CommonAPIService.getCall(url, null, router);
  }
}

export default CommandLookupTablesAPIService;
