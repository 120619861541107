import CommonAPIService from 'src/servicehandlers/CommonHandler';

export class CompaniesAPIService {
  constructor() {
    this.CommonAPIService = new CommonAPIService();
  }

  createCompany(command, router) {
    const url = '/api/companies';
    return this.CommonAPIService.postCall(url, command, router);
  }

  updateCompany(map_id, command, router) {
    const url = `/api/companies/${map_id}`;
    return this.CommonAPIService.putCall(url, command, router);
  }

  deleteCompany(id, router) {
    const url = `/api/companies/${id}`;
    return this.CommonAPIService.deleteCall(url, null, router);
  }

  getCompanies(router) {
    const url = '/api/companies';
    return this.CommonAPIService.getCall(url, null, router);
  }

  getCompanyById(id, router) {
    const url = `/api/companies/${id}`;
    return this.CommonAPIService.getCall(url, null, router);
  }
}

export default CompaniesAPIService;
