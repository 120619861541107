import CommonAPIService from 'src/servicehandlers/CommonHandler';

const base_url = '/api/script_assignments'

export class ScriptAssignmentsAPIService {
  constructor() {
    this.CommonAPIService = new CommonAPIService();
  }

  create(scriptAssignment, router) {
    return this.CommonAPIService.postCall(base_url, scriptAssignment, router);
  }

  update(script_assignment_id, scriptAssignment, router) {
    const url = `${base_url}/${script_assignment_id}`;
    return this.CommonAPIService.putCall(url, scriptAssignment, router);
  }

  delete(id, router) {
    const url = `${base_url}/${id}`;
    return this.CommonAPIService.deleteCall(url, null, router);
  }

  get(router) {
    return this.CommonAPIService.getCall(base_url, null, router);
  }

  getScriptByNameAndHostId(command_name, host_id, router) {
    const url = `${base_url}/${host_id}/${command_name}`;
    return this.CommonAPIService.getCall(url, null, router);
  }
}

export default ScriptAssignmentsAPIService;
