<template>
  <div>
    <card v-if="spinning">
      <div class="row">
        <div class="col-sm-12 text-center">
          <b-spinner variant="primary" label="Spinning"></b-spinner>
        </div>
      </div>
    </card>
    <card>
      <div slot="header">
        <h4 class="card-title">Scripts
          <el-tooltip content="Add New Script">
            <el-button type="text" @click="openNewModal">
              <i class="fa-solid fa-circle-plus"></i>
            </el-button>
          </el-tooltip>
        </h4>
      </div>
      <el-table :data="scripts" border style="width: 100%">
        <el-table-column prop="name" label="Name" width="200"></el-table-column>
        <el-table-column prop="description" label="Description" width="300"></el-table-column>
        <el-table-column prop="operatingSystemName" label="Operating System" width="200"></el-table-column>
        <el-table-column prop="arch" label="Architecture" width="120"></el-table-column>
        <el-table-column prop="scriptTypeName" label="Script Type" width="200"></el-table-column>
        <el-table-column label="Actions" width="200" align="center">
          <template slot-scope="scope">
            <link
              rel="stylesheet"
              href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.0.0/css/all.min.css">
            <div class="center-content">
              <el-tooltip content="Update Script">
                <el-button type="text" @click="openUpdateModal(scope.row, scope.$index)">
                  <i class="fa-regular fa-floppy-disk"></i>
                </el-button>
              </el-tooltip>
              <el-tooltip content="Delete Script">
                <el-button type="text" @click="deleteScript(scope.$index)">
                  <el-icon name="delete" class="icon-large icon-red"></el-icon>
                </el-button>
              </el-tooltip>
            </div>
          </template>
        </el-table-column>
      </el-table>
    </card>
    <el-dialog
      center
      title="Error"
      :visible.sync="modals.error">
      <div class="text-center">
        <span>Error: {{ error_txt }}</span>
        <div class="text-center mt-4">
        <span slot="footer">
          <l-button type="btn btn-danger" round @click="closeModal('error')">OK</l-button>&nbsp;
        </span>
        </div>
      </div>
    </el-dialog>
    <el-dialog
      center
      title="Success"
      :visible.sync="modals.success">
      <div class="text-center">
        <span>{{ success_txt }}</span>
        <div class="text-center">
        <span slot="footer">
          <l-button type="btn btn-success" round @click="closeModal('success')">OK</l-button>&nbsp;
        </span>
        </div>
      </div>
    </el-dialog>
    <el-dialog :visible.sync="modals.add_or_update" width="59%">
      <div class="text-center">
        <h3 style="margin-top: -20px;">Add or Update Script</h3>
      </div>
      <div class="text-center">
        <div class="row my-5">
          <div class="col-sm-4">
            <label>Name:
              <el-input
                style="width: 300px"
                v-model="currentScript.name"
                placeholder="Enter name">
              </el-input>
            </label>
          </div>
          <div class="col-sm-4">
            <label>Description:
              <el-input
                style="width: 300px"
                v-model="currentScript.description"
                placeholder="Enter description">
              </el-input>
            </label>
          </div>
          <div class="col-sm-4">
            <label>Operating System:
              <el-select
                v-model="currentScript.operating_system_id"
                filterable
                placeholder="Operating System"
                style="width: 300px"
              >
                <el-option
                  v-for="operating_system in this.operatingSystems"
                  :key="operating_system.id"
                  :label="operating_system.friendly_name"
                  :value="operating_system.id"
                >
                  {{operating_system.friendly_name}}
                </el-option>
              </el-select>
            </label>
          </div>
        </div>
      </div>
      <div class="text-center">
        <div class="row">
          <div class="col-sm-4">
            <label>Architecture:
              <el-select
                style="width: 300px"
                v-model="currentScript.arch"
                placeholder="Arch"
                id="arch"
              >
                <el-option value="64-bit">64-bit</el-option>
                <el-option value="32-bit">32-bit</el-option>
                <el-option value="Both">Both</el-option>
              </el-select>
            </label>
          </div>
          <div class="col-sm-4">
            <label>Script Type:
              <el-select
                v-model="currentScript.script_type_id"
                filterable
                placeholder="Script Type"
                style="width: 300px"
              >
                <el-option
                  v-for="scriptType in this.scriptTypes"
                  :key="scriptType.id"
                  :label="scriptType.command_name"
                  :value="scriptType.id"
                >
                  {{scriptType.name}}
                </el-option>
              </el-select>
            </label>
          </div>
          <div class="col-sm-4">
            <label>Script File:
              <input type="file" ref="fileInput" style="width: 300px; margin-top: 5px" />
            </label>
          </div>
        </div>
      </div>
      <div class="text-center mt-5">
        <span slot="footer" class="dialog-footer">
          <l-button round type="btn btn-primary" class="mr-1" @click="addOrUpdateScript">Save</l-button>
          <l-button round type="btn btn-danger" @click="closeModal('add_or_update')">Cancel</l-button>
        </span>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import ScriptsAPIService from "src/servicehandlers/ScriptsAPIService";
const scriptsAPIService = new ScriptsAPIService();
import ScriptTypesAPIService from "src/servicehandlers/ScriptTypesAPIService";
const scriptTypesAPIService = new ScriptTypesAPIService();
import OperatingSystemAPIService from "src/servicehandlers/OperatingSystemAPIService";
const operatingSystemAPIService = new OperatingSystemAPIService();
import {Button, Dialog, Icon, Input, Option, Select, Table, TableColumn, Tooltip} from 'element-ui';

export default {
  name: "Scripts",
  components: {
    [Button.name]: Button,
    [Dialog.name]: Dialog,
    [Icon.name]: Icon,
    [Input.name]: Input,
    [Option.name]: Option,
    [Select.name]: Select,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Tooltip.name]: Tooltip,
  },
  data() {
    return {
      spinning: false,
      scripts: [],
      scriptTypes: [],
      operatingSystems: [],
      error_txt: false,
      success_txt: false,
      currentScriptIndex: null,
      currentScript: {
        name: "",
        description: "",
        arch: "64-bit",
        operating_system_id: null,
        script_type_id: null,
      },
      modals: {
        error: false,
        success: false,
        add_or_update: false,
      },
    }
  },
  async mounted() {
    await this.getScriptTypes();
    await this.getOperatingSystems();
    await this.getScripts();
  },
  methods: {
    async getScriptTypes() {
      return await scriptTypesAPIService.get(this.$router)
        .then((response) => {
          this.scriptTypes = response;
        })
        .catch((error) => {
          this.handleError('Error getting script types! ' + error);
        });
    },
    async getOperatingSystems() {
      return await operatingSystemAPIService.get(this.$router)
        .then((response) => {
          this.operatingSystems = response;
        })
        .catch((error) => {
          this.handleError('Error getting operating systems! ' + error);
        })
    },
    getOperatingSystemName (operating_system_id) {
      const operatingSystem = this.operatingSystems.find(({id}) => id === operating_system_id);
      return operatingSystem.friendly_name;
    },
    getScriptTypeName (script_type_id) {
      const scriptType = this.scriptTypes.find(({id}) => id === script_type_id)
      return scriptType.command_name;
    },
    async getScripts() {
      this.scripts = []
      return await scriptsAPIService.get(this.$router)
        .then((response) => {
          response.forEach((script) => {
            const updatedScript = {
              ...script,
              operatingSystemName: this.getOperatingSystemName(script.operating_system_id),
              scriptTypeName: this.getScriptTypeName(script.script_type_id),
            }
            this.scripts.push(updatedScript);
          })
        })
        .catch((error) => {
          this.handleError('Error getting scripts! ' + error);
        });
    },
    openNewModal() {
      this.currentScript = {
        name: "",
        description: "",
        arch: "64-bit",
        operating_system_id: null,
        script_type_id: null,
      };
      if (this.$refs.fileInput?.files?.length) {
        this.$refs.fileInput.value = '';
      }
      this.currentScriptIndex = null;
      this.openModal('add_or_update');
    },
    openUpdateModal(row, index) {
      const script = this.scripts[index];
      if (!script) {
        return;
      }
      if (this.$refs.fileInput?.files?.length) {
        this.$refs.fileInput.value = '';
      }
      this.currentScript = {
        name: row.name,
        description: row.description,
        arch: row.arch,
        operating_system_id: row.operating_system_id,
        script_type_id: row.script_type_id,
      };
      this.currentScriptIndex = index;
      this.openModal('add_or_update');
    },
    async addOrUpdateScript() {
      const scriptData = {
        ...this.currentScript,
        file: this.$refs.fileInput.files[0],
      }
      if (this.currentScriptIndex !== null) {
        try {
          const scriptId = this.scripts[this.currentScriptIndex].id;
          await scriptsAPIService.update(scriptId, scriptData);
        } catch (e) {
          this.handleError('Failure to update script');
          this.closeModal('add_or_update');
          return;
        }
      } else {
        try {
          await scriptsAPIService.create(scriptData);
        } catch (e) {
          this.handleError('Failure to add script');
          this.closeModal('add_or_update');
          return;
        }
      }
      setTimeout(async () => {
        await this.getScripts();
        this.closeModal('add_or_update');
      }, 50); // Allow create/update call to finish before getting updated list of scripts
    },
    deleteScript(index) {
      const scriptId = this.scripts[index].id;
      return scriptsAPIService.delete(scriptId, this.$router)
        .then(() => {
          this.scripts.splice(index, 1);
          this.handleSuccess('Successfully deleted script type');
        })
        .catch((error) => {
          this.handleError('Error deleting script type: ' + error);
        });
    },
    openModal(name) {
      this.modals[name] = true
    },
    closeModal(name) {
      this.modals[name] = false;
    },
    handleSuccess(text) {
      this.success_txt = text;
      this.openModal('success');
      this.spinning = false;
      setTimeout(() => {
        this.modals['success'] = false;
      }, 1500); // Hide after 1.5 secs
    },
    handleError(error) {
      this.error_txt = error;
      this.openModal('error');
      this.spinning = false;
      setTimeout(() => {
        this.modals['error'] = false;
      }, 1500); // Hide after 1.5 secs
    },
  }
}
</script>

<style scoped>

</style>
