<template>
<div>
  <card v-if="spinning">
    <div class="row">
      <div class="col-sm-12 text-center">
        <b-spinner variant="primary" label="Spinning"></b-spinner>
      </div>
    </div>
  </card>
  <card>
    <div slot="header">
      <h4 class="card-title">Host Groups
        <el-tooltip content="Add New Host Group">
          <el-button type="text" @click="createHostgroup('create')">
            <i class="fa-solid fa-circle-plus"></i>
          </el-button>
        </el-tooltip>
      </h4>
    </div>
    <div class="table-responsive table-full-width" >
      <el-table class="table-striped" :data="host_groups">
        <el-table-column label="Host Group" property="name" width="350">
          <template v-slot="{ row }">
            <fg-input placeholder="Hostgroup Name" v-model="row.name"></fg-input>
          </template>
        </el-table-column>
        <el-table-column label="Host Alias" property="alias" width="350">
          <template v-slot="{ row }">
            <fg-input placeholder="Hostgroup Alias" v-model="row.alias"></fg-input>
          </template>
        </el-table-column>
        <el-table-column label="Assign Hosts" width="250" align="center">
            <template v-slot="{ row }">
              <el-tooltip content="Assign Hosts">
                <div class="center-content">
                <el-button type="text" @click="openAssignHostDialog(row)">
                  <el-icon name="user" class="icon-large icon-green"></el-icon>
                </el-button>
                </div>
              </el-tooltip>
            </template>
        </el-table-column>
        <el-table-column label="Actions" width="200" align="center">
          <template v-slot="{ row }">
            <div class="center-content">
            <link
              rel="stylesheet"
              href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.0.0/css/all.min.css">
            <el-tooltip content="Update Host Group">
              <el-button type="text" @click="updateHostgroup(row)">
                <i class="fa-regular fa-floppy-disk"></i>
              </el-button>
            </el-tooltip>
            <el-tooltip content="Delete Host Group">
              <el-button type="text" @click="deleteHostgroup(row)">
                <el-icon name="delete" class="icon-large icon-red"></el-icon>
              </el-button>
            </el-tooltip>
            </div>
          </template>
        </el-table-column>
      </el-table>
    </div>
  </card>
  <el-dialog
    center
    title="Error"
    :visible.sync="modals.error">
    <div class="text-center">
      <span>Error: {{ error_txt }}</span>
      <br>
      <span slot="footer" class="dialog-footer">
        <l-button type="btn btn-danger" round @click="closeModal('error')">OK</l-button>&nbsp;
      </span>
    </div>
  </el-dialog>
  <el-dialog
    center
    title="Success"
    :visible.sync="modals.success">
    <div class="text-center">
      <span>{{ success_txt }}</span>
      <div class="text-center mt-4">
        <span slot="footer" class="dialog-footer">
          <l-button type="btn btn-success" round @click="closeModal('success')">OK</l-button>&nbsp;
        </span>
      </div>
    </div>
  </el-dialog>
  <el-dialog
    center
    :visible.sync="modals.create">
    <div class="row">
      <div class="col-sm-12 text-center">
        <div class="card-title mb-4">
          Add a New Host Group
        </div>
      </div>
    </div>
    <div class="text-center">
      <card>
        <div class="row">
          <div class="card-body d-flex justify-content-center align-items-center">
            <div class="col-sm-6">
              <fg-input label="Hostgroup Name" v-model="name" placeholder="Hostgroup Name"></fg-input>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="card-body d-flex justify-content-center">
            <div class="col-sm-6 mb-2">
              <fg-input label="Hostgroup Alias" v-model="alias" placeholder="Hostgroup Alias"></fg-input>
            </div>
          </div>
        </div>
      </card>
      <br>
      <span slot="footer" class="dialog-footer">
<l-button type="primary" round @click="saveHostgroup('create')">Save</l-button>&nbsp;
<l-button type="danger" round @click="closeModal('create')">Cancel</l-button>&nbsp;
</span>
    </div>
  </el-dialog>
  <el-dialog
    center
    width="59%"
    :visible.sync="modals.assign_hosts">
    <div class="text-center">
      <div class="row">
        <div class="col-sm-12 mb-3">
          <h3 style="margin-top: -20px;">Assign Hosts to {{ selectedHostGroup ? selectedHostGroup.name : '' }}</h3>
        </div>
      </div>
    </div>
    <div class="ml-5">
      <el-transfer
        v-model="selectedHosts"
        :data="hosts"
        :props="{
            key: 'id',
            label: 'name',
          }"
        :titles="['Available Hosts', 'Selected Hosts']"
        filterable
        filter-placeholder="Search check"
      />
    </div>
    <span slot="footer" class="dialog-footer">
      <l-button class="mr-1" round type="primary" @click="assignHostsToGroup">Save</l-button>
      <l-button round type="danger" @click="closeModal('assign_hosts')">Cancel</l-button>
    </span>
  </el-dialog>
</div>
</template>
<script>

import { Dialog } from 'element-ui';
import { Table, TableColumn, Icon, Tooltip, Button, Transfer } from 'element-ui';
import HostGroupsAPIService from "src/servicehandlers/HostGroupsAPIService";
const hostGroupsAPIService = new HostGroupsAPIService();
import HostsAPIService from "src/servicehandlers/HostsAPIService";
const hostsAPIService = new HostsAPIService();

export default {
  data() {
    return {
      spinning: false,
      success_txt: null,
      error_txt: null,
      name: null,
      host_groups: [],
      hosts: [],
      selectedHostGroup: null,
      selectedHosts: [],
      alias: null,
      modals: {
        error: false,
        success: false,
        create: false,
        assign_hosts: false,
      },
    }
  },
  mounted() {
    this.getHostgroups();
    this.getHosts();
  },
  components: {
    [Dialog.name]: Dialog,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Icon.name]: Icon,
    [Tooltip.name]: Tooltip,
    [Button.name]: Button,
    [Transfer.name]: Transfer,
  },
  name: "HostGroups",
  methods: {
    getHosts() {
      return hostsAPIService.get(this.$router)
      .then((response) => {
        this.hosts = response;
      })
      .catch((error) => {
        const self = this;
        this.error_txt = 'Failed to get hosts: ' + error;
        this.openModal('error');
        this.spinning = false;
        setTimeout(function () {
          self.modals['error'] = false; // Use your variable name
        }, 1500); // Hide after 5 secs
      });
    },
    createHostgroup(name) {
      this.openModal(name);
    },
    saveHostgroup(name) {
      this.closeModal(name);
      const newHostgroup = {
        name: this.name,
        alias: this.alias,
      };
      return hostGroupsAPIService.create(newHostgroup, this.$router)
      .then(() => {
        this.name = null;
        this.alias = null;
        const self = this;
        this.success_txt = 'Successfully created host group';
        this.openModal('success');
        this.spinning = false;
        setTimeout(function () {
          self.modals['success'] = false; // Use your variable name
        }, 1500); // Hide after 5 secs
        this.getHostgroups();
      })
      .catch((error) => {
        const self = this;
        this.error_txt = 'Failed to save host group: ' + error;
        this.openModal('error');
        this.spinning = false;
        setTimeout(function () {
          self.modals['error'] = false; // Use your variable name
        }, 1500); // Hide after 5 secs
      })
    },
    deleteHostgroup(hostgroup) {
      return hostGroupsAPIService.delete(hostgroup.id, this.$router)
      .then(() => {
        const self = this;
        this.success_txt = 'Successfully deleted host group';
        this.openModal('success');
        this.spinning = false;
        setTimeout(function () {
          self.modals['success'] = false; // Use your variable name
        }, 1500); // Hide after 5 secs
        this.getHostgroups();
      })
      .catch((error) => {
        const self = this;
        this.error_txt = 'Failed to get host groups: ' + error;
        this.openModal('error');
        this.spinning = false;
        setTimeout(function () {
          self.modals['error'] = false; // Use your variable name
        }, 1500); // Hide after 5 secs
      });
    },
    updateHostgroup(hostgroup) {
      return hostGroupsAPIService.update(hostgroup.id, hostgroup, this.$router)
      .then(() => {
        const self = this;
        this.success_txt = 'Successfully updated host group';
        this.openModal('success');
        this.spinning = false;
        setTimeout(function () {
          self.modals['success'] = false; // Use your variable name
        }, 1500); // Hide after 5 secs
        this.getHostgroups();
      })
      .catch((error) => {
        const self = this;
        this.error_txt = 'Failed to get host groups: ' + error;
        this.openModal('error');
        this.spinning = false;
        setTimeout(function () {
          self.modals['error'] = false; // Use your variable name
        }, 1500); // Hide after 5 secs
      });
    },
    getHostgroups() {
      return hostGroupsAPIService.getHostgroupsWithHosts(this.$router)
      .then((response) => {
        this.host_groups = response;
      })
      .catch((error) => {
        const self = this;
        this.error_txt = 'Failed to get host groups: ' + error;
        this.openModal('error');
        this.spinning = false;
        setTimeout(function () {
          self.modals['error'] = false; // Use your variable name
        }, 1500); // Hide after 5 secs
      });
    },
    openModal(name) {
      this.modals[name] = true
    },
    closeModal(name) {
      this.modals[name] = false;
    },
    openAssignHostDialog(row) {
      // get a list of hosts that are associated with the group
      this.selectedHosts = [];
      this.selectedHostGroup = row;
      for (const host of row.hosts) {
        this.selectedHosts.push(host.id);
      }
      this.modals.assign_hosts = true;
    },
    assignHostsToGroup() {
      const hosts = {
        hosts: this.selectedHosts
      };
      if (!this.selectedHostGroup || !this.selectedHosts.length) return;
      // assign the hosts to the hostgroup, clear the selectedHosts array
      return hostGroupsAPIService.addHostsToHostgroup(this.selectedHostGroup.id, hosts, this.$router)
      .then(() => {
        this.selectedHosts = [];
        this.closeModal('assign_hosts');
        const self = this;
        this.success_txt = 'Successfully assigned hosts to host group';
        this.openModal('success');
        this.spinning = false;
        setTimeout(function () {
          self.modals['success'] = false; // Use your variable name
        }, 1500); // Hide after 5 secs
        this.getHostgroups();
      })
      .catch((error) => {
        this.selectedHosts = [];
        this.closeModal('assign_hosts');
      });

    },
  }
}
</script>

<style scoped>
.fa-circle-plus {
  color: mediumseagreen;
  font-size: 25px;
}
.card-title {
  font-size: 25px;
  color: dimgrey;
}
.center-content {
  display: flex;
  align-items: center;
  justify-content: center;
}
.fa-floppy-disk {
  font-size: 19px;
  color: dodgerblue;
}
</style>
