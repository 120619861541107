import CommonAPIService from './CommonHandler';

export class ChecksAssignmentsAPIService {
  constructor() {
    this.CommonAPIService = new CommonAPIService();
  }
  create(check, router) {
    const url = '/api/checks_to_hosts';
    return this.CommonAPIService.postCall(url, check, router);
  }
  delete(id, router) {
    const url = `/api/checks_to_hosts/${id}`;
    return this.CommonAPIService.deleteCall(url, null, router);
  }
  update(id, check, router) {
    const url = `/api/checks_to_hosts/${id}`;
    return this.CommonAPIService.putCall(url, check, router);
  }
  get(router) {
    const url = '/api/checks_to_hosts';
    return this.CommonAPIService.getCall(url, null, router);
  }
  linkCheckToHost(check_id, host_id, router) {
    const url = `/api/checks_to_hosts/${check_id}/${host_id}/link`;
    return this.CommonAPIService.getCall(url, null, router);
  }
  unlinkCheckToHost(check_id, host_id, router) {
    const url = `/api/checks_to_hosts/${check_id}/${host_id}/unlink`;
    return this.CommonAPIService.getCall(url, null, router);
  }
}

export default ChecksAssignmentsAPIService;
