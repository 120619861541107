import CommonAPIService from 'src/servicehandlers/CommonHandler';

const base_url = '/api/scripts'

export class ScriptsAPIService {
  constructor() {
    this.CommonAPIService = new CommonAPIService();
  }

  create(script) {
    return this.CommonAPIService.newFileCall(base_url, script);
  }

  update(script_id, script) {
    const url = `${base_url}/${script_id}`;
    return this.CommonAPIService.newFileCall(url, script);
  }

  delete(id, router) {
    const url = `${base_url}/${id}`;
    return this.CommonAPIService.deleteCall(url, null, router);
  }

  get(router) {
    return this.CommonAPIService.getCall(base_url, null, router);
  }

  createScriptAssignment(script_assignment, router) {
    const url = `${base_url}/script_assignment`;
    return this.CommonAPIService.postCall(url, script_assignment, router);
  }
  updateScriptAssignment(script_assignment_id, script_assignment, router) {
    const url = `${base_url}/${script_assignment_id}/script_assignment`;
    return this.CommonAPIService.putCall(url, script_assignment, router);
  }
  deleteScriptAssignment(script_assignment_id, script_assignment, router) {
    const url = `${base_url}/${script_assignment_id}/script_assignment`;
    return this.CommonAPIService.deleteCall(url, script_assignment, router);
  }

  assignScriptToHost(host_id, script, router) {
    const url = `${base_url}/${host_id}/script`;
    return this.CommonAPIService.postCall(url, script, router);
  }
  getAssignedScriptsForHost(host_id, router) {
    const url = `${base_url}/${host_id}/script`;
    return this.CommonAPIService.getCall(url, null, router);
  }
}

export default ScriptsAPIService;
