import CommonAPIService from 'src/servicehandlers/CommonHandler';

export class CommandsMapAPIService {
  constructor() {
    this.CommonAPIService = new CommonAPIService();
  }

  createCommandMap(command, router) {
    const url = '/api/command_lookup';
    return this.CommonAPIService.postCall(url, command, router);
  }

  updateCommandMap(map_id, command, router) {
    const url = `/api/command_lookup/${map_id}`;
    return this.CommonAPIService.putCall(url, command, router);
  }

  deleteCommandMap(id, router) {
    const url = `/api/command_lookup/${id}`;
    return this.CommonAPIService.deleteCall(url, null, router);
  }

  getCommandMaps(router) {
    const url = '/api/command_lookup';
    return this.CommonAPIService.getCall(url, null, router);
  }

  getCommandMapById(id, router) {
    const url = `/api/command_lookup/${id}`;
    return this.CommonAPIService.getCall(url, null, router);
  }
}

export default CommandsMapAPIService;
