<template>
  <div>
    <card>
      <div class="row">
        <div class="col-sm-12">
          <h1 class="header-txt">Check Assignments</h1>
          <h4 class="header-txt">The purpose of this page is to assign the checks to the hosts so they can be monitored.</h4>
        </div>
      </div>
    </card>
    <card>
    <div>
      <div class="row">
        <div class="col-sm-6">
          <label>Filter the search by host: </label>
          <fg-input
            style="width: 350px; margin-bottom: 5px;"
            v-model="searchQuery"
            type="text"
            placeholder="Search by name, alias, or IP"
          />
        </div>
      </div>
      <div class="row">
        <div class="col-sm-6">
          <label class="group-select" for="group-select">Hostgroups: </label>
          <el-select
            class="wide-select group-select mb-2"
            id="group-select"
            v-model="selectedHostGroups"
            multiple
            filterable
            placeholder="Select Host Group"
            @change="handleHostGroupChange">
            <el-option
              v-for="group in hostGroups"
              :key="group.id"
              :label="group === 'All' ? 'Select All' : group.name"
              :value="group.id">
            </el-option>
          </el-select>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-6">
          <label class="os-select" for="os-select">Operating Systems: </label>
          <el-select
            class="wide-select os-select mb-2"
            id="os-select"
            v-model="selectedOS"
            multiple
            filterable
            placeholder="Select Operating Systems"
            @change="handleOSChange">
            <el-option
              v-for="os in operating_systems"
              :key="os.id"
              :label="os === 'All' ? 'Select All' : os.friendly_name"
              :value="os.id">
            </el-option>
          </el-select>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-12 mb-4">
        <div>
          <!-- This is the new dropdown at the top of your page -->
          <label class="os-select" for="os-select">Select a check to add/remove to all systems: </label>
        </div>
        <el-select class="check-select" v-model="selectedItemForAll" filterable placeholder="Please select one">
          <el-option v-for="dropdownItem in checks"
                     :key="dropdownItem.id"
                     :label="dropdownItem.text"
                     :value="dropdownItem">
          </el-option>
        </el-select>
        <!-- Apply To All Button -->
        <l-button class="ml-2 mr-1 mt-1" type="success" @click="applyToAll" :disabled="!selectedItemForAll">Apply To All</l-button>

        <!-- Remove From All Button -->
        <l-button class="mt-1" type="danger" @click="removeFromAll" :disabled="!selectedItemForAll">Remove From All</l-button>
      </div>
    </div>

      <el-table border :data="paginatedHosts">
        <el-table-column min-width="200" prop="name" label="Hostname" align="center"></el-table-column>
        <el-table-column label="Operating System" align="center">
          <template slot-scope="scope">
            {{ getOperatingSystemName(scope.row.operating_system_id)}}
          </template>
        </el-table-column>
        <el-table-column label="Assign Checks" align="center">
          <template slot-scope="scope">
            <el-button type="primary" round @click="assignChecks(scope.row)">Assign Checks</el-button>
          </template>
        </el-table-column>
        <el-table-column min-width="200" label="Assigned Checks" align="center">
          <template slot-scope="scope">
            <div v-for="item in scope.row.items" :key="item.id" class="mb-2">
              {{ item.text }}
              <el-button type="text" @click="removeItem(scope.row, item)">
                <el-icon name="delete" class="icon-large icon-red"></el-icon>
              </el-button>
            </div>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="currentPage"
        :page-sizes="pageSizes"
        :page-size="pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="filteredHosts.length"
      >
      </el-pagination>
    </card>
    <el-dialog
      center
      title="Error"
      :visible.sync="modals.error">
      <div class="text-center">
        <span>Error: {{ error_txt }}</span>
        <br>
        <span slot="footer" class="dialog-footer">
<l-button type="btn btn-danger" round @click="closeModal('error')">OK</l-button>&nbsp;
</span>
      </div>
    </el-dialog>
    <el-dialog
      center
      title="Success"
      :visible.sync="modals.success">
      <div class="text-center">
        <span>{{ success_txt }}</span>
        <br>
        <span slot="footer" class="dialog-footer">
<l-button type="btn btn-success" round @click="closeModal('success')">OK</l-button>&nbsp;
</span>
      </div>
    </el-dialog>
    <el-dialog
      width="40%"
      :visible.sync="modals.assign_checks">
      <div class="row">
        <div class="col-sm-12 d-flex justify-content-center">
          <div class="card-title">
            <h3 style="margin-top: -20px;">Assign Checks</h3>
          </div>
        </div>
      </div>
      <div class="text-center">
        <div class="row">
          <div class="col-sm-12">
            <label class="pb-1">Select an item from the dropdown and it will save it to the host, one at a time:</label>
            <el-select id="selectdropdown" style="width: 500px"
              filterable
              v-model="selectedCategory.selected_dropdown_item"
              @change="selectDropdownItem(selectedCategory, selectedCategory.selected_dropdown_item)"
              placeholder="Please select one">
              <el-option v-for="dropdownItem in checks"
                         :key="dropdownItem.id"
                         :label="dropdownItem.description"
                         :value="dropdownItem">
              </el-option>
            </el-select>
          </div>
        </div>
        <br>
        <span slot="footer" class="dialog-footer">
<l-button round type="primary" @click="saveChecks">Save</l-button>&nbsp;
<l-button round type="danger" @click="closeModal('assign_checks')">Cancel</l-button>&nbsp;
</span>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import 'element-ui/lib/theme-chalk/index.css';
import {Message, Transfer, Button, Icon, Table, TableColumn, Pagination, Option, Select, Dialog } from 'element-ui';
import HostsAPIService from "src/servicehandlers/HostsAPIService";
const hostsAPIService = new HostsAPIService();
import OperatingSystemAPIService from "src/servicehandlers/OperatingSystemAPIService";
const operatingSystemAPIService = new OperatingSystemAPIService();
import ChecksAPIService from "src/servicehandlers/ChecksAPIService";
const checksAPIService = new ChecksAPIService();
import PluginsAPIService from "src/servicehandlers/PluginsAPIService";
const pluginsAPIService = new PluginsAPIService();
import ChecksAssignmentsAPIService from "src/servicehandlers/ChecksAssignmentsAPIService";
const checksAssignmentsAPIService = new ChecksAssignmentsAPIService();
import HostGroupsAPIService from "src/servicehandlers/HostGroupsAPIService";
const hostgroupsAPIService = new HostGroupsAPIService();

export default {
  name: "AssignChecksToHosts",
  components: {
    [Transfer.name]: Transfer,
    [Icon.name]: Icon,
    [Button.name]: Button,
    [Pagination.name]: Pagination,
    [Select.name]: Select,
    [Option.name]: Option,
    [Dialog.name]: Dialog,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Message.name]: Message,
  },
  data() {
    return {
      checks: [],
      searchQuery: '',
      selectedCategory: {
        items: [],
      },
      plugins: [],
      selectedItemForAll: null,
      selectedOS: 'All',
      selectedHostGroups: 'All',
      operating_systems: [],
      initialSelectedCheckValues: [],
      selectedChecks: [],
      hosts: [],
      hostGroups: [],
      error_txt: null,
      success_txt: null,
      selected_dropdown_item: null,
      draggedItemIndex: null,
      totalItems: 0,
      currentPage: 1,
      perPage: 50,
      pageSize: 20,
      pageSizes: [20, 50, 100, 200, 500, 1000],
      modals: {
        error: false,
        success: false,
        assign_checks: false,
      },
      categories: []
    }
  },
  watch: {
    filteredHosts: {
      handler(newVal) {
        this.totalItems = newVal.length;
      },
      immediate: true  // to run the handler immediately after the watcher is created
    }
  },
  computed: {
    filteredHosts() {
      let filtered = [...this.categories];

      // Filter by operating system
      if (!this.selectedOS.includes('All') && this.selectedOS.length) {
        filtered = filtered.filter(host => this.selectedOS.includes(host.operating_system_id));
      }


      if (!this.selectedHostGroups.includes('All') && this.selectedHostGroups.length) {
        filtered = filtered.filter(host => {
          // Checking if host_groups exists for the host
          if (!host.host_groups) {
            return false;
          }

          // Extracting the host group IDs from the host's host_groups
          const hostGroupIds = host.host_groups.map(group => group.id);
          // Checking if current host matches any selected host group
          const hasMatchingGroup = hostGroupIds.some(groupId => this.selectedHostGroups.includes(groupId));

          return hasMatchingGroup;
        });
      }

      // Filter by search query
      if (this.searchQuery) {
        const query = this.searchQuery.toLowerCase();
        filtered = filtered.filter(host => {
          return [host.name, host.alias, host.ip_address].some(attr =>
            attr && attr.toLowerCase().includes(query)
          );
        });
      }

      return filtered;
    },
    paginatedHosts() {
      const start = (this.currentPage - 1) * this.pageSize;
      const end = this.currentPage * this.pageSize;
      return this.filteredHosts.slice(start, end);
    },
  },

  async mounted() {
    await this.getChecks();
    await this.getPlugins();
    await this.getHosts();
    await this.buildCategories();
    await this.getOperatingSystems();
    await this.getHostgroups();
  },
  methods: {
    getOperatingSystemName(osId) {
      const os = this.operating_systems.find(system => system.id === osId);
      return os ? os.friendly_name : '';
    },
    getHostgroups() {
      return hostgroupsAPIService.get(this.$router)
      .then((hostgroups) => {
        this.hostGroups = hostgroups;
      })
      .catch((error) => {
        this.handleError('Unable to get hostgroups!: ' + error);
      })
    },
    saveChecks() {
      this.closeModal('assign_checks');
    },
    assignChecks(row) {
      this.selectedCategory = row;
      this.openModal('assign_checks');
    },
    async getOperatingSystems() {
      let os = [];
      try {
        os = await operatingSystemAPIService.get(this.$router);
        this.operating_systems = os;
      } catch (e) {
        this.handleError('Error getting operating systems: ' + JSON.stringify(e));
      }
    },
    async applyToAll() {
      const selectedItem = this.selectedItemForAll;
      if (!selectedItem) return; // exit if no selected item

      for (let category of this.filteredHosts) {
        try {
          // Ensure dropdown_list exists
          if (!category.dropdown_list) {
            category.dropdown_list = [];
          }

          // Attempt to link the selected item to the host in the DB
          await checksAssignmentsAPIService.linkCheckToHost(selectedItem.id, category.id, this.$route);

          // Check if the item is already in the category items, add if not
          const foundInItems = category.items.some(item => item.id === selectedItem.id);
          if (!foundInItems) {
            category.items.push(selectedItem);
          }

          // Remove the item from the dropdown list if it exists there
          const foundInDropdown = category.dropdown_list.some(item => item.id === selectedItem.id);
          if (foundInDropdown) {
            category.dropdown_list = category.dropdown_list.filter(item => item.id !== selectedItem.id);
          }

        } catch (e) {
          this.handleError(e); // Handle error as per your logic
        }
      }

      // Reset the selectedItemForAll after applying to all
      this.selectedItemForAll = null;
    },

    async removeFromAll() {
      const selectedItem = this.selectedItemForAll;
      if (!selectedItem) return; // exit if no selected item

      for (let category of this.filteredHosts) {
        try {
          // Ensure dropdown_list exists
          if (!category.dropdown_list) {
            category.dropdown_list = [];
          }

          // Attempt to unlink the selected item to the host in the DB
          await checksAssignmentsAPIService.unlinkCheckToHost(selectedItem.id, category.id, this.$route);

          // Check if the item is already in the category items, remove if found
          const index = category.items.findIndex(item => item.id === selectedItem.id);
          if (index > -1) {
            category.items.splice(index, 1);

            // Push to dropdown list if not already present
            if (!category.dropdown_list.some(item => item.id === selectedItem.id)) {
              category.dropdown_list.push(selectedItem);
            }
          }
        } catch (e) {
          this.handleError('Failed to unlink check to host'); // Handle error as per your logic

          // If you want to break out of the loop on error, uncomment the line below.
          // break;
        }
      }

      // Reset the selectedItemForAll after removing from all
      this.selectedItemForAll = null;
    },
    async removeItem(category, item) {
      const index = category.items.indexOf(item);
      if (index > -1) {
        // Remove the item from the category items
        category.items.splice(index, 1);

        // Add the removed item back to the dropdown list
        // category.dropdown_list.push(item);

        // Now unlink the item
        try {
          await checksAssignmentsAPIService.unlinkCheckToHost(item.id, category.id, this.$route);
          Message.success("Removed items from host successfully");
        } catch (e) {
          Message.error("Failed to remove check to Host");
          this.handleError('Failed to unlink check to host');
          // In case of an error, re-add the item to the category items and remove it from the dropdown_list
          // category.items.push(item);
          // category.dropdown_list = category.dropdown_list.filter(dropdownItem => dropdownItem.id !== item.id);
        }
      }
    },

    async selectDropdownItem(category, selectedItem = null) {
      const itemToApply = selectedItem || category.selected_dropdown_item;

      // Make sure there is an item to apply before proceeding
      if (!itemToApply) return;

      const selected_id = itemToApply.id;

      // Check if the item is already in the category items
      let found = category.items.some(item => item.id === selected_id);

      if (!found) {
        try {
          // Attempt to link the selected item to the host in the DB
          await checksAssignmentsAPIService.linkCheckToHost(selected_id, category.id, this.$route);

          this.selectedCategory.items.push(selectedItem);
          Message.success("Added Check to Host Success");
          // If successful, add the selected item to the category items
          // category.items.push(itemToApply);

          // Remove the selected item from the dropdown list
          // category.dropdown_list = category.dropdown_list.filter(item => item.id !== selected_id);

        } catch (e) {
          Message.error("Failed to add check to Host");
          this.handleError(e); // A helper method to handle errors, you might replace it with your error handling logic.
          return;
        }
      }

      // Reset the selected dropdown item for the category
      category.selected_dropdown_item = null;
    },

    handleSuccess(text) {
      this.success_txt = text;
      this.openModal('success');
      this.spinning = false;
      setTimeout(() => {
        this.modals['success'] = false;
      }, 1500); // Hide after 1.5 secs
    },
    handleError(error) {
      this.error_txt = error;
      this.openModal('error');
      this.spinning = false;
      setTimeout(() => {
        this.modals['error'] = false;
      }, 1500); // Hide after 1.5 secs
    },
    handleOSChange(values) {
      if (values.includes('All')) {
        if (this.selectedOS.length !== this.operating_systems.length) {
          this.selectedOS = [...this.operating_systems]; // Select all options when "All" is selected and not all options are already selected.
        } else {
          this.selectedOS = []; // Deselect all options when "All" is selected and all options are already selected.
        }
      }
    },
    handleHostGroupChange(values) {
      if (values.includes('All')) {
        if (this.selectedHostGroups.length !== this.hostGroups.length) {
          this.selectedHostGroups = [...this.hostGroups]; // Select all options when "All" is selected and not all options are already selected.
        } else {
          this.selectedHostGroups = []; // Deselect all options when "All" is selected and all options are already selected.
        }
      }
    },
    async buildCategories() {
      for (const host of this.hosts) {
        const constructed_host = {
          "id": host.id,
          "operating_system_id": host.operating_system_id,
          "name": host.name + '-' + host.alias + '(' + host.ip_address + ')',
          "host_groups": [],
          "visible": true,
          "items": [],
          // "dropdown_list": [],
        };
        if (host.host_groups) {
          constructed_host.host_groups = host.host_groups;
        }
        // constructed_host.dropdown_list = [...this.checks];
        const host_checks = host.checks;
        for (const host_check of host_checks) {
          host_check.value = host_check.id;
          host_check.text = host_check.name + ' - ' + host_check.description;
          constructed_host.items.push(host_check);
        }
        this.categories.push(constructed_host);
      }
    },
    handleSizeChange(val) {
      // do nothin
    },
    handleCurrentChange(val) {
      this.currentPage = val;
    },
    async getChecks() {
      return checksAPIService.get(this.router)
      .then((resp) => {
        this.checks = [];
        for (const check of resp) {
          check.key = check.id;
          check.label = check.name;
          check.value = check.id;
          check.text = check.name;
          this.checks.push(check);
        }
      })
      .catch((error) => {
        this.handleError('Failed to get checks: ' + error);
      })
    },
    async getPlugins() {
      return pluginsAPIService.getPlugins(this.router)
      .then((resp) => {
        this.plugins = resp;
      })
      .catch((error) => {
        this.handleError('Failed to get plugins: ' + error);
      })
    },
    async getHosts() {
      return hostsAPIService.getHostsWithChecks(this.router)
      .then((resp) => {
        this.hosts = resp;
      })
      .catch((error) => {
        this.handleError('Failed to get hosts: ', error);
      })
    },
    openModal(name) {
      this.modals[name] = true
    },
    closeModal(name) {
      this.modals[name] = false;
    },
  },
}
</script>

<style scoped>
.list-container {
  display: flex;
  justify-content: space-around;
}
.el-select-dropdown .el-select-dropdown__item:hover {
  color: black !important; /* or any color that suits your design */
}
.list {
  border: 1px solid #ccc;
  padding: 16px;
  width: 40%;
}
.wide-select {
  width: 500px;
}
.icon-large {
  font-size: 20px;
}
.icon-red {
  color: red;
}
.icon-blue {
  color: blue;
}
.icon-green {
  color: green;
}
.header-txt {
  color: dimgrey;
  font-size: 17px;
  margin-top: 0;
  margin-bottom: 0;
}
.txt-1 {
  color: dimgrey;
  font-size: 20px;
}
.os-select {
  //margin-left: 35px;
  width: 350px;
}
.group-select {
  width: 350px;
}
.check-select {
  width: 400px;
  margin-bottom: 0;
  margin-top: 0;
}
</style>
