<template>
<div>
  <card>
    <div class="row">
      <div class="col-sm-12 text-center">
        <h1 class="card-1">AI Superadmin</h1>
        <div class="row">
          <div class="col-sm-12 card-2">
            Welcome to AI Superadmin, I will help you manage, monitor, backup, and work with all your systems.<br>
            It looks like you have nothing to manage yet.<br>
            Let's get to it!<br>
            Go ahead and set up your first system so I can start managing it for you.  Click on "Add New Host" to start creating hosts manually.
          </div>
        </div>
      </div>
    </div>
  </card>
  <card>
    <div class="row">
      <div class="col-sm-12">
        <h2 class="custom-text">Setup Your Hosts</h2>
        <h4 class="custom-text2"><a href="upload.xlsx">Click here</a> to get the latest file version to upload hosts</h4>
        <h4 class="custom-text3 mb-2">Uploading your hosts helps the AI work with your systems and platform</h4>
        <div
          @dragover.prevent
          @dragenter.prevent
          @drop="onFilesSelected"
          class="dropzone"
          :class="{ 'active-dropzone': isActive }"
        >
          <input type="file" class="choose-files" multiple @change="onFilesSelected" ref="fileInput" />
          <h4 class="custom-text2" >Drag & drop your files or <span @click="selectFiles">click here</span> to upload.
          </h4>
        </div>
      </div>
    </div>
  </card>
  <card>
    <div slot="header">
      <h4 class="card-title">Hosts
        <el-tooltip content="Add New Host">
          <el-button type="text" @click="openModal('create')">
            <i class="fa-solid fa-circle-plus"></i>
          </el-button>
        </el-tooltip>
      <p class="card-category">Displays full list of manageable hosts for you system</p>
      </h4>
    </div>
    <!-- Search Input -->
    <div class="search-bar">
    <div class="row pb-5">
    <div class="col-sm-6">
    <el-input
      v-model="searchQuery"
      placeholder="Search..."
      clearable
      @clear="filterData"
      @input="filterData">
      <span slot="prepend">Search</span>
    </el-input>
    </div>
    <el-button @click="searchQuery = ''">Clear</el-button>
    </div>
    </div>
    <div class="table-responsive table-full-width" >
      <el-table class="table-striped" border :data="filteredData">
        <el-table-column label="Hostname" property="name" width="250">
          <template v-slot="{ row }">
            <fg-input placeholder="hostname" v-model="row.name"></fg-input>
          </template>
        </el-table-column>
        <el-table-column label="Alias" property="alias" width="300">
          <template v-slot="{ row }">
            <fg-input placeholder="hostname" v-model="row.alias"></fg-input>
          </template>
        </el-table-column>
        <el-table-column label="IP Address" prop="ip_address" width="200">
          <template v-slot="{ row }">
            <fg-input placeholder="hostname" v-model="row.ip_address"></fg-input>
          </template>
        </el-table-column>
        <el-table-column label="Operating System" property="operating_system_id" width="275">
          <template v-slot="{ row }">
            <select class="form-control" name="os_select" v-model="row.operating_system_id">
              <option v-for="operating_system of operating_systems" :key="operating_system.id" :value="operating_system.id">
                {{ operating_system.name }}
              </option>
            </select>
          </template>
        </el-table-column>
        <el-table-column label="Actions" property="action" width="130" align="center">
          <template slot-scope="scope">
            <div class="center-content">
            <el-tooltip class="mr-2 mt-1 ask-ai" content="Ask AI">
            <el-button type="text" @click="manageHost(scope.row)">AI
            </el-button>
            </el-tooltip>
            <link
              rel="stylesheet"
              href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.0.0/css/all.min.css">
            <el-tooltip content="Update Host">
              <el-button type="text" @click="updateHost(scope.row)">
                <i class="fa-regular fa-floppy-disk"></i>
              </el-button>
            </el-tooltip>
            <el-tooltip content="Delete Host">
            <el-button  type="text" @click="deleteHost(scope.row.id)">
              <el-icon name="delete" class="icon-large icon-red"></el-icon>
            </el-button>
            </el-tooltip>
            </div>
          </template>
        </el-table-column>
      </el-table>
    </div>
  </card>
  <el-dialog
    center
    width="80%"
    :title="'AI Command Center for Host: ' + currentHost + ' - ' + currentIP + ' - ' + currentAlias"
    :visible.sync="modals.ai">
    <div class="text-center">
      <div class="row">
        <div class="col-sm-12">
          <p style="margin-top: -20px;">Please select from the buttons below, or type in what you would like done:</p>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-12">
          <card>
            <div class="chat-component">
              <div class="chat-display" ref="chatDisplay">
                <div v-for="message in items" :key="message.id" :class="['message', message.type]">
                  <span v-if="message.type === 'user'">You: </span>
                  <span v-else-if="message.type === 'bot'">AI Super Admin: </span>
                  <div v-else-if="message.type === 'loading'">
                    <b-spinner small></b-spinner> {{ message.text }}
                  </div>
                  <span v-if="message.type !== 'loading'">{{ message.text }}</span>
                </div>
              </div>
              <input v-model="userInput" @keyup.enter="addItem" placeholder="Type a message..." />
            </div>
            <el-button type="primary" @click="clear">Clear</el-button>
          </card>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-4">
          <el-button style="background-color: #BBBBBB;" @click="userInput += 'restart service <SERVICE_HERE> on ' + currentHost">Restart Service</el-button>
        </div>
        <div class="col-sm-4">
          <el-button style="background-color: #BBBBBB;" @click="sendTextThenAddItem('reboot the server ' + currentHost)">Reboot the server</el-button>
        </div>
        <div class="col-sm-4">
          <el-button style="background-color: #BBBBBB;" @click="sendTextThenAddItem('install a program on ' + currentHost)">Install a program</el-button>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-4 mt-3 mb-4">
          <el-button style="background-color: #BBBBBB;" @click="sendTextThenAddItem('install custom nagios monitoring on ' + currentHost)">Install custom monitoring software</el-button>
        </div>
        <div class="col-sm-4 mt-3 mb-4">
          <el-button style="background-color: #BBBBBB;" @click="sendTextThenAddItem('patch the server ' + currentHost)">Run patch updates</el-button>
        </div>
        <div class="col-sm-4 mt-3 mb-4">
          <el-button style="background-color: #BBBBBB;" @click="sendTextThenAddItem('backup the server ' + currentHost)">Run backups</el-button>
        </div>
      </div>
      <card>
        <div class="row">
          <div class="col-sm-12">
            <div slot="header">
              <h4 class="card-title">Current Running Jobs</h4>
              <p class="card-category mb-2">Click on each job to view more about it</p>
            </div>
            <el-table class="table-striped" border :data="hostJobs">
              <el-table-column label="AI Command" property="command_string"></el-table-column>
              <el-table-column label="System Command" property="client_action"></el-table-column>
              <el-table-column label="Needs Authentication" property="needs_auth"></el-table-column>
            </el-table>
          </div>
        </div>
      </card>
      <card>
        <div class="row">
          <div class="col-sm-12">
            <div slot="header">
              <h4 class="card-title">Old Jobs</h4>
              <p class="card-category mb-2">Click on each job to view more about it</p>
            </div>
            <el-table class="table-striped" border :data="hostArchiveJobs">
              <el-table-column label="AI Command" property="command_string"></el-table-column>
              <el-table-column label="System Command" property="client_action"></el-table-column>
              <el-table-column label="Needs Authentication" property="needs_auth"></el-table-column>
            </el-table>
          </div>
        </div>
        <br>
      </card>
      <span slot="footer" class="dialog-footer">
<button class="btn btn-primary" @click="closeModal('ai')">Close</button>&nbsp;
</span>
    </div>
  </el-dialog>
  <el-dialog
    center
    width="50%"
    :visible.sync="modals.create">
    <div class="row">
      <div class="col-sm-12">
        <div class="text-center mb-4">
          <h3 style="margin-top: -20px;">Create A Host</h3>
        </div>
      </div>
    </div>
    <div class="text-center">
      <div class="row">
        <div class="col-sm-6">
          <label>Hostname<br>(Enter the exact machine name)</label>
        </div>
        <div class="col-sm-6">
          <label>Alias<br>(Friendly name of the host.<br>Name it what you want)</label>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-6 d-flex justify-content-center">
          <fg-input type="text" placeholder="Hostname" v-model="hostname"></fg-input>
        </div>
        <div class="col-sm-6 d-flex justify-content-center">
          <fg-input type="text" placeholder="Alias" v-model="alias"></fg-input>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-6">
          <label>IP Address<br>(IPV4 address of host)</label>
        </div>
        <div class="col-sm-6">
          <label>Operating Systems<br>(Choose one from the list below)</label>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-6 d-flex justify-content-center">
          <fg-input type="text" placeholder="IP Address" v-model="ip_address"></fg-input>
        </div>
        <div class="col-sm-6">
          <el-select style="width: 300px;" id="operating_system_selector" v-model="os" filterable placeholder="Operating System">
            <el-option v-for="operating_system in operating_systems"
                       :key="operating_system.id"
                       :label="operating_system.friendly_name"
                       :value="operating_system.id">{{
                operating_system.friendly_name
              }}
            </el-option>
          </el-select>
        </div>
      </div>
    </div>
    <span slot="footer" class="dialog-footer">
        <l-button type="btn btn-primary" class="mr-2" round @click="createHost()">Save</l-button>
        <l-button type="btn btn-danger" round @click="closeModal('create')">Cancel</l-button>
</span>
  </el-dialog>
  <el-dialog
    center
    title="Error"
    :visible.sync="modals.error">
    <div class="text-center">
      <span>Error: {{ error_txt }}</span>
      <div class="text-center mt-4">
        <span slot="footer" class="dialog-footer">
<l-button type="btn btn-danger" round @click="closeModal('error')">OK</l-button>&nbsp;
</span>
      </div>
    </div>
  </el-dialog>
  <el-dialog
    center
    title="Success"
    :visible.sync="modals.success">
    <div class="text-center">
      <span>{{ success_txt }}</span>
      <div class="text-center mt-4">
        <span slot="footer">
<l-button type="btn btn-success" round @click="closeModal('success')">OK</l-button>&nbsp;
</span>
      </div>
    </div>
  </el-dialog>
  <el-dialog
    center
    title="Host Type Creation"
    :visible.sync="modals.host_type">
    <div class="text-center">
      <div class="row">
        <div class="col-sm-12">
          <label>
            Host Type Name: <fg-input v-model="host_type_name" placeholder="Host Type Name"></fg-input>
          </label>
        </div>
      </div>
      <span slot="footer">
<l-button type="primary" @click="createHostType('host_type')">Create Host Type</l-button>&nbsp;
<l-button type="danger" @click="closeModal('host_type')">Cancel</l-button>&nbsp;
</span>
    </div>
  </el-dialog>
</div>
</template>

<script>
import {Select, Option, Icon, Button, Table, TableColumn, Tooltip } from 'element-ui';
import { Dialog } from 'element-ui';
import HostsAPIService from "src/servicehandlers/HostsAPIService";
const hostsAPIService = new HostsAPIService();
import FilesAPIHandler from "src/servicehandlers/FilesAPIHandler";
const filesAPIHandler = new FilesAPIHandler();
import OperatingSystemAPIService from "src/servicehandlers/OperatingSystemAPIService";
const operatingSystemAPIService = new OperatingSystemAPIService();
import RunningCommandsAPIService from "src/servicehandlers/RunningCommandsAPIService";
const runningCommandsAPIService = new RunningCommandsAPIService();
import RunningCommandsArchiveAPIService from "src/servicehandlers/RunningCommandsArchiveAPIService";
const runningCommandsArchiveAPIService = new RunningCommandsArchiveAPIService();
import AIAPIService from "src/servicehandlers/AIAPIService";
const aiAPIService = new AIAPIService();

export default {
  name: "Hosts",
  components: {
    [Select.name]: Select,
    [Option.name]: Option,
    [Button.name]: Button,
    [Icon.name]: Icon,
    [Dialog.name]: Dialog,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Tooltip.name]: Tooltip,
  },
  data() {
    return {
      searchQuery: '',
      filteredData: [], // Data after applying search filter
      userInput: '',
      currentHost: null,
      currentHostID: null,
      currentIP: null,
      itemClass: null,
      currentAlias: null,
      hosts: [],
      items: [],
      inputText: '',
      host_types: [],
      error_txt: null,
      isActive: false,
      selected_hostname: null,
      host: false,
      hostJobs: [],
      hostArchiveJobs: [],
      success_txt: null,
      operating_systems: [],
      hostname: null,
      alias: null,
      os: null,
      host_type_name: null,
      ip_address: null,
      modals: {
        error: false,
        success: false,
        host_type: false,
        create: false,
        ai: false,
      },
    }
  },
  mounted() {
    this.getHostsWithOperatingSystems();
    this.getOperatingSystems();
  },
  methods: {
    clear() {
      this.items = [];
      const beginningMessage = {
        id: this.items.length,
        text: "Go ahead and enter text in to perform a command...",
        type: "bot",
      };
      this.items.push(beginningMessage);
    },
    onDrop(e) {
      this.isActive = false;
      const files = e.dataTransfer.files;
      this.uploadFiles(files);
    },
    onFilesSelected(e) {
      const files = e.target.files;
      this.uploadFile(files);
    },
    selectFiles() {
      this.$refs.fileInput.click();
    },
    uploadFiles(files) {
      if (!files.length) return;

      // Handle your file uploads here.
      // You might use a service like axios to post your files to a server.
    },
    createHostType() {
      this.closeModal('host_type');
      const newHostType = {
        name: this.host_type_name
      };
      return hostsAPIService.createHostType(newHostType)
      .then((response) => {
        const self = this;
        this.success_txt = 'Host type creation success';
        this.spinning = false;
        this.pluginFile = null;
        this.plugin_types = [];
        this.closeModal('host_type');
        this.openModal('success');
        setTimeout(function () {
          self.modals['success'] = false; // Use your variable name
        }, 1500); // Hide after 5 secs
      })
      .catch((error) => {
        const self = this;
        this.error_txt = 'Failed to create new host type' + error;
        this.openModal('error');
        this.spinning = false;
        setTimeout(function () {
          self.modals['error'] = false; // Use your variable name
        }, 1500); // Hide after 5 secs
      })
    },
    scrollToBottom() {
      this.$nextTick(() => {
        const chatDisplay = this.$refs.chatDisplay;
        chatDisplay.scrollTop = chatDisplay.scrollHeight;
      });
    },
    sendTextThenAddItem(text) {
      this.userInput = text;
      this.addItem();
    },
    getItemClass(index) {
      return index % 2 === 0 ? "grey-bg" : "white-bg";
    },
    async addItem() {
      this.scrollToBottom();
      if (!this.userInput.trim()) {
        return;
      }
      this.spinning = true;

      const userMessage = {
        id: this.items.length,
        text: this.userInput,
        type: "user",
      };
      this.items.push(userMessage);
      this.scrollToBottom();

      this.userInput = "";

      const loadingMessage = {
        id: this.items.length,
        text: "Waiting for response, this may take up to two minutes...",
        type: "loading",
      };
      this.items.push(loadingMessage);

      let base_action = null;
      try {
        const response = await aiAPIService.process_sentence(this.user_id, userMessage.text, false, this.$router)
        // Remove loading message once the response is received
        this.items.pop();
        for (const resp of response.data.response) {
          // determine if it's something like text and multiple items
          if (resp && resp.message) {
            // push the bot message up

            if (resp.type === 'ACTION') {
              // add the command to the queue
              base_action = resp.action;
              // does the user have rights to run this command?
              //if (this.store.commandRights.some(item => item.command_name === base_action)) {
              const running_command = {
                command_string: resp.action,
                client_action: resp.action,
                is_running: false,
                needs_auth: 0,
                type: resp.type,
              };
              if (resp.needs_auth === 'True') {
                running_command.needs_auth = 1;
              }
              await runningCommandsAPIService.create(running_command, this.$router)
              .then((resp) => {
              })
              .catch((error) => {
                this.handleError('An error occurred while getting response back from the server: ' + error);
              });
              //  } else {
              //    this.items.push('NOT AUTHORIZED TO RUN COMMAND: ' + base_action);
              //   }
            } else if (resp.type === 'HOST_LOOKUP') {
              // compare rights, throw an error if it isn't correct
              const action = this.parseToObject(resp.action);
            } else if (resp.type === 'MESSAGE') {
              // just a regular message
            } else if (resp.type === 'QUERY') {
              // just a regular message
            } else if (resp.type === 'FAILURE') {
            } else {
              this.handleError('Unknown command!');
            }
            const botMessage = {
              id: this.items.length,
              text: resp.message,
              type: "bot",
            };
            this.items.push(botMessage);
          }
        }
      } catch (error) {
        this.spinning = false;
        if (error.code === 'ECONNABORTED') {
          this.handleError('Connection was aborted!')
        } else {
          this.handleError('There was an error sending the message! ' + error)
        }
      }
      /*
       this.spinning = true;
       const initial_text = this.inputText;

       if (this.inputText) {
       this.items.push('You: ' + this.inputText);
       return aiAPIService.process_sentence(this.user_id, this.inputText, this.$router)
       .then((response) => {
       this.inputText = "";

       this.$nextTick(() => {
       // Scroll to the bottom after Vue's DOM updates
       this.$refs.listContainer.scrollTop = this.$refs.listContainer.scrollHeight;
       });
       this.spinning = false;
       })
       .catch((error) => {
       this.spinning = false;
       this.error_txt = 'An error occurred while getting response back from AI: ' + error;
       this.openModal('error');
       });
       }

       */
    },
    parseToObject(str) {
      try {
        const jsonStr = str.replace(/'/g, '"');
        const obj = JSON.parse(jsonStr);
        return obj;
      } catch (e) {
        return null;
      }
    },
    deleteHost(id) {
      return hostsAPIService.delete(id, this.$router)
      .then(() => {
        const self = this;
        this.success_txt = 'Host Delete Successfully';
        this.spinning = false;
        this.pluginFile = null;
        this.plugin_types = [];
        this.closeModal('host_type');
        this.openModal('success');
        setTimeout(function () {
          self.modals['success'] = false; // Use your variable name
        }, 1500); // Hide after 5 secs
        this.getHostsWithOperatingSystems();
        this.getOperatingSystems();
      })
      .catch((error) => {
        const self = this;
        this.error_txt = 'Failed to get hosts from the DB' + error;
        this.openModal('error');
        this.spinning = false;
        setTimeout(function () {
          self.modals['error'] = false; // Use your variable name
        }, 1500); // Hide after 5 secs
      });
    },
    updateHost(host) {
      return hostsAPIService.update(host.id, host, this.$router)
      .then(() => {
        const self = this;
        this.success_txt = 'Host Updated Successfully';
        this.spinning = false;
        this.pluginFile = null;
        this.plugin_types = [];
        this.closeModal('host_type');
        this.openModal('success');
        setTimeout(function () {
          self.modals['success'] = false; // Use your variable name
        }, 1500); // Hide after 5 secs
        this.getHostsWithOperatingSystems();
      })
      .catch((error) => {
        const self = this;
        this.error_txt = 'Failed to get hosts with their operating systems' + error;
        this.openModal('error');
        this.spinning = false;
        setTimeout(function () {
          self.modals['error'] = false; // Use your variable name
        }, 1500); // Hide after 5 secs
      });
    },
    createHost() {
      this.closeModal('create');
      const host = {
        name: this.hostname,
        operating_system_id: this.os,
        ip_address: this.ip_address,
        alias: this.alias,
      };
      return hostsAPIService.create(host, this.$router)
      .then((host) => {
        const self = this;
        this.success_txt = 'Host created Successfully';
        this.spinning = false;
        this.pluginFile = null;
        this.plugin_types = [];
        this.closeModal('host_type');
        this.openModal('success');
        setTimeout(function () {
          self.modals['success'] = false; // Use your variable name
        }, 1500); // Hide after 5 secs
        this.hostname = null;
        this.operating_system_id = null;
        this.ip_address = null;
        this.alias = null;
        this.getHostsWithOperatingSystems();
      })
      .catch((error) => {
        const self = this;
        this.error_txt = 'Failed to create host' + error;
        this.openModal('error');
        this.spinning = false;
        setTimeout(function () {
          self.modals['error'] = false; // Use your variable name
        }, 1500); // Hide after 5 secs
      });
    },
    getOperatingSystems() {
      return operatingSystemAPIService.get(this.$router)
      .then((operating_systems) => {
        this.operating_systems = operating_systems;
      })
      .catch((error) => {
        const self = this;
        this.error_txt = 'Failed to get operating systems' + error;
        this.openModal('error');
        this.spinning = false;
        setTimeout(function () {
          self.modals['error'] = false; // Use your variable name
        }, 1500); // Hide after 5 secs
      })
    },
    manageHost(host) {
      this.items = [];
      this.currentHost = host.name;
      this.currentIP = host.ip_address;
      this.currentAlias = host.alias;
      this.currentHostID = host.id;
      const beginningMessage = {
        id: this.items.length,
        text: "Use this section to control hosts through the AI",
        type: "bot",
      };
      this.items.push(beginningMessage);
      this.inputText = '';
      return runningCommandsAPIService.getRunningCommandsByHostID(this.currentHostID, this.$router)
      .then((currentJobs) => {
        this.hostJobs = currentJobs;
        return runningCommandsArchiveAPIService.getByHostID(this.currentHostID, this.$router);
      })
      .then((archiveJobs) => {
        this.hostArchiveJobs = archiveJobs;
        this.openModal('ai');
      })
      .catch((error) => {
        const self = this;
        this.error_txt = 'Failed to get host jobs: ' + error;
        this.openModal('error');
        this.spinning = false;
        setTimeout(function () {
          self.modals['error'] = false; // Use your variable name
        }, 1500); // Hide after 5 secs
      })
    },
    filterData() {
      const query = this.searchQuery.toLowerCase();
      this.filteredData = this.hosts.filter(item =>
        item.name.toLowerCase().includes(query) ||
        item.alias.toLowerCase().includes(query) ||
        item.ip_address.toLowerCase().includes(query)
      );
    },
    getHostsWithOperatingSystems() {
      return hostsAPIService.getHostsWithRights(this.$router)
      .then((allhostswithOperatingSystems) => {
        this.hosts = [];
        for (const host of allhostswithOperatingSystems) {
          const newObj = {
            id: host.id,
            name: host.name,
            alias: host.alias,
            ip_address: host.ip_address,
            operating_system_id: host.operating_system_id,
            action: {},
          };
          this.hosts.push(newObj);
        }
        this.filteredData = this.hosts;
      })
      .catch((error) => {
        const self = this;
        this.error_txt = 'Failed to get operating systems' + error;
        this.openModal('error');
        this.spinning = false;
        setTimeout(function () {
          self.modals['error'] = false; // Use your variable name
        }, 1500); // Hide after 5 secs
      })
    },
    openModal(name) {
      this.modals[name] = true
    },
    closeModal(name) {
      this.modals[name] = false;
    },
    async uploadFile() {
      const formData = new FormData();
      formData.append('file', this.$refs.fileInput.files[0]);
      let response = null;
      try {
        response = await filesAPIHandler.upload(formData, this.$router);
        const self = this;
        this.success_txt = 'Upload of file successful';
        this.openModal('success');
        this.spinning = false;
        setTimeout(function () {
          self.modals['success'] = false; // Use your variable name
        }, 1500); // Hide after 5 secs
        this.getHostsWithOperatingSystems();
      } catch (e) {
        this.handleError('Error Uploading the spreadsheet: ' + e);
      }
    },
    handleSuccess(text) {
      this.success_txt = text;
      this.openModal('success');
      this.spinning = false;
      setTimeout(() => {
        this.modals['success'] = false;
      }, 1500); // Hide after 1.5 secs
    },
    handleError(error) {
      this.error_txt = error;
      this.openModal('error');
      this.spinning = false;
      setTimeout(() => {
        this.modals['error'] = false;
      }, 1500); // Hide after 1.5 secs
    },
  },

}
</script>

<style scoped>
.dropzone {
  padding: 20px;
  border: 2px dashed #ccc;
  text-align: center;
  cursor: pointer;
  transition: background-color 0.2s;
}
.dropzone.active-dropzone {
  background-color: #f0f0f0;
}
.chat-component {
  max-width: 800px;
  margin: auto;
  padding: 20px;
}

.chat-display {
  border: 1px solid #ccc;
  padding: 20px;
  margin-bottom: 10px;
  overflow-y: auto;
  max-height: 700px;
}

input {
  width: 100%;
  padding: 10px;
  box-sizing: border-box;
}

.message {
  padding: 10px;
  margin: 5px 0;
  border-bottom: 1px solid #eee; /* Lightly marked border between messages */
}

.user {
  background-color: #fff; /* White background for user message */
}

.bot {
  background-color: #f0f0f0; /* Light grey background for bot message */
}

.list-container {
  max-height: 600px; /* Adjust as needed */
  overflow-y: auto;
  border: 1px solid #cccccc;
}

.grey-bg {
  background-color: grey;
  padding: 5px;
}

.white-bg {
  background-color: white;
  padding: 5px;
}
.fa-compact-disc {
  font-size: 18px;
  rotate: 90deg;
  color: cornflowerblue;
}

.card-title {
  font-size: 25px;
  color: dimgrey;
}
.custom-text {
  color: dimgrey;
  font-size: 20px;
  margin-top: 0;
  margin-bottom: 0;
}
.choose-files {
  font-size: 15px;
  position: absolute;
  left: 5%;
}
.custom-text2 {
  font-size: 17px;
  color: dimgrey;
  margin-top: 10px;
  margin-bottom: 0;
}
.custom-text3 {
  font-size: 17px;
  color: dimgrey;
  margin-top: 0;
  margin-bottom: 0;
}
.center-content {
  display: flex;
  align-items: center;
  justify-content: center;
}
.ask-ai {
  color: mediumseagreen;
}
.card-1 {
  color: dimgrey;
  margin-top: 0;
  font-size: 30px;
}
.card-2 {
  color: dimgrey;
  font-size: 17px;
  margin-bottom: 10px;
}
.fa-circle-plus {
  color: mediumseagreen;
  font-size: 25px;
}
.fa-floppy-disk {
  font-size: 19px;
  color: dodgerblue;
}
</style>
