<template>
  <div>
    <card>
    <h1 class="card-title2">Trap Translation Page</h1>
    <h4 class="card-title2">The purpose of this page is to allow the user to modify and set the traps that they have processed</h4>
    </card>
    <card>
      <div class="row ml-1">
        <h4 class="card-title">SNMP Trap Translation</h4>
      </div>
    <div class="row pb-5 mt-4">
      <div class="col-sm-5">
        <el-input
          v-model="searchQuery"
          placeholder="Search for MIB by name or filename"
          clearable
          @clear="searchQuery = ''">
          <span slot="prepend">Search</span>
        </el-input>
      </div>
        <el-button @click="searchQuery = ''">Clear</el-button>
    </div>
    <div>
      <el-table :data="paginatedMIBS" border @row-click="viewMIB">
        <div class="text-center">
        <el-table-column prop="name" label="MIB Name" width="350"></el-table-column>
        </div>
        <div class="text-center">
        <el-table-column prop="filename" label="Filename" width="350"></el-table-column>
        </div>
        <div class="text-center">
        <el-table-column prop="createdAt" label="Date Created" width="350">
          <template slot-scope="scope">
            {{ formatDate(scope.row.createdAt) }}
          </template>
        </el-table-column>
        </div>
        <el-table-column label="Actions" align="center">
          <template slot-scope="scope">
            <div class="center-content">
            <el-tooltip content="Delete MIB File" placement="top">
              <el-button type="text" @click="deleteMIB(scope.row.id)">
                <el-icon name="delete" class="icon-large icon-red"></el-icon>
              </el-button>
            </el-tooltip>
            </div>
          </template>
        </el-table-column>
      </el-table>
    </div>


      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="currentPage"
        :page-sizes="pageSizes"
        :page-size="pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="filteredMibs.length"
      >
      </el-pagination>


    <el-dialog
      center
      title="Error"
      :visible.sync="modals.error">
      <div class="text-center">
        <span>Error: {{ error_txt }}</span>
        <br>
        <span slot="footer" class="dialog-footer">
<l-button type="btn btn-danger" round @click="closeModal('error')">OK</l-button>&nbsp;
</span>
      </div>
    </el-dialog>
    <el-dialog
      center
      title="Success"
      :visible.sync="modals.success">
      <div class="text-center">
        <span>{{ success_txt }}</span>
        <div class="text-center">
          <span slot="footer">
<l-button type="btn btn-success" round @click="closeModal('success')">OK</l-button>&nbsp;
</span>
        </div>
      </div>
    </el-dialog>
    <el-dialog :visible.sync="modals.are_you_sure" width="30%">
      <span>Are you sure you want to delete this MIB?</span>
      <div class="row">
        <div class="col-sm-12">
          <div class="text-center">
            <span slot="footer" class="dialog-footer">
        <l-button type="primary" round @click="deleteMib">Confirm</l-button>
              <l-button type="btn btn-danger" round @click="modals.are_you_sure = false">Cancel</l-button>
      </span>
          </div>
        </div>
      </div>
    </el-dialog>
    </card>
  </div>
</template>

<script>
import SNMPAPIService from "src/servicehandlers/SNMPAPIService";

const snmpAPIService = new SNMPAPIService();
import {Button, Table, TableColumn, Dialog, Icon, Tooltip, Pagination, } from 'element-ui';

export default {
  name: "SNMPTrapTranslation",
  components: {
    [Button.name]: Button,
    [Dialog.name]: Dialog,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Icon.name]: Icon,
    [Tooltip.name]: Tooltip,
    [Pagination.name]: Pagination,
  },
  data() {
    return {
      searchQuery: '',
      targetMib: null,
      mibs: [],
      error_txt: null,
      success_txt: null,
      currentPage: 1,
      pageSizes: [20, 50, 100, 200, 500, 1000],
      pageSize: 20,
      modals: {
        error: false,
        success: false,
        are_you_sure: false,
      },
    }
  },
  watch: {
    handler(newVal) {
      this.totalItems = newVal.length;
    },
    immediate: true
  },
  computed: {
    filteredMibs() {
      if (!this.searchQuery) return this.mibs;
      const query = this.searchQuery.toLowerCase();

      return this.mibs.filter(mib =>
        mib.name.toLowerCase().includes(query) ||
        mib.filename.toLowerCase().includes(query)
      );
    },
    paginatedMIBS() {
      const start = (this.currentPage - 1) * this.pageSize;
      const end = this.currentPage * this.pageSize;
      return this.filteredMibs.slice(start, end);
    }
  },
  mounted() {
    this.getSNMP();
  },
  methods: {
    formatDate(date) {
      return new Date(date).toISOString().split('T')[0]; // Convert to "YYYY-MM-DD" format
    },
    async deleteMIB(mibId) {
    },
    viewMIB(row) {
      this.$router.push({name: 'TrapViewer', params: {mibId: row.id}});
    },
    openDialog(mib) {
      this.targetMib = mib; // Set the MIB to delete
      this.openModal('are_you_sure'); // Open the dialog
    },
    deleteMib() {
      // Here, perform the deletion logic like calling an API endpoint to delete the MIB.
      this.closeModal('are_you_sure'); // Close the dialog after deleting the MIB
    },
    getSNMP() {
      return snmpAPIService.getProcessedMibs(this.$router)
        .then((response) => {
          this.mibs = response;
        })
        .catch((error) => {
          // console.log('Error: ', error);
        })
    },
    downloadMib(row) {
      // Your logic to download MIB file
    },

    openModal(name) {
      this.modals[name] = true
    },
    closeModal(name) {
      this.modals[name] = false;
    },
    handleSizeChange(val) {
      // console.log(`Each page ${val} lines`);
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      // console.log(`Current page: ${val}`);
    },
  }
}
</script>

<style scoped>
.custom-label {
  font-size: 20px;
  color: dimgrey;
}
.card-title {
  font-size: 25px;
  color: dimgrey;
}
.center-content {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}
.card-title2 {
  color: dimgrey;
  text-align: left;
  font-size: 15px;
  margin-bottom: 0;
  margin-top: 0;
}
</style>
