<template>
<div>
  <card>
    <div class="row">
      <div class="col-sm-12 text-center">
        <h1 class="card-title header-text">AI Completed Commands Page</h1>
      </div>
    </div>
    <el-table class="table-striped" border :data="paginatedQueues">
      <el-table-column label="Command" property="command_string" width="300" :header-align="headerAlignCenter" :align="cellAlignCenter"></el-table-column>
      <el-table-column label="Client Action" property="client_action" width="300" :header-align="headerAlignCenter" :align="cellAlignCenter"></el-table-column>
      <el-table-column label="Results" property="needs_auth" width="200" :header-align="headerAlignCenter" :align="cellAlignCenter">
        <template slot-scope="scope">
          <button class="btn btn-primary" @click="showResult(scope.row.id)">Show Full Result Text</button>
        </template>
      </el-table-column>
      <el-table-column label="Exit Code" property="exit_code" width="100" :header-align="headerAlignCenter" :align="cellAlignCenter"></el-table-column>
      <el-table-column label="Initiator" property="full_name" width="120" :header-align="headerAlignCenter" :align="cellAlignCenter"></el-table-column>
      <el-table-column label="Time" property="createdAt" width="160" :header-align="headerAlignCenter" :align="cellAlignCenter"></el-table-column>
    </el-table>

    <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="currentPage"
      :page-sizes="pageSizes"
      :page-size="pageSize"
      layout="total, sizes, prev, pager, next, jumper"
      :total="filteredQueues.length"
    >
    </el-pagination>

  </card>
  <el-dialog
    center
    title="Error"
    :visible.sync="modals.error">
    <div class="text-center">
      <span>Error: {{ error_txt }}</span>
      <div class="text-center mt-4">
        <span slot="footer">
<l-button type="btn btn-danger" round @click="closeModal('error')">OK</l-button>&nbsp;
</span>
      </div>
    </div>
  </el-dialog>
  <el-dialog
    center
    :visible.sync="modals.result">
    <div class="row">
      <div class="col-sm-12">
        <div class="text-center mb-4">
          <h3 style="margin-top: -20px;">Results:</h3>
        </div>
      </div>
    </div>
    <div class="text-center">
      <span v-html="result_txt"></span>
      <br>
      <span slot="footer" class="dialog-footer">
<l-button class="btn btn-primary" round @click="closeModal('result')">OK</l-button>&nbsp;
</span>
    </div>
  </el-dialog>
  <el-dialog
    center
    title="Success"
    :visible.sync="modals.success">
    <div class="text-center">
      <span>{{ success_txt }}</span>
      <div class="text-center mt-4">
        <span slot="footer">
<l-button type="btn btn-success" round @click="closeModal('success')">OK</l-button>&nbsp;
</span>
      </div>
    </div>
  </el-dialog>
</div>
</template>

<script>
const moment = require('moment');
import { Dialog, Table, TableColumn, Pagination, } from 'element-ui';
import RunningCommandsArchiveAPIService  from "src/servicehandlers/RunningCommandsArchiveAPIService";
const runningCommandsArchiveAPIService = new RunningCommandsArchiveAPIService();


export default {
  name: "ClientCompletedCommands",
  components: {
    [Dialog.name]: Dialog,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Pagination.name]: Pagination,
  },
  data() {
    return {
      queue_string: null,
      headerAlignCenter: 'center',
      cellAlignCenter: 'center',
      queues: [],
      search_commands: null,
      error_txt: null,
      success_txt: null,
      result_txt: null,
      currentPage: 1,
      pageSize: 20,
      pageSizes: [20, 50, 100, 200, 500, 1000],
      modals: {
        error: false,
        result: false,
        success: false,
      },
    }
  },

  watch: {
    filteredQueues: {
      handler(newVal) {
        this.totalItems = newVal.length;
      },
      immediate: true  // to run the handler immediately after the watcher is created
    }
  },

  computed: {
    filteredQueues() {
      let filtered = this.queues;

      if (this.searchTerm) {
        const lowercasedQuery = this.searchTerm.toLowerCase();

        filtered = filtered.filter(queue => {
          const userName = queue.user?.toLowerCase() ?? '';
          const description = queue.description?.toLowerCase() ?? '';

          return userName.includes(lowercasedQuery) ||
            userName.includes(lowercasedQuery)
        });
      }

      return filtered;
    },
    paginatedQueues() {
      const start = (this.currentPage - 1) * this.pageSize;
      const end = this.currentPage * this.pageSize;
      return this.filteredQueues.slice(start, end);
    },
  },

  mounted() {
    this.getQueue();
  },
  methods: {
    searchCommands() {
    },
    getAlternateColorClass(index) {
      return index % 2 === 0 ? "even-row" : "odd-row";
    },
    showResult(id) {
      for (const queue of this.queues) {
        if (id === queue.id) {
          this.result_txt = queue.results;
          if (queue.results) {
            this.result_txt = this.result_txt.replace(/\n/g, "<br />");
          }
          break;
        }
      }
      this.openModal('result');
    },
    openModal(name) {
      this.modals[name] = true
    },
    closeModal(name) {
      this.modals[name] = false;
    },
    beforeDestroy() {
      clearInterval(this.refreshInterval);
    },
    getQueue() {
      this.queues = [];
      return runningCommandsArchiveAPIService.getAll(this.$router)
      .then((queues) => {
        for (const queue of queues) {
          queue.createdAt = moment(queue.createdAt).format('MM-DD-YYYY HH:MM:SS');
          queue.full_name = queue.user.first_name + ' ' + queue.user.last_name;
          this.queues.push(queue);
        }
      })
      .catch((error) => {
        const self = this;
        this.error = 'Failed to get command results' + error;
        this.openModal('error');
        setTimeout(function () {
          self.modals['error'] = false; // Use your variable name
        }, 1500); // Hide after 5 secs
      });
    },
    handleSizeChange(val) {
      // console.log(`Each page ${val} lines`);
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      // console.log(`Current page: ${val}`);
    },
  },
}
</script>

<style scoped>
.even-row {
  background-color: #f2f2f2; /* Replace with your desired color for even rows */
}

.odd-row {
  background-color: #ffffff; /* Replace with your desired color for odd rows */
}
.card-title {
  font-size: 25px;
  margin-bottom: 30px;
  margin-top: 20px;
}
.header-text{
  text-align: center;
  font-size: 25px;
  margin-bottom: 30px;
  margin-top: 20px;
  color: dimgrey;
}
</style>
