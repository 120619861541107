<template>
  <div>
    <card v-if="spinning">
      <div class="row">
        <div class="col-sm-12 text-center">
          <b-spinner variant="primary" label="Spinning"></b-spinner>
        </div>
      </div>
    </card>
    <card>
      <div class="row">
        <div class="col-sm-12 text-center">
          <h3 class="custom-header">Check View Page</h3>
          <h4 class="custom-header-2">This page makes it so you can easily find the checks that are running on the
            system. You can click on any item listed below or you can ask the AI questions about the
            issues.</h4>

          <p class="custom-header-2">Select a button from below to sort through the issues that are happening on the system. You can also click on the "Ask AI" button to further determine the root cause and have
            the
            AI respond with an action.</p>
        </div>
      </div>
    </card>
    <card>
      <div slot="header">
        <h4 class="card-title mb-1">Checks</h4>
        <p class="card-category mb-3">Click to view more data on each check or host</p>
        <!-- Search box -->
        <div class="filter-container mb-1">
          <el-input
            v-model="searchTerm"
            placeholder="Search"
            clearable
          ></el-input>
          <el-button @click="clearAllFilters">Clear All</el-button>
        </div>
          <div class="dropdown-container mb-1">
          <el-select v-model="selectedHostGroups" multiple placeholder="Filter by Host groups">
            <el-option
              v-for="hostgroup in availableHostGroups"
              :key="hostgroup.id"
              :label="hostgroup.name"
              :value="hostgroup.id">
            </el-option>
          </el-select>
          <el-button @click="clearHostGroups">Clear</el-button>
        </div>
        <!-- Operating Systems Multi-Select Box with Clear Button -->
        <div class="dropdown-container">
          <el-select v-model="selectedOS" multiple placeholder="Filter by OS">
            <el-option
              v-for="os in availableOS"
              :key="os.id"
              :label="os.friendly_name"
              :value="os.id">
            </el-option>
          </el-select>
          <el-button @click="clearOperatingSystems">Clear</el-button>
        </div>
        <div class="active-filters">
  <span v-if="selectedCheckName">
    Filtered by Check: {{ selectedCheckName }}
    <el-button icon="el-icon-close" size="mini" @click="selectedCheckName = ''"></el-button>
  </span>

          <span v-if="selectedHostname">
    Filtered by Host: {{ selectedHostname }}
    <el-button icon="el-icon-close" size="mini" @click="selectedHostname = ''"></el-button>
  </span>
        </div>
      </div>
        <div class="card-body">
          <div class="btn-group">
            <ToggleButton :initialActive="critical" @click.native="setFilter('critical')"
                          :activeColor="'#FB404B'" :inactiveColor="'#ffffff'"
                          :button-text="'Critical ' + total_critical_checks "></ToggleButton>
            <ToggleButton :initialActive="warning" @click.native="setFilter('warning')"
                          :activeColor="'#fad533'" :inactiveColor="'#ffffff'"
                          :button-text="'Warning ' + total_warning_checks"></ToggleButton>
            <ToggleButton :initialActive="unknown" @click.native="setFilter('unknown')"
                          :activeColor="'#FFA534'" :inactiveColor="'#ffffff'"
                          :button-text="'Unknown ' + total_unknown_checks "></ToggleButton>
            <ToggleButton :initialActive="ok" @click.native="setFilter('ok')" :activeColor="'#87CB16'"
                          :inactiveColor="'#ffffff'"
                          :button-text="'OK ' + total_ok_checks"></ToggleButton>
            <ToggleButton :initialActive="acknowledged" @click.native="setFilter('acknowledged')"
                          :activeColor="'#447DF7'" :inactiveColor="'#ffffff'"
                          :button-text="'Acknowledged ' + total_acknowledged_checks "></ToggleButton>
          </div>&nbsp;
        </div>
      <div class="table-responsive table-full-width">
        <el-table class="table-striped" :data="paginatedChecks" :row-class-name="rowClassName"
                  :row-style="rowStyle">
          <el-table-column label="Actions" prop="actions" width="120" :header-align="headerAlignCenter" :align="cellAlignCenter">
            <template v-slot="{ row }">
              <div class="row">
                <div class="col-sm-12">
                  <l-button type="success"
                            @click="askAIAboutProblem(row.host_id, row.check_id, row.name, row.msg, row.check_name)">
                    Ask AI
                  </l-button>
                </div>
              </div>
              <div class="row">
                <div class="col-sm-12">
                  <l-button type="success"
                            @click="sendAICommand(row)">
                    AI Fix
                  </l-button>
                </div>
              </div>
            </template>
          </el-table-column>
          <el-table-column label="Hostname" property="name" width="150" :header-align="headerAlignCenter" :align="cellAlignCenter">
            <template v-slot="{ row }">
              <div>
                <span class="clickable-item" @click="setSelectedHostname(row.name)">{{ row.name }}</span>
              </div>
            </template>
          </el-table-column>
          <el-table-column label="Checks" property="check_name" width="150" :header-align="headerAlignCenter" :align="cellAlignCenter">
            <template v-slot="{ row }">
                <span class="clickable-item" @click="setSelectedCheckName(row.check_name)">{{ row.check_name }}</span>
            </template>
          </el-table-column>
          <el-table-column label="Status" prop="exit_code" width="120" :header-align="headerAlignCenter" :align="cellAlignCenter">
            <template v-slot="{ row }">
              <div>
                {{ getColorStatus(row.exit_code, row.is_acknowledged) }}
              </div>
            </template>
          </el-table-column>
          <el-table-column label="Message" property="msg" width="250" :header-align="headerAlignCenter" :align="cellAlignCenter">
            <template v-slot="{ row }">
              <div v-html="row.msg"></div>
            </template>
          </el-table-column>
          <el-table-column label="Time Entered State" prop="time_entered_state" width="200" :header-align="headerAlignCenter" :align="cellAlignCenter"></el-table-column>
          <el-table-column label="Alerting" width="160" :header-align="headerAlignCenter">
            <template v-slot="{ row }">
              <!-- Notifications checkbox with tooltip -->
              <el-tooltip v-if="tooltipTimeout[row.id]" :content="getNotificationStatus(row)"
                          placement="top">
                <el-checkbox name="alerts" v-model="row.notifications_enabled"
                             @change="toggleNotifications(row)">
                  {{ row.notifications_enabled ? 'Disable' : 'Enable' }}<br>Notifications
                </el-checkbox>
              </el-tooltip>

              <!-- Notifications checkbox without tooltip (when tooltip should not be shown) -->
              <el-checkbox
                v-if="!tooltipTimeout[row.id]"
                name="alerts"
                v-model="row.notifications_enabled"
                @change="toggleNotifications(row)"
              >
                {{ row.notifications_enabled ? 'Disable' : 'Enable' }}<br>Notifications
              </el-checkbox>

              <!-- Acknowledge/Unacknowledge checkbox with tooltip -->
              <el-tooltip v-if="tooltipTimeout[row.id]" :content="getAcknowledgmentStatus(row)"
                          placement="top">
                <el-checkbox
                  v-if="row.exit_code !== 0"
                  v-model="row.is_acknowledged"
                  @change="toggleNotification(row)"
                >
                  {{ row.is_acknowledged ? 'Unacknowledge' : 'Acknowledge' }}
                </el-checkbox>
              </el-tooltip>

              <!-- Checkbox without tooltip (when tooltip should not be shown) -->
              <el-checkbox
                v-if="row.exit_code !== 0 && !tooltipTimeout[row.id]"
                v-model="row.is_acknowledged"
                @change="toggleNotification(row)"
              >
                {{ row.is_acknowledged ? 'Unacknowledge' : 'Acknowledge' }}
              </el-checkbox>

            </template>
          </el-table-column>
        </el-table>
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="currentPage"
          :page-sizes="pageSizes"
          :page-size="pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="totalItems">
        </el-pagination>
      </div>
    </card>
    <el-dialog
      center
      title="Error"
      :visible.sync="modals.error">
      <div class="text-center">
        <span>Error: {{ error_txt }}</span>
        <div class="text-center mt-4">
          <span slot="footer" class="dialog-footer">
<l-button type="btn btn-danger" round @click="closeModal('error')">OK</l-button>&nbsp;
</span>
        </div>
      </div>
    </el-dialog>
    <el-dialog
      center
      title="Success"
      :visible.sync="modals.success">
      <div class="text-center">
        <span>{{ success_txt }}</span>
        <div class="text-center mt-4">
          <span slot="footer" class="dialog-footer">
<l-button type="btn btn-success" round @click="closeModal('success')">OK</l-button>&nbsp;
</span>
        </div>
      </div>
    </el-dialog>
    <el-dialog
      center
      :visible.sync="modals.ai">
      <div class="row">
        <div class="col-sm-12">
          <div class="text-center mb-2">
            <h3 style="margin-top: -30px;">AI Command Center</h3>
          </div>
        </div>
      </div>
      <div class="text-center">
        <a-i-interface-condensed :is="currentModal" :initial_text="inputText"></a-i-interface-condensed>
        <span slot="footer" class="dialog-footer">
<button class="btn btn-primary" @click="closeModal('ai')">Close</button>&nbsp;
</span>
      </div>
    </el-dialog>
    <el-dialog
      center
      :visible.sync="modals.fix_ai">
      <div class="row">
        <div class="col-sm-12">
          <div class="text-center mb-2">
            <h3 style="margin-top: -30px;">Use this interface to send a command directly to the host: {{ hostname }}</h3>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-12">
          <fg-input v-model="fix_command" :placeholder="fix_command" type="text" label="AI Command"></fg-input>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-12">
          <el-button @click="runAICommand()">Run AI Command Now</el-button>
        </div>
      </div>
      <div class="text-center">
        <span slot="footer" class="dialog-footer">
<button class="btn btn-primary" @click="closeModal('fix_ai')">Close</button>&nbsp;
</span>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import moment from 'moment';
import ToggleButton from "../../../../components/ToggleButton.vue";
import { Dialog } from 'element-ui';
import {Pagination, Button, Select, Option, Tooltip, Checkbox, Table, TableColumn } from 'element-ui';
import HostsAPIService from "src/servicehandlers/HostsAPIService";
const hostsAPIService = new HostsAPIService();
import ChecksToHostsAPIService from "src/servicehandlers/ChecksToHostsAPIService";

const checksToHostsAPIService = new ChecksToHostsAPIService();
import AIAPIService from "../../../../servicehandlers/AIAPIService";

const aiAPIService = new AIAPIService();
import RunningCommandsAPIService from "../../../../servicehandlers/RunningCommandsAPIService";

const runningCommandsAPIService = new RunningCommandsAPIService();
import HostGroupsAPIService from "src/servicehandlers/HostGroupsAPIService";

const hostGroupsAPIService = new HostGroupsAPIService();
import OperatingSystemAPIService from "src/servicehandlers/OperatingSystemAPIService";

import HostActionQueuesAPIService from "../../../../servicehandlers/HostActionQueuesAPIService";
const hostActionQueuesAPIService = new HostActionQueuesAPIService();

const operatingSystemAPIService = new OperatingSystemAPIService();
import AIInterfaceCondensed from "../AI/AIInterfaceCondensed.vue";

export default {
  components: {
    AIInterfaceCondensed,
    [Pagination.name]: Pagination,
    [Button.name]: Button,
    [Select.name]: Select,
    [Option.name]: Option,
    [Tooltip.name]: Tooltip,
    [Checkbox.name]: Checkbox,
    [Dialog.name]: Dialog,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    ToggleButton
  },
  name: "MainView",

  data() {
    return {
      spinning: false,
      ok_checks: [],
      searchTerm: '',
      hostname: null,
      fix_command: null,
      host_ai: null,
      headerAlignCenter: 'center',
      cellAlignCenter: 'center',
      acknowledged_checks: [],
      critical_checks: [],
      warning_checks: [],
      unknown_checks: [],
      aiButtonTxt: null,
      currentQuestion: '',
      currentCheckID: null,
      currentHostID: null,
      items: [],
      totalPages: 1,
      inputText: '',
      checksButton: true,
      hostsButton: true,
      limit: 50,
      checkTableData: [],
      error_txt: null,
      success_txt: null,
      hosts_active: false,
      checks_active: true,
      ok: false,
      warning: true,
      unknown: true,
      critical: true,
      acknowledged: false,
      timer: 0,
      timerInterval: null,
      checkFilters: {},
      hostFilters: {},
      selectedOS: [],
      availableOS: [],
      pageSize: 50,
      currentPage: 1,
      totalItems: 0,
      pageSizes: [20, 50, 100, 200, 500, 1000],
      selectedHostGroups: [],
      availableHostGroups: [],
      total_ok_hosts: 0,
      selectedCheckName: '',
      selectedHostname: '',
      total_warning_hosts: 0,
      total_critical_hosts: 0,
      total_unknown_hosts: 0,
      total_ok_checks: 0,
      total_warning_checks: 0,
      total_critical_checks: 0,
      total_unknown_checks: 0,
      total_acknowledged_checks: 0,
      total_hosts: 0,
      currentModal: null,
      operating_systems: [],
      host_groups: [],
      tooltipTimeout: {},
      total_checks: 0,
      lastUpdated: null,
      showTooltipForRow: null,
      modals: {
        error: false,
        success: false,
        ai: false,
        fix_ai: false,
      },
    }
  },
  async mounted() {
    await this.getHostsWithChecks();
    await this.setFilter();
    await this.getHostGroups();
    await this.getOperatingSystems();
    this.startTimer();
  },
  watch: {
    filteredChecks: {
      handler(newVal) {
        this.totalItems = newVal.length;
      },
      immediate: true  // to run the handler immediately after the watcher is created
    }
  },
  computed: {
    paginatedChecks() {
      const start = (this.currentPage - 1) * this.pageSize;
      const end = this.currentPage * this.pageSize;
      return this.filteredChecks.slice(start, end);
    },

    filteredChecks() {
      let checks = this.checkTableData;

      // Search by check name
      if (this.searchTerm) {
        const term = this.searchTerm.toLowerCase();
        checks = checks.filter(check => check.name && check.name.toLowerCase().includes(term));
      }

      // Filter by selected host groups
      if (this.selectedHostGroups.length) {
        if (this.selectedHostGroups.length) {
          checks = checks.filter(check => {
            return check.hostGroups && check.hostGroups.some(group => this.selectedHostGroups.includes(group.id));
          });
        }
      }

      if (this.selectedCheckName) {
        checks = checks.filter(check => check.check_name === this.selectedCheckName);
      }

      if (this.selectedHostname) {
        checks = checks.filter(check => check.name === this.selectedHostname);
      }

      // Filter by selected operating systems
      if (this.selectedOS && this.selectedOS.length) {
        checks = checks.filter(check => this.selectedOS.includes(check.operatingSystemId)); // Assuming each check has an operatingSystemId property
      }

      return checks;
    }
  },
  beforeDestroy() {
    this.stopTimer();
  },
  methods: {
    runAICommand() {
      if (!this.fix_command) {
        this.handleError('You must at least specify a command');
        return;
      }
      const host_action_queue = {
        host_id: this.host_id,
        action: this.fix_command,
        status: 'pending',
      };
      this.fix_command = null;
      this.autorun = false;
      this.hostname = null;
      this.host_id = null;
      return hostActionQueuesAPIService.create(host_action_queue, this.$route)
      .then((resp) => {
        this.closeModal('fix_ai');
        this.handleSuccess('Successfully created queue action to run command');
      })
      .catch((error) => {
        this.closeModal('fix_ai');
        this.handleError('Error inserting action into run command! ' + error);
      });
    },
    sendAICommand(row) {
      this.fix_command = row.fix_command;
      this.autorun = row.autorun;
      this.hostname = row.name;
      this.host_id = row.host_id;
      this.openModal('fix_ai');
    },
    startTimer() {
      this.timerInterval = setInterval(() => {
        this.timer += 1;

        if (this.timer % 60 === 0) {
          this.updateHostsWithChecks();
        }

      }, 1000);
    },

    stopTimer() {
      if (this.timerInterval) {
        clearInterval(this.timerInterval);
        this.timerInterval = null;
      }
    },

    handleSizeChange(newSize) {
      this.pageSize = newSize;
      this.currentPage = 1;  // reset to the first page when the page size changes
    },
    handleCurrentChange(newPage) {
      this.currentPage = newPage;
    },
    clearAllFilters() {
      this.searchTerm = '';
      this.selectedHostGroups = [];
      this.selectedOS = [];
      this.selectedCheckName = '';
      this.selectedHostname = '';
    },
    setSelectedCheckName(checkName) {
      this.selectedCheckName = checkName;
      this.selectedHostname = '';  // clear hostname filter when a check name is clicked
    },
    setSelectedHostname(hostname) {
      this.selectedHostname = hostname;
      this.selectedCheckName = ''; // clear check name filter when a hostname is clicked
    },
    async getOperatingSystems() {
      try {
        this.availableOS = await operatingSystemAPIService.get(this.$router);
      } catch (e) {
        this.handleError('Error getting operating systems');
      }
    },

    async getHostGroups() {
      try {
        this.availableHostGroups = await hostGroupsAPIService.get(this.$router);
      } catch (e) {
        this.handleError('Error getting host groups');
      }
    },
    clearHostGroups() {
      this.selectedHostGroups = [];
    },

    clearOperatingSystems() {
      this.selectedOS = [];
    },
    async toggleNotifications(row) {
      try {
        await checksToHostsAPIService.update(row.id, row, this.$router);
      } catch (e) {
        this.handleError('Error updating acknowedgement!!');
        return;
      }
      if (row.notifications_enabled) {
        // Logic for enabling notifications (this can be an API call or similar)
        // For demonstration, just updating the notification status
        row.notificationStatus = 'Notifications Enabled';
      } else {
        // Logic for disabling notifications (this can be an API call or similar)
        row.notificationStatus = 'Notifications Disabled';
      }

      this.$set(this.tooltipTimeout, row.id, true);

      // Hide the tooltip after 1.5 seconds
      setTimeout(() => {
        this.$set(this.tooltipTimeout, row.id, false);
      }, 1500);
    },
    getNotificationStatus(row) {
      return row.notificationStatus || '';
    },
    async toggleNotification(row) {
      try {
        await checksToHostsAPIService.update(row.id, row, this.$router);
      } catch (e) {
        this.handleError('Error updating notifications!!');
        return;
      }
      if (row.is_acknowledged) {
        row.acknowledgmentStatus = 'Check Acknowledged';
      } else {
        row.acknowledgmentStatus = 'Check Un-Acknowledged';
      }

      this.$set(this.tooltipTimeout, row.id, true);

      // Hide the tooltip after 1.5 seconds
      setTimeout(() => {
        this.$set(this.tooltipTimeout, row.id, false);
      }, 1500);
    },
    getAcknowledgmentStatus(row) {
      return row.acknowledgmentStatus || '';
    },
    async toggleAcknowledge(row) {
      try {
        await checksToHostsAPIService.update(row.id, row, this.$router);
        row.acknowledgmentStatus = 'Update Successful!';
      } catch (e) {
        row.acknowledgmentStatus = 'Update Failed!';
        this.handleError('Error updating host');
      }
    },
    async updateHostsWithChecks() {
      // pull the host stats
      return hostsAPIService.getHostCountsWithChecks(this.$router)
      .then((resp) => {
        let total_checks = 0;
        let total_acknowledged_checks = 0;
        let total_ok_checks = 0;
        let total_critical_checks = 0;
        let total_warning_checks = 0;
        let total_unknown_checks = 0;
        const acknowledged_checks = [];
        const ok_checks = [];
        const unknown_checks = [];
        const warning_checks = [];
        const critical_checks = [];

        // first set the check data
        for (const row of resp) {
          // get the hostname first
          if (row.checks) {
            for (const check of row.checks) {
              total_checks++;
              const rowItem = {
                name: row.name,
                host_exit_code: row.exit_code
              };
              rowItem.check_name = check.description;
              rowItem.time_entered_state = moment(check.time_entered_state).format('MM/DD/YY HH:MM:ss');
              rowItem.msg = check.checks_to_hosts.msg;
              rowItem.host_id = check.checks_to_hosts.host_id;
              rowItem.check_id = check.checks_to_hosts.check_id;
              rowItem.exit_code = check.checks_to_hosts.exit_code;
              rowItem.notifications_enabled = check.checks_to_hosts.notifications_enabled;
              rowItem.is_acknowledged = check.checks_to_hosts.is_acknowledged;
              if (row.operating_system && row.operating_system.id) {
                rowItem.operatingSystemId = row.operating_system.id;
              }
              rowItem.actions = {};
              rowItem.recommended = {};
              rowItem.hostGroups = row.host_groups;
              rowItem.id = check.checks_to_hosts.id;
              if (rowItem.is_acknowledged) {
                total_acknowledged_checks++;
              }
              if (rowItem.is_acknowledged) {
                acknowledged_checks.push(rowItem);
              } else {
                if (rowItem.exit_code === 0) {
                  total_ok_checks++;
                  ok_checks.push(rowItem);
                } else if (rowItem.exit_code === 1) {
                  total_warning_checks++;
                  warning_checks.push(rowItem);
                } else if (rowItem.exit_code === 2) {
                  total_critical_checks++;
                  critical_checks.push(rowItem);
                } else {
                  total_unknown_checks++;
                  unknown_checks.push(rowItem);
                }
              }
            }
          }
        }
        this.total_checks = total_checks;
        this.total_acknowledged_checks = total_acknowledged_checks;
        this.total_ok_checks = total_ok_checks;
        this.total_acknowledged_checks = total_acknowledged_checks;
        this.total_critical_checks = total_critical_checks;
        this.total_warning_checks = total_warning_checks;
        this.total_unknown_checks = total_unknown_checks;
        this.acknowledged_checks = acknowledged_checks;
        this.ok_checks = ok_checks;
        this.unknown_checks = unknown_checks;
        this.warning_checks = warning_checks;
        this.critical_checks = critical_checks;
        this.setFilter();
      })
      .catch((error) => {
        this.handleError('An error occurred while getting the response back from the system in update hosts with checks: ' + JSON.stringify(error));
      });
    },
    async getHostsWithChecks() {
      // pull the host stats
      return hostsAPIService.getHostCountsWithChecks(this.$router)
      .then((resp) => {
        this.checkTableData = [];

        // first set the check data
        for (const row of resp) {
          // get the hostname first
          if (row.checks) {
            for (const check of row.checks) {
              this.total_checks++;
              const rowItem = {
                name: row.name,
                host_exit_code: row.exit_code
              };

              let autorun = false;
              let fix_command = null;
              if (check.autorun) {
                autorun = check.autorun;
              }
              if (check.fix_command) {
                fix_command = check.fix_command;
              }
              if (check.checks_to_hosts.autorun) {
                autorun = check.checks_to_hosts.autorun;
              }
              if (check.fix_command) {
                fix_command = check.checks_to_hosts.fix_command;
              }
              rowItem.autorun = autorun;
              rowItem.fix_command = fix_command;
              rowItem.check_name = check.description;
              rowItem.time_entered_state = moment(check.time_entered_state).format('MM/DD/YY HH:MM:ss');
              rowItem.msg = check.checks_to_hosts.msg;
              rowItem.host_id = check.checks_to_hosts.host_id;
              rowItem.check_id = check.checks_to_hosts.check_id;
              rowItem.exit_code = check.checks_to_hosts.exit_code;
              rowItem.notifications_enabled = check.checks_to_hosts.notifications_enabled;
              rowItem.is_acknowledged = check.checks_to_hosts.is_acknowledged;
              if (row.operating_system && row.operating_system.id) {
                rowItem.operatingSystemId = row.operating_system.id;
              }
              rowItem.actions = {};
              rowItem.recommended = {};
              rowItem.hostGroups = row.host_groups;
              rowItem.id = check.checks_to_hosts.id;
              if (rowItem.is_acknowledged) {
                this.total_acknowledged_checks++;
              }
              if (rowItem.is_acknowledged) {
                this.acknowledged_checks.push(rowItem);
              } else {
                if (rowItem.exit_code === 0) {
                  this.total_ok_checks++;
                  this.ok_checks.push(rowItem);
                } else if (rowItem.exit_code === 1) {
                  this.total_warning_checks++;
                  this.warning_checks.push(rowItem);
                } else if (rowItem.exit_code === 2) {
                  this.total_critical_checks++;
                  this.critical_checks.push(rowItem);
                } else {
                  this.total_unknown_checks++;
                  this.unknown_checks.push(rowItem);
                }
              }
            }
          }
        }
      })
      .catch((error) => {
        this.handleError('An error occurred while getting the response back from the system in gethostswithchecks: ' + JSON.stringify(error));
      });
    },
    getBackgroundColor(exit_code, acked) {
      if (acked) {
        return '#8d8def';
      }
      switch (exit_code) {
        case 0:
          return '#ade07b';
        case 1:
          return 'yellow';
        case 3:
          return 'orange';
        case 2:
          return '#fa6161';
        default:
          return 'orange';
      }
    },
    askAIAboutProblem(host_id, check_id, hostname, msg, check_name) {
      this.currentModal = AIInterfaceCondensed;
      this.currentQuestion = '';
      this.currentHostID = host_id;
      this.currentCheckID = check_id;
      this.inputText = 'What does: ' + msg + ' mean on server ' + hostname;
      if (check_name) {
        this.inputText += ' and service name ' + check_name + '?';
      }

      // now ask the AI about it, then populate the results
      this.openModal('ai');
    },
    runCommand(text) {
      this.inputText = text;
      this.addItem();
    },
    setFilter(filter) {
      this.checkTableData = [];
      if (filter === 'ok') {
        if (this.ok === false) {
          this.ok = true;
        } else {
          this.ok = false;
        }
      }
      if (filter === 'unknown') {
        if (this.unknown === false) {
          this.unknown = true;
        } else {
          this.unknown = false;
        }
      }
      if (filter === 'critical') {
        if (this.critical === false) {
          this.critical = true;
        } else {
          this.critical = false;
        }
      }
      if (filter === 'warning') {
        if (this.warning === false) {
          this.warning = true;
        } else {
          this.warning = false;
        }
      }
      if (filter === 'acknowledged') {
        if (this.acknowledged === false) {
          this.acknowledged = true;
        } else {
          this.acknowledged = false;
        }
      }

      if (this.critical) {
        this.checkTableData = this.checkTableData.concat(this.critical_checks);
      }
      if (this.unknown) {
        this.checkTableData = this.checkTableData.concat(this.unknown_checks);
      }
      if (this.warning) {
        this.checkTableData = this.checkTableData.concat(this.warning_checks);
      }
      if (this.ok) {
        this.checkTableData = this.checkTableData.concat(this.ok_checks);
      }
      if (this.acknowledged) {
        this.checkTableData = this.checkTableData.concat(this.acknowledged_checks);
      }
    },
    openModal(name) {
      this.modals[name] = true
    },
    closeModal(name) {
      this.currentModal = null;
      this.modals[name] = false;
    },
    handleHostsClick() {
      this.setFilter(null);
      if (this.hosts_active === false) {
        this.hosts_active = true;
      } else {
        this.hosts_active = false;
      }
    },
    parseToObject(str) {
      try {
        const jsonStr = str.replace(/'/g, '"');
        const obj = JSON.parse(jsonStr);
        return obj;
      } catch (e) {
        this.handleError('Failed to parse string');
        return null;
      }
    },
    addItem() {
      this.spinning = true;
      let base_action = null;
      if (this.inputText) {
        this.items.push('You: ' + this.inputText);
        return aiAPIService.process_sentence(this.user_id, this.inputText, this.$router)
        .then((response) => {
          this.inputText = "";
          for (const resp of response.data) {
            if (resp.text) {
              this.items.push(resp.text);
            }
            if (resp.custom && resp.custom.message) {
              this.items.push(resp.custom.message);
              if (resp.custom.type === 'COMMAND') {
                // add the command to the queue
                base_action = resp.custom.base_action;
                // does the user have rights to run this command?
                //if (this.store.commandRights.some(item => item.command_name === base_action)) {
                const running_command = {
                  command_string: resp.custom.base_action,
                  client_action: resp.custom.action,
                  is_running: false,
                  needs_auth: 0,
                  type: resp.custom.type,
                };
                if (resp.custom.needs_auth === 'True') {
                  running_command.needs_auth = 1;
                }
                runningCommandsAPIService.create(running_command, this.$router)
                .then((resp) => {
                })
                .catch((error) => {
                  this.spinning = false;
                  this.error_txt = 'An error occurred while getting response back from the server in addItem: ' + error;
                  this.openModal('error');
                });
                //  } else {
                //    this.items.push('NOT AUTHORIZED TO RUN COMMAND: ' + base_action);
                //   }
              } else if (resp.custom.type === 'HOST_LOOKUP') {
                // compare rights, throw an error if it isn't correct
                const action = this.parseToObject(resp.custom.action);
              } else {
                this.handleError('Unknown Command');
              }
            }
          }
          this.$nextTick(() => {
            // Scroll to the bottom after Vue's DOM updates
            this.$refs.listContainer.scrollTop = this.$refs.listContainer.scrollHeight;
          });
          this.spinning = false;
        })
        .catch((error) => {
          this.spinning = false;
          this.error_txt = 'An error occurred while getting response back from AI: ' + error;
          this.openModal('error');
        });
      }
    },
    getItemClass(index) {
      return index % 2 === 0 ? "grey-bg" : "white-bg";
    },
    getColorStatus(status, acked) {
      if (acked) {
        return 'ACKNOWLEDGED';
      }
      if (status === 0) {
        return 'OK';
      } else if (status === 1) {
        return 'WARNING';
      } else if (status === 2) {
        return 'CRITICAL';
      } else {
        return 'UNKNOWN';
      }
    },
    getTextColor(status, acked) {
      if (acked) {
        return 'white';
      }
      if (status === 0) {
        return 'black';
      } else if (status === 1) {
        return 'black';
      } else if (status === 2) {
        return 'white';
      } else {
        return 'white';
      }
    },
    handleSuccess(text) {
      this.success_txt = text;
      this.openModal('success');
      this.spinning = false;
      setTimeout(() => {
        this.modals['success'] = false;
      }, 1500); // Hide after 1.5 secs
    },
    handleError(error) {
      this.error_txt = error;
      this.openModal('error');
      this.spinning = false;
      setTimeout(() => {
        this.modals['error'] = false;
      }, 1500); // Hide after 1.5 secs
    },
    rowClassName({ row }) {
      if (row.is_acknowledged) {
        return 'exit-code-acknowledged';
      }
      switch (row.exit_code) {
        case 0:
          return 'exit-code-0';
        case 1:
          return 'exit-code-1';
        case 2:
          return 'exit-code-2';
        case 3:
          return 'exit-code-3';
        default:
          return '';
      }
    },
    rowStyle({ row }) {
      if (row.is_acknowledged) {
        return { backgroundColor: '#9eb8f1' };
      }
      switch (row.exit_code) {
        case 0:
          return { backgroundColor: '#DFF0D8' };
        case 1:
          return { backgroundColor: '#FCF8E3' };
        case 2:
          return { backgroundColor: '#F2DEDE' };
        // ... add cases for other exit codes ...
        default:
          return {backgroundColor: '#e3cd3a' };
      }
    }
  }
}
</script>

<style scoped>
.clickable-item {
  cursor: pointer;
  color: rgba(30, 29, 29, 0.31);
}

.clickable-item:hover {
  text-decoration: underline;
}

.filter-container {
  display: flex;
  align-items: center;
  gap: 5px;
  width: 500px;
}
.dropdown-container {
  display: flex;
  align-items: center;
  gap: 5px;
  margin-top: 10px;
  margin-bottom: 10px;
}
.active-filters {
  display: flex;
  gap: 10px;
  margin-bottom: 10px;
}

.active-filters > span {
  display: flex;
  align-items: center;
  background-color: #f2f2f2;
  padding: 5px 10px;
  border-radius: 5px;
  gap: 5px;
}
.el-table .exit-code-0 .cell {
  background-color: #DFF0D8;  /* Green */
}
.el-table .exit-code-1 .cell {
  background-color: #FCF8E3;  /* Yellow */
}
.el-table .exit-code-2 .cell {
  background-color: #F2DEDE;  /* Red */
}
.el-table .exit-code-3 .cell {
  background-color: #e3cd3a;  /* Orange */
}
.el-table .exit-code-0 .cell {
  background-color: #DFF0D8 !important;  /* Green */
}
.el-table .exit-code-acknowledged .cell {
  background-color: #9eb8f1 !important;  /* Blue */
}
.custom-header {
  color: dimgrey;
  font-size: 17px;
  text-align: left;
  margin-bottom: 5px;
  margin-top: 5px;
}
.custom-header-2 {
  color: dimgrey;
  font-size: 15px;
  text-align: left;
  margin-bottom: 5px;
  margin-top: 0;
}
.card-category {
  margin-bottom: 15px;
}
</style>
