import AICyberSecurity from 'src/pages/Dashboard/Pages/AI/AICyberSecurity.vue'
import AIInterface from 'src/pages/Dashboard/Pages/AI/AIInterface.vue'
import Alerts from 'src/pages/Dashboard/Pages/Monitoring/Alerts.vue'
import AssignChecksToHosts from 'src/pages/Dashboard/Pages/Admin/Monitoring/AssignChecksToHosts.vue'
import Assignments from 'src/pages/Dashboard/Pages/Admin/Monitoring/Assignments.vue'
import AuditLogs from 'src/pages/Dashboard/Pages/Reports/AuditLogs.vue'
import AuthGroupsScopesAdmin from 'src/pages/Dashboard/Pages/Users/AuthGroupsScopesAdmin.vue'
import AvailabilityReport from "../pages/Dashboard/Pages/Reports/AvailabilityReport.vue";
import BackupRestore from 'src/pages/Dashboard/Pages/Tools/BackupRestore.vue'
import BackupRestoreGuardian from 'src/pages/Dashboard/Pages/Configuration/BackupRestore.vue';
import CheckView from 'src/pages/Dashboard/Pages/Monitoring/CheckView.vue'
import Checks from 'src/pages/Dashboard/Pages/Admin/Monitoring/Checks.vue'
import ClientCompletedCommands from "../pages/Dashboard/Pages/Admin/ClientCompletedCommands.vue";
import ClientRunningCommands from 'src/pages/Dashboard/Pages/Admin/ClientRunningCommands.vue'
import Collectors from 'src/pages/Dashboard/Pages/Admin/CollectorAssignments.vue'
import CommandMap from 'src/pages/Dashboard/Pages/Admin/CommandMap.vue'
import DashboardLayout from 'src/pages/Dashboard/Layout/DashboardLayout.vue'
import Email from "../pages/Dashboard/Pages/Admin/Email.vue";
import Fixit from 'src/pages/Dashboard/Pages/Tools/Fixit.vue'
import HostGroups from "../pages/Dashboard/Pages/Admin/Monitoring/HostGroups.vue";
import Hosts from 'src/pages/Dashboard/Pages/Admin/Monitoring/Hosts.vue'
import License from "../pages/Dashboard/Pages/Admin/License.vue";
import Login from 'src/pages/Dashboard/Pages/Login.vue'
import NotFound from 'src/pages/GeneralViews/NotFoundPage.vue'
import OperatingSystems from 'src/pages/Dashboard/Pages/Monitoring/OperatingSystems.vue'
import Plugins from 'src/pages/Dashboard/Pages/Monitoring/Plugins.vue'
import Profiles from 'src/pages/Dashboard/Pages/Configuration/Profiles.vue'
import Register from 'src/pages/Dashboard/Pages/Register.vue'
import Router from 'vue-router';
import SNMP from "../pages/Dashboard/Pages/Admin/Monitoring/SNMP.vue";
import SNMPTrapTranslation from 'src/pages/Dashboard/Pages/Configuration/SNMPTrapTranslation.vue'
import Scheduling from "../pages/Dashboard/Pages/Admin/Scheduling.vue";
import ScriptAssignments from 'src/pages/Dashboard/Pages/Configuration/ScriptAssignments';
import ScriptTypes from 'src/pages/Dashboard/Pages/Configuration/ScriptTypes';
import Scripts from 'src/pages/Dashboard/Pages/Configuration/Scripts';
import Support from 'src/pages/Dashboard/Pages/Support/Support.vue'
import UserAdmin from 'src/pages/Dashboard/Pages/Users/UserAdmin.vue'
import UserAuthgroupsAdmin from 'src/pages/Dashboard/Pages/Users/UserAuthgroupsAdmin.vue'
import UserGroupAssignments from "../pages/Dashboard/Pages/Users/UserGroupAssignments.vue";
import UserGroups from "src/pages/Dashboard/Pages/Users/UserGroups.vue";
import { useLoginStore } from '@/stores/loginStore';

const login = {
  path: '/login',
  name: 'Login',
  component: Login
}

const register = {
  path: '/register',
  name: 'Register',
  component: Register
}

const aiMenu = {
  path: '/ai',
  component: DashboardLayout,
  children: [
    {
      path: 'interface',
      name: 'AI Interface',
      component: AIInterface
    },
    {
      path: 'analysis',
      name: 'AI Cyber Security',
      component: AICyberSecurity
    },
  ]
}

const configurationMenu = {
  path: '/configuration',
  component: DashboardLayout,
  children: [
    {
      path: 'check_assignments',
      name: 'Check Assignments',
      component: AssignChecksToHosts
    },
    {
      path: 'collector_assignments',
      name: 'Collector Assignments',
      component: Collectors
    },
    {
      path: 'rights_assignments',
      name: 'Rights Assignments',
      component: Assignments
    },
    {
      path: 'checks',
      name: 'Checks',
      component: Checks
    },
    {
      path: 'command_maps',
      name: 'Command Maps',
      component: CommandMap
    },
    {
      path: 'hosts',
      name: 'Hosts',
      component: Hosts
    },
    {
      path: 'host_groups',
      name: 'Host Groups',
      component: HostGroups
    },
    {
      path: 'operating_systems',
      name: 'Operating Systems',
      component: OperatingSystems
    },
    {
      path: 'plugins',
      name: 'Plugins',
      component: Plugins
    },
    {
      path: 'profiles',
      name: 'Profiles',
      component: Profiles
    },
    {
      path: 'scheduling',
      name: 'Scheduling',
      component: Scheduling
    },
    {
      path: 'script_assignments',
      name: 'Script Assignments',
      component: ScriptAssignments
    },
    {
      path: 'scripts',
      name: 'Scripts',
      component: Scripts
    },
    {
      path: 'script_types',
      name: 'Script Types',
      component: ScriptTypes
    },
    {
      path: 'snmp',
      name: 'SNMP',
      component: SNMP
    },
    {
      path: 'snmp_trap_translation',
      name: 'SNMP Trap Translation',
      component: SNMPTrapTranslation
    },
  ]
}

const monitoringMenu = {
  path: '/monitoring',
  component: DashboardLayout,
  children: [
    {
      path: 'alerts',
      name: 'Alerts',
      component: Alerts
    },
    {
      path: 'check_view',
      name: 'Check View',
      component: CheckView
    },
  ]
}

const reportsMenu = {
  path: '/reports',
  component: DashboardLayout,
  children: [
    {
      path: 'audit_logs',
      name: 'Audit Logs',
      component: AuditLogs
    },
    {
      path: 'ai_completed_commands',
      name: 'Completed Commands',
      component: ClientCompletedCommands
    },
    {
      path: 'ai_running_commands',
      name: 'Client Commands',
      component: ClientRunningCommands
    },
    {
      path: 'availability_report',
      name: 'Availability Report',
      component: AvailabilityReport
    },
  ]
}

const supportMenu = {
  path: '/support',
  component: DashboardLayout,
  children: [
    {
      path: 'support',
      name: 'Support',
      component: Support
    },
  ]
}

const settingsMenu = {
  path: '/settings',
  component: DashboardLayout,
  children: [
    {
      path: 'license',
      name: 'License',
      component: License
    },
    {
      path: 'email',
      name: 'Email',
      component: Email
    },
  ]
}

const toolsMenu = {
  path: '/tools',
  component: DashboardLayout,
  children: [
    {
      path: 'backup_restore',
      name: 'Backup and Restore',
      component: BackupRestore
    },
    {
      path: 'fixit_tools',
      name: 'Fixit Tools',
      component: Fixit
    },
    {
      path: 'backup_restore_guardian',
      name: 'Backup and Restore Guardian',
      component: BackupRestoreGuardian
    },
  ]
}

const usersMenu = {
  path: '/users',
  component: DashboardLayout,
  children: [
    {
      path: 'authentication_groups',
      name: 'Authentication Groups',
      component: UserAuthgroupsAdmin
    },
    {
      path: 'users',
      name: 'Users',
      component: UserAdmin
    },
    {
      path: 'user_scopes',
      name: 'User Scopes',
      component: AuthGroupsScopesAdmin
    },
    {
      path: 'user_groups',
      name: 'User Groups',
      component: UserGroups
    },
    {
      path: 'user_group_assignments',
      name: 'User Group Assignments',
      component: UserGroupAssignments
    },
  ]
}

const routes = [
  login,
  register,
  aiMenu,
  configurationMenu,
  monitoringMenu,
  reportsMenu,
  supportMenu,
  settingsMenu,
  toolsMenu,
  usersMenu,
  {path: '*', component: NotFound}
]

export const router = new Router({
  mode: 'history',
  linkActiveClass: 'active',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    // always scroll to top
    return { top: 0 }
  },
});
router.beforeEach((to, from, next) => {
  const publicPages = ['/login','/register'];
  const authRequired = !publicPages.includes(to.path);
  const store = useLoginStore();
  const loggedIn = store.loggedIn;

  // trying to access a restricted page + not logged in
  // redirect to login page
  if (authRequired && !loggedIn) {
    next('/login');
  } else {
    next();
  }
});
