import CommonAPIService from './CommonHandler';

export class OperatingSystemAPIService {
  constructor() {
    this.CommonAPIService = new CommonAPIService();
  }
  create(operating_system, router) {
    const url = '/api/operating_systems';
    return this.CommonAPIService.postCall(url, operating_system, router);
  }
  delete(id, router) {
    const url = `/api/operating_systems/${id}`;
    return this.CommonAPIService.deleteCall(url, null, router);
  }
  update(id, host, router) {
    const url = `/api/operating_systems/${id}`;
    return this.CommonAPIService.putCall(url, host, router);
  }

  get(router) {
    const url = '/api/operating_systems';
    return this.CommonAPIService.getCall(url, null, router);
  }
}

export default OperatingSystemAPIService;
