import CommonAPIService from 'src/servicehandlers/CommonHandler';

export class ProfilesAPIService {
  constructor() {
    this.CommonAPIService = new CommonAPIService();
  }

  create(profile, router) {
    const url = '/api/profiles';
    return this.CommonAPIService.postCall(url, profile, router);
  }

  update(profile_id, profile, router) {
    const url = `/api/profiles/${profile_id}`;
    return this.CommonAPIService.putCall(url, profile, router);
  }

  delete(id, router) {
    const url = `/api/profiles/${id}`;
    return this.CommonAPIService.deleteCall(url, null, router);
  }

  get(router) {
    const url = '/api/profiles';
    return this.CommonAPIService.getCall(url, null, router);
  }

  getProfileByID(id, router) {
    const url = `/api/profiles/${id}`;
    return this.CommonAPIService.getCall(url, null, router);
  }
  setProfileInformation(id, profileInformation, router) {
    const url = `/api/profiles/${id}/setprofile`;
    return this.CommonAPIService.postCall(url, profileInformation, router);
  }
}

export default ProfilesAPIService;
