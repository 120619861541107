<template>
<div>
  Support
</div>
</template>

<script>
export default {
  name: "Support"
}
</script>

<style scoped>

</style>
