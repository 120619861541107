<template>
  <div>
    <card>
      <div class="row">
        <div class="col-lg-4 text-center">
          <label for="hostLimit">Select A Host</label>
          <br>
          <el-select v-model="selectedHostID" @input="inputEvent('host')" id="hostLimit" style="width: 75%" clearable>
            <el-option v-for="host in hosts" v-bind:key="host.id" :value="host.id" :label="host.name">
              {{host.name}}
            </el-option>
          </el-select>
        </div>
        <div class="col-lg-4 text-center">
          <label for="hostGroupLimit">Select A Host Group</label>
          <br>
          <el-select v-model="selectedHostGroup" @input="inputEvent('hostgroup')" id="hostGroupLimit" style="width: 75%" clearable>
            <el-option v-for="hostgroup in hostGroups" v-bind:key="hostgroup.id" :value="hostgroup.id" :label="hostgroup.name">
              {{hostgroup.name}}
            </el-option>
          </el-select>
        </div>
        <div class="col-lg-4 text-center">
          <label for="timeFrameSelect">Select a Time Frame</label>
          <br>
          <el-select v-model="selectedTimeFrame" id="timeFrameSelect" style="width: 75%" @input="inputEvent('time')">
            <el-option value="4hr" label="Last 4 hours">Last 4 hours</el-option>
            <el-option value="24hr" label="Last 24 hours">Last 24 hours</el-option>
            <el-option value="1week" label="Last Week">Last Week</el-option>
            <el-option value="1month" label="This Month">This Month</el-option>
            <el-option value="1year" label="Last Year">Last Year</el-option>
            <el-option value="custom" label="Custom">Custom</el-option>
          </el-select>
        </div>
      </div>
      <div class="row" v-if="selectedTimeFrame === 'custom'">
        <div class="col-lg-3"></div>
        <div class="col-lg-6">
          <div class="row">
            <div class="col-md-6">
              <label>Start Date</label>
              <br>
              <el-date-picker v-model="customStartTime" type="date" placeholder="Start Date"></el-date-picker>
            </div>
            <div class="col-md-6">
              <label>End Date</label>
              <br>
              <el-date-picker v-model="customEndTime" type="date" placeholder="End Date"></el-date-picker>
            </div>
          </div>
        </div>
      </div>
    </card>
    <card v-if="spinning">
      <div class="row">
        <div class="col-sm-12 text-center">
          <label>Collecting Data</label>
          <br>
          <b-spinner variant="dark"></b-spinner>
        </div>
      </div>
    </card>
    <card v-if="!collected">
      <div class="row">
        <div class="col-lg-12 text-center">
          <h2>Select a host or hostgroup</h2>
        </div>
      </div>
    </card>
    <card>
      <div class="row">
        <div class="col-lg-3"></div>
        <div class="col-lg-6">
          <card :title="returnChartTitle()" :subTitle="returnChartSubtitle()">
            <div class="chart-legend">
              <div class="legend-title">
                <h5 class="legend-color">Total Time</h5>
              </div>
              <div class="legend-item">
                <span class="legend-color">Ok: {{returnTimeAmount(dataValues.ok)}}</span>
              </div>
              <div class="legend-item">
                <span class="legend-color">Critical: {{returnTimeAmount(dataValues.critical)}}</span>
              </div>
              <div class="legend-item">
                <span class="legend-color">Warning: {{returnTimeAmount(dataValues.warning)}}</span>
              </div>
              <div class="legend-item">
                <span class="legend-color">Unknown: {{returnTimeAmount(dataValues.unknown)}}</span>
              </div>
            </div>
            <div id="chartPie" class="ct-slice-pie"></div>
            <div class="row">
              <div class="col-sm-12">
                <label id="graphLabel" style="float: right"></label>
              </div>
            </div>
          </card>
        </div>

        <!-- <div class="col-lg-6">
          <card title="24 Hours Performance" subTitle="Disk Usage">
            <div id="chartPerformance" class="ct-chart"></div>
          </card>
        </div>

        <div class="col-lg-6">
          <card title="System Errors" subTitle="">
            <div id="chartStock" class="ct-chart"></div>
          </card>
        </div>
        <div class="col-lg-6">
          <card title="Alerts Over Time" subTitle="Total Alerts">
            <div id="chartViews" class="ct-chart"></div>
          </card>
        </div> -->
      </div>
    </card>
    <el-dialog
      center
      title="Error"
      :visible.sync="modals.error">
      <span><h5>Error Occurred</h5></span>
      <div class="row">
        <div class="col-md-12"></div>
      </div>
      <div class="row text-center">
        <div class="col-md-12">
          <label>{{ error_text}}</label>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <button class="btn btn-danger" @click="closeModal('error')">Error</button>&nbsp;
      </span>
    </el-dialog>
  </div>
</template>
<script>
import { DatePicker, Dialog, Select, Option, Pagination } from 'element-ui';
import HostsAPIService from "src/servicehandlers/HostsAPIService";
const hostsAPIService = new HostsAPIService();
import HostGroupsAPIService from "src/servicehandlers/HostGroupsAPIService";
const hostgroupsAPIService = new HostGroupsAPIService();
import ChecksToHostsAPIService from "src/servicehandlers/ChecksToHostsAPIService";
const checksToHostsAPIService = new ChecksToHostsAPIService();
import MonitoringResultsAPIService from "src/servicehandlers/MonitoringResultsAPIService";
import {FadeRenderTransition} from "../../../../components";
import AuthLayout from "../AuthLayout.vue";
const monitoringResultsAPIService = new MonitoringResultsAPIService

export default {
  components: {
    [Dialog.name]: Dialog,
    [Select.name]: Select,
    [Option.name]: Option,
    [DatePicker.name]: DatePicker,
  },
  data() {
    return {
      $Chartist: null,
      selectedHostID: null,
      selectState: null,
      selectedHostGroup: null,
      selectedTimeFrame: '4hr', // default selection
      customStartTime: null,
      customEndTime: null,
      hosts: [],
      hostGroups: [],
      serviceChecks: [],
      allAvailabilityData: [],
      error_text: '',
      modals: {
        error: false,
      },
      dataValues: {
        ok: 0,
        ok_percent: 0,
        warning: 0,
        warning_percent: 0,
        critical: 0,
        critical_percent: 0,
        unknown: 0,
        unknown_percent: 0,
        total: 0,
      },
      spinning: false,
      collected: false,
    }
  },
  methods: {
    async fetchData() {
      this.spinning = true;
      // Fetch basic data
      /*
      this.hosts = await axios.get('/api/hosts');
      this.hostGroups = await axios.get('/api/host-groups');
      this.serviceChecks = await axios.get('/api/service-checks');
       */
      // If user selects a custom timeframe, filter data based on that
      // Filter data based on customStartTime and customEndTime
      try {
        this.allAvailabilityData = await monitoringResultsAPIService.getAvailabilityReportByTimeFrame(this.selectedTimeFrame, 'availability', this.selectedHostID, this.selectedHostGroup, this.customStartTime, this.customEndTime, this.$router);
        if (this.selectedTimeFrame !== "4hr"){
          let otherData = await monitoringResultsAPIService.getAvailabilityReportByTimeFrame('4hr', 'availability', this.selectedHostID, this.selectedHostGroup, this.customStartTime, this.customEndTime, this.$router)
          this.allAvailabilityData = this.allAvailabilityData.concat(otherData)
        }
        this.collected = true
      } catch (e) {
        this.handleError('Error getting availability data');
      }

      this.spinning = false;
    },
    initPerformanceChart() {
      const dataPerformance = {
        labels: ['6pm', '9pm', '11pm', '2am', '4am', '8am', '2pm', '5pm', '8pm', '11pm', '4am'],
        series: [
          [1, 6, 8, 7, 4, 7, 8, 12, 16, 17, 14, 13]
        ]
      }

      const optionsPerformance = {
        showPoint: false,
        lineSmooth: true,
        axisX: {
          showGrid: false,
          showLabel: true
        },
        axisY: {
          offset: 40

        },
        low: 0,
        high: 16,
        height: '250px'
      }

      this.$Chartist.Line('#chartPerformance', dataPerformance, optionsPerformance)
    },
    initActivityChart() {
      const data = {
        labels: ['Jan', 'Feb', 'Mar', 'Apr', 'Mai', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
        series: [
          [542, 443, 320, 780, 553, 453, 326, 434, 568, 610, 756, 895],
          [412, 243, 280, 580, 453, 353, 300, 364, 368, 410, 636, 695]
        ]
      }

      const options = {
        seriesBarDistance: 10,
        axisX: {
          showGrid: false
        },
        height: '250px'
      }

      const responsiveOptions = [
        ['screen and (max-width: 640px)', {
          seriesBarDistance: 5,
          axisX: {
            labelInterpolationFnc(value) {
              return value[0]
            }
          }
        }]
      ]

      this.$Chartist.Bar('#chartActivity', data, options, responsiveOptions)
    },
    initPieChart() {
      this.dataValues.ok = 0;
      this.dataValues.warning = 0;
      this.dataValues.critical = 0;
      this.dataValues.unknown = 0;
      this.dataValues.ok_percent = 0;
      this.dataValues.warning_percent = 0;
      this.dataValues.critical_percent = 0;
      this.dataValues.unknown_percent = 0;
      this.dataValues.total = 0;

      for (let i = 0; i < this.allAvailabilityData.length; i++) {
        this.dataValues.ok += parseFloat(this.allAvailabilityData[i]['OK_seconds'])
        this.dataValues.warning += parseFloat(this.allAvailabilityData[i]['warning_seconds'])
        this.dataValues.critical += parseFloat(this.allAvailabilityData[i]['critical_seconds'])
        this.dataValues.unknown += parseFloat(this.allAvailabilityData[i]['unknown_seconds'])
      }


      this.dataValues.total = this.dataValues.ok + this.dataValues.warning + this.dataValues.critical + this.dataValues.unknown
      this.dataValues.ok_percent = Math.round(this.dataValues.ok / this.dataValues.total * 100)
      this.dataValues.warning_percent = Math.round(this.dataValues.warning / this.dataValues.total * 100)
      this.dataValues.critical_percent = Math.round(this.dataValues.critical / this.dataValues.total * 100)
      this.dataValues.unknown_percent = Math.round(this.dataValues.unknown / this.dataValues.total * 100)

      let timeAmount = 0

      switch (this.selectedTimeFrame){
        case "4hr":
          timeAmount = 4 * 60 * 60;
          break
        case "24hr":
          timeAmount = 24 * 60 * 60;
          break
        case "1week":
          timeAmount = 7 * 24 * 60 * 60
          break
      }

      if (timeAmount !== 0) {
        this.dataValues.ok = timeAmount * (this.dataValues.ok_percent / 100)
        this.dataValues.critical = timeAmount * (this.dataValues.critical_percent / 100)
        this.dataValues.warning = timeAmount * (this.dataValues.warning_percent / 100)
        this.dataValues.unknown = timeAmount * (this.dataValues.unknown_percent / 100)
      }

      if (isNaN(this.dataValues.ok_percent) || isNaN(this.dataValues.warning_percent) || isNaN(this.dataValues.critical_percent) || isNaN(this.dataValues.unknown_percent)) {
        this.dataValues.ok = 0
        this.dataValues.critical = 0
        this.dataValues.warning = 0
        this.dataValues.unknown = 0
        document.getElementById('chartPie').innerHTML = "<h4 style='text-align: center'>No Data</h4>"
      } else {
        document.getElementById('chartPie').innerHTML = ""

        const original = [this.dataValues.ok_percent, this.dataValues.critical_percent, this.dataValues.unknown_percent, this.dataValues.warning_percent]

        const filtered = original.filter(value => value !== 0)

        let filteredLabels = []

        if (this.dataValues.ok_percent !== 0) {
          filteredLabels.push('Ok: ' + this.dataValues.ok_percent)
        }
        if (this.dataValues.critical_percent !== 0) {
          filteredLabels.push('Critical: ' + this.dataValues.critical_percent)
        }
        if (this.dataValues.unknown_percent !== 0) {
          filteredLabels.push('Unknown: ' + this.dataValues.unknown_percent)
        }
        if (this.dataValues.warning_percent !== 0) {
          filteredLabels.push('Warning: ' + this.dataValues.warning_percent)
        }

        const data = {
          labels: filteredLabels,
          series: filtered
        };

        var options = {
          height: '320px',
          labelOffset: 80,
          labelInterpolationFnc: function (value) {
            return value[0]
          }
        };

        var responsiveOptions = [
          ['screen and (min-width: 640px)', {
            chartPadding: 50,
            labelOffset: 200,
            labelDirection: 'explode',
            labelInterpolationFnc: function (value) {
              return value + '%';
            }
          }],
          ['screen and (min-width: 1024px)', {
            labelOffset: 70,
            chartPadding: 50
          }]
        ];

        this.$Chartist.Pie('#chartPie', data, options, responsiveOptions);
      }
    },
    initStockChart() {
      const dataStock = {
        labels: ['\'07', '\'08', '\'09', '\'10', '\'11', '\'12', '\'13', '\'14', '\'15'],
        series: [
          [22.20, 34.90, 42.28, 51.93, 62.21, 80.23, 62.21, 82.12, 102.50, 107.23]
        ]
      }
      const optionsStock = {
        lineSmooth: false,
        axisY: {
          offset: 40,
          labelInterpolationFnc(value) {
            return `$${value}`
          }

        },
        low: 10,
        height: '250px',
        high: 110,
        classNames: {
          point: 'ct-point ct-green',
          line: 'ct-line ct-green'
        }
      }
      this.$Chartist.Line('#chartStock', dataStock, optionsStock)
    },
    initViewsChart() {
      const dataViews = {
        labels: ['Jan', 'Feb', 'Mar', 'Apr', 'Mai', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
        series: [
          [542, 443, 320, 780, 553, 453, 326, 434, 568, 610, 756, 895]
        ]
      }
      const optionsViews = {
        seriesBarDistance: 10,
        classNames: {
          bar: 'ct-bar',
        },
        axisX: {
          showGrid: false
        },
        height: '250px',
      }
      const responsiveOptionsViews = [
        ['screen and (max-width: 640px)', {
          seriesBarDistance: 5,
          axisX: {
            labelInterpolationFnc(value) {
              return value[0]
            }
          }
        }]
      ]
      this.$Chartist.Bar('#chartViews', dataViews, optionsViews, responsiveOptionsViews)
    },
    initCharts() {
      /*this.initPerformanceChart()
      this.initStockChart()
      this.initViewsChart()
      this.initActivityChart()*/
      this.initPieChart()
    },
    handleSuccess(text) {
      this.success_txt = text;
      this.openModal('success');
      this.spinning = false;
      setTimeout(() => {
        this.modals['success'] = false;
      }, 1500); // Hide after 1.5 secs
    },
    handleError(error) {
      this.error_text = error;
      this.openModal('error');
      this.spinning = false;
      setTimeout(() => {
        this.modals['error'] = false;
      }, 3000); // Hide after 1.5 secs
    },
    openModal(name) {
      this.modals[name] = true;
    },
    returnChartTitle(){
      if (this.selectedHostID){
        for (let i = 0; i < this.hosts.length; i++){
          if (this.hosts[i].id === this.selectedHostID) return this.hosts[i].name
        }
      } else if (this.selectedHostGroup){
        for (let i = 0; i < this.hostGroups.length; i++){
          if (this.hostGroups[i].id === this.selectedHostGroup) return this.hostGroups[i].name
        }
      } else {
        return "All Hosts"
      }
    },
    returnChartSubtitle(){
      let output = 'Availability Report from '
      switch (this.selectedTimeFrame){
        case '4hr':
          output += 'the last four hours'
          break
        case '24hr':
          output += 'the last 24 hours'
          break
        case '1week':
          output += 'the last week'
          break
        case '1month':
          output += 'the last month'
          break
        case '1year':
          output += 'the last year'
          break
        case 'custom':
          output += 'custom'
          break
      }
      return output
    },
    returnTimeAmount(amount){
      let seconds = Math.round(amount)
      let minutes = Math.round(Math.floor(seconds / 60))
      let hours = Math.round(Math.floor(minutes / 60))
      let days = Math.round(Math.floor(hours / 24))

      seconds %= 60
      minutes %= 60
      hours %= 24

      let output = ''

      if (days > 0) output += days + 'd '
      if (hours > 0) output += hours + "h "
      if (minutes > 0) output += minutes + "m "
      output += seconds + 's'

      return output
    },
    async inputEvent(from){
      if (from === 'host'){
        this.selectedHostGroup = null
        document.getElementById('graphLabel').innerHTML = 'Host ID: ' + this.selectedHostID
      } else if (from === 'hostgroup'){
        this.selectedHostID = null
        document.getElementById('graphLabel').innerHTML = 'Hostgroup ID: ' + this.selectedHostGroup
      }

      if (this.selectedHostID || this.selectedHostGroup) {
        await this.fetchData()
        this.initCharts()
      }
    },
  },
  computed: {
    /*
    hostAvailability() {
      // Logic to calculate host availability
    },
    hostGroupAvailability() {
      // Logic to calculate host group availability
    },
    serviceCheckAvailability() {
      // Logic to calculate service check availability
    }
     */
  },
  async mounted() {
    let chartist = await import('chartist')
    this.$Chartist = chartist.default ? chartist.default : chartist;
    try {
      this.hosts = await hostsAPIService.get(this.$router);
    } catch (e) {
      this.handleError('Error getting hosts');
    }
    try {
      this.hostGroups = await hostgroupsAPIService.get(this.$router);
    } catch (e) {
      this.handleError('Error getting hosts');
    }
    try {
      this.serviceChecks = await checksToHostsAPIService.get(this.$router);
    } catch (e) {
      this.handleError('Error getting hosts');
    }
  },

}
</script>
<style>
.ct-series-a .ct-slice-pie {
  fill: #00ff00; /* green */
}

.ct-series-b .ct-slice-pie {
  fill: #ff0000; /* red */
}

.ct-series-c .ct-slice-pie {
  fill: #f8cb05; /* blue */
}

.ct-series-d .ct-slice-pie {
  fill: #ffff00; /* yellow */
}
.ct-label {
  transform: translateX(10px); /* move labels 10 pixels to the right */
}
.text-1 {
  color: dimgrey;
  margin-top: 10px;
  margin-bottom: 5px;
}
.form-control {
  //width: 300px;
}
.error-txt {
  color: red;
}
.ok-txt {
  color: #00ff00;
}
.warning-txt {
  color: #f6de72;
}
.unknown-txt {
  color: #f8cb05;
}
.host-select {
  width: 350px;
}
</style>
